import * as React from 'react';

import { SafeStripeProvider, StripeContext, StripeElements } from 'app/scriptLoading';

import { Panel, FormModel, MultiContext, useForm, HBox, Body, Part } from 'app2/components';
import { addCreditCard, CardForm, CardModel, AchForm, UsBankAccountModel } from 'app2/views';

import { useParentPaymentMethodsQuery } from './generated';

// note that this operates a little different than a standard form
// with a toggle edit mode.  the card can be removed w/o going into edit
// mode.  edit mode is just for adding a new card.

type PaymentMethods = CardModel & UsBankAccountModel;

function InnerPaymentMethod() {
  const stripe = React.useContext<StripeContext>(MultiContext).stripe;
  const [creditCardResult] = useParentPaymentMethodsQuery();
  const existingCard = creditCardResult.data?.user?.creditCard;
  const existingUsBankAccount = creditCardResult.data?.user?.usBankAccount;

  const form = useForm<PaymentMethods>(() => ({ existingCard, existingUsBankAccount }), [existingCard, existingUsBankAccount]);

  function render() {
    return (
      <Panel icon="CreditCard" title="Payment methods" form={form}  type={form.values.existingCard ? 'display' : 'toggle'} alwaysSave={false} onOk={onOk}>
        <HBox gap='$20' layout={['vbox', 'hbox']}>
          <CardForm />
          <AchForm />
          {renderNoPaymentMethod()}
        </HBox>
      </Panel>
    );
  }

  function renderNoPaymentMethod() {
    if (existingCard || existingUsBankAccount) {
      return;
    }

    return <Part mode='display'><Body>No payment method added</Body></Part>;
  }

  async function onOk(form: FormModel<PaymentMethods>) {
    const [success] = await addCreditCard({ name: form.values.newCard.name, stripe, error: form });
    return success;
  }

  return render();
}

export function PaymentMethod() {
  return <SafeStripeProvider><StripeElements><InnerPaymentMethod /></StripeElements></SafeStripeProvider>;
}

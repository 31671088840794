import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
import { StudentDetailFieldsFragmentDoc } from '../../tabs/gql/generated/studentDetailsFields.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RestrictedStudentDetailsQueryVariables = Types.Exact<{
  token: Types.Scalars['String'];
  id: Types.Scalars['ID'];
}>;

export type RestrictedStudentDetailsQuery = {
  studentByToken?:
    | {
        id: string;
        name: string;
        firstName: string;
        lastName: string;
        nickname?: string | null | undefined;
        dob?: string | null | undefined;
        grade: string;
        automaticGradeUpdate: boolean;
        notes?: string | null | undefined;
        parents: Array<{ attendanceCodeCode?: string | null | undefined; id: string; name?: string | null | undefined; phone?: string | null | undefined; email: string }>;
        emergencyContacts: Array<{
          attendanceCodeCode?: string | null | undefined;
          id: string;
          name?: string | null | undefined;
          relation?: string | null | undefined;
          phone?: string | null | undefined;
          email?: string | null | undefined;
        }>;
        authorizedPickupContacts: Array<
          | { attendanceCodeCode?: string | null | undefined; id: string; name?: string | null | undefined; relation?: string | null | undefined; phone?: string | null | undefined; email?: string | null | undefined }
          | null
          | undefined
        >;
        site?: { id: string; name?: string | null | undefined } | null | undefined;
        classroom?: { id: string; name: string; grade: string } | null | undefined;
        medical?:
          | {
              epiPen?: boolean | null | undefined;
              conditions?: string | null | undefined;
              allergies?: Array<{ allergicTo: string; notes?: string | null | undefined }> | null | undefined;
              medications?: Array<{ medication: string; dose: string; notes?: string | null | undefined }> | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const RestrictedStudentDetailsDocument = gql`
  query RestrictedStudentDetails($token: String!, $id: ID!) {
    studentByToken(token: $token, id: $id) {
      ...studentDetailFields
    }
  }
  ${StudentDetailFieldsFragmentDoc}
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type RestrictedStudentDetailsArgs = Omit<UseQueryArgs<RestrictedStudentDetailsQueryVariables, RestrictedStudentDetailsQuery>, 'query'>;

export function useRestrictedStudentDetailsQuery(options: RestrictedStudentDetailsArgs = {}) {
  return useQuery<RestrictedStudentDetailsQueryVariables, RestrictedStudentDetailsQuery>({ query: RestrictedStudentDetailsDocument, ...options });
}

export type RestrictedStudentDetailsOptions = ExecQueryOptions<RestrictedStudentDetailsQueryVariables>;

export function restrictedStudentDetails(options: RestrictedStudentDetailsOptions) {
  return executeQuery<RestrictedStudentDetailsQuery, RestrictedStudentDetailsQueryVariables>(RestrictedStudentDetailsDocument, options);
}

export type RestrictedStudentDetailsSelections = RestrictedStudentDetailsQuery['studentByToken'];

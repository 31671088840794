import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { omitBy, isUndefined } from 'lodash-es';

import { VBox } from './Box';
import { getFirstFocusableOfParent, hasFocus, getScrollParent, Rect, ScrollWatcher, contains, getZindex } from './dom-utils';
import { formContentClass } from './theme'
import { ComponentProps } from './utils';

export type DropdownTriggerType = HTMLDivElement | HTMLInputElement;
export const DropdownZindex = 50;

export interface DropdownBaseProps extends Omit<ComponentProps<React.InputHTMLAttributes<DropdownTriggerType>>, 'onChange' | 'value' | 'list' | 'min' | 'max'> {
  rightAligned?:boolean;
  offset?:{x?:number, y?:number};
  modal?:boolean;
  // hover opens the modal
  hover?:boolean;
  // by default the dropdown width is the same as the input area
  dropdownWidth?:number;
}

export interface DropdownState {
  open?:boolean;
  dropDownWidth?:string;
}

export enum DropdownFocusType {
  both, // both can get the focus
  trigger, // dropdown never gets the focus
  dropdown // trigger never gets the focus
}

export class DropdownBase<P extends DropdownBaseProps, S extends DropdownState = DropdownState, C = any> extends React.Component<P, S, C> {
  state:S = {} as S;
  openState?:boolean;// mirrors state.open because of react not always updating state immediately
  inline = false;
  modal = false;
  focusType = DropdownFocusType.trigger;
  selectAllOnFocus = true;
  sizeDropdownToTrigger = false;
  triggerRef = React.createRef<DropdownTriggerType>();
  dropdownRef = React.createRef<DropdownTriggerType>();
  scrollWatcher:ScrollWatcher;
  hasDocListeners:boolean;
  zIndex:number;
  unmounted?:boolean;
  forceVisible?:boolean;
  delayTimeout:any;
  inModal:boolean;

  get triggerElement():DropdownTriggerType {
    return this.triggerRef.current;
  }

  get dropdownElement():HTMLElement {
    return this.dropdownRef.current;
  }

  get open() {
    return this.openState;
  }

  set open(value:boolean) {
      this.clearDelayTimeout();

      if (this.props.disabled) {
      return;
    }

    if (this.open == value) {
      return;
    }

    this.openState = value;

    if (this.unmounted) {
      return;
    }

    this.setState({open: value}, () => {
      // if there's two set state calls in a row and the
      // callbacks aren't triggered until both are called, the callback
      // for the first might be invalid
      if (this.state.open != value) {
        return;
      }

      if (value) {
        this.handleOpen();
      }
      else {
        this.handleClose();
      }
    });
  }

  handleOpen() {
    this.addDocListeners();

    // set this before changing focus because changing the focus
    // will generate an event whose handler might be using contains
    //@ts-ignore
    this.dropdownElement.portalParent = this.triggerElement;

    this.setFocusAfterOpen();
    // for some reason componentDidUpdate is not always being called 
    // so call updateDropdownPosition to make sure the dropdon is positioned
    this.updateDropdownPosition();
    this.onOpen();
  }

  handleClose() {
    this.removeDocListeners();
    this.onClose();
  }

  // can be overriden
  onOpen() {
  }

  // can be overriden
  onClose() {
  }

  openDropdown = (delay = false) => {
    this.clearDelayTimeout();

    if (delay) {
      if (!this.open) {
        this.addDelayTimeout();
      }
    }
    else {
      this.open = true;
    }
  }

  toggleDropdown = (event?:React.MouseEvent) => {
    if (this.props.disabled) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();

    const open = !this.open;
    this.open = open;

    if (!open) {
      this.setFocusToTrigger();
    }
  }

  closeDropdown(keepFocus:boolean, delay = false) {
    this.clearDelayTimeout();

    if (delay) {
      if (this.open) {
        this.addDelayTimeout();
      }
    }
    else {
      this.open = false;

      if (keepFocus) {
        this.setFocusToTrigger();
      }
    }
  }

  onDelayOpenClose() {
    this.delayTimeout = null;
    this.open = !this.open;
  }

  addDelayTimeout() {
    this.clearDelayTimeout();
    this.delayTimeout = setTimeout(() => this.onDelayOpenClose(), 300);
  }

  clearDelayTimeout() {
    if (!this.delayTimeout) {
      return;
    }

    clearTimeout(this.delayTimeout);
    this.delayTimeout = null;
  }

  componentDidMount() {
    this.scrollWatcher = new ScrollWatcher({onScroll:this.updateDropdownPosition, checkScrollableSize:true})
    this.scrollWatcher.watch(this.triggerElement);

    let e = this.triggerElement as HTMLElement;
    while (e && !this.inModal) {
      this.inModal = e.hasAttribute('data-modal')
      e = e.parentElement;
    }

    this.zIndex = (this.props.zIndex as number) || Math.max(getZindex(this.triggerElement) + 1, DropdownZindex);
    this.updateWidth();
    this.updateDropdownPosition();
  }

  componentDidUpdate(prevProps:P, prevState:S) {
    this.updateWidth();
    this.updateDropdownPosition();
  }

  componentWillUnmount() {
    this.unmounted = true;

    this.clearDelayTimeout();

    if (this.open) {
      this.handleClose();
    }
    
    this.scrollWatcher.unwatch();
    this.removeDocListeners();
  }

  render() {
    if (this.inline) {
      return this.renderInlineStyle();
    }

    if (this.modal) {
      return this.renderModalStyle();
    }

    return this.renderPopupStyle();
  }

  renderPopupStyle() {
    return this.renderOuterContainer(this.state.open && ReactDOM.createPortal(this.renderOuterDropdown({position:'fixed'}), this.dropdownContainer));
  }

  renderInlineStyle() {
    const {width, rightAligned, dropdownWidth, zIndex, offset, ...remaining} = this.props;

    return <VBox vAlign='justify' display='inline-flex'>
      {this.renderOuterTrigger(remaining)}
      <div>{this.renderOuterDropdown()}</div>
    </VBox>
  }

  renderModalStyle() {
    return this.renderOuterContainer(
      this.state.open &&
        ReactDOM.createPortal(<div style={{position: 'fixed', display:'flex', alignItems: 'center', justifyContent: 'center', left:0, top:0, width: '100%', height: '100%', zIndex:DropdownZindex + 99999}}>
          <div style={{position: 'absolute', left:0, top:0, width: '100%', height: '100%', background: 'black', opacity: .7}} />
          {this.renderOuterDropdown({maxWidth:'100%', padding: '16px'})}
        </div>, document.body)
    );
  }

  renderOuterContainer(children?:React.ReactNode) {
    const props = this.props;
    const style = this.props.style;
    const minHeight = props.minHeight || props.height || style?.minHeight || style?.height;
    const {width, maxWidth, rightAligned, dropdownWidth, flex, m, ml, mr, mt, mb, mx, my, offset, ...remaining} = props;

    return <VBox width={this.triggerWidth} maxWidth={maxWidth} minHeight={minHeight} flex={flex} display='inline-flex' {...omitBy({m, ml, mr, mt, mb, mx, my}, isUndefined)}>
      {this.renderOuterTrigger(remaining)}
      {children}
    </VBox>
  }

  renderOuterTrigger(triggerProps:DropdownBaseProps) {
    return <div ref={this.triggerRef} style={{minHeight: '100%', width: '100%'}} onFocus={this.onTriggerFocus} onBlurCapture={this.onTriggerBlur} onBlur={this.onTriggerBlur} onMouseDown={this.onTriggerMouseDown} onMouseMove={this.onMouseMove} onMouseLeave={this.onMouseMove} onKeyDown={this.onTriggerKeyDown.bind(this)}>
      {this.renderTrigger(triggerProps)}
    </div>
  }

  renderTrigger(triggerProps:DropdownBaseProps):React.ReactElement {
    // override
    return null;
  }

  renderOuterDropdown(styles?:any) {
    return <div ref={this.dropdownRef} data-test='Popup' style={{zIndex:this.zIndex, width: this.listWidth, outline: 'none', ...styles}} onBlurCapture={this.onDropdownBlur} onWheel={this.onDropdownScroll} 
      onKeyUp={this.onEscapeKeyUp} onMouseDown={event => event.stopPropagation()} tabIndex={this.isTriggerFocusType ? undefined : 0}>
      {this.renderDropdown()}
    </div>
  }

  renderDropdown():React.ReactElement {
    // override
    return null;
  }

  updateWidth() {
    if (!this.sizeDropdownToTrigger) {
      return;
    }

    if (!this.triggerElement) {
      return;
    }

    const dropDownWidth = this.triggerElement.offsetWidth + 'px';

    if (this.state.dropDownWidth !== dropDownWidth) {
      this.setState({dropDownWidth});
    }
  }

  updateDropdownPosition = () => {
    if (!this.open || this.inline || this.modal) {
      return;
    }

    const dropdown = this.dropdownRef.current;

    if (!dropdown) {
      return;
    }

    const display = dropdown.style.display;

    // set the dropdown to the default position
    // before measuring it
    dropdown.style.left = '0';
    dropdown.style.right = 'unset';
    dropdown.style.top = '1px';
    dropdown.style.bottom = 'unset';

    const dropdownRect = this.dropdownRect;

    // hide the dropdown before measuring the available scroll area
    dropdown.style.display = 'none';

    const scrollRect = this.scrollerRect;
    const triggerRect = this.triggerRect;

    this.positionHorizontal(dropdown, scrollRect, triggerRect, dropdownRect);
    this.positionVertical(dropdown, scrollRect, triggerRect, dropdownRect);

    dropdown.style.display = display;
  }

  positionVertical(dropdown:HTMLElement, scrollRect:Rect, triggerRect:Rect, dropdownRect:Rect) {
    // the default position of the dropdown is below the trigger
    // aligned on the left side of the trigger

    // if the dropdown won't fit below the trigger within the
    // scrolling area, try to move it bottom aligned with the 
    // top of the trigger, but only do it if there's enough space above
    // otherwise leave it clipped at the bottom

    // if positioned below - the default
    const dropdownBottom = triggerRect.bottom + 1 + dropdownRect.height + this.offsetY;

    // if positioned above
    const dropdownTop = triggerRect.top - dropdownRect.height + 1 + this.offsetY;

    // the iphone scrolls our main document even when we tell it not too and have to
    // account for that when positioning the dropdown
    const iPhoneHack = document.body.getBoundingClientRect().top;

    let top = 0;
    
    if (dropdownBottom > scrollRect.bottom && dropdownTop > scrollRect.top) {
      top = ((-iPhoneHack) + triggerRect.top - dropdownRect.height - 1) + this.offsetY;
    }
    else {
      top = ((-iPhoneHack) + triggerRect.bottom + 1) + this.offsetY;
    }

    if (this.forceVisible) {
      const r = this.visibleArea
      top = Math.max(r.top, Math.min(r.bottom - dropdownRect.height, top));
    }

    dropdown.style.top = top + 'px';
  }

  positionHorizontal(dropdown:HTMLElement, scrollRect:Rect, triggerRect:Rect, dropdownRect:Rect) {
    // if the dropdown won't fit on the right side of the
    // scrolling area, try it right aligned with the trigger

    // if positioned to the right
    const dropdownRight = triggerRect.left + dropdownRect.width;

    // if positioned to the left
    const dropdownLeft = triggerRect.right - dropdownRect.width;

    const invert = this.props.rightAligned 
      ? dropdownLeft < scrollRect.left
      : dropdownRight > scrollRect.right

    const rightAlign = invert ? !this.props.rightAligned : this.props.rightAligned;

    if (rightAlign) {
      this.positionRight(dropdown, scrollRect, triggerRect, dropdownRect);
    }
    else {
      this.positionLeft(dropdown, scrollRect, triggerRect, dropdownRect);
    }
  }

  positionLeft(dropdown:HTMLElement, scrollRect:Rect, triggerRect:Rect, dropdownRect:Rect) {
    dropdown.style.left = Math.max(triggerRect.left + this.offsetX, 0) + 'px';
    dropdown.style.right = 'unset';
  }

  positionRight(dropdown:HTMLElement, scrollRect:Rect, triggerRect:Rect, dropdownRect:Rect) {
    const right = scrollRect.right - triggerRect.right;
    const left = scrollRect.width - right - dropdownRect.width;

    dropdown.style.left = 'unset';
    dropdown.style.right = (right + Math.min(left < 0 ? left : 0) + this.offsetX) + 'px'
  }

  onTriggerMouseDown = (event:React.MouseEvent) => {
    this.openDropdown();

    if (this.selectAllOnFocus && !this.triggerHasFocus) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  onTriggerKeyDown(event:React.KeyboardEvent<DropdownTriggerType>) {
    if (this.props.disabled) {
      return;
    }

    if (event.key == 'Tab') {
      // allow tab to fall through so the default focus switching happens
      this.closeDropdown(false);
    }
    else
    if (event.key == 'Escape' && this.open) {
      // close will occur in keyup in order to blow react-js popup which
      // listens to keyup instead of keydown to close modals (which
      // we want to block when we are closing a dropdown in a modal)
      //this.closeDropdown(true);

      // don't allow escape to fall through so parent dropdowns are not closed
      event.preventDefault();
      event.stopPropagation();
    }
    else
    if (this.dropdownElement && this.forwardDropdownEvent(event)) {
      const keyboardEvent = new KeyboardEvent(event.type, event);

      this.dispatchDropdownEvent(keyboardEvent);

      event.preventDefault();
      event.stopPropagation();
    }
    else 
    if (event.key == 'ArrowDown') {
      this.open = true;

      event.preventDefault();
      event.stopPropagation();
    }
    else
    // when the user starts typing text (detected by key length), the 
    // dropdown should show but only if we have the focus (this method can be
    // called when we don't have the focus, such as through rendering updates).
    if (!this.open && this.keyEventIsText(event) && this.triggerHasFocus) {
      //this is eating the keystroke, so we delay it
      setTimeout(() => this.open = true, 10);
    }

    this.props.onKeyDown?.(event);
  }

  onTriggerFocus = (event:React.FocusEvent<HTMLElement>) => {
    if (this.isDropdown(event.relatedTarget)) {
      return;
    }

    if (this.selectAllOnFocus) {
      this.selectAllInTrigger();
    }
  }

  onTriggerBlur = (event:React.FocusEvent<DropdownTriggerType>) => {
    if (this.isDropdown(event.relatedTarget)) {
      if (this.isTriggerFocusType) {
        this.setFocusToTrigger();
      }

      event.stopPropagation();

      return;
    }

    this.closeDropdown(false);
    this.onBlur();
    this.props.onBlur?.(event as React.FocusEvent<DropdownTriggerType>);
  }

  onBlur() {
    //override if needed
  }

  onEscapeKeyUp = (event:React.KeyboardEvent<DropdownTriggerType> | KeyboardEvent) => {
    if (event.key != 'Escape' || !this.open) {
      return;
    }

    // if we got this through capturing, its possible the event is meant for a 
    // dropdown that we contain.  in that case ignore it.  the only reason we
    // process capturing phase is to work around reacts popup

    if (event.eventPhase == Event.CAPTURING_PHASE && !this.isTrigger(event.target)) {
      return;
    }

    this.closeDropdown(true);

    // don't allow escape to fall through so parent dropdowns are not closed
    event.preventDefault();
    event.stopPropagation();
  }

  onDropdownBlur = (event:React.FocusEvent<DropdownTriggerType>) => {
    if (event.relatedTarget && (this.isTrigger(event.relatedTarget) || this.isDropdown(event.relatedTarget))) {
      return;
    }

    this.closeDropdown(false);
    this.props.onBlur?.(event as React.FocusEvent<DropdownTriggerType>);
  }

  onDocumentMouseDown = (event:React.MouseEvent<HTMLElement> | MouseEvent) => {
    if (this.isTrigger(event.target) || this.isDropdown(event.target)) {
      return;
    }

    this.closeDropdown(false);
  }

  // we use fixed/absolute positioning on the dropdown so the 
  // browsers overscroll behavior doesn't work.  this means when
  // the dropdown has been fulled scrolled, scroll events that should
  // then go to its parent container to cause the parent container
  // to scroll do not.  it causes scrolling to appear broken.
  // to fix we have to mimic this beahvior by catching scroll events
  // detecting that the dropdown is scrolled all the way, and if so
  // send the event to the parent.

  onDropdownScroll = (event:React.WheelEvent) => {
    if (this.inline || this.modal) {
      return;
    }
    
    if (!this.dropdownElement.contains(event.target as HTMLElement)) {
      return;
    }

    // this is the scroller getting the wheel event, which can be
    // the dropdown or 
    const scroller = getScrollParent(event.target as Node);

    if (!this.scrollWatcher.scroller.contains(scroller)) {
      return;
    }

    // if the detected scroller is above us then def pass on the event
    // else its scrollable and let it handle it (we dont even check if theres
    // any scroll room left because its hard to do overflow scroll behavior
    // that is as good as a browser, and it feels a bit jarring to do it
    if (this.dropdownElement.contains(scroller)) {
      return;
    }

    this.scrollWatcher.scroller.scrollBy(event.deltaX, event.deltaY);
  }

  onMouseMove = (event:MouseEvent | React.MouseEvent) => {
    if (!this.props.hover) {
      return
    }

    const target = event.type == 'mouseleave' ? event.relatedTarget : event.target;
    const inside = (this.isTrigger(target) || this.isDropdown(target));

    if (inside) {
      this.openDropdown(true)
    }
    else {
      this.closeDropdown(false, true)
    }
  }

  get listWidth() {
    if (this.props.dropdownWidth) {
      return this.props.dropdownWidth;
    }

    return this.state.dropDownWidth;
  }

  get triggerWidth() {
    return this.props.width || this.props.style?.width;
  }

  get triggerHasFocus() {
    return hasFocus(this.triggerElement);
  }

  get triggerRect() {
    return new Rect(this.triggerElement.getBoundingClientRect());
  }

  get dropdownRect() {
    return new Rect(this.dropdownRef.current.getBoundingClientRect());
  }

  get dropdownContainer() {
    // hack so we don't put the dropdown in a form because
    // it will throw off the form styling

    let element = getScrollParent(this.triggerElement);

    while (element.classList.contains(formContentClass)) {
      element = getScrollParent(element.parentElement);
    }

    return element == document.documentElement ? document.body : element;
  }

  get scrollTop() {
    return this.scrollWatcher.scroller.scrollTop;
  }

  get scrollerParent() {
    return getScrollParent(this.scrollWatcher.scroller.parentElement);
  }

  // we try to keep the dropdown within the scrollable area
  // but in modals that can be small so sometimes we use the visible area
  get scrollerRect() {
    return new Rect(this.scrollWatcher.scroller.getBoundingClientRect());
  }

  // used when trying to force the dropdown to be visible
  get visibleArea() {
    // if we are in a modal then the bounding area is the screen not the form
    return this.inModal ? new Rect(document.body.getBoundingClientRect()) : this.scrollerRect;
}

  setFocusAfterOpen() {
    if (!this.isDropdownFocusType) {
      this.setFocusToTrigger();
    }
    else {
      this.setFocusToDropdown();
    }
  }

  addDocListeners() {
    if (this.hasDocListeners) {
      return;
    }

    this.hasDocListeners = true;
    document.addEventListener('keyup', this.onEscapeKeyUp, true);
    document.addEventListener('mousemove', this.onMouseMove, true);
    document.addEventListener('mousedown', this.onDocumentMouseDown, true);
  }

  removeDocListeners() {
    if (!this.hasDocListeners) {
      return;
    }

    this.hasDocListeners = false;
    document.removeEventListener('keyup', this.onEscapeKeyUp, true);
    document.removeEventListener('mousemove', this.onMouseMove, true);
    document.removeEventListener('mousedown', this.onDocumentMouseDown, true);
  }

  setFocusToTrigger() {
    getFirstFocusableOfParent(this.triggerElement)?.focus();
  }

  setFocusToDropdown() {
    getFirstFocusableOfParent(this.dropdownElement)?.focus();
  }

  selectAllInTrigger() {
    const input = this.triggerElement?.querySelector('input');

    input?.setSelectionRange(0, input?.value.length);
  }

  isDropdown(target:Node | EventTarget):boolean {
    return contains(this.dropdownElement, target as HTMLElement);
  }

  isTrigger(target:Node | EventTarget):boolean {
    return this.triggerElement?.contains(target as Node)
  }

  get isTriggerFocusType() {
    return this.focusType == DropdownFocusType.trigger && this.isTriggerFocusable && !this.modal;
  }

  get isDropdownFocusType() {
    return this.focusType == DropdownFocusType.dropdown || !this.isTriggerFocusable || this.modal;
  }

  get isTriggerFocusable() {
    return getFirstFocusableOfParent(this.triggerElement) != null;
  }

  get offsetX() {
    return this.props.offset?.x || 0;
  }

  get offsetY() {
    return this.props.offset?.y || 0;
  }

  forwardDropdownEvent(event:React.KeyboardEvent) {
    return event.key == 'ArrowUp' || event.key == 'ArrowDown' || event.key == 'Enter';
  }

  dispatchDropdownEvent(event:KeyboardEvent) {
    getFirstFocusableOfParent(this.dropdownElement)?.dispatchEvent(event);
  }

  keyEventIsText(event:React.KeyboardEvent) {
    return event.key.length == 1 && !event.altKey && !event.ctrlKey && !event.metaKey;
  }
}

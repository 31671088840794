import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompanySetAsContactMutationVariables = Types.Exact<{
  company: Types.Scalars['ID'];
  memberId: Types.Scalars['ID'];
}>;

export type CompanySetAsContactMutation = { companySetContact?: { entity?: { id: string } | { id: string } | null | undefined } | null | undefined };

export const CompanySetAsContactDocument = gql`
  mutation companySetAsContact($company: ID!, $memberId: ID!) {
    companySetContact(company: $company, member: $memberId) {
      entity {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type CompanySetAsContactOptions = ExecMutationOptions<CompanySetAsContactMutationVariables>;

export function companySetAsContact(options: CompanySetAsContactOptions) {
  return executeMutation<CompanySetAsContactMutation, CompanySetAsContactMutationVariables>(CompanySetAsContactDocument, options);
}

import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminHistoryQueryVariables = Types.Exact<{
  type: Types.Scalars['String'];
  id: Types.Scalars['ID'];
}>;

export type AdminHistoryQuery = {
  history?:
    | Array<{
        id: string;
        createdAt?: string | null | undefined;
        type?: string | null | undefined;
        attributes?: { [key: string]: any } | null | undefined;
        user?: { id: string; name?: string | null | undefined } | null | undefined;
      }>
    | null
    | undefined;
};

export const AdminHistoryDocument = gql`
  query AdminHistory($type: String!, $id: ID!) {
    history(type: $type, id: $id) {
      id
      createdAt
      user {
        id
        name
      }
      type
      attributes
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminHistoryArgs = Omit<UseQueryArgs<AdminHistoryQueryVariables, AdminHistoryQuery>, 'query'>;

export function useAdminHistoryQuery(options: AdminHistoryArgs = {}) {
  return useQuery<AdminHistoryQueryVariables, AdminHistoryQuery>({ query: AdminHistoryDocument, ...options });
}

export type AdminHistoryOptions = ExecQueryOptions<AdminHistoryQueryVariables>;

export function adminHistory(options: AdminHistoryOptions) {
  return executeQuery<AdminHistoryQuery, AdminHistoryQueryVariables>(AdminHistoryDocument, options);
}

export type AdminHistorySelections = AdminHistoryQuery['history'][0];

(AdminHistoryDocument as any).additionalTypenames = ['HistoryVersion'];

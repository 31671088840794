import * as React from 'react';

import { Course } from 'app2/api';
import { Box, FormLabel } from 'app2/components';

interface Props {
  course: Pick<Course, 'courseTags'>;
}

export function CourseCategory(props: Props) {
  const course = props.course;

  function render() {
    return !course?.courseTags?.[0]
      ? <></>
      : <Box p="$4" mb="$16" bg="courseDay" borderRadius="standard">
        <FormLabel>{course?.courseTags?.[0]?.name}</FormLabel>
      </Box>
  }

  return render();
}

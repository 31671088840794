import * as React from 'react';
import { useHistory } from 'react-router';

import { useIsShieldLoading } from '../shield';

import { TabStripHookProps, TabsHandle } from './TabStripHookProps';
import { useSelectedTab } from './useSelectedTab';

// useTabs is a hook function that doess the following
// - determine if the selected tab prop should be used or internal state
// - removes undefined and null tabs (which happens when doing conditional rendering of tabs)
// - loads/saves the selected tab from a preference
// - notifies a onChange callback

export function useTabStrip(props:TabStripHookProps) {
  const {baseUrl, urlParameter} = props;
  const usingUrlParameter = !(!urlParameter || !baseUrl);
  const history = useHistory();
  const loading = useIsShieldLoading();

  const tabState = props.tabState || useSelectedTab(props)

  // checks to make sure that if we are using the url to control the selected
  // tab that its correct, except don't do this while loading as the tabs shown
  // might be dependent on the data.  we always put the tab name in the url
  // even for the first tab, else we can't tell the difference between coming to
  // the page with no tab in the url vs. hitting the browser back to go to the first tab
  let replaceUrl:string = '';

  if (!loading && usingUrlParameter && (tabState.tabNameFromUrl != tabState.tabs[tabState.selected]?.name && tabState.tabNameFromUrl != tabState.tabs[tabState.selected]?.name + props.urlHideSuffix)) {
    const tabName = tabState.tabs.length ? tabState.tabs[tabState.selected]?.name : '';
    replaceUrl = baseUrl.replace(':' + urlParameter, tabName);
  }
  
  React.useEffect(() => {
    if (replaceUrl) {
      history.replace(mergeQueryIntoUrl(replaceUrl, history), history.location.state)
    }
  });

  const selectTab = React.useMemo(() => (newTab:number, navState:any = undefined) => {
    if (tabState.selected == newTab || (tabState.selected === undefined && newTab === 0) || !tabState.tabs?.[newTab]) {
      return;
    }

    tabState.selected = newTab;
    tabState.setTab(newTab);

    if (baseUrl) {
      const tabUrl = tabState.tabs[newTab].to;
      const to = props.preserveQuery === false ? tabUrl : mergeQueryIntoUrl(tabUrl, history);

      history[props.history || 'push'](to, navState)

      // didn't navigate so dont propogate onChange
      if (history.location.pathname != to) {
        return;
      }
    }

    props.onChange?.(newTab);
  }, [tabState.selected, tabState.setTab]);

  const ref = props.tabsRef || React.useRef();
  React.useImperativeHandle<TabsHandle, TabsHandle>(ref, () => {
    return {selected:tabState.selected, setTab:selectTab}
  }, [selectTab, tabState.selected]);

  return {selectedTab: tabState.selected, normalizedTabs: tabState.tabs, selectTab, hideTabs:tabState.hideTabs, tabState};
}

function mergeQueryIntoUrl(url:string, history:ReturnType<typeof useHistory>) {
  const parts = url.split('#');
  const hash = parts[1] || history.location.hash.substring(1);
  return `${parts[0]}${history.location.search || ''}${hash ? '#' + hash : ''}`
}

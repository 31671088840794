import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
import { MessageFieldsFragmentDoc } from './messageFields.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type MessageThreadQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  entityMessages?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type MessageThreadQuery = {
  msgThread?:
    | {
        id: string;
        entityMember?: boolean | null | undefined;
        broadcast?: boolean | null | undefined;
        labels?: Array<{ id: string; name?: string | null | undefined; email?: string | null | undefined }> | null | undefined;
        messages?:
          | Array<{
              id: string;
              threadId?: string | null | undefined;
              threadScope?: string | null | undefined;
              createdAt: string;
              preferredService?: Types.MessageServiceKind | null | undefined;
              systemMessage?: boolean | null | undefined;
              broadcast?: boolean | null | undefined;
              read?: boolean | null | undefined;
              subject?: string | null | undefined;
              inReplyTo?: { id: string } | null | undefined;
              from?:
                | {
                    role?: string | null | undefined;
                    from: { __typename: 'SharedUser'; id: string; name?: string | null | undefined };
                    students?: Array<{ id: string; name?: string | null | undefined }> | null | undefined;
                  }
                | null
                | undefined;
              to?:
                | Array<{
                    smsStatus?: Types.MessageServiceDeliveryStatus | null | undefined;
                    emailStatus?: Types.MessageServiceDeliveryStatus | null | undefined;
                    role?: string | null | undefined;
                    to?: { __typename: 'SharedUser'; id: string; name?: string | null | undefined } | null | undefined;
                    toEntity?: { name?: string | null | undefined } | null | undefined;
                    students?: Array<{ id: string; firstNameWithNickname?: string | null | undefined }> | null | undefined;
                  }>
                | null
                | undefined;
              cc?:
                | Array<{
                    smsStatus?: Types.MessageServiceDeliveryStatus | null | undefined;
                    emailStatus?: Types.MessageServiceDeliveryStatus | null | undefined;
                    role?: string | null | undefined;
                    to?: { __typename: 'SharedUser'; id: string; name?: string | null | undefined } | null | undefined;
                    toEntity?: { name?: string | null | undefined } | null | undefined;
                    students?: Array<{ id: string; firstNameWithNickname?: string | null | undefined }> | null | undefined;
                  }>
                | null
                | undefined;
              bcc?:
                | Array<{
                    smsStatus?: Types.MessageServiceDeliveryStatus | null | undefined;
                    emailStatus?: Types.MessageServiceDeliveryStatus | null | undefined;
                    role?: string | null | undefined;
                    to?: { __typename: 'SharedUser'; id: string; name?: string | null | undefined } | null | undefined;
                    toEntity?: { name?: string | null | undefined } | null | undefined;
                    students?: Array<{ id: string; firstNameWithNickname?: string | null | undefined }> | null | undefined;
                  }>
                | null
                | undefined;
              labels?: Array<{ id: string; name?: string | null | undefined }> | null | undefined;
              body?: { text?: string | null | undefined; html?: string | null | undefined } | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const MessageThreadDocument = gql`
  query MessageThread($id: ID!, $entityMessages: Boolean) {
    msgThread(id: $id, entityMessages: $entityMessages) {
      id
      labels {
        id
        name
        email
      }
      entityMember
      broadcast
      messages {
        id
        ...messageFields
        inReplyTo {
          id
        }
      }
    }
  }
  ${MessageFieldsFragmentDoc}
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type MessageThreadArgs = Omit<UseQueryArgs<MessageThreadQueryVariables, MessageThreadQuery>, 'query'>;

export function useMessageThreadQuery(options: MessageThreadArgs = {}) {
  return useQuery<MessageThreadQueryVariables, MessageThreadQuery>({ query: MessageThreadDocument, ...options });
}

export type MessageThreadOptions = ExecQueryOptions<MessageThreadQueryVariables>;

export function messageThread(options: MessageThreadOptions) {
  return executeQuery<MessageThreadQuery, MessageThreadQueryVariables>(MessageThreadDocument, options);
}

export type MessageThreadSelections = MessageThreadQuery['msgThread'];

import * as React from 'react';
import { useParams } from 'react-router';

import { providerPreferences, CourseKind, CourseStatus } from 'app2/api';
import { StandardTableActions } from 'app2/components';
import { useCurrentUser } from 'app2/views/shared-public';
import { AppendRowAction, ApproveCourseAction, CancelCourseAction, CreateActivities, CloseEnrollmentAction, OpenEnrollmentAction, CoursesTableWithTabs, DeleteRowAction, useCourseTableState, SaveAndTransition } from 'app2/views/shared';

import { CompanyRouteParams } from '../companyRoutes';
import { useCompanyQuery, useBasicCompanyQuery } from '../shared'

import { FinalizeCourseAction } from './FinalizeCourseAction';
import { companyCourseTables } from './companyColumns';

const COMPANY_COURSES_TABLE_PREFS_VERSION = '3';

interface Props {
  kind:CourseKind;
}

export function CompanyCoursesTable(props:Props) {
  const { company:companyId } = useParams<CompanyRouteParams>();
  const { company } = useCompanyQuery(useBasicCompanyQuery);  

  const { user } = useCurrentUser();
  const state = useCourseTableState(providerPreferences.companyPreferences(companyId).tablePreferences(props.kind));
  const queryVars = {companyId};

  function render() {
    return <CoursesTableWithTabs 
      kind={props.kind}
      tabs={renderTabs()}
      queryVars={queryVars}
      state={state}
      defaultValues={getCourseDefaults()}
      prefsVersion={COMPANY_COURSES_TABLE_PREFS_VERSION}
    />
  }
  
  function renderTabs() {
    const approved = company?.nonPartnerApproved;
  
    const tabs = [{
      ...companyCourseTables.requests,
      primaryActions: renderPrimaryActions(),
      secondaryActions:renderSecondaryActions(false),
      saveActions: renderSaveActions()
    }, {
      ...companyCourseTables.published, 
      primaryActions: renderPrimaryActions(),
      secondaryActions:renderSecondaryActions(false),
      saveActions: renderSaveActions()
    }];

    if (approved) {
      tabs.push({
        ...companyCourseTables.draft, 
        primaryActions: renderPrimaryActions(),
        secondaryActions:renderSecondaryActions(true),
        saveActions: renderSaveActions()
      });
    }

    return tabs;
  }
  
  function renderPrimaryActions() {
    const approved = company?.nonPartnerApproved;
    return state.editing || !approved ? undefined : <CreateActivities kind={props.kind} />;
  }

  function renderSecondaryActions(drafts:boolean) {
    return {editing:drafts ? renderEditingActions() : [], display:renderNonEditingActions()};
  }

  function renderEditingActions() {
    return [
      <DeleteRowAction table={state.table.current} kind={props.kind} />,
      <AppendRowAction table={state.table.current} kind={props.kind} />,
    ]
  }

  function renderNonEditingActions() {
    return [
      <CancelCourseAction table={state.table.current} kind={props.kind} />,
      <FinalizeCourseAction table={state.table.current} />,
      <ApproveCourseAction table={state.table.current} kind={props.kind} />,
      <OpenEnrollmentAction table={state.table.current} kind={props.kind} />,
      <CloseEnrollmentAction table={state.table.current} kind={props.kind} />,
      <StandardTableActions />,
    ]
  }

  function renderSaveActions() {    
    return [<SaveAndTransition state={state} queryVars={queryVars} />];
  }

  function getCourseDefaults() {
    return {
      status: CourseStatus.Draft,
      searchable: true,
      company: {id: companyId, userCompanyRole: user?.roles?.provider}
    }
  }

  return render();
}

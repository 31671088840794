import * as React from 'react';

import { CourseUtils, EnrollmentStatusFilter, RateType, authorizedCoursePreferenceKeys } from 'app2/api';
import { DataTable, useRef } from 'app2/components';

import { CourseSelections } from '../../generated';

import { EnrollmentsTable } from '../EnrollmentsTable';
import { renderRefundButton } from '../actions/refund';
import { useCourseEnrollmentCols } from '../useCourseEnrollmentCols';

import { RemovedEnrollmentsSelections, useRemovedEnrollmentsQuery } from './generated';
import { ReenrollAction } from '../actions/reenroll';

const TABLE_PREFS_VERSION = '5';

interface Props {
  course: CourseSelections;
}

export function Removed(props: Props) {
  const cols = useCourseEnrollmentCols<RemovedEnrollmentsSelections>(removedCols, props.course, EnrollmentStatusFilter.Removed);
  const tableRef = useRef<DataTable<RemovedEnrollmentsSelections>>();

  function render() {
    if (CourseUtils.getRateOrPriceType(props.course?.prices) !== RateType.basic) {
      return null;
    }

    return (
      <EnrollmentsTable<RemovedEnrollmentsSelections>
        course={props.course}
        header={{ icon: 'User', title: 'Removed', secondaryActions: renderSecondaryActions() }}
        table={{ cols, ref: tableRef, none: 'No removed students' }}
        prefsVersion={TABLE_PREFS_VERSION}
        prefsKey={authorizedCoursePreferenceKeys.removedEnrollmentsTable}
        queryHook={useRemovedEnrollmentsQuery}
      />
    );
  }

  function renderSecondaryActions() {
    return [<ReenrollAction course={props.course} table={tableRef.current as DataTable<any>} />, renderRefundButton(props.course, tableRef.current)];
  }

  return render();
}

export const removedCols = [
  'student.firstName',
  'student.lastName',
  'student.grade',
  'rosterPeriod',
  'student.classroom.displayName',
  'dismissal',
  'parent.name',
  'parent.email',
  'groups'
];

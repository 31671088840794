import * as React from 'react';

import { Enrollment, EnrollmentStatusEnum, PaymentStatus } from 'app2/api';
import { HBox, BoxProps, theme, FieldInfo } from 'app2/components';

interface Props extends BoxProps {
  paymentStatus: PaymentStatus;
  paymentFailureReason?: string;
  // label is an alternative label than the default
  // for example if you want to use the status bg color and tooltip but put an amount in
  label?:React.ReactNode;
  info?:FieldInfo<Enrollment, 'paymentStatus', 'paymentFailureReason'>;
}

export function PaymentStatusTag(props: Props) {
  const { paymentStatus, paymentFailureReason, info, label, ...remaining } = props;
  const statusProps = paymentStatuses[paymentStatus?.toUpperCase() as PaymentStatus];
  const removed = info?.record?.status === EnrollmentStatusEnum.RemovedFromCourse;

  if (!statusProps) {
    return <></>;
  }

  const reason = paymentFailureReason || info?.record?.paymentFailureReason;
  const tooltip = reason ? `${statusProps.tooltip}: ${reason}` : statusProps.tooltip;

  return <>
    <HBox bg={statusProps.bg} color={statusProps.color}  tooltip={tooltip} text="formlabel" borderRadius="standard" px="$12" py="4.5px" {...remaining} fontWeight={700} width="fit-content" cursor='default'>
      {label || statusProps.label}
    </HBox>
    {removed && <HBox ml="8px" text="formlabel" bg={theme.colors.error} borderRadius="standard" px="$12" py="4.5px" {...remaining} fontWeight={700} color='white'>Removed</HBox>}
  </>
}

PaymentStatusTag.fieldProps = {
  valueProperty: 'paymentStatus',
  fieldProperty: 'info',
};

export const paymentStatuses = {
  [PaymentStatus.Pending]: { bg: theme.colors.disabledBackground, color: undefined as string, label: 'Pending', tooltip: "The parent hasn't been been charged" },
  [PaymentStatus.Processing]: { bg: theme.colors.warningFill, color: undefined as string, label: 'Processing', tooltip: 'The charge is in progress' },
  [PaymentStatus.Canceled]: { bg: theme.colors.errorFill, color: undefined as string, label: 'Canceled', tooltip: 'The charge was canceled' },
  [PaymentStatus.Paid]: { bg: theme.colors.successFill, color: undefined as string, label: 'Paid', tooltip: 'The charge was successful' },
  [PaymentStatus.RetriableError]: { bg: theme.colors.errorFill, color: undefined as string, label: 'Failed', tooltip: 'The charge failed' },
  [PaymentStatus.Failed]: { bg: theme.colors.errorFill, color: undefined as string, label: 'Permanently failed', tooltip: 'The charge failed and cannot be retried' },
  // for refunds
  ["COMPLETED"]: { bg: theme.colors.successFill, color: undefined as string, label: 'Completed', tooltip: 'The refund has been issued to the family' },
  ["READY"]: { bg: theme.colors.warningFill, color: undefined as string, label: 'Processing', tooltip: 'The refund has being processed' }
};

import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AddCourseToCartMutationVariables = Types.Exact<{
  enrollments?: Types.InputMaybe<Array<Types.EnrollmentInput> | Types.EnrollmentInput>;
  trackingParameters?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type AddCourseToCartMutation = {
  cartAddEnrollments?:
    | {
        expiryRefreshed?: boolean | null | undefined;
        enrollments?:
          | Array<
              | {
                  id: string;
                  status: Types.EnrollmentStatusEnum;
                  templateId?: string | null | undefined;
                  priceConfig?: { kind: Types.PriceConfigKind } | { kind: Types.PriceConfigKind } | { kind: Types.PriceConfigKind } | { kind: Types.PriceConfigKind } | null | undefined;
                  parentEnrollment?: { id: string } | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const AddCourseToCartDocument = gql`
  mutation AddCourseToCart($enrollments: [EnrollmentInput!], $trackingParameters: String) @urql(additionalTypenames: ["User"]) {
    cartAddEnrollments(enrollments: $enrollments, trackingParameters: $trackingParameters) {
      enrollments {
        id
        status
        templateId
        priceConfig {
          kind
        }
        parentEnrollment {
          id
        }
      }
      expiryRefreshed
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type AddCourseToCartOptions = ExecMutationOptions<AddCourseToCartMutationVariables>;

export function addCourseToCart(options: AddCourseToCartOptions) {
  return executeMutation<AddCourseToCartMutation, AddCourseToCartMutationVariables>(AddCourseToCartDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['User']) }
  });
}

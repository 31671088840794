import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerScheduleSeasonQueryVariables = Types.Exact<{
  seasonId: Types.Scalars['ID'];
}>;

export type OrganizerScheduleSeasonQuery = {
  season?: { coursesBegin: string; coursesFinish: string; enrollmentOpens: string; enrollmentCloses: string; holidays: Array<string | null | undefined>; status: Types.SeasonStatus } | null | undefined;
};

export const OrganizerScheduleSeasonDocument = gql`
  query OrganizerScheduleSeason($seasonId: ID!) {
    season(season: $seasonId) {
      coursesBegin
      coursesFinish
      enrollmentOpens
      enrollmentCloses
      holidays
      status
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type OrganizerScheduleSeasonArgs = Omit<UseQueryArgs<OrganizerScheduleSeasonQueryVariables, OrganizerScheduleSeasonQuery>, 'query'>;

export function useOrganizerScheduleSeasonQuery(options: OrganizerScheduleSeasonArgs = {}) {
  return useQuery<OrganizerScheduleSeasonQueryVariables, OrganizerScheduleSeasonQuery>({ query: OrganizerScheduleSeasonDocument, ...options });
}

export type OrganizerScheduleSeasonOptions = ExecQueryOptions<OrganizerScheduleSeasonQueryVariables>;

export function organizerScheduleSeason(options: OrganizerScheduleSeasonOptions) {
  return executeQuery<OrganizerScheduleSeasonQuery, OrganizerScheduleSeasonQueryVariables>(OrganizerScheduleSeasonDocument, options);
}

export type OrganizerScheduleSeasonSelections = OrganizerScheduleSeasonQuery['season'];

import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminCompanyVendorsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type AdminCompanyVendorsQuery = {
  company?: { id: string; name?: string | null | undefined; vendors?: Array<{ id: string; nickName: string; site: { id: string; name?: string | null | undefined } }> | null | undefined } | null | undefined;
};

export const AdminCompanyVendorsDocument = gql`
  query AdminCompanyVendors($id: ID!) {
    company(company: $id) {
      id
      name
      vendors {
        id
        nickName
        site {
          id
          name
        }
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminCompanyVendorsArgs = Omit<UseQueryArgs<AdminCompanyVendorsQueryVariables, AdminCompanyVendorsQuery>, 'query'>;

export function useAdminCompanyVendorsQuery(options: AdminCompanyVendorsArgs = {}) {
  return useQuery<AdminCompanyVendorsQueryVariables, AdminCompanyVendorsQuery>({ query: AdminCompanyVendorsDocument, ...options });
}

export type AdminCompanyVendorsOptions = ExecQueryOptions<AdminCompanyVendorsQueryVariables>;

export function adminCompanyVendors(options: AdminCompanyVendorsOptions) {
  return executeQuery<AdminCompanyVendorsQuery, AdminCompanyVendorsQueryVariables>(AdminCompanyVendorsDocument, options);
}

export type AdminCompanyVendorsSelections = AdminCompanyVendorsQuery['company'];

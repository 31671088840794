import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TimeEntriesQueryVariables = Types.Exact<{
  ownerType: Types.EntityKind;
  ownerId: Types.Scalars['ID'];
  sorts?: Types.InputMaybe<Array<Types.Sort> | Types.Sort>;
  filters?: Types.InputMaybe<Array<Types.Filter> | Types.Filter>;
}>;

export type TimeEntriesQuery = {
  timeEntries?:
    | Array<{
        id: string;
        checkedIn?: string | null | undefined;
        checkedOut?: string | null | undefined;
        hours?: number | null | undefined;
        user?: { id: string; name?: string | null | undefined; email: string; phone?: string | null | undefined } | null | undefined;
      }>
    | null
    | undefined;
};

export const TimeEntriesDocument = gql`
  query TimeEntries($ownerType: EntityKind!, $ownerId: ID!, $sorts: [Sort!], $filters: [Filter!]) {
    timeEntries(ownerType: $ownerType, ownerId: $ownerId, sorts: $sorts, filters: $filters) {
      id
      checkedIn
      checkedOut
      hours
      user {
        id
        name
        email
        phone
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type TimeEntriesArgs = Omit<UseQueryArgs<TimeEntriesQueryVariables, TimeEntriesQuery>, 'query'>;

export function useTimeEntriesQuery(options: TimeEntriesArgs = {}) {
  return useQuery<TimeEntriesQueryVariables, TimeEntriesQuery>({ query: TimeEntriesDocument, ...options });
}

export type TimeEntriesOptions = ExecQueryOptions<TimeEntriesQueryVariables>;

export function timeEntries(options: TimeEntriesOptions) {
  return executeQuery<TimeEntriesQuery, TimeEntriesQueryVariables>(TimeEntriesDocument, options);
}

export type TimeEntriesSelections = TimeEntriesQuery['timeEntries'][0];

(TimeEntriesDocument as any).additionalTypenames = ['TimeEntry'];

import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type VendorComplianceQueryVariables = Types.Exact<{
  vendor: Types.Scalars['ID'];
}>;

export type VendorComplianceQuery = {
  vendorCompliance?:
    | Array<
        | {
            expiresAt?: string | null | undefined;
            id: string;
            status?: string | null | undefined;
            definition: { complianceType: string; id: string; instructions?: string | null | undefined; name: string; downloads?: Array<{ id: string; name: string } | null | undefined> | null | undefined };
            uploads?: Array<{ id: string; name: string } | null | undefined> | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export const VendorComplianceDocument = gql`
  query VendorCompliance($vendor: ID!) {
    vendorCompliance(vendor: $vendor) {
      definition {
        complianceType
        downloads {
          id
          name
        }
        id
        instructions
        name
      }
      expiresAt
      id
      status
      uploads {
        id
        name
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type VendorComplianceArgs = Omit<UseQueryArgs<VendorComplianceQueryVariables, VendorComplianceQuery>, 'query'>;

export function useVendorComplianceQuery(options: VendorComplianceArgs = {}) {
  return useQuery<VendorComplianceQueryVariables, VendorComplianceQuery>({ query: VendorComplianceDocument, ...options });
}

export type VendorComplianceOptions = ExecQueryOptions<VendorComplianceQueryVariables>;

export function vendorCompliance(options: VendorComplianceOptions) {
  return executeQuery<VendorComplianceQuery, VendorComplianceQueryVariables>(VendorComplianceDocument, options);
}

export type VendorComplianceSelections = VendorComplianceQuery['vendorCompliance'][0];

(VendorComplianceDocument as any).additionalTypenames = ['ComplianceRequirement'];

import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { chunk } from 'lodash-es';

import { observer } from 'app/observable';
import { sharedDataManager } from 'app/admin/api';
import { eventManager } from 'app2/api';
import {
  CatalogListing,
  MarketAreaStore,
  ProviderCard as IProviderCard
} from 'app2/api';
import {
  buildCloudinaryImageUrl,
  VBox,
  HBox,
  Heading1,
  Heading2,
  Subtitle1,
  useBreakpoints,
  buildBreadCrumbStr,
  CardRow,
  Loader
} from 'app2/components';

import { Page, PageContent } from '../../shared-public';

import { HowItWorks } from './HowItWorks';
import { CategoryCard } from './CategoryCard';
import { CatalogCard, CATALOG_CARD_RATIO } from './CatalogCard';
import { ProviderCard, PROVIDER_CARD_RATIO } from './ProviderCard';
import { CardPreviewRow } from './CardPreviewRow';

import header from './header.svg';

type Props = RouteComponentProps<{ slug: string }>;

const previewAmount = 11;

const PROVIDER_PAGINATION_QUERY_PARAM = 'providersPage';
const CATALOG_LISTING_PAGINATION_QUERY_PARAM = 'catalogListingsPage';

@observer
export class InnerMarketArea extends React.Component<Props> {
  store: MarketAreaStore = sharedDataManager.get(MarketAreaStore);

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: Props) {
    this.loadData();
  }

  get url() {
    return `/in/${this.store.marketArea.slug}`;
  }

  get crumbs() {
    if (!this.store.marketArea) {
      return [];
    }

    return [
      {
        label: this.store.marketArea.name,
        url: this.url
      }
    ];
  }

  async loadData() {
    try {
      const slug = this.props.match.params.slug;
      const promises = this.store.load(slug);

      // we only load the first set of catalogs if the
      // slug has changed because we load all catalogs
      // all at once

      if (!promises.length) {
        return;
      }

      promises.push(this.store.loadCatalogPage(previewAmount));
      promises.push(this.store.loadProviderPage(previewAmount));

      await Promise.all(promises);

      this.logPageView();
    } catch (_) {}
  }

  logPageView() {
    eventManager.log('viewCityLandingPage', {
      url: window.location.href,
      breadCrumbs: buildBreadCrumbStr(this.crumbs),
      totalNumberOfCatalogListings: this.store.catalogs.pagination.count,
      totalNumberOfProviders: this.store.catalogs.meta.provider_count,
      totalNumberOfCategories: this.store.categories.length
    });
  }

  catalogListingsCount() {
    return this.store.catalogs.pagination.count;
  }

  providersCount() {
    return this.store.providers.pagination.count;
  }

  handleCatalogListingSeeMoreLinkClick = () => {
    eventManager.log('clickSeeMoreCatalogListingsLink', {
      url: window.location.href,
      totalNumberOfCatalogListings: this.catalogListingsCount()
    });
  };

  handleCatalogListingSeeMoreCardClick = () => {
    eventManager.log('clickSeeMoreCatalogListingsCard', {
      url: window.location.href,
      totalNumberOfCatalogListings: this.catalogListingsCount()
    });
  };

  handleProviderSeeMoreLinkClick = () => {
    eventManager.log('clickSeeMoreProvidersLink', {
      totalNumberOfProviders: this.providersCount()
    });
  };

  handleProviderSeeMoreCardClick = () => {
    eventManager.log('clickSeeMoreProvidersCard', {
      totalNumberOfProviders: this.providersCount()
    });
  };

  render() {
    if (!this.store.marketArea) {
      return <Loader />;
    }

    const title = `${this.store.marketArea.name}: Browse Local Activities for Kids`;
    const description = `Discover local kids activities covering a wide range of subjects including, art, music, cooking, stem and coding near ${this.store.marketArea.name}.`;

    return <Page head={{title, description}} crumbs={this.crumbs} above={<Header store={this.store} />} useMaxWidth header footer>
      <CardPreviewRow<CatalogListing>
        results={this.store.catalogs}
        url={this.url}
        location={this.props.location}
        CardComponent={CatalogCard}
        pageParam={CATALOG_LISTING_PAGINATION_QUERY_PARAM}
        title="Activities in your region"
        seeMorePath="/catalog"
        seeMoreCardRatio={CATALOG_CARD_RATIO}
        onSeeMoreLinkClick={this.handleCatalogListingSeeMoreLinkClick}
        onSeeMoreCardClick={this.handleCatalogListingSeeMoreCardClick}
      />
      <CardPreviewRow<IProviderCard>
        results={this.store.providers}
        url={this.url}
        location={this.props.location}
        CardComponent={ProviderCard}
        pageParam={PROVIDER_PAGINATION_QUERY_PARAM}
        title="Browse by providers"
        seeMorePath="/providers"
        seeMoreCardRatio={PROVIDER_CARD_RATIO}
        onSeeMoreLinkClick={this.handleProviderSeeMoreLinkClick}
        onSeeMoreCardClick={this.handleProviderSeeMoreCardClick}
      />
      <HowItWorks />
      <CategoryCards store={this.store} />
    </Page>
  }
}

function Header(props: { store: MarketAreaStore }) {
  const heroImageUrl = props.store.marketArea.hero_img_url;
  const url = heroImageUrl ? buildCloudinaryImageUrl(heroImageUrl) : header;

  return (
    <VBox position="relative" minHeight="150px">
      <VBox
        background={`url(${url})`}
        backgroundSize="cover"
        width="100%"
        height="100%"
        position="absolute"
        zIndex={-2}
      />
      <VBox
        position="absolute"
        width="100%"
        height="100%"
        background="rgba(0, 0, 0, .65)"
        zIndex={-1}
      />

      <PageContent pt="$15" pb="$15">
        <Heading1 color="#fff" mb="$15">
          {props.store.marketArea.heading_1}
        </Heading1>
        <Heading2 color="#fff">{props.store.marketArea.heading_2}</Heading2>
      </PageContent>
    </VBox>
  );
}

function CategoryCards(props: { store: MarketAreaStore }) {
  const categories = props.store.categories;

  if (!categories) {
    return (
      <VBox hAlign="center" mb="$30" width="100%">
        <Loader />
      </VBox>
    );
  }

  const breakpoint = useBreakpoints(['635px', '1040px']);
  const numPerRow = [1, 2, 3][breakpoint];
  const chunks = chunk(categories, numPerRow);

  return (
    <VBox mb="$60" maxWidth="100%" minWidth="100%">
      <HBox mb="$10" maxWidth="100%" minWidth="100%" vAlign="center">
        <Subtitle1>Browse activities by category</Subtitle1>
      </HBox>
      {chunks.map((row, index) => (
        <CardRow
          key={index}
          numPerRow={numPerRow}
          items={row.map(category => (
            <CategoryCard
              marketArea={props.store.marketArea}
              category={category}
            />
          ))}
        />
      ))}
    </VBox>
  );
}

export const MarketArea = withRouter(InnerMarketArea);

import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerEmergencyContactsQueryVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
}>;

export type OrganizerEmergencyContactsQuery = {
  site?: { id: string; contacts: Array<{ id: string; name?: string | null | undefined; email?: string | null | undefined; phone?: string | null | undefined; position?: string | null | undefined }> } | null | undefined;
};

export const OrganizerEmergencyContactsDocument = gql`
  query OrganizerEmergencyContacts($siteId: ID!) {
    site(site: $siteId) {
      id
      contacts {
        id
        name
        email
        phone
        position
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type OrganizerEmergencyContactsArgs = Omit<UseQueryArgs<OrganizerEmergencyContactsQueryVariables, OrganizerEmergencyContactsQuery>, 'query'>;

export function useOrganizerEmergencyContactsQuery(options: OrganizerEmergencyContactsArgs = {}) {
  return useQuery<OrganizerEmergencyContactsQueryVariables, OrganizerEmergencyContactsQuery>({ query: OrganizerEmergencyContactsDocument, ...options });
}

export type OrganizerEmergencyContactsOptions = ExecQueryOptions<OrganizerEmergencyContactsQueryVariables>;

export function organizerEmergencyContacts(options: OrganizerEmergencyContactsOptions) {
  return executeQuery<OrganizerEmergencyContactsQuery, OrganizerEmergencyContactsQueryVariables>(OrganizerEmergencyContactsDocument, options);
}

export type OrganizerEmergencyContactsSelections = OrganizerEmergencyContactsQuery['site'];

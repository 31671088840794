import * as React from 'react'

import { Link, HBox, Text, VBox } from 'app2/components'
import { marketingUrl } from 'app2/views/shared-public'

import { Section } from './Section'
import howItWorksOrganizers from './learn-how-it-works-organizers.png'
import howItWorksProviders from './learn-how-it-works-providers.png'

export function Learn() {
  return <Section layout='vbox'>
    <Text text='heading2' mb='$20'>Learn how Homeroom can support your goals</Text>
    <HBox hItemSpace={['unset', '20px']} vItemSpace={['20px', 'unset']} layout={['vbox', 'hbox']} itemWidth={['100%', '50%']}>
      <VBox p={['$16', '$40']} bg='#F3DFE3' borderRadius='standard' hAlign='left' backgroundImage={`url(${howItWorksOrganizers})`} backgroundSize='contain' backgroundRepeat='no-repeat' backgroundPosition='bottom right'>
        <Text text='subtitle1' mb='$8'>For Independent Schools</Text>
        <Text text='body' mb='$20'>Streamline enrollment, maintain accurate daily rosters, and ensure precise billing for summer camps, enrichment clubs, and extended day care.</Text>
        <Link button='primary' mb='$40' to={marketingUrl('/for-schools/independent-schools')}>Learn more</Link>
      </VBox>
      <VBox p={['$16', '$40']} bg='marketingBg' borderRadius='standard' hAlign='left' backgroundImage={`url(${howItWorksProviders})`} backgroundSize='contain' backgroundRepeat='no-repeat' backgroundPosition='bottom right'>
        <Text text='subtitle1' mb='$8'>For Public Schools</Text>
        <Text text='body' mb='$20'>Enhance your school's activities by offloading administrative work and focusing on what matters.</Text>
        <Link button='primary' mb='$40' to={marketingUrl('/about/for-schools/public-schools')}>Learn more</Link>
      </VBox>
    </HBox>
  </Section>
}

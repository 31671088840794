import * as React from 'react'

import { EnrollmentStatusFilter } from 'app2/api';
import { DataTableColumn } from 'app2/components';

import { CourseSelections } from '../generated';

import { useCfQuestionCols } from '../../custom-fields';
import { createRosterView } from '../../roster/createRosterView';
import { StudentModal, restrictedModalRenderer } from '../../student';

export function useCourseEnrollmentCols<T>(cols:(string | DataTableColumn<T>)[], course:CourseSelections, status:EnrollmentStatusFilter, cfQuestions?:boolean) {
  const cfSeason = cfQuestions ? useCfQuestionCols<T>(course?.cfQuestions) : [];
  const cfActivity = cfQuestions && course.site?.userSiteRole ? useCfQuestionCols<T>({owner:course?.season}) : [];

  return React.useMemo(() => {
    const studentModal = course?.behavior?.studentUpdate ? StudentModal : restrictedModalRenderer(course?.token)

    return createRosterView({cols, cfCols: cfSeason.concat(cfActivity), course, status, studentModal}).cols;
  }, [cols, course, status, cfSeason, cfActivity])
}

import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompanyInviteMemberMutationVariables = Types.Exact<{
  company: Types.Scalars['ID'];
  attributes: Types.InviteInput;
}>;

export type CompanyInviteMemberMutation = { companyInviteCreate?: { entity?: { id: string } | { id: string } | null | undefined } | null | undefined };

export const CompanyInviteMemberDocument = gql`
  mutation companyInviteMember($company: ID!, $attributes: InviteInput!) {
    companyInviteCreate(company: $company, attributes: $attributes) {
      entity {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type CompanyInviteMemberOptions = ExecMutationOptions<CompanyInviteMemberMutationVariables>;

export function companyInviteMember(options: CompanyInviteMemberOptions) {
  return executeMutation<CompanyInviteMemberMutation, CompanyInviteMemberMutationVariables>(CompanyInviteMemberDocument, options);
}

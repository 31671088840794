import * as React from 'react'

import { defaultFieldProps } from 'app2/components';
import { SearchDropdown, SearchDropdownProps } from 'app2/views';

import { useSiteSearchQuery, SiteSearchSelections } from './gql';

export type DefaultSite = SiteSearchSelections;

export interface SitesDropdownProps extends SearchDropdownProps<SiteSearchSelections> {
  connectedSitesOnly?:boolean;
  searchableOnly?:boolean;
  activeOnly?:boolean;
}

export function SitesDropdown(props:SitesDropdownProps) {
  const {connectedSitesOnly, searchableOnly, activeOnly, ...remaining} = props;

  function render() {
    return <SearchDropdown<SiteSearchSelections> query={useSiteSearchQuery} queryVars={{connectedSitesOnly, searchableOnly, activeOnly}} placeholder='Select a school' renderItem={renderOptionLabel} {...remaining} />
  }
  
  function renderOptionLabel(item:SiteSearchSelections) {
    const hasCityState = item?.city && item?.state;
    const cityState = hasCityState ? ` (${item?.city}, ${item?.state})` : '';
    return `${item?.name}${cityState}`;
  }
  
  return render();
}

SitesDropdown.fieldProps = {
  ...defaultFieldProps,
  readOnlyProperty: 'readOnly'
}

import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
import { CommonCompanyFragmentDoc } from './commonCompany.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type BasicCompanyQueryVariables = Types.Exact<{
  company: Types.Scalars['ID'];
}>;

export type BasicCompanyQuery = {
  company?:
    | {
        zipcode?: string | null | undefined;
        timezone?: string | null | undefined;
        id: string;
        name?: string | null | undefined;
        userCompanyRole?: Types.MemberRole | null | undefined;
        nonPartnerApproved?: boolean | null | undefined;
      }
    | null
    | undefined;
};

export const BasicCompanyDocument = gql`
  query BasicCompany($company: ID!) {
    company(company: $company) {
      ...commonCompany
      zipcode
      timezone
    }
  }
  ${CommonCompanyFragmentDoc}
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type BasicCompanyArgs = Omit<UseQueryArgs<BasicCompanyQueryVariables, BasicCompanyQuery>, 'query'>;

export function useBasicCompanyQuery(options: BasicCompanyArgs = {}) {
  return useQuery<BasicCompanyQueryVariables, BasicCompanyQuery>({ query: BasicCompanyDocument, ...options });
}

export type BasicCompanyOptions = ExecQueryOptions<BasicCompanyQueryVariables>;

export function basicCompany(options: BasicCompanyOptions) {
  return executeQuery<BasicCompanyQuery, BasicCompanyQueryVariables>(BasicCompanyDocument, options);
}

export type BasicCompanySelections = BasicCompanyQuery['company'];

import * as React from 'react';

import { Panel, Form, FormProps, FormModel, Modal, useForm } from 'app2/components';
import { useCurrentUser, DefaultSite } from 'app2/views/shared-public';
import { StudentDetailsFields, StudentDetailsConfigProps, upsertStudent } from 'app2/views/shared';

import { parentCopyStudentContacts, ParentStudentDetailsSelections, useParentStudentDetailsWithoutCodesQuery, useParentStudentDetailsQuery, useParentSiteClassroomsQuery, parentUpsertStudent } from './generated';

type Student = ParentStudentDetailsSelections;

interface Props extends FormProps<Student>, Omit<StudentDetailsConfigProps, 'role'> {
  id?:string;
  defaultSite?:DefaultSite;
  copy?:boolean;
}

export function ParentStudentDetails(props:Props) {
  const { courseKinds, purpose, id, defaultSite, copy, ...remaining } = props;
  const { user } = useCurrentUser();
  const student = getStudent();
  const form = useForm<Student>(student);
  const formProps:FormProps<Student> = {icon:"User", title:"Student details", form, alwaysSave:false, onOk, ...remaining};

  function render() {
    return purpose == 'add'
      ? renderAdd()
      : renderEdit()
  }

  function renderAdd() {
    return <Modal title='Add new student'>
      <Form {...formProps} editing> 
        {renderBody()}
      </Form>
    </Modal>
  }

  function renderEdit() {
    return <Panel type={purpose == 'add' ? 'edit' : purpose == 'edit' ? 'toggle' : 'edit-no-save-button'} autoSave={purpose == 'checkout'} name='details' {...formProps}>
      {renderBody()}
    </Panel>
  }

  function renderBody() {
    return <StudentDetailsFields title={false} role='parent' courseKinds={courseKinds} purpose={purpose} getClassrooms={getClassrooms} defaultSite={defaultSite} onCopyContacts={id && copy ? onCopyContacts : null} />
  }

  function getStudent() {
    return purpose == 'add' ? createStudent() : loadStudent()
  }

  function createStudent() {
    return React.useMemo(() => {
      return {
        automaticGradeUpdate: true,
        parents:[{
          name: user?.name,
          email: user?.email,
          phone: user?.phone
        }]
      } as Student
    }, []);
  }

  function loadStudent() {
    const query = purpose == 'checkout' ? useParentStudentDetailsWithoutCodesQuery : useParentStudentDetailsQuery;
    const [result] = query({ variables: { id:props.id } });
    return result?.data?.student;
  }

  function getClassrooms(site:string, pause?:boolean) {
    const [response] = useParentSiteClassroomsQuery({variables: {siteId: site}, pause, hideLoader: true});
    return response.data?.site?.classrooms;
  }

  async function onOk(form:FormModel<Student>) {
    const success = await upsertStudent(parentUpsertStudent, form, student);

    return success;
  }

  async function onCopyContacts() {
    form.setValues({emergencyContacts: [], authorizedPickupContacts: []});
    return parentCopyStudentContacts({variables:{id: props.id}});
  }
    
  return render();
}

import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type NonPartnerSitesQueryVariables = Types.Exact<{
  term: Array<Types.Scalars['JSON']> | Types.Scalars['JSON'];
}>;

export type NonPartnerSitesQuery = { sites?: Array<{ id: string; name?: string | null | undefined; city?: string | null | undefined; state?: string | null | undefined; typename: 'Site' }> | null | undefined };

export const NonPartnerSitesDocument = gql`
  query NonPartnerSites($term: [JSON!]!) {
    sites(queries: $term, filters: [{ name: "partner", value: false }], sorts: [{ name: "name", direction: ASC }]) {
      typename: __typename
      id
      name
      city
      state
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type NonPartnerSitesArgs = Omit<UseQueryArgs<NonPartnerSitesQueryVariables, NonPartnerSitesQuery>, 'query'>;

export function useNonPartnerSitesQuery(options: NonPartnerSitesArgs = {}) {
  return useQuery<NonPartnerSitesQueryVariables, NonPartnerSitesQuery>({ query: NonPartnerSitesDocument, ...options });
}

export type NonPartnerSitesOptions = ExecQueryOptions<NonPartnerSitesQueryVariables>;

export function nonPartnerSites(options: NonPartnerSitesOptions) {
  return executeQuery<NonPartnerSitesQuery, NonPartnerSitesQueryVariables>(NonPartnerSitesDocument, options);
}

export type NonPartnerSitesSelections = NonPartnerSitesQuery['sites'][0];

(NonPartnerSitesDocument as any).additionalTypenames = ['Site'];

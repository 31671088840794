import * as React from 'react';
import moment from 'moment';

import { CourseUtils, EnrollmentUtils } from 'app2/api';
import { ActionButton, DataTable, NotificationManager } from 'app2/components';

import { CourseSelections } from '../../../generated';
import { DistinctEnrollmentsSelections } from '../../enrolled';

import { ChangeSeasonPlan } from './ChangeSeasonPlan';

interface Props {
  table: DataTable<DistinctEnrollmentsSelections>;
  course: CourseSelections;
}

export function ChangeSeasonPlanAction(props: Props) {
  function render() {
    return !CourseUtils.getCourseAbilities(props.course).seasonChangeable || !props.course?.behavior?.enrollmentsChangeSeasonPlan
    ? <></>
    : <ActionButton icon="Edit" autoLoader={false} onClick={onClick}>
      Change plan
    </ActionButton>
  }

  function onClick(): JSX.Element {
    if (props.course.childVariantCourses?.length) {
      return void NotificationManager.warning("Please navigate to the specific time slot to change an enrollment's plan.");
    }

    const enrollments = props.table.selections.selectedItems;

    return enrollments.some(e => !EnrollmentUtils.usingConfigurableSeasonPricing(e) || e.isInvitation) ? (
      void NotificationManager.warning(
        'Change plan only applies to enrollments that are priced by days per week and have been successfully charged. Please review the selected enrollments.'
      )
    ) : (
      <ChangeSeasonPlan course={props.course} enrollments={enrollments} />
    );
  }

  return render();
}

import * as React from 'react';

import { Route } from 'app2/views/shared-public/page/Route';
import { Field } from 'app2/components';

import { SeasonLink, QueryList, HistoryLink } from '../../shared';

import { useSeasonsQuery } from './generated';
import { Season } from './Season';

export function Seasons() {
  return <>
    <Route path="/admin2/schools/:id/seasons/:season" component={Season} role='admin' exact />
    <Route path='/admin2/schools/:id/seasons' exact>
      <QueryList icon='Home' title='Seasons' query={useSeasonsQuery} name='seasons' fields={[
        <Field name='name' label='Name' component={SeasonLink} />,
        <Field name='status' label='Status' />,
        <Field name='id' label='History' component={HistoryLink} type='Season' />,
      ]} />
    </Route>
  </>
}

import * as React from 'react'

import { Tooltip } from '../../Tooltip';
import { TextHighlighter } from '../../TextHighlighter';

import { CellRenderProps } from './CellRendererProps';
import { useOverflow } from './useOverflow';

export function CellRenderer(props:CellRenderProps) {
  const table = props.table;
  let className = (props.className || '') + ' hr-table-cell';

  const editingStyle = table.props.cellStyle === 'editable' || (table.props.cellStyle !== 'read-only' && table.props.editable);
  className += !editingStyle ? ' hr-table-cell-readonly' : ' hr-table-cell-editable';

  if (props.focus && !editingStyle) {
    className += ' hr-table-cell-focus';
  }

  const rowErrors = table?.getRecordErrors(props.rowPos);
  const hasRowErrors = rowErrors?.length > 0;
  const showErrorBorder = (props.errors?.length || hasRowErrors);

  if (showErrorBorder) {
    className += ' hr-table-row-error';
  }

  if (props.errors?.length) {
    className += ' hr-table-cell-error';
  }

  // first col needs special styling to make it appear it has a flat left edge
  if (props.colPos == 0) {
    className += ' hr-table-first-col';
  }

  const ref = React.useRef<HTMLDivElement>();
  const incomingStyles =  {...props.style};// clone the styles so that useOverflow can change them if needed
  const overflow = useOverflow(ref, props, incomingStyles);
  const style = overflow.style ? {...incomingStyles, ...overflow.style} : incomingStyles;
  
  const nonEditable = editingStyle && (props.readOnly || props.disabled);

  if (nonEditable) {
    className += ' hr-table-cell-disabled';
  }

  if (props.col.break) {
    className += ' hr-table-page-break';
  }

  const row = props.rowPos;
  const highlight = table.props.highlightText;

  const cell = <div ref={ref} data-row={row} data-field={props.col.label} className={className} style={style}>
    {props.children}
    {highlight ? <TextHighlighter parent={ref} focus={props.focus} text={highlight} /> : null}
  </div>;

  return props.errors?.length
    ? renderTooltip(cell, props.errors)
    : cell;
}

function renderTooltip(contents:any, errors:string[]) {
  return <Tooltip tip={errors}>
    {contents}
  </Tooltip>
}


import * as React from 'react';
import * as ReactIs from 'react-is';
import { isEmpty, omitBy, isUndefined, uniqBy } from 'lodash-es';
import { Location } from 'history';
import pluralize from 'pluralize';

import { BoxProps, HBox, VBox } from '../../Box';
import { Button } from '../../Button';
import { Point } from '../../dom-utils';
import { DataTable, DataTableColumn, DataTableProps, ObservableCollection } from '..';
import { HistoryAction, HistoryListener } from '../../history';
import { Icon, IconNames } from '../../icons';
import { OptionsMenu } from '../../menu';
import { Shield } from '../../shield';
import { Subtitle1, Body } from '../../Text';
import { theme } from '../../theme';
import { NotificationManager } from '../../notification';
import { UnsavedChanges } from '../../UnsavedChanges';
import { keyElements, handleOnClickOrModal, MultiContextProvider, OnClickOrElement } from '../../utils';
import { NavigationPrompt } from '../../form';

import { GroupByPicker, GroupByPickerType, GroupedDataTableProps, GroupedDataTableHandle } from '../group-by';

import { ActionBar } from './ActionBar';
import { ActionHandler } from './ActionButton';
import { StandardTableActions } from './StandardTableActions';

export interface DataTableHeaderProps extends BoxProps {
  icon?:IconNames;
  title:React.ReactNode;
  units?:string;
  total?:number;
  options?:React.ReactElement[];
  primaryActions?:React.ReactElement | React.ReactElement[];
  secondaryActions?:React.ReactElement | React.ReactElement[] | 'hide';
  editing?:boolean;
  saveChanges?:string;
  saveActions?:React.ReactElement | React.ReactElement[];
  // appears below the title, and does not use the space of the right menu options
  subtitle?:React.ReactNode;
  // appears below the title, but will occupy the space of the right menu options
  totals?:React.ReactNode;
  // if you want alternative content from the title/count/subtitle
  header?:React.ReactNode;
  groupByEnabled?:boolean;
  groupByPicker?:boolean;
  groupByOptions?:DataTableColumn[];
  onEdit?:(table:DataTable) => void;
  onCancelEdits?:(table:DataTable) => void;
  onSaveEdits?:(table:DataTable) => void;
  onStopEditing?:(table:DataTable) => void;
  // only applies if the user is editing when there's a navigation
  onNavigation?:NavigationPrompt;
  children:React.ReactElement<DataTableProps<any>>;
}

export interface DataTableHeaderContext<T = any> {
  header:DataTableHeader<T>;
}

export class DataTableHeader<T> extends React.Component<DataTableHeaderProps> {
  static defaultProps = {
    editing: false,
    onNavigation: 'prompt',
    saveChanges: 'Save changes'
  }

  _tableRef = React.createRef<DataTable<T>>();
  _headerRef = React.createRef<HTMLElement>();
  _observer:ResizeObserver;
  _oldData:ObservableCollection<T>
  _hasRows:boolean;
  _groupBy:string[];
  _uniqueCount:number;
  findText:string;

  get tableRef():React.RefObject<DataTable<T> | GroupedDataTableHandle<T>> {
    return (this.props.children as any).ref || this._tableRef;
  }

  get isGrouped() {
    return this.groupedTable?.tables != null
  }

  get groupedTable() {
    return this.tableRef.current as GroupedDataTableHandle;
  }

  get ungroupedTable() {
    return this.tableRef.current as DataTable<T>;
  }

  get table():DataTable<T> {
    return this.isGrouped ? this.groupedTable.tables[0]?.current : this.ungroupedTable;
  }

  get tableProps() {
    return Object.assign({}, DataTable.defaultProps, this.props.children.props);
  }

  get autoSave() {
    const props = this.tableProps;

    return this.props.editing && props.editable && props.cellStyle == 'read-only';
  }

  componentDidMount():void {
    this._observer = new ResizeObserver(this.onResize);
    this._observer.observe(this._headerRef.current);
    this.updateGroupBy();
    this.updateCollection(this.table?.data);
  }
  
  componentWillUnmount():void {
    this._observer.disconnect();
    this._observer = null;

    this.updateCollection(null);
  }

  componentDidUpdate(prevProps:DataTableHeaderProps) {
    this.updateGroupBy(prevProps);
    this.updateCollection(this.table?.data);
  }

  updateGroupBy(prevProps?:DataTableHeaderProps) {
    if (this.props?.children?.props?.groupBy != (prevProps?.children?.props as GroupedDataTableProps)?.groupBy) {
      this._groupBy = (this.tableProps.groupBy || []).slice();
      this.setState({});
    }
  }

  updateCollection(newData:ObservableCollection<T>) {
    if (this._oldData != newData) {
      if (this._oldData) {
        this._oldData.unobserve(this.collectionUpdated);
      }

      this._oldData = newData;

      if (this._oldData) {
        this._oldData.observe(this.collectionUpdated);
      }

      this.collectionUpdated();
    }
  }

  collectionUpdated = () => {
    const countBefore = this._uniqueCount;
    this._uniqueCount = this.calcUniqueCount();

    const hasRows = this.table?.data?.length != 0;

    if (this._hasRows != hasRows || this._uniqueCount != countBefore) {
      this._hasRows = hasRows;
      this.setState({});
    }
  }

  calcUniqueCount() {
    // we assume that all data has an id we need a unique count for use-cases
    // where the table might repeat items which can happen especially with
    // groupBy if we do paging then this will need to be moved to the server
    const data = this.tableProps.data;
    const ids = new Set();

    if (Array.isArray(data)) {
      if (data.length == 0 || data[0].id === undefined) {
        return data.length;
      }

      data.forEach(e => ids.add(e.id));
    }
    else
    if (data?.getItem) {
      for (let pos = 0; pos < data?.length; ++pos) {
        ids.add((data.getItem(pos) as any).id);
      }
    }

    return ids.size;
  }

  render() {
    let {icon, title, units, total, options, primaryActions, secondaryActions, editing, saveChanges, saveActions, subtitle, header, groupByEnabled, groupByPicker, groupByOptions, onEdit, onCancelEdits, onSaveEdits, onStopEditing, onNavigation, children, ...remaining} = this.props;
    const tableProps = this.tableProps;
    const editable = tableProps.editable;
    const headerHeight = this._headerRef.current?.getBoundingClientRect?.()?.height || 0;
    const groupBy = groupByEnabled ? this._groupBy : undefined;
    const stickyOffset = tableProps.stickyOffset;
    const additionalTableProps = {editable: Boolean(editing && editable), groupBy, borderRadius:`0px 0px ${theme.radii.standard} ${theme.radii.standard}`, border:'solid 1px', borderColor:'border', stickyOffset: new Point(stickyOffset?.x || 0, (stickyOffset?.y || 0) + (this.table?.tableIsScroller ? 0 : headerHeight)), highlightText: this.findText, ref: this.tableRef}
    const dataTable = React.cloneElement(children, omitBy(additionalTableProps, isUndefined));

    return <Shield layout='vbox' width='min-content' data-test={`Table(${this.props['aria-label'] || title})`} {...remaining}>
      <HistoryListener onChange={this.onHistoryChange} />
      {onNavigation == 'prompt'
        ? <UnsavedChanges editing={() => this.table?.dirty} /> 
        : ''}
      <MultiContextProvider<DataTableHeaderContext> header={this}>
        {this.renderHeader(editable, tableProps)}
        {dataTable}
      </MultiContextProvider>
    </Shield>
  }

  renderHeader(editable:boolean, tableProps:any) {
    const stickyOffset = this.tableProps.stickyOffset;
    const stickyX = stickyOffset?.x || 0;
    const stickyY = stickyOffset?.y || 0;

    return <VBox ref={this._headerRef} width='100%' borderRadius={`${theme.radii.standard} ${theme.radii.standard} 0px 0px`} border='solid 1px' borderColor='border' position='sticky' top={stickyY + 'px'} left={stickyX + 'px'} zIndex={50}>
      {this.renderTitleBar(editable, tableProps)}
      {this.renderSecondaryActions()}
    </VBox>
  }

  renderTitleBar(editable:boolean, tableProps:any) {
    let {icon, title, units, total } = this.props;
    units = units || (typeof title == 'string' ? title.toLocaleLowerCase() : '');
    units = pluralize(units, total || tableProps.data?.length);

    const count = this._uniqueCount;
    const outOf = total !== undefined && total != count ? `of ${total} ` : '';
    const showing = count || outOf ? `${count} ${outOf} ${units}` : '';

    return <VBox width='100%' px={['$12', '$20', '$30']} py={['$12', '$20']} bg='formBackground' gap='$16'>
      <HBox vAlign='top' flexWrap='wrap' gap={['20px 10px', '30px 20px', '30px 20px']}>
        <VBox flex={1} gap='$8'>
          {this.props.header ||
          <>
            <HBox width='100%' vAlign='center' gap='$8'>{icon && <Icon name={icon} size='medium' />}<Subtitle1 className='hr-table-title'>{title}</Subtitle1></HBox>
            <HBox width='100%' vAlign='center'>{icon && <Icon name={icon} size='medium' pr='$8' opacity={0} />}<Body>{showing}</Body></HBox>
          </>}
          {this.props.subtitle}
        </VBox>
        {this.renderPrimaryActions(editable)}
      </HBox>
      {this.props.totals}
    </VBox>
  }

  renderPrimaryActions(editable:boolean) {
    const actions = [];
    
    if (this.props.options?.length) {
      actions.push(<OptionsMenu key='options'>{keyElements(this.props.options)}</OptionsMenu>);
    }

    if (editable && !this.autoSave) {
      !this.props.editing
        ? actions.push(<Icon key='edit' alt='edit' name='Edit' size='medium' onClick={this.onEdit} cursor='pointer' clickEvent='Click Edit' />)
        : actions.push(
          <HBox flexWrap='wrap' gap='$8' vAlign='center' key='save_cancel'>
            <Button key='cancel' kind={this.props.saveActions ? 'tertiary' : 'secondary'} onClick={this.onCancelEdits} clickEvent='Click Cancel'>Cancel</Button>
            {keyElements(this.props.saveActions)}
            <Button key='save' autoLoader onClick={event => this.onAction(this.onSaveEdits, false, event, true)} clickEvent={`Click ${this.props.saveChanges}`}>{this.props.saveChanges}</Button>
          </HBox>
        )
    }

    if (this.props.groupByEnabled && this.props.groupByPicker) {
      actions.push(<HBox key='groupBy' gap='$8' vAlign='center'>
        <Body>Group by</Body>
        <GroupByPicker cols={this.props.groupByOptions || this.tableProps.cols} value={this._groupBy} onChange={this.onGroupSelectionChange} />
      </HBox>);
    }

    if (this.props.primaryActions) {
      actions.push(<HBox vAlign='top' flexWrap='wrap' key='primaryActions' gap={['20px 8px', '20px 8px', '20px 8px']}>
        {keyElements(this.props.primaryActions)}
      </HBox>);
    }

    return actions;
  }

  renderSecondaryActions() {
    if (this.props.secondaryActions == 'hide') {
      return;
    }

    const secondaryActions = Array.isArray(this.props.secondaryActions) ? this.props.secondaryActions.slice() : [this.props.secondaryActions];

    return <ActionBar>
        {...keyElements(secondaryActions)}
        {secondaryActions.filter(e => !!e).find(e => e.type == StandardTableActions) == null ? <StandardTableActions /> : undefined}
      </ActionBar>
  }

  onResize = () => {
    this.setState({});
  }

  onEdit = () => {
    if (this.props.onEdit) {
      this.props.onEdit(this.table);
      setTimeout(() => this.table?.focus?.());
    }
  }

  onCancelEdits = () => {
    this.props.onCancelEdits?.(this.table);
    this.table.cancelChanges();
    setTimeout(() => this.table?.focus?.());
    this.props.onStopEditing?.(this.table);
  }

  onSaveEdits = (table:DataTable) => {
    const result = this.props.onSaveEdits?.(table);
    this.props.onStopEditing?.(table);

    return result;
  }

  onAction = async (handler:ActionHandler, requireSelection:boolean, event:React.MouseEvent<any>, setTableFocus?:boolean) => {
    if (!handler) {
      return;
    }

    if (requireSelection && isEmpty(this.table.selections.selectedItems)) {
      NotificationManager.add({ type: 'warning', message: 'Please select one or more rows' });
      this.table?.focus?.();
      return;
    }

    if (!ReactIs.isElement(handler)) {
      handler = handler.bind(handler, this.table);
    }

    await handleOnClickOrModal(handler as unknown as OnClickOrElement, event);

    if (setTableFocus) {
      this.table?.focus?.();
    }

    this.table?.clearSelection?.();
  }

  onHistoryChange = (location: Location, action: HistoryAction) => {
    if ((action != 'PUSH' && action != 'POP') || this.props.onNavigation == 'nothing' || !this.props.editing) {
      return;
    }

    this.onCancelEdits();
  }

  onGroupSelectionChange = (event:React.ChangeEvent<GroupByPickerType>) => {
    this._groupBy = event.currentTarget.value;
    this.setState({});
  }
}
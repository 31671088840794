import * as React from 'react'

import { SearchDropdown, SearchDropdownProps } from 'app2/views';

import { useAdminUsersQuery, AdminUsersSelections } from './generated';

export function UserDropdown(props:SearchDropdownProps<AdminUsersSelections>) {
  function render() {
    return <SearchDropdown query={useAdminUsersQuery} variables={variables} renderItem={renderUser} {...props} />
  }

  function renderUser(user:AdminUsersSelections) {
    return `${user.name} - ${user.email} (${user.id})`
  }

  function variables(term:string) {
    return [{search:term}]
  }

  return render();
}

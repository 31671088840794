import * as React from 'react';
import { useParams } from 'react-router';

import { CancelConfirmationField, Info, Form, Modal, MenuItem, OptionsMenu, Section, SaveableResult, Tabs } from 'app2/components';

import { UserRouteParams } from '../adminRoutes';
import { AdminPage } from '../AdminPage';

import { UserInfo } from './UserInfo';
import { Sites } from './Sites';
import { Companies } from './Companies';
import { Students } from './Students';
import { Enrollments } from './Enrollments';
import { Events } from './Events';
import { activateUser, deactivateUser, deleteUser } from './generated';

export function User() {
  const { id } = useParams<UserRouteParams>();

  function render() {
    return <AdminPage title='User' actions={renderActions()}>
      <Tabs baseUrl={`/admin2/users/${id}/:tab`} urlParameter='tab' tabs={[
        {label:'Information', name: 'info', content: <UserInfo />}, 
        {label:'Schools', name:'schools', content: <Sites />},
        {label:'Providers', name:'providers', content: <Companies />},
        {label:'Students', name:'students', content: <Students />},
        {label:'Enrollments', name:'enrollments', content: <Enrollments />},
        {label:'Events', name:'events', content: <Events />},
      ]} />
    </AdminPage>
  }

  function renderActions() {
    return <OptionsMenu>
      <MenuItem label="Activate user" onClick={activate} />
      <MenuItem label="Deactivate user (undoable)" onClick={deactivate} />
      <MenuItem label="Delete user (permament)" onClick={deleteU} />
    </OptionsMenu>
  }

  async function activate() {
    const result = await Modal.warning('Deactivate user', 'Are you sure you want to activate this user?', true);

    if (result.result == SaveableResult.cancel) {
      return;
    }

    const [success] = await activateUser({variables:{id}});
    return success;
  }

  async function deactivate() {
    const info = [
      'This action signs the user out of their account and prevents them from accessing it in the future.',
      'Email addresses associated with deactivated accounts are reserved.',
      'If a user attempts to sign in to an deactivated account, the following message will be displayed: "This account no longer exists. If you need further assistance, please contact support at hello@homeroom.com."'
    ]
    const result = await Modal.warning('Deactivate user', info, true);

    if (result.result == SaveableResult.cancel) {
      return;
    }

    const [success] = await deactivateUser({variables:{id}});
    return success;
  }

  async function deleteU() {
    const modal = <Modal title="Delete user">
      <Form title='THIS CAN NOT BE UNDONE'>
        <Info type='error' title='Attention' message={[
          'This will anonymize PII and other sensitive information associated with their account.',
          'This action signs the user out of their account and prevents them from accessing it in the future.',
          'If a user attempts to sign in to an deactivated account, the following message will be displayed: "This account no longer exists. If you need further assistance, please contact support at hello@homeroom.com."',
        ]} />
        
        <Section name='cancel' component={CancelConfirmationField} />
      </Form>
    </Modal>

    const result = await Modal.show(modal);

    if (result.result == SaveableResult.cancel) {
      return;
    }

    const [success] = await deleteUser({variables:{id}});
    return success;
  }

  return render();
}

import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminUserEnrollmentsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type AdminUserEnrollmentsQuery = {
  adminUser?:
    | {
        enrollments?:
          | Array<
              | {
                  id: string;
                  status: Types.EnrollmentStatusEnum;
                  student?: { id: string; name: string } | null | undefined;
                  course: { id: string; name?: string | null | undefined; status: Types.CourseStatus };
                  parent?: { id: string; name?: string | null | undefined } | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const AdminUserEnrollmentsDocument = gql`
  query AdminUserEnrollments($id: ID!) {
    adminUser(user: $id) {
      enrollments {
        id
        status
        student {
          id
          name
        }
        course {
          id
          name
          status
        }
        parent {
          id
          name
        }
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminUserEnrollmentsArgs = Omit<UseQueryArgs<AdminUserEnrollmentsQueryVariables, AdminUserEnrollmentsQuery>, 'query'>;

export function useAdminUserEnrollmentsQuery(options: AdminUserEnrollmentsArgs = {}) {
  return useQuery<AdminUserEnrollmentsQueryVariables, AdminUserEnrollmentsQuery>({ query: AdminUserEnrollmentsDocument, ...options });
}

export type AdminUserEnrollmentsOptions = ExecQueryOptions<AdminUserEnrollmentsQueryVariables>;

export function adminUserEnrollments(options: AdminUserEnrollmentsOptions) {
  return executeQuery<AdminUserEnrollmentsQuery, AdminUserEnrollmentsQueryVariables>(AdminUserEnrollmentsDocument, options);
}

export type AdminUserEnrollmentsSelections = AdminUserEnrollmentsQuery['adminUser'];

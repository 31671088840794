import * as React from 'react'

import { MessageEntityInput } from 'app2/api';
import { DateText, Field, HBox, HRule, Img, Info, Link, Panel, Repeater, Text, VBox, LONG_DATE_FORMAT, TIME_FORMAT } from 'app2/components';

import { BackLink } from 'app2/views/shared-public';

import megaphone from './create/megaphone.png';
import { MessageParticipants } from './MessageParticipants';
import { useBaseMessageUrl } from './useBaseMessageUrl';
import { useThreadId } from './useThreadId';
import { useNavigateToFirstMessage } from './useNavigateToFirstMessage';
import { useMessageThreadsQuery } from './generated'

export interface MessageListProps {
  labelOrParticipant?:MessageEntityInput;
  nonSystemMessages?: boolean;
  systemMessages?: boolean;
  entityMessages?:boolean;
  primaryActions?:React.ReactNode;
  inline?:boolean
  role: 'parent' | 'user' | 'organizer' | 'provider';
}

export function ThreadList(props:MessageListProps) {
  const {labelOrParticipant, nonSystemMessages, systemMessages, entityMessages, primaryActions, inline, role} = props;

  const { scoped, id, threadId, threadScope } = useThreadId();
  const {loading, threads} = getThreads();
  const baseUrl = useBaseMessageUrl();
  const broadcastRole = (role == 'organizer' || role == 'provider');

  useNavigateToFirstMessage(inline, loading ? null : threads.threads)

  function render() {
    return <Panel initialValues={threads} primaryActions={primaryActions} p='$16' flex={1} mb={0} width='100%' minWidth={0} overflow='auto'>
      {renderBack()}
      {renderScopedHeader()}
      <Repeater name='threads' delimeter={<HRule />} layout='vbox' gap='$12' width='100%' render={renderThreadEntry} none={renderNone()} />
    </Panel>
  }

  function renderNone() {
    if (loading) {
      return '';
    }

    return !scoped || !broadcastRole
      ? <Text text='body'>No messages</Text>
      : <Text text='body'>No replies yet!</Text>
  }

  function renderBack() {
    if (!scoped) {
      return <></>;
    }

    return <BackLink to={baseUrl + '/messages'}>Back to all messages</BackLink>
  }

  function renderScopedHeader() {
    if (!scoped || !broadcastRole) {
      return
    }

    return <Info icon={false} small type='warning' layout='hbox' gap='$8' vAlign='top'><Img src={megaphone} width='16px' />This is a broadcast message. Replies from recipients will only be visible to you. Other recipients will not see their responses.</Info>
  }

  function renderThreadEntry(thread:typeof threads.threads[0], index:number) {
    const isCurrent = thread.id == threadId || thread.id == id;
    const read = thread.read;
    const broadcast = thread?.broadcast && thread?.entityMember && broadcastRole;
    const url = broadcast && thread.id.indexOf('-') == -1
      ? `${baseUrl}/messages/${thread.id}-`
      : `${baseUrl}/messages/${thread.id}`

    return <Link to={url} underline={false} hover={false} width='100%' bg={isCurrent ? 'backgroundMediumGrey' : undefined} padding='$8' borderRadius='form'>
      <VBox gap='$4' width='100%'>
        <HBox width='100%' vAlign='center' gap='$8'>
          <Field name="participants" component={MessageParticipants} maxLines={1} flex={1} text='subtitle2' color='body' bold={!read} />
          {broadcast && <Img src={megaphone} width='16px' />}
          <Field name="updatedAt" component={DateText} dateFormat={`${LONG_DATE_FORMAT} ${TIME_FORMAT}`} color='disabledText' bold />
        </HBox>
        <VBox flex={1}>
          {thread.systemMessage && <Field name="subject" width='100%' maxLines={1} text='body' color='subtitle2' bold={!read} />}
          <Field name="preview" none='no message' width='100%' maxLines={1} text='body' color='subtitle2' bold={!read}  />
        </VBox>
        {thread?.labels?.[0]?.id != labelOrParticipant?.id && <Field name="labels.0.name" none={false} maxLines={1} text='body' color='disabled' />}
      </VBox>
    </Link>
  }

  function getThreads() {
    const pause = !(labelOrParticipant?.type == 'User' || labelOrParticipant?.id != null);
    const [result] = useMessageThreadsQuery({variables:{labelOrParticipant, nonSystemMessages, systemMessages, entityMessages, threadId: scoped ? threadId : undefined}, autoPause: false, pause});
    const threads = React.useMemo(() => ({threads: result.data?.msgThreads}), [result.data?.msgThreads]);

    return {loading: result.loading, threads};
  }

  return render();
}

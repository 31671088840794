import { appendCols, DataTable, DataTableColumn, GroupedDataTableHandle, expandCols, removeCols } from 'app2/components';

import { ContentType, createReportViewFromTable, downloadReport, ReportColumn, TableView, ReportView, reportCols } from '../datatable';

import { rosterColsSortFilterParams, createRosterView } from './createRosterView';
import { byAll } from './byAll';

export type ViewAttribute = Omit<(keyof TableView), 'groups' | 'sort'>

export function downloadRoster(fileName:string | any[], contentType:ContentType, query:string, variables:any, groupedOrRegularTable:DataTable | GroupedDataTableHandle, view?:TableView, viewAttributes:ViewAttribute[]  = ['title'], appendRemainingColumns?:boolean) {
  return downloadReport(fileName, contentType, createRosterReportFromTable(contentType, query, variables, groupedOrRegularTable, view, viewAttributes, appendRemainingColumns));
}

export function createRosterReportFromTable(contentType:ContentType, query:string, variables:any, groupedOrRegularTable:DataTable | GroupedDataTableHandle, view?:TableView, viewAttributes:ViewAttribute[] = ['title'], appendRemainingColumns?:boolean):ReportView {
  const report = createReportViewFromTable(query, variables, groupedOrRegularTable, rosterColsSortFilterParams);

  view = createRosterView({...variables, scaleCols: true}, view);

  if (viewAttributes.includes('title')) {
    report.title = view.title;
  }

  if (viewAttributes.includes('groups')) {
    report.groups = reportCols(view.groups as DataTableColumn[]);
  }

  // for csv we'll append whatever columns are not already specified in the view
  const oldCols = report.cols;

  if (viewAttributes.includes('cols')) {
    report.cols = reportCols(view.cols as DataTableColumn[]);
  }

  // for csv append most all of the other columns not alread specified
  if (appendRemainingColumns || (appendRemainingColumns === undefined && contentType == ContentType.CSV)) {
    const viewWithCols = createRosterView({...variables, cols: removeCols(byAll.cols as DataTableColumn[], report.groups)});

    report.cols = appendCols(report.cols, reportCols(viewWithCols.cols)) as ReportColumn[];
    report.cols = appendCols(report.cols, reportCols(oldCols)) as ReportColumn[];
  }

  return report;
}

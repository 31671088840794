import * as React from 'react';
import { omit } from 'lodash-es';

import { CourseKind, CourseStatus, authorizedPreferences, authorizedCoursePreferenceKeys } from 'app2/api';
import { mergeCols } from 'app2/components';
import { courseKindBehavior } from 'app2/views/shared-public';
import { CoursesTable, useCourseCols, useCourseTableState, nonCancelledStatuses } from 'app2/views/shared';

import { courseColumnSets } from '../../course-table';

import { CourseSelections } from '../generated';

const PREFS_VERSION = "1"

interface Props {
  course: CourseSelections;
}

export function Games(props:Props) {
  const course = props.course;
  const state = useCourseTableState();

  const queryVars = {courseId: course?.id, kinds: [CourseKind.Game], statuses: nonCancelledStatuses};
  const behavior = courseKindBehavior[CourseKind.Game];

  function render() {
    return <CoursesTable
      header={{icon:behavior.icon, title:behavior.label, options: []}} kind={CourseKind.Game}
      table={{editable:false}}
      queryVars={{...queryVars, totals: [nonCancelledStatuses]}}
      courseModel={getCourseModel()}
      state={state}
      showAppend={true}
      defaultValues={getCourseDefaults()}
      prefs={authorizedPreferences.coursePreferences(course?.id)}
      prefsKey={authorizedCoursePreferenceKeys.courseVariantsTable}
      prefsVersion={PREFS_VERSION}
    />
  }

  function getCourseModel() {
    const courseModel = useCourseCols({site:course?.site?.id, courses:state.coursesCollection, queryVars, timezone: course?.site?.timezone});

    return React.useMemo(() => {
      return {
        ...courseModel, 
        cols: mergeCols(courseColumnSets.games.published.cols.base, courseModel.cols)
      }
    }, [courseModel])
  }

  function getCourseDefaults() {
    if (!course) {
      return {};
    }

    return {
      ...omit(course, 'id', 'courseDays', 'childVariantCourses', 'kind', 'name'),
      searchable: true
    }
  }

  return render();
}

import * as React from 'react';

import { Donation, Enrollment } from 'app2/api';
import { Body, DateField, Field, FieldValue, formatDecimalAligned, HBox, Panel, Phone, TabletOrDesktop, Repeater, Table, Thead, Tbody, Tr, VBox, FieldRendererProps, Icon, Text } from 'app2/components';
import { DownloadLink, EnrollmentActivityLink, PaymentStatusTag } from 'app2/views/shared';
import { PublicCompanyLink, PublicCourseLink } from 'app2/views/shared-public';

import { WithdrawAction } from './WithdrawAction';
import { useParentOrdersQuery, ParentOrdersSelections as Parent } from './generated';

type Order = Extract<Parent['receipts'][0], { items: any }>;
type Refund = Extract<Parent['receipts'][0], { item: any }>;
type OrderItem = Order['items'][0];
type Product = OrderItem['product'];

export function PurchaseHistory() {
  const [result] = useParentOrdersQuery();
  const parent = result.data?.user;

  function render() {
    return <Panel icon="DollarSign" title="Purchase history" initialValues={parent || {}}>
      {renderPurchaseHistoryDesktop()}
      {renderPurchaseHistoryPhone()}
    </Panel>
  }

  function renderPurchaseHistoryDesktop() {
    return <TabletOrDesktop>
      <Table text="subtitle2" gapY="$10" gapX='4px'>
        <Thead borderBottom="solid 1px" borderColor="border" rows={[['Date', 'Description', 'Bill period', 'Provider', 'Name', formatDecimalAligned('Amount'), 'Status', 'Receipt', '']]} />
        <Tbody>
          <Repeater name="receipts" render={renderDesktopRow} />
        </Tbody>
      </Table>
    </TabletOrDesktop>
  }

  function renderPurchaseHistoryPhone() {
    return (
      <Phone>
        <Repeater name="receipts" render={renderPhoneRow} />
      </Phone>
    );
  }

  function renderDesktopRow(receipt: Order | Refund) {
    return <Tr verticalAlign="baseline" borderTop="solid 1px" borderBottom="solid 1px" borderColor="border" cells={renderRow(receipt)} />;
  }

  function renderPhoneRow(receipt: Order | Refund) {
    const [date, productNames, billPeriod, companyNames, studentNames, amounts, paymentStatus, receiptLink, refundLinks] = renderRow(receipt);

    return <VBox borderBottom="solid 1px" borderColor="border" pb="$20">
      <HBox vAlign="center">
        {date}
        <HBox flex={1} />
        {receiptLink}
      </HBox>
      <HBox>
        {productNames}
        {amounts}
        <HBox flex={1} />
        {refundLinks}
      </HBox>
      <HBox>
        {paymentStatus}
        <HBox flex={1} />
      </HBox>
    </VBox>
  }

  function renderRow(orderOrReceipt: Order | Refund) {
    return 'items' in orderOrReceipt ? renderPurchaseRow() : renderRefundRow();
  }

  function renderPurchaseRow() {
    // note the use of lineHeight where we repeat enrollments
    // in an order ... this is needed because of the hacky where
    // we are simulating rows across the table...w/o this, things
    // won't align properly.

    return [
      <Field name="createdAt" component={DateField} label="Date" />,
      <Repeater name="items" layout="vbox" flex={100}>
        <Field name="id" maxLines={1} lineHeight="28px" render={props => renderOrderItemDescription(props.info.record.product)} label="Description" />
      </Repeater>,
      <Repeater name="items" layout="vbox">
        <Field name="product.billPeriod" label="Bill period" lineHeight="28px" />
      </Repeater>,
      <Repeater name="items" layout="vbox">
        <Field name="product.course.company.name" maxLines={1} lineHeight="28px" component={PublicCompanyLink} none={false} label="Provider" />
      </Repeater>,
      <VBox>
        <Repeater name="items" layout="vbox">
          <Field name="product.student.name" label="Name" lineHeight="28px" />
        </Repeater>
        <Body bold>Total purchase</Body>
      </VBox>,
      <VBox>
        <Repeater name="items">
          <Field name="total" text="body" whiteSpace="nowrap" lineHeight="28px" render={renderItemTotal} label="Amount" />
        </Repeater>
        <Field name="total" text="body" bold whiteSpace="nowrap" lineHeight="28px" format={formatDecimalAligned} />
      </VBox>,
      <Field name="paymentStatus" render={props => <PaymentStatusTag paymentStatus={props.info.record.status} paymentFailureReason={props.info.record.paymentFailureReason} />} label="Status" />,
      <Field name="receipt" render={props => <DownloadLink icon="Download" url={`/orders/${props.info.record.id}/receipt`} mimeType="application/pdf" data-test="Link(Download)" />} label="Receipt" />,
      <Repeater name="items" layout="vbox">
        <Field name="refund" render={props => renderRefundAction(props.info.record.product)} />
      </Repeater>
    ];
  }

  function renderOrderItemDescription(product: Product) {
    return isEnrollment(product) ? renderEnrollmentOrderItemDescription(product) : renderDonationOrderItemDescription(product);
  }

  function renderEnrollmentOrderItemDescription(product: Product) {
    // note that this is relyng on the fact that currently an order
    // with a description will only have 1 item in the order
    // if that assumption becomes invalid things won't align
    // the issue is that we are two repeating lists - orders and then
    // items in the order - because we are currently not normalizing
    // this into a single list of rows (instead doing rows within rows per column)
    // it relies on a fix row height for things to align.
    // to fix we need to extract out all order items from an order
    // and put those in the same structure as the order attributes (total)
    // so that each line height will be indepdent and work properly

    const enrollment = (product as unknown) as Enrollment;

    return <VBox maxWidth='500px'>
      <EnrollmentActivityLink enrollment={enrollment} maxLines={1} lineHeight="28px" role='public' />
      {enrollment?.priceConfig?.updateDescription && <Text text="body" lineHeight="28px">{enrollment?.priceConfig?.updateDescription}</Text>}
    </VBox>
  }

  function renderDonationOrderItemDescription(product: Product) {
    const donation = (product as unknown) as Donation;

    return <FieldValue maxLines={1} lineHeight="28px">
    {donationDescription(donation)}
    </FieldValue>
  }

  function renderItemTotal(props: FieldRendererProps<any, string>) {
    const total = props.info.record.total;
    const formattedTotal = formatDecimalAligned(total);
    const enrollment = (props.info.record.product as unknown) as Enrollment;
    const enrollmentPriceTierUse = enrollment.enrollmentPriceTierUse;
    const tooltip = enrollmentPriceTierUse ? `${enrollmentPriceTierUse.cartPriceTierUse.name} discount` : undefined;

    return (
      <FieldValue data-field="Amount" lineHeight="28px">
        <>
          {formattedTotal}
          {tooltip && <Icon name="Info" size="small" tooltip={tooltip} ml="$4" />}
        </>
      </FieldValue>
    );
  }

  function renderRefundAction(product: Product) {
    if (!isEnrollment(product)) {
      return <></>;
    }

    const enrollment = (product as unknown) as Enrollment;

    return <WithdrawAction enrollment={enrollment} />;
  }

  function isEnrollment(product: Product) {
    return product.typename.toLowerCase().includes('enrollment');
  }

  function renderRefundRow() {
    return [
      <Field name="createdAt" component={DateField} />,
      <Field name="id" render={renderRefundDescription} maxLines={1} flex={100} />,
      <Field name="item.product.billPeriod" label="Bill period" lineHeight="28px" />,
      <Field name="item.product.course.company.name" component={PublicCompanyLink} maxLines={1} lineHeight="28px" />,
      <Field name="item.product.student.name" maxLines={1}  lineHeight="28px" />,
      <Field name="total" text="body" format={(val: number) => formatDecimalAligned(-val)} whiteSpace="nowrap" data-field="Amount" lineHeight="28px"  />,
      <Field name="paymentStatus" render={props => <PaymentStatusTag paymentStatus={props.info.record.status} />} label="Status" />,
      <Field name="receipt" render={props => <DownloadLink icon="Download" url={`/refunds/${props.info.record.id}/receipt`} mimeType="application/pdf" data-test="Link(Download)" />} />,
      <Field name="refund" none={false} />
    ];
  }

  function renderRefundDescription(props: FieldRendererProps<Refund>) {
    const refund = props.info.record;
    const prefix = `${refund.void ? 'Void' : 'Refund'}:`;
    const product = refund?.item?.product;

    if (isEnrollment((product as unknown) as Product)) {
      return renderEnrollmentRefundDescription(prefix, (product as unknown) as Enrollment, refund);
    } else {
      return renderDonationRefundDescription(prefix, (product as unknown) as Donation);
    }
  }

  function renderEnrollmentRefundDescription(prefix: string, enrollment: Enrollment, refund: Refund) {
    return <VBox flex={100} maxWidth='500px'>
      <PublicCourseLink value={`${prefix} ${enrollment?.course?.name}`} course={enrollment.course} lineHeight="28px" />
      {refund.description && <Text text="body" lineHeight="28px">{refund.description}</Text>}
    </VBox>;
  }

  function renderDonationRefundDescription(prefix: string, donation: Donation) {
    return <VBox flex={100}>
      <Text text="body" lineHeight="28px">{`${prefix} ${donationDescription(donation)}`}</Text>;
      </VBox>;
  }

  function donationDescription(donation: Donation) {
    return `Donation to ${donation?.site?.charityName || donation?.site?.name}`;
  }


  return render();
}

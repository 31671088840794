import * as React from 'react'
import { SystemStyleObject } from '@styled-system/css';

import { CellRenderProps } from '../datatable';
import { hoveredItemAlpha, componentToHex, blendColor, palette } from '../theme';
import { Box, BoxProps } from '../Box';
import { Checkbox } from '../Checkbox';

import { List } from './List';

export interface ListItemRendererProps extends BoxProps, Partial<CellRenderProps> {
  list?:List;
}

export function ListItemRenderer(props:ListItemRendererProps) {
  const {list, style, className, table, col, colPos, rowPos, errors, children, readOnly, disabled, measuring, focus, ...remaining} = props;
  const selected = list.state.selectedIndexes.indexOf(rowPos) != -1;
  const bg = componentToHex(blendColor(palette.primary.green.components, hoveredItemAlpha));
  const css = {':hover': {bg}};

  function render() {
    return props.list.props.selectedStyle == 'bar'
      ? renderBarStyle()
      : renderCheckboxStyle()
  }

  function renderBarStyle() {
    return <Box css={css} borderLeft='solid 4px' borderColor={selected ? 'primary' : 'rgb(0, 0, 0, 0)'} text='body' className={className} style={{padding: '8px', cursor: 'pointer', ...props.style}} tooltip={list.props.tooltips || list.props.maxLines ? children : undefined} {...remaining}>{children}</Box>;
  }

  function renderCheckboxStyle() {
    return <Box css={css} text='body' layout='hbox' vAlign='center' cursor='pointer' p='8px' pl='10px' className={className} style={props.style} {...remaining}><Checkbox checked={selected} label={children} readOnly tooltip={list.props.tooltips || list.props.maxLines ? children : undefined} /></Box>;
  }

  return render();
}


import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SiteWithTimezoneQueryVariables = Types.Exact<{
  site: Types.Scalars['ID'];
}>;

export type SiteWithTimezoneQuery = { site?: { id: string; name?: string | null | undefined; timezone?: string | null | undefined } | null | undefined };

export const SiteWithTimezoneDocument = gql`
  query SiteWithTimezone($site: ID!) {
    site(site: $site) {
      id
      name
      timezone
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type SiteWithTimezoneArgs = Omit<UseQueryArgs<SiteWithTimezoneQueryVariables, SiteWithTimezoneQuery>, 'query'>;

export function useSiteWithTimezoneQuery(options: SiteWithTimezoneArgs = {}) {
  return useQuery<SiteWithTimezoneQueryVariables, SiteWithTimezoneQuery>({ query: SiteWithTimezoneDocument, ...options });
}

export type SiteWithTimezoneOptions = ExecQueryOptions<SiteWithTimezoneQueryVariables>;

export function siteWithTimezone(options: SiteWithTimezoneOptions) {
  return executeQuery<SiteWithTimezoneQuery, SiteWithTimezoneQueryVariables>(SiteWithTimezoneDocument, options);
}

export type SiteWithTimezoneSelections = SiteWithTimezoneQuery['site'];

import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type VendorInvitesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type VendorInvitesQuery = { user?: { vendorInvites?: Array<{ token: string; site: { name?: string | null | undefined } }> | null | undefined } | null | undefined };

export const VendorInvitesDocument = gql`
  query VendorInvites {
    user: currentUser {
      vendorInvites {
        token
        site {
          name
        }
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type VendorInvitesArgs = Omit<UseQueryArgs<VendorInvitesQueryVariables, VendorInvitesQuery>, 'query'>;

export function useVendorInvitesQuery(options: VendorInvitesArgs = {}) {
  return useQuery<VendorInvitesQueryVariables, VendorInvitesQuery>({ query: VendorInvitesDocument, ...options });
}

export type VendorInvitesOptions = ExecQueryOptions<VendorInvitesQueryVariables>;

export function vendorInvites(options: VendorInvitesOptions) {
  return executeQuery<VendorInvitesQuery, VendorInvitesQueryVariables>(VendorInvitesDocument, options);
}

export type VendorInvitesSelections = VendorInvitesQuery['user'];

import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminReviewsQueryVariables = Types.Exact<{
  sorts?: Types.InputMaybe<Array<Types.Sort> | Types.Sort>;
  filters?: Types.InputMaybe<Array<Types.Filter> | Types.Filter>;
}>;

export type AdminReviewsQuery = {
  reviews?:
    | Array<{
        id: string;
        createdAt?: string | null | undefined;
        status?: string | null | undefined;
        comments?: string | null | undefined;
        instructorRating?: number | null | undefined;
        overallRating?: number | null | undefined;
        qualityRating?: number | null | undefined;
        course?: { name?: string | null | undefined } | null | undefined;
        site?: { id: string; name?: string | null | undefined } | null | undefined;
        company?: { id: string; name?: string | null | undefined } | null | undefined;
        student?: { firstName: string; lastName: string } | null | undefined;
        parent?: { firstName: string; lastName: string } | null | undefined;
      }>
    | null
    | undefined;
};

export const AdminReviewsDocument = gql`
  query AdminReviews($sorts: [Sort!], $filters: [Filter!]) {
    reviews(sorts: $sorts, filters: $filters, pageSize: 1000) {
      id
      createdAt
      status
      comments
      instructorRating
      overallRating
      qualityRating
      course {
        name
      }
      site {
        id
        name
      }
      company {
        id
        name
      }
      student {
        firstName
        lastName
      }
      parent {
        firstName
        lastName
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminReviewsArgs = Omit<UseQueryArgs<AdminReviewsQueryVariables, AdminReviewsQuery>, 'query'>;

export function useAdminReviewsQuery(options: AdminReviewsArgs = {}) {
  return useQuery<AdminReviewsQueryVariables, AdminReviewsQuery>({ query: AdminReviewsDocument, ...options });
}

export type AdminReviewsOptions = ExecQueryOptions<AdminReviewsQueryVariables>;

export function adminReviews(options: AdminReviewsOptions) {
  return executeQuery<AdminReviewsQuery, AdminReviewsQueryVariables>(AdminReviewsDocument, options);
}

export type AdminReviewsSelections = AdminReviewsQuery['reviews'][0];

(AdminReviewsDocument as any).additionalTypenames = ['Review'];

import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import pluralize from 'pluralize';
import * as qs from 'query-string';

import { observer } from 'app/observable';
import { eventManager } from 'app2/api';

import { MarketAreaStore } from 'app2/api/public';
import {
  HBox,
  Heading1,
  Subtitle2,
  BreakpointProps,
  buildBreadCrumbStr,
  LoadMore,
  withBreakpoints,
  Loader
} from 'app2/components';

import { Page } from '../../shared-public';

import { ProviderCard } from './ProviderCard';

const PAGE_SIZE = 99;

interface Props
  extends BreakpointProps,
    RouteComponentProps<{ slug: string }> {}

@observer
export class InnerMarketAreaProviders extends React.Component<Props> {
  store: MarketAreaStore = new MarketAreaStore();

  get url() {
    return `/in/${this.store.marketArea.slug}/providers`;
  }

  get page() {
    return Math.max(qs.parse(this.props.location.search).page || 0, 0);
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(_: Props) {
    this.loadData();
  }

  async loadData() {
    try {
      const slug = this.props.match.params.slug;
      const promises = this.store.load(slug);

      if (!promises.length) {
        return;
      }

      promises.push(this.store.loadProviderPage(PAGE_SIZE, this.page));

      await Promise.all(promises);

      this.logPageView();
    } catch (_) {}
  }

  logPageView() {
    eventManager.log('viewCityProvidersPage', {
      breadCrumbs: buildBreadCrumbStr(this.crumbs),
      totalNumberOfProviders: this.store.providers.pagination.count
    });
  }

  get crumbs() {
    if (!this.store.marketArea) {
      return [];
    }

    return [
      {
        label: this.store.marketArea.name,
        url: `/in/${this.store.marketArea.slug}`
      },
      {
        label: 'Providers',
        url: this.url
      }
    ];
  }

  render() {
    if (!this.store.marketArea || !this.store.providers) {
      return <Loader />;
    }

    const providers = this.store.providers;
    const numPerRow = [1, 2, 3][this.props.breakpoint];

    const title = `${this.store.marketArea.name}: Browse Local Activity Providers`;
    const description = `Discover local providers of kids activities covering a wide range of subjects including, art, music, cooking, stem and coding near ${this.store.marketArea.name}.`;

    return <Page head={{title, description}} crumbs={this.crumbs} useMaxWidth header footer>
      <HBox mb="$40" width="100%" vAlign="center">
        <Heading1>Providers in your region</Heading1>
        <HBox flex="1" />
        <Subtitle2 mr="$15">
          {providers.pagination.count}{' '}
          {pluralize('provider', providers.pagination.count)}
        </Subtitle2>
      </HBox>
      <LoadMore
        width="100%"
        numPages={providers.pagination.pages}
        numPerRow={numPerRow}
        load={this.loadProviders}
        component={ProviderCard}
        dataKey={this.store.slug}
      />
    </Page>
  }

  loadProviders = async (page: number) => {
    const promise = this.store.loadProviderPage(PAGE_SIZE, page);

    if (promise) {
      await promise;
    }

    return this.store.providers.records;
  };
}

export const MarketAreaProviders = withBreakpoints(
  withRouter(InnerMarketAreaProviders),
  ['635px', '1040px']
);

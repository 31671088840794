import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
import { CommonCompanyFragmentDoc } from '../../shared/generated/commonCompany.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompanyWithCoursesQueryVariables = Types.Exact<{
  company: Types.Scalars['ID'];
}>;

export type CompanyWithCoursesQuery = {
  company?:
    | {
        timezone?: string | null | undefined;
        courseKindGroups: Array<Types.CourseKindGroup>;
        hasCourses: boolean;
        hasRequests: boolean;
        id: string;
        name?: string | null | undefined;
        userCompanyRole?: Types.MemberRole | null | undefined;
        nonPartnerApproved?: boolean | null | undefined;
      }
    | null
    | undefined;
};

export const CompanyWithCoursesDocument = gql`
  query CompanyWithCourses($company: ID!) {
    company(company: $company) {
      ...commonCompany
      timezone
      courseKindGroups
      hasCourses
      hasRequests
    }
  }
  ${CommonCompanyFragmentDoc}
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type CompanyWithCoursesArgs = Omit<UseQueryArgs<CompanyWithCoursesQueryVariables, CompanyWithCoursesQuery>, 'query'>;

export function useCompanyWithCoursesQuery(options: CompanyWithCoursesArgs = {}) {
  return useQuery<CompanyWithCoursesQueryVariables, CompanyWithCoursesQuery>({ query: CompanyWithCoursesDocument, ...options });
}

export type CompanyWithCoursesOptions = ExecQueryOptions<CompanyWithCoursesQueryVariables>;

export function companyWithCourses(options: CompanyWithCoursesOptions) {
  return executeQuery<CompanyWithCoursesQuery, CompanyWithCoursesQueryVariables>(CompanyWithCoursesDocument, options);
}

export type CompanyWithCoursesSelections = CompanyWithCoursesQuery['company'];

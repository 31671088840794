import * as React from 'react'

import { Text, VBox, Img } from 'app2/components'

import { Section } from './Section'
import moreThan from './more-than.png'

export function MoreThan() {
  return <Section layout={['vbox', 'hbox']} itemWidth={['100%', '50%']}>
    <VBox bg='brand'><Img src={moreThan} overflow='hidden' height={['100%', '100%', 'unset']} width={['unset', 'unset', '100%']} borderRadius={['0px 0px 150px 0px', '0px 0px 200px 0px']} style={{objectFit:'cover'}} /></VBox>
    <VBox hAlign='left' vAlign='center' px={['$16', '$40', '$60']} py={['$30', '$40', '$60']} bg='brand'>
      <Text text='heading2' mb='$8' color='brandInverse'>Purpose built for school based programs</Text>
      <Text text='body' color='brandInverse' fontSize='16px'>With our team of experts by your side, your program will run smoother and delight your community.
      <br/><br/>
      From streamlined enrollment to daily operations to billing, Homeroom is your partner in success.</Text>
    </VBox>
  </Section>
}

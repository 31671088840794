import * as React from 'react';
import { UseQueryResponse, UseQueryState } from 'urql';

import { Preferences } from 'app2/api';
import { GroupedDataTable, GroupedDataTableProps, DataTable, DataTableHeader, DataTableColumn, DataTableHeaderProps, MenuItem, SkipColPrefs, HBox, VBox, Subtitle2, noopFormatter, colId, useTablePrefs } from 'app2/components';

import { autoPauseQuery, UseQueryArgs } from '../urql';

import { useVersionedSortFilter } from './useVersionedSortFilter';

export type GeneratedQueryArgs<V = any, D = any> = Omit<UseQueryArgs<V, D>, 'query'> & {query?:UseQueryArgs<V, D>['query']};
export type GeneratedQueryHook<V = any, D = any> = (args: GeneratedQueryArgs) => UseQueryResponse<V, D>;

export type HrDataTableHeaderProps = Omit<DataTableHeaderProps, 'children'>;
export type DataTableProps<T> = Omit<GroupedDataTableProps<T>, 'data'> & Partial<Pick<GroupedDataTableProps<T>, 'data'>>;

export const MIN_COL_WIDTH = 138;

export interface HrDataQueryResult<T> {
  total?:number;
  items:T[];
  totals?:number[];
}

export interface HrDataTableProps<T> {
  header: HrDataTableHeaderProps;
  table: DataTableProps<T>;
  prefs?: Preferences;
  prefsKey?: string;
  prefsVersion?: string;
  prefsSkipAttributes?:SkipColPrefs;
  query?: GeneratedQueryHook;
  queryHook?: GeneratedQueryHook;
  queryOptions?: GeneratedQueryArgs;
  queryResponseHandler?:(response:UseQueryState<any, any>) => HrDataQueryResult<T>;
  sortFilterType: 'v1' | 'v2';
  totals?:DataTableColumn[];
  filterSummaries?:string[];
}

// a version of above that has things partial so that other base components can fill in defaults
export interface PartialHrDataTableProps<T> extends Omit<HrDataTableProps<T>, 'header' | 'table'> {
  header?: Partial<HrDataTableHeaderProps>;
  table?: Partial<DataTableProps<T>>;
}

export function HrDataTable<T>(props: HrDataTableProps<T>) {
  // currently we don't have any ui to show/hide columns, so when cols are hidden its code
  // defined and we shouldn't save that attribute for now
  const prefsSkipAttributes = (props.prefsSkipAttributes || []).concat('hidden');
  const { loadedPrefs, saveCols, resetCols } = useTablePrefs(props.table, props.prefs, props.prefsKey, props.prefsVersion, prefsSkipAttributes);
  const { cols, queryVars, handleSortFilter } = useVersionedSortFilter(props.sortFilterType, loadedPrefs.cols, props.queryOptions);
  const queryOptions = { ...props.queryOptions, pause: props.queryOptions?.pause || (props.queryOptions?.autoPause === undefined ? autoPauseQuery(props.queryOptions) : false), variables: { ...props.queryOptions?.variables, ...queryVars } };

  const [result] = (props.query || props.queryHook)?.(queryOptions as UseQueryArgs<any, any>) || []; // Codegen inserts query document.
  const info = React.useMemo(() => props.queryResponseHandler(result), [result]);
  const header = {...headerDefaults, total: info?.total, totals: renderSubtitle(), ...props.header, options: renderOptions()};
  const Table = header.groupByEnabled ? GroupedDataTable : DataTable;

  function render() {
    return (
      <DataTableHeader {...header}>
        {/* ts wont like that we toggle between GroupedDataTable and DataTable, but it works
        //@ts-ignore */}
        <Table<T> data={info?.items} onViewChange={saveCols} onSortFilter={handleSortFilter} {...tableDefaults} {...props.table} lockedCol={loadedPrefs.lockedCol} cols={cols} none={result?.fetching ? undefined : props.table?.none} />
      </DataTableHeader>
    );
  }

  function renderOptions() {
    const options = props.header.options?.slice() || [];

    if (props.prefs) {
      options.push(<MenuItem onClick={resetCols}>Reset table settings</MenuItem>);
    }

    return options;
  }

  function renderSubtitle() {
    const totals = renderTotals();
    const filters = renderFilterSummaries();

    if (!totals && !filters) {
      return;
    }

    return <VBox width='100%' gap='$12'>
      {totals}
      {filters}
    </VBox>
  }

  function renderTotals() {
    if (!props.totals) {
      return null;
    }

    const nameValues = props.totals.map((nameValue, index) => [nameValue.label, (nameValue.format || noopFormatter)(info?.totals?.[index])]);

    return renderSubtitleTable(nameValues);
  }

  function renderFilterSummaries() {
    if (!props.filterSummaries) {
      return;
    }

    const nameValues = props.filterSummaries.filter(c => c !== undefined).map((summary) => {
      const col = (cols as DataTableColumn[]).find(col => colId(col) == summary);
      return [typeof col?.label == 'string' ? `${col?.label}(s)` : col?.label, col ? (col.filter ? col.filter?.join(', ') : 'All') : '']
    })

    return renderSubtitleTable(nameValues);
  }

  function renderSubtitleTable(nameValues:any[][]) {
    if (!nameValues?.length) {
      return;
    }

    const widths = [1, 'unset', ((1 / nameValues.length) * 100)]
    return <HBox gap='$8' flexWrap='wrap' hAlign='justify' layout={['vbox', 'hbox', 'hbox']} width='100%'>{nameValues.map((nameValue, index) =>
      <VBox key={index} flex={widths} layout={['hbox', 'vbox', 'vbox']} hAlign={['justify', 'left', 'left']} gap='2px'>
        <Subtitle2 whiteSpace='nowrap'>{nameValue[0]}</Subtitle2>
        <Subtitle2 fontWeight='normal' maxLines={1}>{(nameValue[1])}</Subtitle2>
      </VBox>
    )}
    </HBox>
  }

  return render();
}

HrDataTable.defaultProps = {
  queryResponseHandler: (response:UseQueryState<any, any>) => {
    const result:any = Object.values(response?.data || {})[0];

    return !result || result?.items !== undefined ? result : {items:result};
  },
  sortFilterType: 'v1'
}

const headerDefaults = {
  width: '100%',
  mb: '$15'
}

const tableDefaults:Partial<GroupedDataTableProps<any>> = {
  editable: false,
  defaultColWidth: MIN_COL_WIDTH,
  minHeight: '370px',
  fillWidth: true,
  colHeaderHeight: 54,
  rowHeight:64,
  unstickyX: true
}

import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GameSeasonsQueryVariables = Types.Exact<{
  site: Types.Scalars['ID'];
}>;

export type GameSeasonsQuery = {
  site?:
    | {
        id: string;
        name?: string | null | undefined;
        active?: boolean | null | undefined;
        slug: string;
        streetAddress?: string | null | undefined;
        city?: string | null | undefined;
        state?: string | null | undefined;
        zipcode?: string | null | undefined;
        timezone?: string | null | undefined;
        grades?: Array<{ value: string; label: string }> | null | undefined;
        gameSeasons: Array<{ id: string; name: string; status: Types.SeasonStatus; coursesBegin: string; coursesFinish: string }>;
      }
    | null
    | undefined;
};

export const GameSeasonsDocument = gql`
  query GameSeasons($site: ID!) {
    site(site: $site) {
      id
      name
      active
      slug
      streetAddress
      city
      state
      zipcode
      timezone
      grades {
        value
        label
      }
      gameSeasons {
        id
        name
        status
        coursesBegin
        coursesFinish
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type GameSeasonsArgs = Omit<UseQueryArgs<GameSeasonsQueryVariables, GameSeasonsQuery>, 'query'>;

export function useGameSeasonsQuery(options: GameSeasonsArgs = {}) {
  return useQuery<GameSeasonsQueryVariables, GameSeasonsQuery>({ query: GameSeasonsDocument, ...options });
}

export type GameSeasonsOptions = ExecQueryOptions<GameSeasonsQueryVariables>;

export function gameSeasons(options: GameSeasonsOptions) {
  return executeQuery<GameSeasonsQuery, GameSeasonsQueryVariables>(GameSeasonsDocument, options);
}

export type GameSeasonsSelections = GameSeasonsQuery['site'];

import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
import { CartedEnrollmentFieldsFragmentDoc } from './cartedEnrollmentFields.generated';
import { QuestionFieldsFragmentDoc } from '../../../shared/custom-fields/generated/cfQuestionFields.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentUserWithCartQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ParentUserWithCartQuery = {
  user?:
    | {
        tosAccepted?: boolean | null | undefined;
        cart?:
          | {
              dueLater: number;
              subtotal: number;
              discountTotal: number;
              priceTierDiscountTotal: number;
              total: number;
              paymentMethodRequired: boolean;
              paymentService?: Types.PaymentService | null | undefined;
              priceTierUse?: { id: string; displayOrder: number; name: string; priceTierId: string; rate: number; amountOff: number } | null | undefined;
              donation?: { id: string; coverTxnFees: boolean; initialAmount: number; finalAmount: number; txnFee: number } | null | undefined;
              enrollments: Array<{
                id: string;
                amount?: number | null | undefined;
                affirmEnabled: boolean;
                pickupDropoffRequired: boolean;
                studentSelectionRequired: boolean;
                status: Types.EnrollmentStatusEnum;
                waitlistKind: Types.WaitlistKind;
                dismissal?: string | null | undefined;
                pickup?: string | null | undefined;
                discountId?: string | null | undefined;
                inGradeRange?: boolean | null | undefined;
                inAgeRange?: boolean | null | undefined;
                gradeAtStart?: string | null | undefined;
                ageAtStart?: number | null | undefined;
                startDate?: string | null | undefined;
                endDate?: string | null | undefined;
                billPeriod?: string | null | undefined;
                rosterPeriod?: string | null | undefined;
                rosterAmount?: string | null | undefined;
                listPrice: number;
                templateId?: string | null | undefined;
                isChildEnrollment: boolean;
                pendingInstances: Array<{
                  amount?: number | null | undefined;
                  affirmEnabled: boolean;
                  pickupDropoffRequired: boolean;
                  studentSelectionRequired: boolean;
                  status: Types.EnrollmentStatusEnum;
                  waitlistKind: Types.WaitlistKind;
                  dismissal?: string | null | undefined;
                  pickup?: string | null | undefined;
                  discountId?: string | null | undefined;
                  inGradeRange?: boolean | null | undefined;
                  inAgeRange?: boolean | null | undefined;
                  gradeAtStart?: string | null | undefined;
                  ageAtStart?: number | null | undefined;
                  startDate?: string | null | undefined;
                  endDate?: string | null | undefined;
                  billPeriod?: string | null | undefined;
                  rosterPeriod?: string | null | undefined;
                  rosterAmount?: string | null | undefined;
                  listPrice: number;
                  templateId?: string | null | undefined;
                  isChildEnrollment: boolean;
                  athleticsConfig?: { teamName?: string | null | undefined; students?: Array<{ name: string; email: string }> | null | undefined } | null | undefined;
                  discount?: { code?: string | null | undefined } | null | undefined;
                  discountUses: Array<{ id: string; amount: number; code: string; discountDefinitionId: string; kind: Types.DiscountKind }>;
                  student?: { id: string; name: string } | null | undefined;
                  enrollmentPriceTierUse?: { id: string } | null | undefined;
                  priceConfig?:
                    | { kind: Types.PriceConfigKind; template: boolean }
                    | { kind: Types.PriceConfigKind; unit: Types.RecurringUnit; weekdays: Array<string>; template: boolean; startDate: string; startDateDisabled: boolean }
                    | {
                        kind: Types.PriceConfigKind;
                        template: boolean;
                        deposit?: boolean | null | undefined;
                        billingDate?: string | null | undefined;
                        depositAmount?: number | null | undefined;
                        installmentDates?: Array<string> | null | undefined;
                        weekdays: Array<string>;
                      }
                    | { kind: Types.PriceConfigKind; template: boolean; usageUnit: Types.UsageUnit }
                    | null
                    | undefined;
                  courseOption?: { kind: Types.CourseOptionKind } | null | undefined;
                  parentEnrollment?: { id: string; course: { id: string; startDate?: string | null | undefined } } | null | undefined;
                  course: {
                    id: string;
                    name?: string | null | undefined;
                    kind?: Types.CourseKind | null | undefined;
                    disambiguatedName?: string | null | undefined;
                    ageGroup?: string | null | undefined;
                    gradeGroup?: string | null | undefined;
                    startDate?: string | null | undefined;
                    endDate?: string | null | undefined;
                    noEnrichmentDays: Array<string | null | undefined>;
                    classesCount: number;
                    courseFrameworkId?: string | null | undefined;
                    seasonId: string;
                    teamMaxCapacity?: number | null | undefined;
                    courseDays: Array<{ start?: string | null | undefined; finish?: string | null | undefined; days?: Array<string> | null | undefined } | null | undefined>;
                    company?: { slug: string; name?: string | null | undefined; id: string } | null | undefined;
                    site: { id: string; donationConfig: { enabled: boolean } };
                    cfQuestions?:
                      | Array<{
                          id: string;
                          ownerId?: string | null | undefined;
                          ownerType?: Types.CfOwnerKind | null | undefined;
                          kind?: Types.CfKind | null | undefined;
                          title?: string | null | undefined;
                          description?: string | null | undefined;
                          choices?: Array<string> | null | undefined;
                          stage?: Types.CfStage | null | undefined;
                          required?: boolean | null | undefined;
                          owner?: { name?: string | null | undefined } | { name: string } | { name: string } | null | undefined;
                          files?: Array<{ id: string; name: string; fileType?: string | null | undefined } | null | undefined> | null | undefined;
                        }>
                      | null
                      | undefined;
                  };
                  firstPayment?: { amount: number; dueOn: string; nextPaymentOn?: string | null | undefined; prorated: boolean; recurringAmount: number; recurringListPrice: number } | null | undefined;
                }>;
                athleticsConfig?: { teamName?: string | null | undefined; students?: Array<{ name: string; email: string }> | null | undefined } | null | undefined;
                discount?: { code?: string | null | undefined } | null | undefined;
                discountUses: Array<{ id: string; amount: number; code: string; discountDefinitionId: string; kind: Types.DiscountKind }>;
                student?: { id: string; name: string } | null | undefined;
                enrollmentPriceTierUse?: { id: string } | null | undefined;
                priceConfig?:
                  | { kind: Types.PriceConfigKind; template: boolean }
                  | { kind: Types.PriceConfigKind; unit: Types.RecurringUnit; weekdays: Array<string>; template: boolean; startDate: string; startDateDisabled: boolean }
                  | {
                      kind: Types.PriceConfigKind;
                      template: boolean;
                      deposit?: boolean | null | undefined;
                      billingDate?: string | null | undefined;
                      depositAmount?: number | null | undefined;
                      installmentDates?: Array<string> | null | undefined;
                      weekdays: Array<string>;
                    }
                  | { kind: Types.PriceConfigKind; template: boolean; usageUnit: Types.UsageUnit }
                  | null
                  | undefined;
                courseOption?: { kind: Types.CourseOptionKind } | null | undefined;
                parentEnrollment?: { id: string; course: { id: string; startDate?: string | null | undefined } } | null | undefined;
                course: {
                  id: string;
                  name?: string | null | undefined;
                  kind?: Types.CourseKind | null | undefined;
                  disambiguatedName?: string | null | undefined;
                  ageGroup?: string | null | undefined;
                  gradeGroup?: string | null | undefined;
                  startDate?: string | null | undefined;
                  endDate?: string | null | undefined;
                  noEnrichmentDays: Array<string | null | undefined>;
                  classesCount: number;
                  courseFrameworkId?: string | null | undefined;
                  seasonId: string;
                  teamMaxCapacity?: number | null | undefined;
                  courseDays: Array<{ start?: string | null | undefined; finish?: string | null | undefined; days?: Array<string> | null | undefined } | null | undefined>;
                  company?: { slug: string; name?: string | null | undefined; id: string } | null | undefined;
                  site: { id: string; donationConfig: { enabled: boolean } };
                  cfQuestions?:
                    | Array<{
                        id: string;
                        ownerId?: string | null | undefined;
                        ownerType?: Types.CfOwnerKind | null | undefined;
                        kind?: Types.CfKind | null | undefined;
                        title?: string | null | undefined;
                        description?: string | null | undefined;
                        choices?: Array<string> | null | undefined;
                        stage?: Types.CfStage | null | undefined;
                        required?: boolean | null | undefined;
                        owner?: { name?: string | null | undefined } | { name: string } | { name: string } | null | undefined;
                        files?: Array<{ id: string; name: string; fileType?: string | null | undefined } | null | undefined> | null | undefined;
                      }>
                    | null
                    | undefined;
                };
                firstPayment?: { amount: number; dueOn: string; nextPaymentOn?: string | null | undefined; prorated: boolean; recurringAmount: number; recurringListPrice: number } | null | undefined;
              }>;
              sites: Array<{
                id: string;
                name?: string | null | undefined;
                slug: string;
                checkoutDestinations?: Array<string> | null | undefined;
                pickupDestinations?: Array<string> | null | undefined;
                usingAch: boolean;
                donationConfig: { enabled: boolean };
                priceTiers: Array<{ id: string; displayOrder: number; name: string } | null | undefined>;
                waivers?: Array<{ id: string; name: string } | null | undefined> | null | undefined;
              }>;
              seasons: Array<{
                id: string;
                name: string;
                siteId: string;
                cfQuestions?:
                  | Array<{
                      id: string;
                      ownerId?: string | null | undefined;
                      ownerType?: Types.CfOwnerKind | null | undefined;
                      kind?: Types.CfKind | null | undefined;
                      title?: string | null | undefined;
                      description?: string | null | undefined;
                      choices?: Array<string> | null | undefined;
                      stage?: Types.CfStage | null | undefined;
                      required?: boolean | null | undefined;
                      owner?: { name?: string | null | undefined } | { name: string } | { name: string } | null | undefined;
                      files?: Array<{ id: string; name: string; fileType?: string | null | undefined } | null | undefined> | null | undefined;
                    }>
                  | null
                  | undefined;
              }>;
            }
          | null
          | undefined;
        creditCard?: { id: string; brand: Types.CardBrand; expiration: string; last4: string; name?: string | null | undefined } | null | undefined;
        usBankAccount?: { fingerprint: string; last4: string; bankName: string; routingNumber: string } | null | undefined;
        students?: Array<{ id: string; name: string; upToDate?: boolean | null | undefined; grade: string; age?: number | null | undefined }> | null | undefined;
      }
    | null
    | undefined;
};

export const ParentUserWithCartDocument = gql`
  query ParentUserWithCart {
    user: currentUser {
      cart {
        priceTierUse {
          id
          displayOrder
          name
          priceTierId
          rate
          amountOff
        }
        donation {
          id
          coverTxnFees
          initialAmount
          finalAmount
          txnFee
        }
        dueLater
        subtotal
        discountTotal
        priceTierDiscountTotal
        total
        paymentMethodRequired
        paymentService
        enrollments {
          id
          amount
          ...cartedEnrollmentFields
          pendingInstances {
            amount
            ...cartedEnrollmentFields
          }
        }
        sites {
          id
          name
          slug
          checkoutDestinations
          pickupDestinations
          usingAch
          donationConfig {
            enabled
          }
          priceTiers {
            id
            displayOrder
            name
          }
          waivers {
            id
            name
          }
        }
        seasons {
          id
          name
          siteId
          cfQuestions {
            ...questionFields
          }
        }
      }
      creditCard {
        id
        brand
        expiration
        last4
        name
      }
      usBankAccount {
        fingerprint
        last4
        bankName
        routingNumber
      }
      students {
        id
        name
        upToDate
        grade
        age
      }
      tosAccepted
    }
  }
  ${CartedEnrollmentFieldsFragmentDoc}
  ${QuestionFieldsFragmentDoc}
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type ParentUserWithCartArgs = Omit<UseQueryArgs<ParentUserWithCartQueryVariables, ParentUserWithCartQuery>, 'query'>;

export function useParentUserWithCartQuery(options: ParentUserWithCartArgs = {}) {
  return useQuery<ParentUserWithCartQueryVariables, ParentUserWithCartQuery>({ query: ParentUserWithCartDocument, ...options });
}

export type ParentUserWithCartOptions = ExecQueryOptions<ParentUserWithCartQueryVariables>;

export function parentUserWithCart(options: ParentUserWithCartOptions) {
  return executeQuery<ParentUserWithCartQuery, ParentUserWithCartQueryVariables>(ParentUserWithCartDocument, options);
}

export type ParentUserWithCartSelections = ParentUserWithCartQuery['user'];

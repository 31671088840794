import * as React from 'react'
import { omitBy, isUndefined } from 'lodash-es';

import { HBox, BoxProps  } from '../Box';
import { Icon, IconNames } from '../icons';
import { Popup, PopupProps } from '../Popup';
import { Link } from '../Link';
import { convertElements } from '../utils';

import { Menu } from './Menu';
import { MenuItem } from './MenuItem';

export interface MenuDropdownProps extends BoxProps {
  icon?:IconNames;
  label?:React.ReactNode;
  rightAligned?:boolean;
  nested?:boolean;
  dropdown?:Partial<PopupProps>;
}

export function MenuDropdown(props:React.PropsWithChildren<MenuDropdownProps>) {
  const [open, setOpen] = React.useState(false);
  const children = convertElements(props.children, Link, MenuItem)

  if (!children.length) {
    return <></>;
  }

  const {m, ml, mr, mt, mb, mx, my} = props;

  return <Popup trigger={renderMenuLabel(props, open)} rightAligned={props.rightAligned} onOpen={() => setOpen(true)} onClose={() => setOpen(false)} 
    offset={(props.nested ? submenuOffset : undefined)} forceVisible={props.nested} hover={props.nested} {...omitBy({m, ml, mr, mt, mb, mx, my}, isUndefined)} {...props.dropdown}>
    <Menu tabIndex={0}>
      {children}
    </Menu>
  </Popup>
}

function renderMenuLabel(props:React.PropsWithChildren<MenuDropdownProps>, open:boolean) {
  const {icon, label, rightAligned, nested, children, m, ml, mr, mt, mb, mx, my, fontSize, small, text, dropdown, ...remaining} = props;
  const iconNames:IconNames[] = !nested ? ['DropdownClose', 'DropdownOpen'] : ['Right', 'Right']
  const test = props['aria-label'] || (typeof label == 'string' ? label : undefined);
  
  return <HBox data-test={test ? `Menu(${test})` : 'Menu'} cursor='pointer' vAlign='center' hAlign='center' hItemSpace='$12' link display='inline-flex' {...remaining}>
    {typeof label == 'string' ? <MenuItem fontWeight={700} autoLoader={false} {...{fontSize, small, text}}>{label}</MenuItem> : label}
    <Icon name={icon ? icon : iconNames[1 - Number(open)]} size='medium' />
  </HBox>;
}


MenuDropdown.defaultProps = {
  rightAligned: true
}

const submenuOffset = {x: 80, y: 0}
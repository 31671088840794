import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
import { CourseCardFieldsFragmentDoc } from '../../../shared-public/course/course-card/generated/coursesCardFields.generated';
import {
  BreakdownFieldsFragmentDoc,
  ConfigurableSeasonBreakdownFieldsFragmentDoc,
  RecurringBreakdownFieldsFragmentDoc,
  UsageBreakdownFieldsFragmentDoc
} from '../../course/breakdowns/generated/breakdownFields.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CourseQueryVariables = Types.Exact<{
  courseId: Types.Scalars['ID'];
}>;

export type CourseQuery = {
  course?:
    | {
        id: string;
        status: Types.CourseStatus;
        kind?: Types.CourseKind | null | undefined;
        token: string;
        name?: string | null | undefined;
        description?: string | null | undefined;
        room?: string | null | undefined;
        grades?: Array<string | null | undefined> | null | undefined;
        ageMin?: number | null | undefined;
        ageMax?: number | null | undefined;
        hasCheckoutOptions: boolean;
        startDate?: string | null | undefined;
        endDate?: string | null | undefined;
        classesCount: number;
        enrollmentOpens?: string | null | undefined;
        enrollmentCloses?: string | null | undefined;
        minCapacity?: number | null | undefined;
        maxCapacity?: number | null | undefined;
        teamMaxCapacity?: number | null | undefined;
        supplies?: Array<string | null | undefined> | null | undefined;
        hasEnrolledStudents: boolean;
        cartedCount: number;
        noEnrichmentDays: Array<string | null | undefined>;
        courseImage?: string | null | undefined;
        courseCardImage?: string | null | undefined;
        scholarshipCode?: string | null | undefined;
        searchable: boolean;
        feeType?: Types.SiteFeeTypeEnum | null | undefined;
        feeRate?: number | null | undefined;
        effectiveSiteFeeType: Types.SiteFeeTypeEnum;
        effectiveSiteFeeRate: number;
        closed: boolean;
        waitlistOpen: boolean;
        usageBillingDate?: string | null | undefined;
        behavior?:
          | {
              options?: boolean | null | undefined;
              info?: boolean | null | undefined;
              enrollments?: boolean | null | undefined;
              billing?: boolean | null | undefined;
              attendance?: boolean | null | undefined;
              attendanceLink?: boolean | null | undefined;
              messages?: boolean | null | undefined;
              reviews?: boolean | null | undefined;
              enrollmentAdd?: boolean | null | undefined;
              enrollmentAddWithDiscount?: boolean | null | undefined;
              enrollmentAddExistingStudent?: boolean | null | undefined;
              enrollmentsChangeSubscription?: boolean | null | undefined;
              enrollmentsChangeSeasonPlan?: boolean | null | undefined;
              enrollmentsMove?: boolean | null | undefined;
              enrollmentsPromote?: boolean | null | undefined;
              enrollmentsRemove?: boolean | null | undefined;
              enrollmentsSetGroups?: boolean | null | undefined;
              enrollmentsReenroll?: boolean | null | undefined;
              enrollmentsRefund?: boolean | null | undefined;
              studentUpdate?: boolean | null | undefined;
            }
          | null
          | undefined;
        courseFramework?: { id: string; name: string; description: string; typename: 'CourseFramework' } | null | undefined;
        courseTags: Array<{ id: string; name?: string | null | undefined; typename: 'CourseTag' } | null | undefined>;
        vendor?:
          | {
              id: string;
              email?: string | null | undefined;
              phone?: string | null | undefined;
              displayName: string;
              name: string;
              typename: 'Vendor';
              company?: { id: string; userCompanyRole?: Types.MemberRole | null | undefined } | null | undefined;
              cancellationPolicy?: { text: string } | null | undefined;
            }
          | null
          | undefined;
        teacher?: { id: string; name: string; email?: string | null | undefined; phone?: string | null | undefined; typename: 'Teacher' } | null | undefined;
        courseDays: Array<{ days?: Array<string> | null | undefined; start?: string | null | undefined; finish?: string | null | undefined } | null | undefined>;
        parentVariantCourse?: { id: string; name?: string | null | undefined; kind?: Types.CourseKind | null | undefined } | null | undefined;
        childVariantCourses: Array<{
          enrolledCount: number;
          id: string;
          name?: string | null | undefined;
          status: Types.CourseStatus;
          kind?: Types.CourseKind | null | undefined;
          grades?: Array<string | null | undefined> | null | undefined;
          ageMin?: number | null | undefined;
          ageMax?: number | null | undefined;
          noEnrichmentDays: Array<string | null | undefined>;
          maxCapacity?: number | null | undefined;
          startDate?: string | null | undefined;
          endDate?: string | null | undefined;
          classesCount: number;
          courseImage?: string | null | undefined;
          courseCardImage?: string | null | undefined;
          closed: boolean;
          waitlistOpen: boolean;
          hasCheckoutOptions: boolean;
          searchable: boolean;
          courseTags: Array<{ id: string; name?: string | null | undefined } | null | undefined>;
          company?: { id: string; name?: string | null | undefined } | null | undefined;
          courseDays: Array<{ days?: Array<string> | null | undefined; start?: string | null | undefined; finish?: string | null | undefined } | null | undefined>;
          prices: {
            season: { days?: number | null | undefined; price?: number | null | undefined };
            seasons: Array<{ days?: number | null | undefined; price?: number | null | undefined }>;
            recurring: Array<{ days?: number | null | undefined; unit?: Types.RecurringUnit | null | undefined; price?: number | null | undefined }>;
            dropIn: { price?: number | null | undefined };
            usage: Array<{ unit?: Types.UsageUnit | null | undefined; price?: number | null | undefined }>;
          };
          teacher?: { name: string } | null | undefined;
          site: { id: string; branding?: { logo?: string | null | undefined } | null | undefined; siteCompanies: Array<{ id: string }> };
        }>;
        options: Array<
          | { id: string; checkout: boolean; description?: string | null | undefined; kind: Types.CourseOptionKind; required: boolean; title: string; choices: Array<{ id: string; name?: string | null | undefined }> }
          | null
          | undefined
        >;
        rates: {
          season: {
            rate?: number | null | undefined;
            homeroomFeeRoundingIncrementCents?: number | null | undefined;
            materialsRate?: number | null | undefined;
            depositAmount?: number | null | undefined;
            installmentDates?: Array<string> | null | undefined;
          };
          seasons: Array<{
            days?: number | null | undefined;
            rate?: number | null | undefined;
            homeroomFeeRoundingIncrementCents?: number | null | undefined;
            materialsRate?: number | null | undefined;
            depositAmount?: number | null | undefined;
            installmentDates?: Array<string> | null | undefined;
          }>;
          recurring: Array<{
            days?: number | null | undefined;
            unit?: Types.RecurringUnit | null | undefined;
            rate?: number | null | undefined;
            homeroomFeeRoundingIncrementCents?: number | null | undefined;
            startDateDisabled?: boolean | null | undefined;
          }>;
          dropIn: { rate?: number | null | undefined; homeroomFeeRoundingIncrementCents?: number | null | undefined };
          usage: Array<{
            unit?: Types.UsageUnit | null | undefined;
            rate?: number | null | undefined;
            homeroomFeeRoundingIncrementCents?: number | null | undefined;
            roundingIncrement?: number | null | undefined;
            gracePeriod?: number | null | undefined;
          }>;
        };
        priceBreakdowns: {
          season?:
            | {
                discountAmount?: number | null | undefined;
                homeroomCharges?: number | null | undefined;
                listPrice?: number | null | undefined;
                listPriceBeforeDiscount?: number | null | undefined;
                price?: number | null | undefined;
                priceBeforeDiscount?: number | null | undefined;
                siteCharges?: number | null | undefined;
                siteCreditCardFee?: number | null | undefined;
                siteReceives?: number | null | undefined;
                vendorCharges?: number | null | undefined;
                vendorCreditCardFee?: number | null | undefined;
                vendorReceives?: number | null | undefined;
              }
            | null
            | undefined;
          seasons?:
            | Array<{
                discountAmount?: number | null | undefined;
                homeroomCharges?: number | null | undefined;
                listPrice?: number | null | undefined;
                listPriceBeforeDiscount?: number | null | undefined;
                price?: number | null | undefined;
                priceBeforeDiscount?: number | null | undefined;
                siteCharges?: number | null | undefined;
                siteCreditCardFee?: number | null | undefined;
                siteReceives?: number | null | undefined;
                vendorCharges?: number | null | undefined;
                vendorCreditCardFee?: number | null | undefined;
                vendorReceives?: number | null | undefined;
                days?: number | null | undefined;
              }>
            | null
            | undefined;
          recurring?:
            | Array<{
                discountAmount?: number | null | undefined;
                homeroomCharges?: number | null | undefined;
                listPrice?: number | null | undefined;
                listPriceBeforeDiscount?: number | null | undefined;
                price?: number | null | undefined;
                priceBeforeDiscount?: number | null | undefined;
                siteCharges?: number | null | undefined;
                siteCreditCardFee?: number | null | undefined;
                siteReceives?: number | null | undefined;
                vendorCharges?: number | null | undefined;
                vendorCreditCardFee?: number | null | undefined;
                vendorReceives?: number | null | undefined;
                days?: number | null | undefined;
                unit?: Types.RecurringUnit | null | undefined;
              }>
            | null
            | undefined;
          dropIn?:
            | {
                discountAmount?: number | null | undefined;
                homeroomCharges?: number | null | undefined;
                listPrice?: number | null | undefined;
                listPriceBeforeDiscount?: number | null | undefined;
                price?: number | null | undefined;
                priceBeforeDiscount?: number | null | undefined;
                siteCharges?: number | null | undefined;
                siteCreditCardFee?: number | null | undefined;
                siteReceives?: number | null | undefined;
                vendorCharges?: number | null | undefined;
                vendorCreditCardFee?: number | null | undefined;
                vendorReceives?: number | null | undefined;
              }
            | null
            | undefined;
          usage?:
            | Array<{
                discountAmount?: number | null | undefined;
                homeroomCharges?: number | null | undefined;
                listPrice?: number | null | undefined;
                listPriceBeforeDiscount?: number | null | undefined;
                price?: number | null | undefined;
                priceBeforeDiscount?: number | null | undefined;
                siteCharges?: number | null | undefined;
                siteCreditCardFee?: number | null | undefined;
                siteReceives?: number | null | undefined;
                vendorCharges?: number | null | undefined;
                vendorCreditCardFee?: number | null | undefined;
                vendorReceives?: number | null | undefined;
                unit?: Types.UsageUnit | null | undefined;
              }>
            | null
            | undefined;
        };
        prices: {
          season: { price?: number | null | undefined };
          seasons: Array<{ days?: number | null | undefined; price?: number | null | undefined }>;
          recurring: Array<{ days?: number | null | undefined; unit?: Types.RecurringUnit | null | undefined; price?: number | null | undefined }>;
          dropIn: { price?: number | null | undefined };
          usage: Array<{ unit?: Types.UsageUnit | null | undefined; price?: number | null | undefined }>;
        };
        priceConfigs?: Array<{ kind: Types.PriceConfigKind } | { kind: Types.PriceConfigKind } | { usingInstallments: boolean; kind: Types.PriceConfigKind } | { kind: Types.PriceConfigKind }> | null | undefined;
        season: { id: string; coursesBegin: string; coursesFinish: string; hasConfigurablePricing: boolean; hasNonSeasonPricing: boolean };
        site: {
          id: string;
          slug: string;
          name?: string | null | undefined;
          partner: boolean;
          usingPriceTiers: boolean;
          timezone?: string | null | undefined;
          usingPickups: boolean;
          userSiteRole?: Types.MemberRole | null | undefined;
          branding?: { logo?: string | null | undefined } | null | undefined;
        };
        cfQuestions?: Array<{ id: string; kind?: Types.CfKind | null | undefined; title?: string | null | undefined }> | null | undefined;
      }
    | null
    | undefined;
};

export const CourseDocument = gql`
  query Course($courseId: ID!) {
    course(course: $courseId) {
      id
      status
      kind
      token
      behavior {
        options
        info
        enrollments
        billing
        attendance
        attendanceLink
        messages
        reviews
        enrollmentAdd
        enrollmentAddWithDiscount
        enrollmentAddExistingStudent
        enrollmentsChangeSubscription
        enrollmentsChangeSeasonPlan
        enrollmentsMove
        enrollmentsPromote
        enrollmentsRemove
        enrollmentsSetGroups
        enrollmentsReenroll
        enrollmentsRefund
        studentUpdate
      }
      courseFramework {
        typename: __typename
        id
        name
        description
      }
      name
      description
      courseTags {
        typename: __typename
        id
        name
      }
      vendor {
        typename: __typename
        id
        email
        phone
        company {
          id
          userCompanyRole
        }
        displayName
        cancellationPolicy {
          text
        }
        name
      }
      teacher {
        typename: __typename
        id
        name
        email
        phone
      }
      room
      grades
      ageMin
      ageMax
      courseDays {
        days
        start
        finish
      }
      parentVariantCourse {
        id
        name
        kind
      }
      childVariantCourses {
        ...courseCardFields
        enrolledCount
      }
      hasCheckoutOptions
      options {
        id
        checkout
        choices {
          id
          name
        }
        description
        kind
        required
        title
      }
      startDate
      endDate
      classesCount
      enrollmentOpens
      enrollmentCloses
      minCapacity
      maxCapacity
      teamMaxCapacity
      rates {
        season {
          rate
          homeroomFeeRoundingIncrementCents
          materialsRate
          depositAmount
          installmentDates
        }
        seasons {
          days
          rate
          homeroomFeeRoundingIncrementCents
          materialsRate
          depositAmount
          installmentDates
        }
        recurring {
          days
          unit
          rate
          homeroomFeeRoundingIncrementCents
          startDateDisabled
        }
        dropIn {
          rate
          homeroomFeeRoundingIncrementCents
        }
        usage {
          unit
          rate
          homeroomFeeRoundingIncrementCents
          roundingIncrement
          gracePeriod
        }
      }
      priceBreakdowns {
        season {
          ...breakdownFields
        }
        seasons {
          ...configurableSeasonBreakdownFields
        }
        recurring {
          ...recurringBreakdownFields
        }
        dropIn {
          ...breakdownFields
        }
        usage {
          ...usageBreakdownFields
        }
      }
      prices {
        season {
          price
        }
        seasons {
          days
          price
        }
        recurring {
          days
          unit
          price
        }
        dropIn {
          price
        }
        usage {
          unit
          price
        }
      }
      priceConfigs {
        kind
        ... on SeasonPriceConfig {
          usingInstallments
        }
      }
      supplies
      hasEnrolledStudents
      cartedCount
      noEnrichmentDays
      courseImage
      courseCardImage
      scholarshipCode
      season {
        id
        coursesBegin
        coursesFinish
        hasConfigurablePricing
        hasNonSeasonPricing
      }
      searchable
      site {
        id
        slug
        name
        partner
        usingPriceTiers
        timezone
        usingPickups
        userSiteRole
        branding {
          logo
        }
      }
      feeType
      feeRate
      effectiveSiteFeeType
      effectiveSiteFeeRate
      cfQuestions {
        id
        kind
        title
      }
      closed
      waitlistOpen
      usageBillingDate
    }
  }
  ${CourseCardFieldsFragmentDoc}
  ${BreakdownFieldsFragmentDoc}
  ${ConfigurableSeasonBreakdownFieldsFragmentDoc}
  ${RecurringBreakdownFieldsFragmentDoc}
  ${UsageBreakdownFieldsFragmentDoc}
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type CourseArgs = Omit<UseQueryArgs<CourseQueryVariables, CourseQuery>, 'query'>;

export function useCourseQuery(options: CourseArgs = {}) {
  return useQuery<CourseQueryVariables, CourseQuery>({ query: CourseDocument, ...options });
}

export type CourseOptions = ExecQueryOptions<CourseQueryVariables>;

export function course(options: CourseOptions) {
  return executeQuery<CourseQuery, CourseQueryVariables>(CourseDocument, options);
}

export type CourseSelections = CourseQuery['course'];

import queryString from 'query-string'
import React, { Component } from 'react'
import { Switch, withRouter, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import favicon from 'images/Favicon.png'
import brandmark from 'images/brandmark.svg'
import { eventManagerConfig, eventManager, isImpersonatingUser, amplitude } from 'app2/api'
import { Maintenance } from 'app2/views/shared-public/maintenance'
import { getAppMode } from 'app2/components/AppModes'
import { installKeyStateTracker, installMouseStateTracker, installViewHeightFix } from 'app2/components/dom-utils'
import { HelpScoutButton } from 'app2/views/shared-public/HelpScoutButton'
import { AppStatusMonitor, testModeMonitor } from './status'
import { startAnchorLogging, restartAnchorLogging } from 'helpers/logWhenVisible'
import { xpath } from 'helpers/xpath'
import { PageRoot } from 'app2/views/shared-public/page/PageRoot'
import { scriptManager } from 'app/scriptLoading/ScriptManager'
import { getBeacon } from 'app/scriptLoading/helpscout';
import { captureLogRocketSessions } from 'app/helpers'

import { routes } from '../routes'

testModeMonitor();

const title = 'After school enrichment programs made easy'
const metaDesc = 'Homeroom is comprehensive software solution for schools to manage afterschool programs, summer camps, enrichment clubs, and extended day care. Streamline enrollment, maintain accurate rosters, and ensure proper billing with Homeroom’s easy-to-use platform. Watch the video or get a demo today!'

export const UNDER_MAINTENANCE = process.env.UNDER_MAINTENANCE;
const isUnderMaintenance = JSON.parse(UNDER_MAINTENANCE || false)

class AppContainer extends Component {
  static propTypes = {
    history: PropTypes.any
  }

  constructor(props) {
    super(props)

    eventManagerConfig.gtm = getAppMode('gtm');
    installKeyStateTracker()
    installMouseStateTracker();
    installViewHeightFix();

    const params = queryString.parse(location.search)
    eventManager.onAppStart({ ...params })
  }

  componentDidMount() {
    eventManager.pageView(window.location.href)
    startAnchorLogging()
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      eventManager.pageView(window.location.href)
      restartAnchorLogging()
    }
  }

  render() {
    return (
      <div id="wrapper">
        <HelpScoutButton />
        <Helmet>
          <link href={favicon} rel="icon" sizes="16x16" type="image/png" />

          <title>{title}</title>
          <meta name="description" content={metaDesc} />

          {/* https://twitter.com/levelsio/status/1187624477795835904 */}
          <meta name="supported-color-schemes" />

          {/* Facebook tags */}
          <meta property="og:title" content={title} />
          <meta property="og:description" content={metaDesc} />
          <meta property="og:image" content={brandmark} />
          <meta property="og:url" content={this.props.match.url} />

          {/* Twitter tags */}
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <PageRoot onChangeUser={this.onChangeUser}>
          <AppStatusMonitor>
            <Switch>{isUnderMaintenance ? <Route component={Maintenance} /> : routes}</Switch>
          </AppStatusMonitor>
        </PageRoot>
      </div>
    )
  }

  onChangeUser = (user) => {
    if (user && !user.roles?.admin) {
      this.setUser(user);
    }
    else {
      this.clearUser();
    }
  }

  setUser(user) {
    scriptManager.loadNow();

    eventManager.identifyUser(user, () => {
      if (captureLogRocketSessions()) {
        // there's no value in tracking impersonators as the real user and
        // can cause some confusion, so we identify impersonated users
        // differently
        let userId = isImpersonatingUser() ? 'impersonated-' + user.id : user.id;
        let email = isImpersonatingUser()
          ? 'impersonated-' + user.email
          : user.email;

        LogRocket.identify(userId, {
          email: email,
          amplitudeId: amplitude.client?.options?.deviceId || 'unknown'
        });
      }
    });

    getBeacon()('identify', {
      user_id: user.id,
      email: user.email,
      name: user.name
    });
  }

  clearUser() {
    eventManager.identifyNoUser(() => {
      if (captureLogRocketSessions()) {
        //@ts-ignore
        LogRocket.identify(null, {
          amplitudeId: amplitude.client?.options?.deviceId || 'unknown'
        });
      }
    });
    }
}

export default withRouter(AppContainer)

// to aid in test debugging
window['$x'] = xpath;
import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GamesQueryVariables = Types.Exact<{
  season: Types.Scalars['ID'];
}>;

export type GamesQuery = {
  season?:
    | {
        id: string;
        name: string;
        games: Array<{
          id: string;
          name?: string | null | undefined;
          disambiguatedName?: string | null | undefined;
          startDate?: string | null | undefined;
          grades?: Array<string | null | undefined> | null | undefined;
          ageMin?: number | null | undefined;
          ageMax?: number | null | undefined;
          room?: string | null | undefined;
          score?: string | null | undefined;
          courseDays: Array<{ start?: string | null | undefined; finish?: string | null | undefined } | null | undefined>;
          homeTeam?: { id: string; name?: string | null | undefined; disambiguatedName?: string | null | undefined } | null | undefined;
          awayTeam?: { id: string; name?: string | null | undefined; disambiguatedName?: string | null | undefined } | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export const GamesDocument = gql`
  query Games($season: ID!) {
    season(season: $season) {
      id
      name
      games {
        id
        name
        disambiguatedName
        startDate
        courseDays {
          start
          finish
        }
        grades
        ageMin
        ageMax
        room
        homeTeam {
          id
          name
          disambiguatedName
        }
        awayTeam {
          id
          name
          disambiguatedName
        }
        score
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type GamesArgs = Omit<UseQueryArgs<GamesQueryVariables, GamesQuery>, 'query'>;

export function useGamesQuery(options: GamesArgs = {}) {
  return useQuery<GamesQueryVariables, GamesQuery>({ query: GamesDocument, ...options });
}

export type GamesOptions = ExecQueryOptions<GamesQueryVariables>;

export function games(options: GamesOptions) {
  return executeQuery<GamesQuery, GamesQueryVariables>(GamesDocument, options);
}

export type GamesSelections = GamesQuery['season'];

import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
import { QuestionFieldsFragmentDoc } from './cfQuestionFields.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerCfQuestionsQueryVariables = Types.Exact<{
  ownerType: Types.CfOwnerKind;
  ownerId: Types.Scalars['ID'];
}>;

export type OrganizerCfQuestionsQuery = {
  cfQuestions?:
    | Array<{
        filterCourses?: Array<string | null | undefined> | null | undefined;
        filterCourseKinds?: Array<Types.CourseKind | null | undefined> | null | undefined;
        id: string;
        ownerId?: string | null | undefined;
        ownerType?: Types.CfOwnerKind | null | undefined;
        kind?: Types.CfKind | null | undefined;
        title?: string | null | undefined;
        description?: string | null | undefined;
        choices?: Array<string> | null | undefined;
        stage?: Types.CfStage | null | undefined;
        required?: boolean | null | undefined;
        owner?: { name?: string | null | undefined } | { name: string } | { name: string } | null | undefined;
        files?: Array<{ id: string; name: string; fileType?: string | null | undefined } | null | undefined> | null | undefined;
      }>
    | null
    | undefined;
};

export const OrganizerCfQuestionsDocument = gql`
  query organizerCfQuestions($ownerType: CfOwnerKind!, $ownerId: ID!) {
    cfQuestions(ownerType: $ownerType, ownerId: $ownerId) {
      ...questionFields
      filterCourses
      filterCourseKinds
    }
  }
  ${QuestionFieldsFragmentDoc}
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type OrganizerCfQuestionsArgs = Omit<UseQueryArgs<OrganizerCfQuestionsQueryVariables, OrganizerCfQuestionsQuery>, 'query'>;

export function useOrganizerCfQuestionsQuery(options: OrganizerCfQuestionsArgs = {}) {
  return useQuery<OrganizerCfQuestionsQueryVariables, OrganizerCfQuestionsQuery>({ query: OrganizerCfQuestionsDocument, ...options });
}

export type OrganizerCfQuestionsOptions = ExecQueryOptions<OrganizerCfQuestionsQueryVariables>;

export function organizerCfQuestions(options: OrganizerCfQuestionsOptions) {
  return executeQuery<OrganizerCfQuestionsQuery, OrganizerCfQuestionsQueryVariables>(OrganizerCfQuestionsDocument, options);
}

export type OrganizerCfQuestionsSelections = OrganizerCfQuestionsQuery['cfQuestions'][0];

(OrganizerCfQuestionsDocument as any).additionalTypenames = ['CfQuestion'];

import * as React from 'react';

import { CourseOption, CourseOptionKind, CourseUtils, Course } from 'app2/api';
import { CheckboxField, DropdownField, FormContent, InputField, Part, Section, TextArea, VBox } from 'app2/components';
import { CoursePicker } from 'app2/views/shared';

interface Props {
  season:string;
  course:string;
  option:CourseOption;
}

export function CourseOptionEdit(props:Props) {
  function render() {
    return <FormContent>
      <Section name='title' label='Title' edit={InputField} required />
      <Section label='Options' vAlign='bottom'>
        <Part name='kind' label='Field type' component={DropdownField} options={kindOptions} required infoTip={renderInfoTip()} />
        <Part name='checkout' label='Ask at checkout' component={CheckboxField} labelProperty={null}  />
      </Section>
      <Section name='choices' label='Activities' component={CoursePicker} queryVars={{seasonId:props.season}} multiple selectedStyle='checkbox' filter={validChildCouse} required />
      <Section name='description' label='Description' placeholder='Optional description' edit={<TextArea autoSize />} />
    </FormContent>
  }

  function renderInfoTip() {
    return <VBox>
      <span><b>Single choice:</b> Pick only one (e.g., Morning elective: Sports or Swimming)</span>
      <span><b>Add-on:</b> Select any extras (e.g., Aftercare, Field trip)</span>
    </VBox>
  }

  // TODO - move this filtering to the server when we switch to the new query api
  function validChildCouse(course:Partial<Course>) {
    return CourseUtils.isEnrollable(course as Pick<Course, 'status'>) && props.course != course.id && !course.parentVariantCourse;
  }

  return render();
}

const kindLabels = {
  [CourseOptionKind.SingleChoice]: 'Single choice',
  [CourseOptionKind.MultipleChoice]: 'Add on'
};

const kindOptions = Object.values(CourseOptionKind)
  .sort((a, b) => Object.keys(kindLabels).indexOf(a) - Object.keys(kindLabels).indexOf(b))
  .map(k => ({ label: kindLabels[k], value: k }));


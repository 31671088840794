import { Filter, FilterOperator, Sort, SortDirectionEnum } from 'app2/api';
import { ColPref, colName, DataTable, DataTableColumn, DataTableColumnSort, Option, OptionValue, useStateWithDeps } from 'app2/components';

import { GeneratedQueryArgs } from './HrDataTable';
import { useMergeSortFilterParams } from './useMergeSortFilterParams';
import { useSortFilter } from './useSortFilter';

export function useVersionedSortFilter<T = any, P extends keyof T = any>(version:'v1' | 'v2', colDefs:ColPref<T, P>[], queryOptions?: GeneratedQueryArgs<T, P>) {
  return version == 'v1'
    ? useSortFilterV1(colDefs, queryOptions)
    : useSortFilterV2(colDefs, queryOptions)
}

function useSortFilterV1<T = any, P extends keyof T = any>(colDefs:ColPref<T, P>[], queryOptions?: GeneratedQueryArgs<T, P>) {
  const { cols, filters:tableFilters, sort:tableSort, handleSortFilter } = useSortFilter<T>(colDefs);
  const { sort, filters } = useMergeSortFilterParams(tableSort, tableFilters, queryOptions);

  return { cols, queryVars:{sort, filters}, handleSortFilter }
}

// new v2
type BaseQueryVars = {sort?:Sort[], filters?:Filter[]};

function useSortFilterV2<T extends BaseQueryVars = any, P extends keyof T = any>(colDefs:DataTableColumn<T, P>[], queryOptions?: GeneratedQueryArgs<T, P>) {
  const [sortFilters, setSortFilters] = useStateWithDeps(() => buildSortFilter(colDefs, queryOptions), [colDefs, queryOptions?.variables]);

  function handleSortFilter(table:DataTable<T>, col:DataTableColumn<T, P>, sort:DataTableColumnSort, filter:OptionValue[]) {
    setSortFilters(buildSortFilter(table.allCols, queryOptions));
  }

  return { cols:colDefs, queryVars:sortFilters, handleSortFilter }
}

export function buildSortFilter<T extends BaseQueryVars = any, P extends keyof T = any>(curCols:ColPref<T>[], queryOptions?: GeneratedQueryArgs<T, P>) {
  return {sorts: buildSorts(curCols), filters: buildFilters(curCols, queryOptions)}
}

export function buildSorts<T = any>(curCols:ColPref<T>[]) {
  return curCols.filter(c => c.sort).map(c => ({name: colName(c), direction: c.sort == DataTableColumnSort.ascending ? SortDirectionEnum.Asc : SortDirectionEnum.Desc}));
}

export function buildFilters<T extends BaseQueryVars = any, P extends keyof T = any>(curCols:DataTableColumn<T>[], queryOptions?: GeneratedQueryArgs<T, P>) {
  const baseFilters = queryOptions?.variables?.filters || [];
  const colFilters = curCols.filter(c => c.filter ?? false).map(c => ({name: colName(c), value: c.filter,  op: c.filterType == 'date-range' ? FilterOperator.Between : undefined})) as unknown as Filter[];

  // remove any col filters that appears in the base filters, because they override the base
  // but otherwise the base filters are always present
  const filters = baseFilters.filter(col => !colFilters.find(f => f.name == col.name)).concat(colFilters);

  return filters;
}

export type GetFilterOptionsPartial<T> = (table:DataTable<T>, col:DataTableColumn<T>) => Promise<Partial<Option>[]>
export type GetFilterOptions<T> = (table:DataTable<T>, col:DataTableColumn<T>) => Promise<Option[]>

import * as Types from '../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type StudentLogEntriesQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type StudentLogEntriesQuery = {
  student?:
    | {
        logEntries?:
          | Array<{
              id: string;
              date?: string | null | undefined;
              kind?: Types.StudentLogEntryKind | null | undefined;
              notes?: string | null | undefined;
              createdBy?: { id: string; firstName: string; lastName: string } | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const StudentLogEntriesDocument = gql`
  query StudentLogEntries($id: ID!) {
    student(student: $id) {
      logEntries {
        id
        date
        createdBy {
          id
          firstName
          lastName
        }
        kind
        notes
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type StudentLogEntriesArgs = Omit<UseQueryArgs<StudentLogEntriesQueryVariables, StudentLogEntriesQuery>, 'query'>;

export function useStudentLogEntriesQuery(options: StudentLogEntriesArgs = {}) {
  return useQuery<StudentLogEntriesQueryVariables, StudentLogEntriesQuery>({ query: StudentLogEntriesDocument, ...options });
}

export type StudentLogEntriesOptions = ExecQueryOptions<StudentLogEntriesQueryVariables>;

export function studentLogEntries(options: StudentLogEntriesOptions) {
  return executeQuery<StudentLogEntriesQuery, StudentLogEntriesQueryVariables>(StudentLogEntriesDocument, options);
}

export type StudentLogEntriesSelections = StudentLogEntriesQuery['student'];

import { EventProperties } from './EventRecorder';
import { EventRecorderProxy } from './EventRecorderProxy';
import { EventRecorderManager } from './EventRecorderManager';
import { FacebookAnalytics } from './FacebookAnalytics';
import { AmplitudeAnalytics } from './AmplitudeAnalytics';
import { GoogleTagAnalytics } from './GoogleTagAnalytics';
import { EventRecorderShim } from './EventRecorderShim';
import { CustomerIo } from './CustomerIo';

export * from './EventRecorder';

export let facebook: FacebookAnalytics;
export let amplitude: AmplitudeAnalytics;
export let gtag: GoogleTagAnalytics;
let customerio: CustomerIo;
export const eventManager: EventRecorderProxy = new EventRecorderProxy(new EventRecorderShim());

let analyticsAggregator: EventRecorderManager;

export interface EventManagerConfig {
  gtm?:string;
}

export const eventManagerConfig:EventManagerConfig = {};

export function startAnalytics() {
  facebook = new FacebookAnalytics();
  amplitude = new AmplitudeAnalytics();
  gtag = new GoogleTagAnalytics(eventManagerConfig.gtm);
  customerio = new CustomerIo();
  analyticsAggregator = new EventRecorderManager([
    facebook,
    amplitude,
    gtag,
    customerio
  ]);

  replaceShim();
}

function replaceShim() {
  if (analyticsAggregator.loaded) {
    const analyticsShim = eventManager.proxied as EventRecorderShim;

    eventManager.proxied = analyticsAggregator;
    analyticsShim.run(eventManager);
  } else {
    setTimeout(replaceShim, 100);
  }
}

// returns a function that when invoked will call the next function
export const logEventWrapper = (
  nextFn: Function = () => {},
  event: string,
  data?: EventProperties
) => {
  return function() {
    eventManager.log(event, data);
    nextFn(...[].slice.call(arguments));
  };
};

import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerSiteSchoolPoliciesQueryVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
}>;

export type OrganizerSiteSchoolPoliciesQuery = {
  site?:
    | {
        policy?:
          | {
              id: string;
              snacks?: string | null | undefined;
              bathroom?: string | null | undefined;
              classroom?: string | null | undefined;
              schoolRules?: string | null | undefined;
              parentWithdrawalsEnabled: boolean;
              parking?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const OrganizerSiteSchoolPoliciesDocument = gql`
  query OrganizerSiteSchoolPolicies($siteId: ID!) {
    site(site: $siteId) {
      policy {
        id
        snacks
        bathroom
        classroom
        schoolRules
        parentWithdrawalsEnabled
        parking
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type OrganizerSiteSchoolPoliciesArgs = Omit<UseQueryArgs<OrganizerSiteSchoolPoliciesQueryVariables, OrganizerSiteSchoolPoliciesQuery>, 'query'>;

export function useOrganizerSiteSchoolPoliciesQuery(options: OrganizerSiteSchoolPoliciesArgs = {}) {
  return useQuery<OrganizerSiteSchoolPoliciesQueryVariables, OrganizerSiteSchoolPoliciesQuery>({ query: OrganizerSiteSchoolPoliciesDocument, ...options });
}

export type OrganizerSiteSchoolPoliciesOptions = ExecQueryOptions<OrganizerSiteSchoolPoliciesQueryVariables>;

export function organizerSiteSchoolPolicies(options: OrganizerSiteSchoolPoliciesOptions) {
  return executeQuery<OrganizerSiteSchoolPoliciesQuery, OrganizerSiteSchoolPoliciesQueryVariables>(OrganizerSiteSchoolPoliciesDocument, options);
}

export type OrganizerSiteSchoolPoliciesSelections = OrganizerSiteSchoolPoliciesQuery['site'];

import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RegistrationGroupsQueryVariables = Types.Exact<{
  site: Types.Scalars['ID'];
  kindGroups?: Types.InputMaybe<Array<Types.CourseKindGroup> | Types.CourseKindGroup>;
}>;

export type RegistrationGroupsQuery = { site?: { registrationGroups?: Array<string> | null | undefined } | null | undefined };

export const RegistrationGroupsDocument = gql`
  query RegistrationGroups($site: ID!, $kindGroups: [CourseKindGroup!]) {
    site(site: $site) {
      registrationGroups(kindGroups: $kindGroups)
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type RegistrationGroupsArgs = Omit<UseQueryArgs<RegistrationGroupsQueryVariables, RegistrationGroupsQuery>, 'query'>;

export function useRegistrationGroupsQuery(options: RegistrationGroupsArgs = {}) {
  return useQuery<RegistrationGroupsQueryVariables, RegistrationGroupsQuery>({ query: RegistrationGroupsDocument, ...options });
}

export type RegistrationGroupsOptions = ExecQueryOptions<RegistrationGroupsQueryVariables>;

export function registrationGroups(options: RegistrationGroupsOptions) {
  return executeQuery<RegistrationGroupsQuery, RegistrationGroupsQueryVariables>(RegistrationGroupsDocument, options);
}

export type RegistrationGroupsSelections = RegistrationGroupsQuery['site'];

import * as Types from '../../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EnrollmentDestinationsQueryVariables = Types.Exact<{
  sourceId: Types.Scalars['ID'];
}>;

export type EnrollmentDestinationsQuery = {
  enrollmentDestinations?:
    | Array<
        | {
            id: string;
            disambiguatedName?: string | null | undefined;
            startDate?: string | null | undefined;
            endDate?: string | null | undefined;
            enrolledCount: number;
            maxCapacity?: number | null | undefined;
            courseDays: Array<{ days?: Array<string> | null | undefined; start?: string | null | undefined; finish?: string | null | undefined } | null | undefined>;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export const EnrollmentDestinationsDocument = gql`
  query EnrollmentDestinations($sourceId: ID!) {
    enrollmentDestinations(sourceId: $sourceId) {
      id
      disambiguatedName
      courseDays {
        days
        start
        finish
      }
      startDate
      endDate
      enrolledCount
      maxCapacity
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type EnrollmentDestinationsArgs = Omit<UseQueryArgs<EnrollmentDestinationsQueryVariables, EnrollmentDestinationsQuery>, 'query'>;

export function useEnrollmentDestinationsQuery(options: EnrollmentDestinationsArgs = {}) {
  return useQuery<EnrollmentDestinationsQueryVariables, EnrollmentDestinationsQuery>({ query: EnrollmentDestinationsDocument, ...options });
}

export type EnrollmentDestinationsOptions = ExecQueryOptions<EnrollmentDestinationsQueryVariables>;

export function enrollmentDestinations(options: EnrollmentDestinationsOptions) {
  return executeQuery<EnrollmentDestinationsQuery, EnrollmentDestinationsQueryVariables>(EnrollmentDestinationsDocument, options);
}

export type EnrollmentDestinationsSelections = EnrollmentDestinationsQuery['enrollmentDestinations'][0];

(EnrollmentDestinationsDocument as any).additionalTypenames = ['Course'];

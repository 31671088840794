import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminHomeroomBalancesQueryVariables = Types.Exact<{
  start: Types.Scalars['ISO8601DateTime'];
  end: Types.Scalars['ISO8601DateTime'];
}>;

export type AdminHomeroomBalancesQuery = {
  homeroom?:
    | {
        hrStripeBalance?: number | null | undefined;
        stripeBalance?: number | null | undefined;
        pendingPayoutBalance?: number | null | undefined;
        transactionTotals?: { netRevenue?: number | null | undefined; costOfRevenue?: number | null | undefined; grossProfit?: number | null | undefined } | null | undefined;
      }
    | null
    | undefined;
};

export const AdminHomeroomBalancesDocument = gql`
  query AdminHomeroomBalances($start: ISO8601DateTime!, $end: ISO8601DateTime!) {
    homeroom {
      hrStripeBalance
      stripeBalance
      pendingPayoutBalance
      transactionTotals(startDate: $start, endDate: $end) {
        netRevenue
        costOfRevenue
        grossProfit
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminHomeroomBalancesArgs = Omit<UseQueryArgs<AdminHomeroomBalancesQueryVariables, AdminHomeroomBalancesQuery>, 'query'>;

export function useAdminHomeroomBalancesQuery(options: AdminHomeroomBalancesArgs = {}) {
  return useQuery<AdminHomeroomBalancesQueryVariables, AdminHomeroomBalancesQuery>({ query: AdminHomeroomBalancesDocument, ...options });
}

export type AdminHomeroomBalancesOptions = ExecQueryOptions<AdminHomeroomBalancesQueryVariables>;

export function adminHomeroomBalances(options: AdminHomeroomBalancesOptions) {
  return executeQuery<AdminHomeroomBalancesQuery, AdminHomeroomBalancesQueryVariables>(AdminHomeroomBalancesDocument, options);
}

export type AdminHomeroomBalancesSelections = AdminHomeroomBalancesQuery['homeroom'];

import * as React from 'react'
import * as ReactDOM from 'react-dom';
import Popup from 'reactjs-popup';
import { Action, History, Location, UnregisterCallback } from 'history';

import { createKey } from '../dom-utils';
import { Shield } from '../shield';
import { ThemeProvider } from '../theme';
import { Viewport } from '../embed';

export type PopupElement = React.ReactElement;
export type PopupHandle = { remove:(action?:number, result?:any) => void; }
export type PopupHandleWithPromise<T = any> = Promise<PopupResult<T>> & PopupHandle;
export type PopupManagerProps = { manager?: PopupHandle} ;

interface PopupResult<T = any> {
  action:number;
  result:T;
}

export declare type PopupPosition = 'top left' | 'top center' | 'top right' | 'right top' | 'right center' | 'right bottom' | 'bottom left' | 'bottom center' | 'bottom right' | 'left top' | 'left center' | 'left bottom' | 'center center';

export interface PopupOptions {
  modal?:boolean;
  closeOnClick?:boolean;
  closeOnDocumentClick?:boolean;
  closeOnEscape?:boolean;
  cancelable?:boolean;
  managerProp?:boolean;
  anchor?:HTMLElement;
  position?:PopupPosition | PopupPosition[];
}

interface PopupInfo<T = any> {
  key: React.Key;
  element:PopupElement;
  promise:PopupHandleWithPromise<T>;
  handle:PopupHandle;
  accept?:(result:PopupResult<T>) => void;
  options:PopupOptions;
}

interface Props {
  history:History;
}

export const PopupHandleContext = React.createContext<PopupHandle>(null);

export class PopupManager extends React.Component<Props> {
  static instance:PopupManager;
  elements:PopupInfo[];
  history:History;
  unregisterHistory:UnregisterCallback;

  constructor(props:Props) {
    super(props);

    this.elements = [];
    PopupManager.instance = this;
  }

  componentWillUnmount(): void {
      this.updateHistory(null);
  }

  render() {
    this.updateHistory(this.props.history);

    return <ThemeProvider>{this.elements.map(info => info.element)}</ThemeProvider>
  }

  updateHistory(history:History) {
    if (this.history == history) {
      return;
    }

    if (this.unregisterHistory) {
      this.unregisterHistory()
    }

    this.history = history;

    if (this.history) {
      this.unregisterHistory = this.history.listen(this.onHistoryChange);
    }
  }

  onHistoryChange = (location: Location, action: Action) => {
    // if just the hash changed, then we don't remove popups 
    // because its the same page and the rendering change
    // could happen before we're notified.  that rendering change
    // might bring up a modal and if we removed it, it would
    // be removing the modal as soon as its rendered.  for full
    // page navigations, usually the history changes before the rendering
    // and that issue doesn't happen.

    if (this.history.location.pathname == location.pathname) {
      return;
    }

    const elements = this.elements.slice();

    elements.forEach(info => PopupManager.remove(info.key, 0));    
  }

  static addModal<T = any>(popup:PopupElement, options:PopupOptions = {cancelable: true}) {
    options.closeOnDocumentClick = options.closeOnDocumentClick !== undefined ? options.closeOnDocumentClick : options.cancelable;
    options.closeOnEscape = options.closeOnEscape !== undefined ? options.closeOnEscape : options.cancelable;
    options.modal = true;
    options.managerProp = true;

    return this.add<T>(popup, options);
  }

  static add<T = any>(popup:PopupElement, options:PopupOptions) {
    const info = this.create<T>(popup, options);

    if (!PopupManager.instance) {
      const root = document.createElement('div');
      document.body.appendChild(root);

      ReactDOM.render(<PopupManager history={null} />, root, () => this.addPopupToState(info));
    }
    else {
      this.addPopupToState(info);
    }

    return info.promise;
  }

  private static addPopupToState(popup:PopupInfo) {
    PopupManager.instance.elements = [...PopupManager.instance.elements, popup];
    PopupManager.instance.forceUpdate();
  }

  private static create<T = any>(popup:PopupElement, options:PopupOptions) {
    const key = popup.key || createKey();
    const info:PopupInfo<T> = {key, options} as PopupInfo<T>;
    const remove = () => PopupManager.remove(key, 0);

    if (!options.cancelable) {
      popup = React.cloneElement(popup, {cancelable: false});
    }

    if (options.closeOnClick) {
      popup = <div onClick={remove}>{popup}</div>
    }

    let trigger:React.ReactElement;
    const anchor = options.anchor;

    if (anchor) {
      const r = anchor.getBoundingClientRect();
      trigger = <div style={{position:'absolute', left: r.left, top: r.top, width: r.width, height: r.height}} />
    }

    const position = options.position || (options.modal ? undefined : 'bottom left');

    info.promise = info.handle = new Promise<PopupResult>((accept, reject) => info.accept = accept) as PopupHandleWithPromise<T>;
    info.promise.remove = (action:number = 0, result:any = undefined) => PopupManager.remove(key, action, result);
    info.element = <Shield key={key} modal nest>
      <Popup onClose={remove} closeOnDocumentClick={options.closeOnDocumentClick} closeOnEscape={options.closeOnEscape}
      modal={options.modal} overlayStyle={options.modal ? {background: 'rgba(0,0,0,.5)'} : undefined} trigger={trigger} position={position} open nested arrow={false}>
      <Viewport layout='vbox' hAlign='center' vAlign='center' >
        <PopupHandleContext.Provider value={info.handle}>{React.cloneElement<PopupManagerProps>(popup, {key, manager: options.managerProp ? info.handle : undefined})}</PopupHandleContext.Provider>
      </Viewport>
    </Popup>

    </Shield>;

    return info;
  }

  static remove(key:React.Key, action:number, result?:any) {
    const elements = PopupManager.instance.elements;
    const pos = elements.findIndex(info => info.key == key);

    if (pos == -1) {
      return;
    }

    const info = elements[pos];
    const updated = elements.slice();
    updated.splice(pos, 1);

    PopupManager.instance.elements = updated;
    PopupManager.instance.forceUpdate();

    info.accept({action: action || 0, result});
  }
}

import * as React from 'react';

import { BoxProps, HBox } from 'app2/components'

interface Props extends BoxProps {
}

export function Section(props:Props) {
  const {px, ...remaining} = props;

  return <HBox width='100%' maxWidth='1280px' px={px} pb={['80px', '80px', '100px']}>
    <HBox width='100%' borderRadius='standard' overflow='hidden' {...remaining}/>
  </HBox>
}

Section.defaultProps = {
  // px: ['$16', '$40']
}
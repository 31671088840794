import * as React from 'react'

import { HBox, Img, Text } from 'app2/components'

import { Section } from './Section'
import trustFairfax from './trust-fairfax.png'
import trustMiami from './trust-miamidade.png'
import trustOakland from './trust-oakland.png'
import trustPrinceton from './trust-princeton.png'
import trustSeattle from './trust-seattle.png'
import trustSfps from './trust-sfps.png'

export function Trusted() {
  return <Section layout='vbox'>
    <Text text='heading2' mb='$8'>Trusted by hundreds of schools around the country</Text>
    <Text text='subtitle2' mb='$20'>Homeroom is used by schools across the U.S., from small privately-run organizations to major public institutions.</Text>
    <HBox hItemSpace={['12px', '42px']} vAlign='center' flexWrap='wrap'>
      <div><Img src={trustFairfax} width={['64px', '81px']} /></div>
      <div><Img src={trustMiami} width={['46px', '58px']} /></div>
      <div><Img src={trustOakland} width={['123px', '154px']} /></div>
      <div><Img src={trustPrinceton} width={['68px', '59px']} /></div>
      <div><Img src={trustSeattle} width={['97px', '85px']} /></div>
      <div><Img src={trustSfps} width={['73px', '64px']} /></div>
    </HBox>
  </Section>
}

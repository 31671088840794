import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CoursesSeachQueryVariables = Types.Exact<{
  term: Types.Scalars['OptionValue'];
}>;

export type CoursesSeachQuery = {
  user?:
    | { accessibleCourses?: Array<{ id: string; disambiguatedName?: string | null | undefined; status: Types.CourseStatus; season: { id: string; name: string; site: { slug: string } } }> | null | undefined }
    | null
    | undefined;
};

export const CoursesSeachDocument = gql`
  query CoursesSeach($term: OptionValue!) {
    user: currentUser {
      accessibleCourses(filters: [{ name: "name", op: CONTAINS, value: $term }], sorts: [{ name: "startDate", direction: DESC }]) {
        id
        disambiguatedName
        status
        season {
          id
          name
          site {
            slug
          }
        }
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type CoursesSeachArgs = Omit<UseQueryArgs<CoursesSeachQueryVariables, CoursesSeachQuery>, 'query'>;

export function useCoursesSeachQuery(options: CoursesSeachArgs = {}) {
  return useQuery<CoursesSeachQueryVariables, CoursesSeachQuery>({ query: CoursesSeachDocument, ...options });
}

export type CoursesSeachOptions = ExecQueryOptions<CoursesSeachQueryVariables>;

export function coursesSeach(options: CoursesSeachOptions) {
  return executeQuery<CoursesSeachQuery, CoursesSeachQueryVariables>(CoursesSeachDocument, options);
}

export type CoursesSeachSelections = CoursesSeachQuery['user'];

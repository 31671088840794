import * as React from 'react';
import { Redirect } from 'react-router-dom';

import { Route } from 'app2/views/shared-public';

import { Schedule } from './schedule';
import { Family, Student } from './family';
import { Payment } from './payment';
import { Checkout } from './checkout';
import { ParentMessages } from './messages';
import { CourseReviewForm } from './review';

export const parentRoutes = [
  <Route path="/family/schedule/:week?" component={Schedule} role='parent' />,
  <Route path="/family/family" exact component={Family} role='parent' />,
  <Route path="/family/family/:studentId" component={Student} role='parent' />,
  <Route path="/family/payment" component={Payment} role='parent' />,
  <Route path="/family/messages" component={ParentMessages} role='parent' />,
  <Route path="/family/review/:token?" component={CourseReviewForm} role='parent' />,

  <Redirect from="/family" to="/family/schedule" exact />,
  <Redirect from="/parent/schedule" to="/family/schedule" />,
  <Redirect from="/parent/family" to="/family/family" />,
  <Redirect from="/settings/billing" to="/family/payment" />,
  <Redirect from="/parent" to="/family" />,
  <Redirect from="/family/family/:studentId/options" to="/family/family/:studentId#options" />,
]

// checkout is separated because non-parents (providers, organizer)
// should be allow to checkout because we have no concept of "current role"
export const checkout = [
  <Route path="/checkout:extra?" component={Checkout} role='logged-in' redirect='/register?role=new_parent' />,

  // old
  <Redirect from="/checkout2" to="/checkout" />,
  <Redirect from="/cart" to="/checkout" />,
  <Redirect from="/waitlist_promotion/:token" to="/checkout" />,
  <Redirect from="/course_review/:token" to="/family/review/:token" exact />,
]

export interface ParentScheduleRouteParams {
  week: string;
}

export interface ParentStudentRouteParams {
  student: string;
}

import * as React from 'react';

import { DataTableColumn, Link } from 'app2/components';
import { HrDataTable } from 'app2/views/shared';

import { useAdminEnrollingSoonSeasonsQuery, AdminEnrollingSoonSeasonsSelections } from './generated';

export function UpcomingSeasons() {
  const cols = getCols()

  function render() {
    
    return <HrDataTable<AdminEnrollingSoonSeasonsSelections>
          header={{
            icon: 'BookOpen',
            title: 'Upcoming seasons'
          }}
          table={{ cols }}
          queryHook={useAdminEnrollingSoonSeasonsQuery}
          queryOptions={{ variables: { } }}
        />
  }

  function getCols() {
    const cols: DataTableColumn<AdminEnrollingSoonSeasonsSelections>[] = [
      { label: 'Id', name: 'id' },
      { label: 'Name', name: 'name' },
      { label: 'Site', name: 'site.name' as keyof AdminEnrollingSoonSeasonsSelections },
      { label: 'Enrollment period', name: 'enrollmentPeriod', width: 250 },
      { label: 'Season period', name: 'seasonPeriod' },
      { label: 'Homeroom fee(%)', name: 'homeroomRate' },
      { label: 'Courses', name: 'totalCourses' },
      { label: 'Spots', name: 'totalSpots' },
      { label: 'Last season id', name: 'loadMetrics.id' as keyof AdminEnrollingSoonSeasonsSelections },
      { label: 'Last season name', name: 'loadMetrics.name' as keyof AdminEnrollingSoonSeasonsSelections },
      { label: 'Last season\'s courses', name: 'loadMetrics.totalCourses' as keyof AdminEnrollingSoonSeasonsSelections },
      { label: 'Last season\'s spots', name: 'loadMetrics.totalSpots' as keyof AdminEnrollingSoonSeasonsSelections },
      { label: 'Last season enrollment\'s (total)', name: 'loadMetrics.totalEnrollments' as keyof AdminEnrollingSoonSeasonsSelections, width: 200 },
      { label: 'Last season enrollment\'s (waitlisted)', name: 'loadMetrics.waitlistedEnrollments' as keyof AdminEnrollingSoonSeasonsSelections, width: 220 },
      { label: 'Last season enrollment\'s (first five min)', name: 'loadMetrics.enrollmentsInFive' as keyof AdminEnrollingSoonSeasonsSelections, width: 220 },
      { label: 'Last season enrollment\'s (first ten min)', name: 'loadMetrics.enrollmentsInTen' as keyof AdminEnrollingSoonSeasonsSelections, width: 220 },
      { label: 'Last season enrollment\'s (first fifteen min)', name: 'loadMetrics.enrollmentsInFifteen' as keyof AdminEnrollingSoonSeasonsSelections, width: 220 },
      { label: 'Last season\'s average order size', name: 'loadMetrics.purchasedPerOrder' as keyof AdminEnrollingSoonSeasonsSelections, width: 200 },

    ];

    return cols;
  }

  return render();
}

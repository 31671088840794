import { createGlobalStyle } from 'styled-components';

import { palette, selectedTextAlpha } from './palette';
import { alphaToHex } from './Color';
import { resetBrowserDefaultStyles } from './resetBrowserDefaultStyles';

const white = '#fff';
const black = '#000';

const primaryFontName = 'Muli';
const secondaryFontName = 'Grad';
const marketingFontName = 'Oooh Baby';

// style systems uses this as
// min-width on the next breakpoint
// so we need to add one so the right thing happens
export const phoneWidth = 440;
export const tabletWidth = 1024;
export const maxWidth = 1200;
const maxWidthBreakpoints = [phoneWidth + 'px', tabletWidth + 'px'];
const minWidthBreakpoints = [phoneWidth + 1 + 'px', tabletWidth + 1 + 'px'];
export enum Breakpoints {
  phone,
  tablet,
  desktop
}

type BreakpointsArray = Array<string> & { phone: string; tablet: string };

export const theme = {
  palette,
  colors: {
    white,
    transparent: '#ffffff00',
    primary: palette.primary.green.hex,
    primaryInverse: white,
    primaryHover: palette.primary.greenHover.hex,
    primarySelected: palette.primary.greenSelected.hex,
    primaryPressed: palette.primary.greenPressed.hex,
    primaryFocused: palette.primary.greenFocus.hex,
    background: white,
    formBackground: palette.tertiary.formGray.hex,
    backgroundMediumGrey: palette.tertiary.lightMediumGray.hex,
    disabled: palette.tertiary.darkGray.hex,
    disabledText: palette.tertiary.darkGray.hex + + alphaToHex(.6),
    disabledBackground: palette.tertiary.lightGray.hex,
    secondaryHover: palette.tertiary.secondaryHover.hex,
    secondaryPressed: palette.tertiary.secondaryPressed.hex,
    brand: palette.primary.mauve.hex,
    brandInverse: white,
    brandLight: palette.secondary.lightPink.hex,
    brandDark: palette.primary.darkMauve.hex,

    heading1: palette.primary.green.hex,
    heading1Hover: palette.primary.greenHover.hex,
    heading2: palette.primary.green.hex,
    heading2Hover: palette.primary.greenHover.hex,
    subtitle1: palette.primary.green.hex,
    subtitle1Hover: palette.primary.greenHover.hex,
    subtitle2: palette.primary.green.hex,
    subtitle2Hover: palette.primary.greenHover.hex,
    body: palette.tertiary.greenBlack.hex,
    bodyHover: palette.tertiary.greenBlackHover.hex,
    formlabel: palette.primary.green.hex,
    formlabelHover: palette.primary.greenHover.hex,
    input: palette.tertiary.greenBlack.hex,
    marketing: palette.tertiary.greenBlack.hex,
    marketingBg: palette.tertiary.marketing.hex,
    border: palette.tertiary.mediumGray.hex,
    placeholder: palette.tertiary.darkGray.hex,
    success: palette.tertiary.success.hex,
    successFill: palette.tertiary.successFill.hex,
    warning: palette.tertiary.warning.hex,
    warningText: palette.tertiary.warningText.hex,
    warningHover: palette.tertiary.warningHover.hex,
    warningFill: palette.tertiary.warningFill.hex,
    warningSelection: palette.tertiary.warningHover.hex + alphaToHex(selectedTextAlpha),
    warningFocused: palette.tertiary.warningFocus.hex,
    positive: 'green',
    negative: palette.tertiary.error.hex,
    error: palette.tertiary.error.hex,
    errorHover: palette.tertiary.errorHover.hex,
    errorFill: palette.tertiary.errorFill.hex,
    errorSelection: palette.tertiary.errorHover.hex + alphaToHex(selectedTextAlpha),
    errorFocused: palette.tertiary.errorFocus.hex,
    selection: '#0085FF' + alphaToHex(selectedTextAlpha),
    enrollment: palette.secondary.teal.hex,
    courseDay: palette.secondary.lightGreen.hex,
    noEnrichment: palette.secondary.lightPink.hex,
    navigation: palette.primary.navigation.hex,
    navigationLink: white,
    navigationLinkHover: white
  },

  fonts: {
    primary: primaryFontName,
    secondary: secondaryFontName,
    heading1: secondaryFontName,
    heading2: secondaryFontName,
    subtitle1: primaryFontName,
    subtitle2: primaryFontName,
    body: primaryFontName,
    formlabel: primaryFontName,
    button: primaryFontName,
    input: primaryFontName,
    marketing: marketingFontName
  },

  fontSizes: {
    heading1: '36px',
    heading2: '24px',
    subtitle1: '20px',
    subtitle2: '16px',
    body: '14px',
    formlabel: '14px',
    button: '16px',
    buttonSmall: '14px',
    input: '14px',
    marketing: '18px'
  },

  fontWeights: {
    normal: '500',
    semibold: '600',
    bold: '700'
  },

  // the fractions matter and are legit css values
  // these values map to 'normal', but we need these
  // values to support maxLines
  lineHeights: {
    heading1: '45.83px',
    heading2: '30.55px',
    subtitle1: '28px',
    subtitle2: '22.4px',
    body: '19.6px',
    formlabel: '19.6px',
    button: '22.4px',
    input: '19.6px',
    marketing: '22px'
  },

  space: {
    $4: '4px',
    $8: '8px',
    $12: '12px',
    $16: '16px',
    $20: '20px',
    $30: '30px',
    $40: '40px',
    $40_half: '20px',
    $60: '60px',
    $120: '120px',
    pageBottom: ['80px', '120px', '160px'],
    'form-content': '30px',
    'form-title-bottom': '20px',
    pannelPadding: ['16px', '16px', '30px'],
    negativePannelPadding: ['-16px', '-16px', '-30px'],

    //old
    $10: '10px',
    $15: '15px'
  },

  iconSize: {
    small: 14,
    medium: 20
  },

  breakpoints: minWidthBreakpoints as BreakpointsArray,

  borderWidths: {
    standard: '1px'
  } as any,

  radii: {
    standard: '4px',
    form: '8px'
  } as any,

  shadows: {
    box: '1px 1px 5px rgba(0, 0, 0, 0.25)'
  } as any
};

export type Theme = typeof theme;

theme.breakpoints.phone = phoneWidth + 'px';
theme.breakpoints.tablet = tabletWidth + 'px';

export const formContentClass = 'hr-form-content';
export const formTitleClass = 'hr-form-title';

export const GlobalStyles = createGlobalStyle`
  ${resetBrowserDefaultStyles}
  
  :root {
    --app-height: 100vh;
  }

  html, body, #root, #wrapper {
    overscroll-behavior-x: none;
  }

  table {
    border-collapse: collapse;
  }

  td {
    padding: 0px;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  a:hover {
    color: inherit;
    text-decoration: inherit;
  }

  .thin-scrollbars {
    scrollbar-width: 1px;
  }

  .thin-scrollbars::-webkit-scrollbar {
    width: 1px;
    height: 1px;
  }

  .${formContentClass} .${formContentClass}:not(.${formContentClass} .${formContentClass}:last-child) {
    margin-bottom: ${theme.space.$30};
  }

  .${formContentClass} .${formContentClass}:not(.${formContentClass} .${formContentClass}:last-child)::after {
    content: ' ';
    margin-top: ${theme.space.$30};
    border-bottom solid 1px ${theme.colors.border};
  }

  .${formTitleClass}:not(.${formTitleClass}:last-child) {
    margin-bottom: ${theme.space['form-title-bottom']};
  }

  ol {
    padding-left: 0px;
    margin-bottom: unset;
  }

  ol.ol-number {
    padding-left: 1.5em;
  }

  ol.ol-number li {
    display: block
  }

  ol.ol-number li:before {
    content: counter(item) ". ";
    counter-increment: item;
    display: inline-block;
    margin-left: -1.38em;
    width: 1.38em;
  }

  ol.ol-no-number li {
    list-style-type: none;
  }

  ul > li > * {
    vertical-align: text-top;
  }
  `;

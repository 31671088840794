import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerPolicyWaiversQueryVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
}>;

export type OrganizerPolicyWaiversQuery = { site?: { waivers?: Array<{ id: string; name: string } | null | undefined> | null | undefined } | null | undefined };

export const OrganizerPolicyWaiversDocument = gql`
  query OrganizerPolicyWaivers($siteId: ID!) {
    site(site: $siteId) {
      waivers {
        id
        name
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type OrganizerPolicyWaiversArgs = Omit<UseQueryArgs<OrganizerPolicyWaiversQueryVariables, OrganizerPolicyWaiversQuery>, 'query'>;

export function useOrganizerPolicyWaiversQuery(options: OrganizerPolicyWaiversArgs = {}) {
  return useQuery<OrganizerPolicyWaiversQueryVariables, OrganizerPolicyWaiversQuery>({ query: OrganizerPolicyWaiversDocument, ...options });
}

export type OrganizerPolicyWaiversOptions = ExecQueryOptions<OrganizerPolicyWaiversQueryVariables>;

export function organizerPolicyWaivers(options: OrganizerPolicyWaiversOptions) {
  return executeQuery<OrganizerPolicyWaiversQuery, OrganizerPolicyWaiversQueryVariables>(OrganizerPolicyWaiversDocument, options);
}

export type OrganizerPolicyWaiversSelections = OrganizerPolicyWaiversQuery['site'];

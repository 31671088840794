import * as React from 'react'

import { Box, Link, Text, VBox, Img } from 'app2/components'
import { marketingUrl } from 'app2/views/shared-public'

import comesFirst from './comes-first.png'

export function ComesFirst() {
  return <Box width='100%' mb='100px' layout={['vbox', 'hbox']} itemWidth={['100%', '50%']}>
    <VBox hAlign='right' vAlign='center' p={['$16', '$40', '$60']} bg='marketingBg'>
      <VBox hAlign='left' maxWidth='545px' pt='$30'>
        <Text text='heading1' mb='$8'>Operate a well-loved afterschool and summer program with ease</Text>
        <Text text='subtitle1' mb='$20'>Focus on your larger goals while Homeroom handles all your administrative tasks</Text>
        <Link button='primary' mb='$30'to='https://meetings.hubspot.com/homeroom/book-a-demo?utm_campaign=Demos%20Scheduled&amp;utm_source=Direct'>Get a demo</Link>
      </VBox>
    </VBox>
    <Img src={comesFirst} height='550px' style={{objectFit:'cover'}} />
  </Box>
}


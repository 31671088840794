import * as React from 'react'
import { useParams } from 'react-router'

import { PublicPage } from 'app2/views/shared-public'
import { Attendance as AttendanceComponent, RestrictedStudentModal } from 'app2/views/shared'

import { AttendanceRouteParams } from '../obfuscatedRoutes'

export function Attendance() {
  const { token } = useParams<AttendanceRouteParams>();
  
  return <PublicPage footer={false}>
    <AttendanceComponent token={token} url={`/activities/${token}/attendance`} studentModal={(props) => <RestrictedStudentModal token={token} id={props.id} />} header tabs />
  </PublicPage>
}

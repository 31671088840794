import * as React from 'react';

import { CourseKind } from 'app2/api';
import { ActionButton, DataTable } from 'app2/components';
import { courseKindBehavior } from 'app2/views/shared-public';

import { TableCoursesSelections } from '../generated'

interface Props {
  table:DataTable<TableCoursesSelections>;
  kind?:CourseKind;
}

export function AppendRowAction(props:Props) {
  const {table} = props;
  const behavior = courseKindBehavior[props.kind];

  return <ActionButton selection={false} icon="PlusCircle" onClick={() => table.append()} clickEvent='Click Create activity'>{behavior?.terms?.create || 'Create activity'}</ActionButton>
}
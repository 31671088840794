import * as React from 'react'
import { uniqBy } from 'lodash-es';
import { useLocation, useHistory } from 'react-router-dom';

import { DeepPartial, Enrollment, MessageServiceKind } from 'app2/api';
import { ActionButton, DataTable, NotificationManager } from 'app2/components';

import { CourseSelections } from '../../generated';

interface Props<T> {
  course:CourseSelections;
  table:DataTable<T>;
}

export function CreateMessageAction<T extends DeepPartial<Pick<Enrollment, 'isInvitation' | 'parent' | 'student'>>>(props:Props<T>) {
  const history = useHistory();
  const location = useLocation();

  function render() {
    if (!props.course?.behavior?.messages) {
      return <></>
    }

    return <ActionButton icon="MessageCircle" autoLoader={false} tooltip="Send a message" onClick={onMessage}>Message</ActionButton>
  }

  function onMessage() {
    const students = props.table.selectedItems.filter(e => !e.isInvitation);
    const hasInvites = props.table.selectedItems.length != students.length;
    
    if (hasInvites && !students.length) {
      NotificationManager.warning('Messages can not be sent to invites.');
      return
    }

    if (hasInvites) {
      NotificationManager.warning('Messages can not be sent to invites and have been filtered out of the recipient list');
    }

    const folders = location.pathname.split('/');
    folders.pop();
    folders.push('messages', 'compose');
    
    const to = folders.join('/');
    const recipients = uniqBy(students?.map(e => e.parent), 'id');
    recipients.forEach((parent:any) => {
      parent.students = students.filter(e => e.parent.id == parent.id).map(e => e.student);
    });

    history.push(to, {to: recipients, hideReplyAll: false, hideService: false, service: MessageServiceKind.Email})
  }

  return render();
}

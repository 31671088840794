import { omit } from 'lodash-es';

import { Student, DeepPartial } from 'app2/api'
import { FormModel } from 'app2/components';
import { errorPathTransform, addDeletedItems } from 'app2/views/shared';

export async function upsertStudent<T extends DeepPartial<Student>>(mutation:any, form:FormModel<T>, student:T) {
  const attributes = {
    id: form.values.id,
    firstName: form.values.firstName,
    lastName: form.values.lastName,
    nickname: form.values.nickname,
    dob: form.values.dob,
    grade: form.values.grade,
    classroomId: form.values.classroom?.id,
    medical: form.values.medical,
    notes: form.values.notes,
    parents: form.values.parents?.map?.(parent => omit(parent, ['attendanceCodeCode'])),
    emergencyContacts: addDeletedItems(student.emergencyContacts?.map?.(c => omit(c, ['attendanceCodeCode'])), form.values.emergencyContacts?.map?.(c => omit(c, ['attendanceCodeCode'])), '_destroy'),
    authorizedPickupContacts: addDeletedItems(student.authorizedPickupContacts?.map?.(c => omit(c, ['attendanceCodeCode'])), form.values.authorizedPickupContacts?.map?.(c => omit(c, ['attendanceCodeCode'])), '_destroy'),
    automaticGradeUpdate: form.values.automaticGradeUpdate
  }

  const transform = [errorPathTransform('classroom', 'classroom.id')];
  const [success] = await mutation({ variables:{attributes}, error: { handler: form, transform } });

  return success;
}

import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ProviderCompaniesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ProviderCompaniesQuery = { user?: { companies?: Array<{ id: string; name?: string | null | undefined; slug: string }> | null | undefined } | null | undefined };

export const ProviderCompaniesDocument = gql`
  query ProviderCompanies {
    user: currentUser {
      companies {
        id
        name
        slug
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type ProviderCompaniesArgs = Omit<UseQueryArgs<ProviderCompaniesQueryVariables, ProviderCompaniesQuery>, 'query'>;

export function useProviderCompaniesQuery(options: ProviderCompaniesArgs = {}) {
  return useQuery<ProviderCompaniesQueryVariables, ProviderCompaniesQuery>({ query: ProviderCompaniesDocument, ...options });
}

export type ProviderCompaniesOptions = ExecQueryOptions<ProviderCompaniesQueryVariables>;

export function providerCompanies(options: ProviderCompaniesOptions) {
  return executeQuery<ProviderCompaniesQuery, ProviderCompaniesQueryVariables>(ProviderCompaniesDocument, options);
}

export type ProviderCompaniesSelections = ProviderCompaniesQuery['user'];

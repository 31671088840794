import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerProvidersQueryVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
}>;

export type OrganizerProvidersQuery = {
  site?:
    | { vendors: Array<{ id: string; displayName: string; email?: string | null | undefined; inCompliance: boolean; phone?: string | null | undefined; company?: { id: string } | null | undefined }> }
    | null
    | undefined;
};

export const OrganizerProvidersDocument = gql`
  query OrganizerProviders($siteId: ID!) @urql(additionalTypenames: ["ComplianceRequirement", "Vendor"]) {
    site(site: $siteId) {
      vendors {
        id
        displayName
        email
        inCompliance
        phone
        company {
          id
        }
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type OrganizerProvidersArgs = Omit<UseQueryArgs<OrganizerProvidersQueryVariables, OrganizerProvidersQuery>, 'query'>;

export function useOrganizerProvidersQuery(options: OrganizerProvidersArgs = {}) {
  return useQuery<OrganizerProvidersQueryVariables, OrganizerProvidersQuery>({ query: OrganizerProvidersDocument, ...options });
}

export type OrganizerProvidersOptions = ExecQueryOptions<OrganizerProvidersQueryVariables>;

export function organizerProviders(options: OrganizerProvidersOptions) {
  return executeQuery<OrganizerProvidersQuery, OrganizerProvidersQueryVariables>(OrganizerProvidersDocument, options);
}

export type OrganizerProvidersSelections = OrganizerProvidersQuery['site'];

(OrganizerProvidersDocument as any).additionalTypenames = ['ComplianceRequirement', 'Vendor'];

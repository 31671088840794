import * as React from 'react'

import { HBox, Link, LinkProps } from 'app2/components';

import { useCart } from '../cart'

interface Props extends LinkProps {
  hideWhenEmpty?:boolean;
}

export function ShoppingCart(props:Props) {
  const {hideWhenEmpty, ...remaining} = props;
  const { cart } = useCart();
  const hasCartItems = cart.hasItems;

  if (!hasCartItems && props.hideWhenEmpty) {
    return <></>;
  }

  return <Link icon='ShoppingCart' to='/checkout' position='relative' alt='Checkout' bg='rgba(255, 255, 255, .8)' borderRadius='standard' p='2px' {...remaining}>
    {hasCartItems && <HBox position='absolute' right={0} top={0} height='8px' width='8px' borderRadius='4px' bg='red' />}
  </Link>
}

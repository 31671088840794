import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UserCcContactsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type UserCcContactsQuery = { user?: { id: string; ccContacts?: Array<{ id: string; email?: string | null | undefined }> | null | undefined } | null | undefined };

export const UserCcContactsDocument = gql`
  query UserCcContacts {
    user: currentUser {
      id
      ccContacts {
        id
        email
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type UserCcContactsArgs = Omit<UseQueryArgs<UserCcContactsQueryVariables, UserCcContactsQuery>, 'query'>;

export function useUserCcContactsQuery(options: UserCcContactsArgs = {}) {
  return useQuery<UserCcContactsQueryVariables, UserCcContactsQuery>({ query: UserCcContactsDocument, ...options });
}

export type UserCcContactsOptions = ExecQueryOptions<UserCcContactsQueryVariables>;

export function userCcContacts(options: UserCcContactsOptions) {
  return executeQuery<UserCcContactsQuery, UserCcContactsQueryVariables>(UserCcContactsDocument, options);
}

export type UserCcContactsSelections = UserCcContactsQuery['user'];

import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type InviteMemberMutationVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
  attributes: Types.InviteInput;
}>;

export type InviteMemberMutation = { siteInviteCreate?: { entity?: { id: string } | { id: string } | null | undefined } | null | undefined };

export const InviteMemberDocument = gql`
  mutation inviteMember($siteId: ID!, $attributes: InviteInput!) {
    siteInviteCreate(site: $siteId, attributes: $attributes) {
      entity {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type InviteMemberOptions = ExecMutationOptions<InviteMemberMutationVariables>;

export function inviteMember(options: InviteMemberOptions) {
  return executeMutation<InviteMemberMutation, InviteMemberMutationVariables>(InviteMemberDocument, options);
}

import * as React from 'react';

import { MemberRole } from 'app2/api';
import { FormModel } from 'app2/components';
import { Member, Team as TeamBase, removePathSegment } from 'app2/views/shared';

import { OrganizerPage, useCurrentSite } from '../shared';

import { inviteMember as _inviteMember } from './gql';
import { removeMember as _removeMember } from './gql';
import { useOrganizerTeamQuery, resendInvite, revokeInvite, setAsContact as _setAsContact } from './gql';

export function Team() {
  const { site } = useCurrentSite();
  const canEditTeam = site?.userSiteRole === MemberRole.Admin;
  const [teamQueryResult] = useOrganizerTeamQuery({ variables: { siteId: site?.slug }});
  const teamAndInvites = teamQueryResult.data?.site

  function render() {
    return (
      <OrganizerPage title="Team">
        <TeamBase members={teamAndInvites?.teamMembers} invites={teamAndInvites?.teamInvites}
          invite={inviteMember} remove={removeMember} resend={resendInvite} revoke={revokeInvite} setAsContact={setAsContact}
          canEditTeam={canEditTeam}
        />
      </OrganizerPage>
    );
  }

  async function inviteMember(form:FormModel<Member>) {
    const [result] = await _inviteMember({ variables: { siteId: site?.slug, attributes:form.values }, successMsg: 'Invitation sent', error:{handler:form, transform: removePathSegment('attributes')} });

    return result;
  }

  async function removeMember(member: Member) {
    const [result] = await _removeMember({ variables: { siteId: site?.slug, memberId: member.id }, successMsg: 'Team member removed' });

    return result;
  }

  async function setAsContact(member:Member) {
    const [result] = await _setAsContact({ variables: { siteId: site?.slug, memberId: member.id }, successMsg: `${member.name} set as the main contact` });

    return result;
  }

  return render();
}

import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type MessageThreadsQueryVariables = Types.Exact<{
  labelOrParticipant?: Types.InputMaybe<Types.MessageEntityInput>;
  nonSystemMessages?: Types.InputMaybe<Types.Scalars['Boolean']>;
  systemMessages?: Types.InputMaybe<Types.Scalars['Boolean']>;
  entityMessages?: Types.InputMaybe<Types.Scalars['Boolean']>;
  threadId?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type MessageThreadsQuery = {
  msgThreads?:
    | Array<{
        id: string;
        messageCount?: number | null | undefined;
        createdAt: string;
        updatedAt: string;
        subject?: string | null | undefined;
        preview?: string | null | undefined;
        read?: boolean | null | undefined;
        broadcast?: boolean | null | undefined;
        systemMessage?: boolean | null | undefined;
        entityMember?: boolean | null | undefined;
        participants?: Array<{ id: string; name?: string | null | undefined }> | null | undefined;
        labels?: Array<{ id: string; name?: string | null | undefined }> | null | undefined;
      }>
    | null
    | undefined;
};

export const MessageThreadsDocument = gql`
  query MessageThreads($labelOrParticipant: MessageEntityInput, $nonSystemMessages: Boolean, $systemMessages: Boolean, $entityMessages: Boolean, $threadId: String) {
    msgThreads(labelOrParticipant: $labelOrParticipant, nonSystemMessages: $nonSystemMessages, systemMessages: $systemMessages, entityMessages: $entityMessages, threadId: $threadId) {
      id
      messageCount
      participants {
        id
        name
      }
      createdAt
      updatedAt
      subject
      preview
      read
      broadcast
      systemMessage
      entityMember
      labels {
        id
        name
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type MessageThreadsArgs = Omit<UseQueryArgs<MessageThreadsQueryVariables, MessageThreadsQuery>, 'query'>;

export function useMessageThreadsQuery(options: MessageThreadsArgs = {}) {
  return useQuery<MessageThreadsQueryVariables, MessageThreadsQuery>({ query: MessageThreadsDocument, ...options });
}

export type MessageThreadsOptions = ExecQueryOptions<MessageThreadsQueryVariables>;

export function messageThreads(options: MessageThreadsOptions) {
  return executeQuery<MessageThreadsQuery, MessageThreadsQueryVariables>(MessageThreadsDocument, options);
}

export type MessageThreadsSelections = MessageThreadsQuery['msgThreads'][0];

(MessageThreadsDocument as any).additionalTypenames = ['MessageThread'];

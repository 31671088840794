import * as React from 'react';
import { isEmpty, last, sumBy } from 'lodash-es';

import { DiscountFormat, ChangePricingPeriod, PriceConfig, PriceConfigKind } from 'app2/api';
import { noOpErrorHandler } from 'app2/components';
import { EnrollmentConfig } from 'app2/views/shared-public';

import { DistinctEnrollmentsSelections } from '../../enrolled/gql';

import { EnrollmentPriceBreakdownSelections, useEnrollmentPriceBreakdownQuery } from './generated';

type Charge = EnrollmentPriceBreakdownSelections['first']['charges'][0];

export interface AddEditEnrollmentBreakdown {
  // first is only present if the first
  // charge is a) prorated, b) not billed by the system, but billed now/registration
  first?: {
    listPrice: number;
    date: string;
    refund?: number;
    prorated?: boolean;
  };
  // first regular ongoing payment
  ongoing?: {
    date: string;
  };
  // standard non-prorated pricing
  // this is needed regardless of whether there will be a
  // ongoing bill (for non-subscription and because even
  // for subscription we show this info)
  standard?: {
    listPrice: number;
    listPriceBeforeDiscount: number;
    discountAmount: number;
    priceConfig: Partial<PriceConfig>;
  };
}

export function useEnrollmentBreakdown(course: string, format: DiscountFormat, rate: number, config?: EnrollmentConfig, changingEnrollment?: DistinctEnrollmentsSelections, effective?: ChangePricingPeriod): AddEditEnrollmentBreakdown {
  const all = getBreakdowns();
  const breakdown = extractBreakdowns() as AddEditEnrollmentBreakdown;

  function getBreakdowns() {
    const invalidConfig = !config || !config.kind;
    const [result] = useEnrollmentPriceBreakdownQuery({
      variables: {
        id: course,
        changingEnrollment: changingEnrollment?.id,
        discount: { format, rate },
        kind: config?.kind,
        recurring: config?.recurring,
        dropIn: config?.dropIn,
        configurableSeason: config?.configurableSeason,
        effective
      },
      pause: invalidConfig,
      hideLoader: true,
      debounce: { delay: format ? 500 : 0 },
      error: noOpErrorHandler
    });

    return invalidConfig ? null : result.data?.enrollmentPriceBreakdown;
  }

  function extractBreakdowns() {
    return React.useMemo(() => {
      if (!all || isEmpty(all)) {
        return { standard: null, first: null } as AddEditEnrollmentBreakdown;
      }

      const priceConfig = all.priceConfig;

      if (priceConfig.kind === PriceConfigKind.Usage) {
        const charge = all.first.charges[0];
        return {
          standard: {
            listPrice: charge.amount,
            listPriceBeforeDiscount: charge.listPrice,
            discountAmount: charge.discountAmount,
            priceConfig
          }
        };
      }

      if (priceConfig.kind === PriceConfigKind.DropIn) {
        return {
          standard: sumDropIns(all.first.charges, priceConfig)
        };
      }

      const first = all.first;
      const future = all.future;
      const firstCharge = last(first.charges);
      const futureCharge = last(future?.charges);
      const result: AddEditEnrollmentBreakdown = {
        first: {
          listPrice: sumBy(first.charges, 'amount'),
          date: firstCharge.date,
          refund: first.refund,
          prorated: firstCharge.prorated
        },
        standard: {
          listPrice: futureCharge?.amount || firstCharge?.amount,
          listPriceBeforeDiscount: futureCharge?.listPrice || firstCharge?.listPrice,
          discountAmount: futureCharge?.discountAmount || firstCharge?.discountAmount,
          priceConfig
        },
        ongoing: {
          date: futureCharge?.date
        }
      };

      return result;
    }, [all]);
  }

  function sumDropIns(dropIns: Charge[], priceConfig: EnrollmentPriceBreakdownSelections['priceConfig']) {
    return dropIns.reduce<AddEditEnrollmentBreakdown['standard']>(
      (aggregate, breakdown) => {
        return {
          ...aggregate,
          discountAmount: aggregate.discountAmount + breakdown.discountAmount,
          listPrice: aggregate.listPrice + breakdown.amount,
          listPriceBeforeDiscount: aggregate.listPriceBeforeDiscount + breakdown.listPrice
        };
      },
      { priceConfig, discountAmount: 0, listPrice: 0, listPriceBeforeDiscount: 0 }
    );
  }

  return breakdown;
}

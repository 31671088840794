import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminPayoutsQueryVariables = Types.Exact<{
  kind: Types.Scalars['JSON'];
}>;

export type AdminPayoutsQuery = {
  payouts?:
    | Array<{
        id: string;
        scheduledAt?: string | null | undefined;
        status?: string | null | undefined;
        amount?: number | null | undefined;
        paymentServiceId?: string | null | undefined;
        lastError?: string | null | undefined;
        target?:
          | { id: string; name?: string | null | undefined; stripeAccount?: string | null | undefined }
          | { id: string; name?: string | null | undefined; stripeAccount?: string | null | undefined }
          | null
          | undefined;
      }>
    | null
    | undefined;
};

export const AdminPayoutsDocument = gql`
  query AdminPayouts($kind: JSON!) {
    payouts(queries: [$kind]) {
      id
      scheduledAt
      status
      amount
      paymentServiceId
      lastError
      target {
        id
        name
        stripeAccount
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminPayoutsArgs = Omit<UseQueryArgs<AdminPayoutsQueryVariables, AdminPayoutsQuery>, 'query'>;

export function useAdminPayoutsQuery(options: AdminPayoutsArgs = {}) {
  return useQuery<AdminPayoutsQueryVariables, AdminPayoutsQuery>({ query: AdminPayoutsDocument, ...options });
}

export type AdminPayoutsOptions = ExecQueryOptions<AdminPayoutsQueryVariables>;

export function adminPayouts(options: AdminPayoutsOptions) {
  return executeQuery<AdminPayoutsQuery, AdminPayoutsQueryVariables>(AdminPayoutsDocument, options);
}

export type AdminPayoutsSelections = AdminPayoutsQuery['payouts'][0];

(AdminPayoutsDocument as any).additionalTypenames = ['Payout'];

import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RemoveMemberMutationVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
  memberId: Types.Scalars['ID'];
}>;

export type RemoveMemberMutation = { siteMemberRemove?: { entity?: { id: string } | { id: string } | null | undefined } | null | undefined };

export const RemoveMemberDocument = gql`
  mutation removeMember($siteId: ID!, $memberId: ID!) {
    siteMemberRemove(site: $siteId, member: $memberId) {
      entity {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type RemoveMemberOptions = ExecMutationOptions<RemoveMemberMutationVariables>;

export function removeMember(options: RemoveMemberOptions) {
  return executeMutation<RemoveMemberMutation, RemoveMemberMutationVariables>(RemoveMemberDocument, options);
}

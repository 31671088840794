import * as React from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import { Field, Form, FormModel, Info, InputField, Saveable, Text, useForm, useStateWithDeps } from 'app2/components';

import { PublicSiteParams } from '../../publicRoutes';

import { usePublicSiteQuery } from '../registration/generated';

import { AttendanceCancel } from './AttendanceCancel';
import { PublicAttendancePage } from './PublicAttendancePage';
import { parentAttendanceStudents } from './generated';

export function PublicAttendanceCode() {
  const history = useHistory();
  const { site: siteId } = useParams<PublicSiteParams>();
  const [siteQueryResult] = usePublicSiteQuery({ variables: { id: siteId } });
  const site = siteQueryResult?.data?.site;
  const [warn, setWarn] = useStateWithDeps(false, [history.location.state]);
  const form = useForm<FormValues>({ code: '' }, [history.location.state]);

  function render() {
    if (site && !site?.usingParentAttendance) {
      return <Redirect to="/" />;
    }

    return <PublicAttendancePage>{renderForm()}</PublicAttendancePage>;
  }

  function renderForm() {
    return <Saveable ok="Submit" cancel={<AttendanceCancel />}>
      <Form title={<Text text={['heading2', 'heading1']}>Enter your 6 digit code</Text>} subtitle="Authorized pickups receive their code when added by a family" form={form} onNavigation="nothing" onOk={handleSubmit}>
        <Field name="code" placeholder="Enter your personal code" inputMode="numeric" maxLength={6} required component={InputField} />
      </Form>
      {warn && (
        <Info type="warning" mt="$30">
          No students are currently eligible for check-in or check-out using this code.
        </Info>
      )}
    </Saveable>
  }

  async function handleSubmit(form: CodeForm) {
    setWarn(false);

    const [success, result] = await parentAttendanceStudents({ variables: { code: form.values.code.toString() }, error: form });
    if (!success) {
      return false;
    }

    if (!result.data?.parentAttendanceStudents?.items?.length) {
      setWarn(true);
      return true;
    }

    history.replace(`/sites/${site.slug}/family-attendance/students`, { code: form.values.code });

    return true;
  }

  return render();
}

interface FormValues {
  code?: string;
}

type CodeForm = FormModel<FormValues>;

import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SiteClaimSearchQueryVariables = Types.Exact<{
  filter: Array<Types.Filter> | Types.Filter;
}>;

export type SiteClaimSearchQuery = {
  sites?:
    | Array<{ id: string; name?: string | null | undefined; streetAddress?: string | null | undefined; city?: string | null | undefined; state?: string | null | undefined; zipcode?: string | null | undefined }>
    | null
    | undefined;
};

export const SiteClaimSearchDocument = gql`
  query SiteClaimSearch($filter: [Filter!]!) {
    sites(filters: $filter) {
      id
      name
      streetAddress
      city
      state
      zipcode
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type SiteClaimSearchArgs = Omit<UseQueryArgs<SiteClaimSearchQueryVariables, SiteClaimSearchQuery>, 'query'>;

export function useSiteClaimSearchQuery(options: SiteClaimSearchArgs = {}) {
  return useQuery<SiteClaimSearchQueryVariables, SiteClaimSearchQuery>({ query: SiteClaimSearchDocument, ...options });
}

export type SiteClaimSearchOptions = ExecQueryOptions<SiteClaimSearchQueryVariables>;

export function siteClaimSearch(options: SiteClaimSearchOptions) {
  return executeQuery<SiteClaimSearchQuery, SiteClaimSearchQueryVariables>(SiteClaimSearchDocument, options);
}

export type SiteClaimSearchSelections = SiteClaimSearchQuery['sites'][0];

(SiteClaimSearchDocument as any).additionalTypenames = ['Site'];

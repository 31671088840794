import * as Types from '../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OpeningSeatsWithWaitlistQueryVariables = Types.Exact<{
  inputs: Array<Types.OpeningSeatsWithWaitlistInput> | Types.OpeningSeatsWithWaitlistInput;
}>;

export type OpeningSeatsWithWaitlistQuery = { openingSeatsWithWaitlist?: Array<{ id: string; name: string; result: boolean }> | null | undefined };

export const OpeningSeatsWithWaitlistDocument = gql`
  query OpeningSeatsWithWaitlist($inputs: [OpeningSeatsWithWaitlistInput!]!) {
    openingSeatsWithWaitlist(inputs: $inputs) {
      id
      name
      result
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type OpeningSeatsWithWaitlistArgs = Omit<UseQueryArgs<OpeningSeatsWithWaitlistQueryVariables, OpeningSeatsWithWaitlistQuery>, 'query'>;

export function useOpeningSeatsWithWaitlistQuery(options: OpeningSeatsWithWaitlistArgs = {}) {
  return useQuery<OpeningSeatsWithWaitlistQueryVariables, OpeningSeatsWithWaitlistQuery>({ query: OpeningSeatsWithWaitlistDocument, ...options });
}

export type OpeningSeatsWithWaitlistOptions = ExecQueryOptions<OpeningSeatsWithWaitlistQueryVariables>;

export function openingSeatsWithWaitlist(options: OpeningSeatsWithWaitlistOptions) {
  return executeQuery<OpeningSeatsWithWaitlistQuery, OpeningSeatsWithWaitlistQueryVariables>(OpeningSeatsWithWaitlistDocument, options);
}

export type OpeningSeatsWithWaitlistSelections = OpeningSeatsWithWaitlistQuery['openingSeatsWithWaitlist'][0];

(OpeningSeatsWithWaitlistDocument as any).additionalTypenames = ['OpeningSeatsWithWaitlistOutput'];

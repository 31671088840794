import * as React from 'react'

import { Text, VBox } from 'app2/components'

import { Section } from './Section'

export function Testimonial() {
  return <Section layout='vbox' hAlign='center' textAlign='center'>
    <Text text='marketing' mb='$12'>Testimonial</Text>
    <Text text='subtitle1' mb='$8'>“Thank you for another successful enrollment experience - <br/>you are all my heroes tonight!”</Text>
    <Text text='body'>-Chau, PTA organizer at Genesee Hill Elementary</Text>
  </Section>
}

import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompanyCourseFrameworksQueryVariables = Types.Exact<{
  company: Types.Scalars['ID'];
}>;

export type CompanyCourseFrameworksQuery = {
  company?:
    | {
        courseFrameworks?:
          | Array<{
              id: string;
              name: string;
              description: string;
              courseCardImage?: string | null | undefined;
              courseImage?: string | null | undefined;
              supplies?: Array<string | null | undefined> | null | undefined;
              typename: 'CourseFramework';
              courseTags?: Array<{ id: string; name?: string | null | undefined; typename: 'CourseTag' } | null | undefined> | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const CompanyCourseFrameworksDocument = gql`
  query CompanyCourseFrameworks($company: ID!) @urql(additionalTypenames: ["CourseFramework"]) {
    company(company: $company) {
      courseFrameworks {
        typename: __typename
        id
        name
        description
        courseTags {
          id
          name
          typename: __typename
        }
        courseCardImage
        courseImage
        supplies
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type CompanyCourseFrameworksArgs = Omit<UseQueryArgs<CompanyCourseFrameworksQueryVariables, CompanyCourseFrameworksQuery>, 'query'>;

export function useCompanyCourseFrameworksQuery(options: CompanyCourseFrameworksArgs = {}) {
  return useQuery<CompanyCourseFrameworksQueryVariables, CompanyCourseFrameworksQuery>({ query: CompanyCourseFrameworksDocument, ...options });
}

export type CompanyCourseFrameworksOptions = ExecQueryOptions<CompanyCourseFrameworksQueryVariables>;

export function companyCourseFrameworks(options: CompanyCourseFrameworksOptions) {
  return executeQuery<CompanyCourseFrameworksQuery, CompanyCourseFrameworksQueryVariables>(CompanyCourseFrameworksDocument, options);
}

export type CompanyCourseFrameworksSelections = CompanyCourseFrameworksQuery['company'];

(CompanyCourseFrameworksDocument as any).additionalTypenames = ['CourseFramework'];

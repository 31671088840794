import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SetAsContactMutationVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
  memberId: Types.Scalars['ID'];
}>;

export type SetAsContactMutation = { siteSetContact?: { entity?: { id: string } | { id: string } | null | undefined } | null | undefined };

export const SetAsContactDocument = gql`
  mutation setAsContact($siteId: ID!, $memberId: ID!) {
    siteSetContact(site: $siteId, member: $memberId) {
      entity {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type SetAsContactOptions = ExecMutationOptions<SetAsContactMutationVariables>;

export function setAsContact(options: SetAsContactOptions) {
  return executeMutation<SetAsContactMutation, SetAsContactMutationVariables>(SetAsContactDocument, options);
}

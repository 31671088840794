import * as React from 'react'

import { Field } from 'app2/components';

import { CourseLink, HistoryLink, UserLink, QueryList } from '../shared';

import { useAdminUserEnrollmentsQuery } from './generated'

export function Enrollments() {
  return <QueryList icon='Home' title='Enrollments' query={useAdminUserEnrollmentsQuery} name='enrollments' fields={[
    <Field name='id' label='ID' />,
    <Field name='student.name' label='Student' />,
    <Field name='status' label='Enrollment status' />,
    <Field name='course.name' label='Activity' component={CourseLink} />,
    <Field name='course.status' label='Activity status' />,
    <Field name='parent.name' label='Family' component={UserLink} />,
    <Field name='id' label='History' component={HistoryLink} type='Enrollment' />,
  ]} />
}
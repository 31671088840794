import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminEnrollingSoonSeasonsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AdminEnrollingSoonSeasonsQuery = {
  enrollingSoonSeasons?:
    | Array<
        | {
            id: string;
            name: string;
            enrollmentPeriod?: string | null | undefined;
            seasonPeriod?: string | null | undefined;
            homeroomRate?: number | null | undefined;
            totalCourses?: number | null | undefined;
            totalSpots?: number | null | undefined;
            timezone?: string | null | undefined;
            loadMetrics?:
              | {
                  id?: string | null | undefined;
                  name?: string | null | undefined;
                  totalCourses?: number | null | undefined;
                  totalSpots?: number | null | undefined;
                  totalEnrollments?: number | null | undefined;
                  waitlistedEnrollments?: number | null | undefined;
                  enrollmentsInFive?: number | null | undefined;
                  enrollmentsInTen?: number | null | undefined;
                  enrollmentsInFifteen?: number | null | undefined;
                  purchasedPerOrder?: number | null | undefined;
                }
              | null
              | undefined;
            site: { id: string; name?: string | null | undefined };
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export const AdminEnrollingSoonSeasonsDocument = gql`
  query AdminEnrollingSoonSeasons {
    enrollingSoonSeasons {
      id
      name
      enrollmentPeriod
      seasonPeriod
      homeroomRate
      totalCourses
      totalSpots
      timezone
      loadMetrics {
        id
        name
        totalCourses
        totalSpots
        totalEnrollments
        waitlistedEnrollments
        enrollmentsInFive
        enrollmentsInTen
        enrollmentsInFifteen
        purchasedPerOrder
      }
      site {
        id
        name
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminEnrollingSoonSeasonsArgs = Omit<UseQueryArgs<AdminEnrollingSoonSeasonsQueryVariables, AdminEnrollingSoonSeasonsQuery>, 'query'>;

export function useAdminEnrollingSoonSeasonsQuery(options: AdminEnrollingSoonSeasonsArgs = {}) {
  return useQuery<AdminEnrollingSoonSeasonsQueryVariables, AdminEnrollingSoonSeasonsQuery>({ query: AdminEnrollingSoonSeasonsDocument, ...options });
}

export type AdminEnrollingSoonSeasonsOptions = ExecQueryOptions<AdminEnrollingSoonSeasonsQueryVariables>;

export function adminEnrollingSoonSeasons(options: AdminEnrollingSoonSeasonsOptions) {
  return executeQuery<AdminEnrollingSoonSeasonsQuery, AdminEnrollingSoonSeasonsQueryVariables>(AdminEnrollingSoonSeasonsDocument, options);
}

export type AdminEnrollingSoonSeasonsSelections = AdminEnrollingSoonSeasonsQuery['enrollingSoonSeasons'][0];

(AdminEnrollingSoonSeasonsDocument as any).additionalTypenames = ['EnrollingSoonSeason'];

import * as React from 'react';
import pluralize from 'pluralize';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { observer } from 'app/observable';
import { sharedDataManager } from 'app/admin/api';
import { eventManager, UserUtils } from 'app2/api';
import { ProviderStore, PROVIDER_BACKGROUND_IMAGE_WIDTH, PROVIDER_BACKGROUND_IMAGE_RATIO } from 'app2/api';
import {
  buildCloudinaryImageUrl,
  VBox,
  BreakpointProps,
  withBreakpoints,
  Heading1,
  Heading2,
  Box,
  Subtitle1,
  Subtitle2,
  BoxProps,
  LoadMore,
  HBox,
  Icon,
  Img,
  Loader
} from 'app2/components';

import { Page, PageContent, EmbedBackLink, ShareButton } from '../../shared-public';

import { CatalogCard } from './CatalogCard';
import { ContactProvider } from './ContactProvider';

interface Props extends BreakpointProps, RouteComponentProps<{ id: string }> {}

@observer
export class InnerProvider extends React.Component<Props> {
  store: ProviderStore = sharedDataManager.get(ProviderStore);

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: Props) {
    this.loadData();
  }

  get url() {
    return `/provider/${this.store.provider.slug}/about`;
  }

  async loadData() {
    try {
      const slug = this.props.match.params.id;
      const promises = this.store.load(slug);

      if (!promises.length) {
        return;
      }

      promises.push(this.store.loadCatalogPage(this.catalogPageSize()));

      await Promise.all(promises);

      this.logPageView();
    } catch (_) {}
  }

  logPageView() {
    eventManager.log('viewProviderPage', {
      totalNumberOfCatalogListings: this.store.catalogs.pagination.count
    });
  }

  catalogPageSize() {
    if (this.props.breakpoint === 0) {
      return 3;
    }

    return 6;
  }

  get title() {
    return `${this.store.provider.display_name}: After School Provider`;
  }

  get description() {
    return `Discover unique activities from ${this.store.provider.display_name}.`;
  }

  get profileImageUrl() {
    return this.store.provider.profile_photo;
  }

  render() {
    if (!this.store.provider) {
      return <Loader />;
    }

    return <Page head={{title:this.title, description:this.description, image:this.profileImageUrl}}
      above={<BackgroundImage breakpoint={this.props.breakpoint} store={this.store} />} useMaxWidth header footer>
      <Header 
        breakpoint={this.props.breakpoint}
        profileImageUrl={this.profileImageUrl}
        store={this.store}
        />
      <Activities
        breakpoint={this.props.breakpoint}
        pageSize={this.catalogPageSize()}
        store={this.store}
      />
    </Page>
  }
}

function Header(props: {
  breakpoint: number;
  profileImageUrl: string;
  store: ProviderStore;
}) {
  return <Box
    position="relative"
    layout={['vbox']}
    mt={-180}
    bg="formBackground"
    p="$40"
    width="100%"
    borderRadius="5px"
    mb='$60'
  >
    <EmbedBackLink />
    <Box layout={['vbox', 'hbox']}>
      <ProfileImageSection
        profileImageUrl={props.profileImageUrl}
        store={props.store}
      />
      <InfoSection store={props.store} />
      <Box
        position={['absolute', 'unset']}
        top={[0, 'unset']}
        right={[0, 'unset']}
        pt={['$40', 0]}
        pr={[34, 0]} // Hack to work around the right margin the Dropdown component from blocks adds to SVG triggers.
      >
        <ShareButton icon link={window.location.href} subject={`Activities from ${props.store.provider.name}`} message='Check out the activities that Inly School offers. I think you might be interested in them.' />
      </Box>
    </Box>
  </Box>
}

function BackgroundImage(props: { breakpoint: number; store: ProviderStore }) {
  const heights = [350, 350, 383];
  const height = heights[props.breakpoint];
  const defaultImage = '/global-assets/default_provider_background_img.png';
  const imageUrl = buildCloudinaryImageUrl(
    props.store.provider.profile_background || defaultImage
  , PROVIDER_BACKGROUND_IMAGE_RATIO, PROVIDER_BACKGROUND_IMAGE_WIDTH);

  return (
    <Box minHeight={height} height={height}>
      <Box
        background={`url(${imageUrl})`}
        backgroundSize="cover"
        width="100%"
        height="100%"
        zIndex={-2}
      />
    </Box>
  );
}

function ProfileImageSection(props: {
  profileImageUrl: string;
  store: ProviderStore;
}) {
  return (
    <VBox alignItems="center" mr={['unset', '$30']} mb={['$30', '$40']}>
      <ProfileImage
        profileImageUrl={props.profileImageUrl}
        store={props.store}
      />
      <ContactProvider provider={props.store.provider} />
    </VBox>
  );
}

function ProfileImage(props: {
  profileImageUrl: string;
  store: ProviderStore;
}) {
  const imageUrl = buildCloudinaryImageUrl(props.profileImageUrl);
  const initials = UserUtils.getInitials(props.store.provider.name);
  const baseProps: BoxProps = {
    height: [160, 248],
    width: [160, 248],
    mb: [30, 40],
    boxShadow: 'box',
    borderRadius: '50%'
  };

  if (imageUrl) {
    return <Img src={imageUrl} {...baseProps} />;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      bg="brandDark"
      zIndex={0}
      {...baseProps}
    >
      <Box fontFamily="primary" color="white" fontSize="144px">
        {initials[0]}
      </Box>
    </Box>
  );
}

function InfoSection(props: { store: ProviderStore }) {
  const provider = props.store.provider;
  const name = provider.name;
  const headline = provider.headline;
  const about = provider.about;

  return (
    <VBox flex="1" textAlign={['center', 'left']} mr={['unset', '$40']}>
      <Heading1 mb="$15">{name}</Heading1>
      {headline && <Heading2 mb="$30">&ldquo;{headline}&rdquo;</Heading2>}
      {about && <Subtitle1 whiteSpace="pre-wrap">{about}</Subtitle1>}
    </VBox>
  );
}

function Activities(props: {
  breakpoint: number;
  pageSize: number;
  store: ProviderStore;
}) {
  const store = props.store;
  const numPerRow = [1, 2, 3][props.breakpoint];

  async function loadCatalogPage(page: number) {
    const promise = store.loadCatalogPage(props.pageSize, page);

    if (promise) {
      await promise;
    }

    return store.catalogs.records;
  }

  if (!store.catalogs) {
    return (
      <VBox hAlign="center" mb="$30" width="100%">
        <Loader />
      </VBox>
    );
  }

  const hasCatalogListings = store.catalogs.pagination.count > 0;

  if (!hasCatalogListings) {
    return <></>
  }

  return (
    <PageContent mb="$60">
      <Box
        layout={['vbox', 'hbox']}
        mb="$15"
        width="100%"
        vAlign={['top', 'center']}
      >
        <HBox mb={['$15', 'unset']}>
          <Icon name="BookOpen" mr="$15" />
          <Heading2 fontWeight="bold !important">
            Activities by this provider
          </Heading2>
        </HBox>
        {hasCatalogListings && (
          <>
            <HBox flex="1" />
            <Subtitle2 mr="$15">
              {store.catalogs.pagination.count}{' '}
              {pluralize('activity', store.catalogs.pagination.count)}
            </Subtitle2>
          </>
        )}
      </Box>
      {hasCatalogListings ? (
        <LoadMore
          width="100%"
          numPages={store.catalogs.pagination.pages}
          numPerRow={numPerRow}
          load={loadCatalogPage}
          component={CatalogCard}
          urlProperty="catalogListingsPage"
        />
      ) : (
        <Subtitle1 my="$40">
          This provider doesn&apos;t have any activities yet. Check back soon.
        </Subtitle1>
      )}
    </PageContent>
  );
}

export const Provider = withBreakpoints(withRouter(InnerProvider), [
  '635px',
  '1040px'
]);

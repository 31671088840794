import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompanyTeachersPickerQueryVariables = Types.Exact<{
  company: Types.Scalars['ID'];
}>;

export type CompanyTeachersPickerQuery = {
  company?: { teachers?: Array<{ id: string; name: string; phone?: string | null | undefined; email?: string | null | undefined; typename: 'Teacher' }> | null | undefined } | null | undefined;
};

export const CompanyTeachersPickerDocument = gql`
  query CompanyTeachersPicker($company: ID!) @urql(additionalTypenames: ["Teacher"]) {
    company(company: $company) {
      teachers(pageSize: 400) {
        typename: __typename
        id
        name
        phone
        email
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type CompanyTeachersPickerArgs = Omit<UseQueryArgs<CompanyTeachersPickerQueryVariables, CompanyTeachersPickerQuery>, 'query'>;

export function useCompanyTeachersPickerQuery(options: CompanyTeachersPickerArgs = {}) {
  return useQuery<CompanyTeachersPickerQueryVariables, CompanyTeachersPickerQuery>({ query: CompanyTeachersPickerDocument, ...options });
}

export type CompanyTeachersPickerOptions = ExecQueryOptions<CompanyTeachersPickerQueryVariables>;

export function companyTeachersPicker(options: CompanyTeachersPickerOptions) {
  return executeQuery<CompanyTeachersPickerQuery, CompanyTeachersPickerQueryVariables>(CompanyTeachersPickerDocument, options);
}

export type CompanyTeachersPickerSelections = CompanyTeachersPickerQuery['company'];

(CompanyTeachersPickerDocument as any).additionalTypenames = ['Teacher'];

import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CourseReviewsQueryVariables = Types.Exact<{
  courseId: Types.Scalars['ID'];
}>;

export type CourseReviewsQuery = {
  course?:
    | {
        reviewCount?: number | null | undefined;
        reviewAverage?: number | null | undefined;
        reviews: Array<{ title?: string | null | undefined; comments?: string | null | undefined; overallRating?: number | null | undefined }>;
      }
    | null
    | undefined;
};

export const CourseReviewsDocument = gql`
  query CourseReviews($courseId: ID!) {
    course(course: $courseId) {
      reviewCount
      reviewAverage
      reviews {
        title
        comments
        overallRating
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type CourseReviewsArgs = Omit<UseQueryArgs<CourseReviewsQueryVariables, CourseReviewsQuery>, 'query'>;

export function useCourseReviewsQuery(options: CourseReviewsArgs = {}) {
  return useQuery<CourseReviewsQueryVariables, CourseReviewsQuery>({ query: CourseReviewsDocument, ...options });
}

export type CourseReviewsOptions = ExecQueryOptions<CourseReviewsQueryVariables>;

export function courseReviews(options: CourseReviewsOptions) {
  return executeQuery<CourseReviewsQuery, CourseReviewsQueryVariables>(CourseReviewsDocument, options);
}

export type CourseReviewsSelections = CourseReviewsQuery['course'];

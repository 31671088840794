import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompanyRemoveMemberMutationVariables = Types.Exact<{
  company: Types.Scalars['ID'];
  memberId: Types.Scalars['ID'];
}>;

export type CompanyRemoveMemberMutation = { companyMemberRemove?: { entity?: { id: string } | { id: string } | null | undefined } | null | undefined };

export const CompanyRemoveMemberDocument = gql`
  mutation companyRemoveMember($company: ID!, $memberId: ID!) {
    companyMemberRemove(company: $company, member: $memberId) {
      entity {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type CompanyRemoveMemberOptions = ExecMutationOptions<CompanyRemoveMemberMutationVariables>;

export function companyRemoveMember(options: CompanyRemoveMemberOptions) {
  return executeMutation<CompanyRemoveMemberMutation, CompanyRemoveMemberMutationVariables>(CompanyRemoveMemberDocument, options);
}

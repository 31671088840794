import * as React from 'react';

import { MemberRole } from 'app2/api';
import { FormModel } from 'app2/components';
import { Member, Team as TeamBase, removePathSegment } from 'app2/views/shared';

import { CompanyPage, useCompanyQuery } from '../shared';

import { companyInviteMember, companyRemoveMember, useCompanyTeamQuery, companyResendInvite, companyRevokeInvite, companySetAsContact } from './gql';

export function Team() {
  const {company} = useCompanyQuery(useCompanyTeamQuery);
  const canEditTeam = company?.userCompanyRole === MemberRole.Admin;

  function render() {
    return (
      <CompanyPage title="Team">
        <TeamBase members={company?.teamMembers} invites={company?.teamInvites}
          invite={inviteMember} remove={removeMember} resend={companyResendInvite} revoke={companyRevokeInvite} setAsContact={setAsContact}
          canEditTeam={canEditTeam}
        />
      </CompanyPage>
    );
  }

  async function inviteMember(form:FormModel<Member>) {
    const [result] = await companyInviteMember({ variables: { company: company?.id, attributes:form.values }, successMsg: 'Invitation sent', error:{handler:form, transform: removePathSegment('attributes')} });

    return result;
  }

  async function removeMember(member: Member) {
    const [result] = await companyRemoveMember({ variables: { company: company?.id, memberId: member.id }, successMsg: 'Team member removed' });

    return result;
  }

  async function setAsContact(member:Member) {
    const [result] = await companySetAsContact({ variables: { company: company?.id, memberId: member.id }, successMsg: `${member.name} set as the main contact` });

    return result;
  }

  return render();
}

import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerSiteSeasonsQueryVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
}>;

export type OrganizerSiteSeasonsQuery = { site?: { seasonsByStatus: Array<{ id: string; name: string; completed: boolean; upcoming: boolean }> } | null | undefined };

export const OrganizerSiteSeasonsDocument = gql`
  query OrganizerSiteSeasons($siteId: ID!) {
    site(site: $siteId) {
      seasonsByStatus {
        id
        name
        completed
        upcoming
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type OrganizerSiteSeasonsArgs = Omit<UseQueryArgs<OrganizerSiteSeasonsQueryVariables, OrganizerSiteSeasonsQuery>, 'query'>;

export function useOrganizerSiteSeasonsQuery(options: OrganizerSiteSeasonsArgs = {}) {
  return useQuery<OrganizerSiteSeasonsQueryVariables, OrganizerSiteSeasonsQuery>({ query: OrganizerSiteSeasonsDocument, ...options });
}

export type OrganizerSiteSeasonsOptions = ExecQueryOptions<OrganizerSiteSeasonsQueryVariables>;

export function organizerSiteSeasons(options: OrganizerSiteSeasonsOptions) {
  return executeQuery<OrganizerSiteSeasonsQuery, OrganizerSiteSeasonsQueryVariables>(OrganizerSiteSeasonsDocument, options);
}

export type OrganizerSiteSeasonsSelections = OrganizerSiteSeasonsQuery['site'];

import * as React from 'react';

import { CourseKind } from 'app2/api';
import { Dropdown, FieldPlaceholder, Section } from 'app2/components';
import { formatCourseDays } from 'app2/views/shared';

import { CourseSelections } from '../../../generated';

interface Props {
  course: CourseSelections;
}

export function TimeSlotPicker(props: Props) {
  const {course} = props;
  const timeslots = course.kind == CourseKind.LessonSet ? course?.childVariantCourses || [] : [];
  const options = React.useMemo(() => timeslots.map(v => ({label: `${formatCourseDays(v.courseDays)} (${v.maxCapacity - v.enrolledCount}/${v.maxCapacity} available)`, value: v})), [timeslots]);

  function render() {
    if (!timeslots.length) {
      return <></>;
    }

    return <Section label="Time slot" name="childCourseVariant" component={Dropdown} placeholder={FieldPlaceholder.select} options={options} required />;
  }

  return render();
}


import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerClassroomsQueryVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
}>;

export type OrganizerClassroomsQuery = { site?: { id: string; classrooms: Array<{ id: string; name: string; grade: string }> } | null | undefined };

export const OrganizerClassroomsDocument = gql`
  query OrganizerClassrooms($siteId: ID!) {
    site(site: $siteId) {
      id
      classrooms {
        id
        name
        grade
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type OrganizerClassroomsArgs = Omit<UseQueryArgs<OrganizerClassroomsQueryVariables, OrganizerClassroomsQuery>, 'query'>;

export function useOrganizerClassroomsQuery(options: OrganizerClassroomsArgs = {}) {
  return useQuery<OrganizerClassroomsQueryVariables, OrganizerClassroomsQuery>({ query: OrganizerClassroomsDocument, ...options });
}

export type OrganizerClassroomsOptions = ExecQueryOptions<OrganizerClassroomsQueryVariables>;

export function organizerClassrooms(options: OrganizerClassroomsOptions) {
  return executeQuery<OrganizerClassroomsQuery, OrganizerClassroomsQueryVariables>(OrganizerClassroomsDocument, options);
}

export type OrganizerClassroomsSelections = OrganizerClassroomsQuery['site'];

import * as React from 'react';

import { MemberInvitation, MemberRole } from 'app2/api';
import { Button, ButtonStripButton, DateLabelField, EmailField, EmailLabelField, Field, FieldInfo, FieldRendererProps, Form, FormModel, Modal, OptionText, Panel, PanelProps, RadioGroup, RepeatingSection, Section } from 'app2/components';

import { ExecMutationOptions, ExecMutationResult } from '../urql';
import { useCurrentUser } from 'app2/views/shared-public';

export type Member = {id:string, name?:string, email:string, role:any};
export type Invite = Omit<MemberInvitation, 'createdAt' | 'updatedAt'>;
export type MemberOrInvite = Member | Invite;

export type InviteOptions = ExecMutationOptions<{inviteToken:string}>;

interface Props extends Partial<PanelProps> {
  members: Member[];
  invites: Invite[];
  invite: (form:FormModel<Member>) => Promise<boolean>;
  remove: (member: Member) => Promise<boolean>;
  resend: (options: InviteOptions) => ExecMutationResult;
  revoke: (options: InviteOptions) => ExecMutationResult;
  setAsContact: (member: Member) => Promise<boolean>;
  canEditTeam: boolean;
}

export function Team(props: Props) {
  const { members, invites, invite, remove, resend, revoke, setAsContact, canEditTeam, ...remaining } = props;

  const { user } = useCurrentUser();
  const team = [].concat(members || []).concat(invites || []);

  function render() {
    return (
      <Panel icon="Users" title="Team members" type="display" values={{ team }} primaryActions={renderActions()} {...remaining}>
        <RepeatingSection
          name="team"
          buttonCols={2}
          fields={[
            <Field label="Team member name" name="name" />,
            <Field label="Email" name="email" component={EmailLabelField} />,
            <Field label="Role" name="role" component={OptionText} options={roleOptions} />,
            <Field label="Main contact" name="isContact" format={renderSetAsContact} />,
            <Field label="Invite sent" name="sentAt" component={DateLabelField} />,
            <Field name="sentAt" render={(props: FieldRendererProps) => (!props.info.value ? '' : <Button kind="tertiary" icon="RefreshCw" autoLoader onClick={event => onResend(event, props.info.record)} />)} />,
            <Field name="id" render={(props: FieldRendererProps) => (!canRemove(props.info.record) ? '' : <Button kind="tertiary" icon="Trash2" onClick={event => onRemove(event, props.info.record)} />)} />
          ]}
        ></RepeatingSection>
      </Panel>
    );
  }

  function renderActions() {
    return !canEditTeam ? '': <Button icon="Plus" iconPosition='left' onClick={() => add()}>Add team member</Button>
  }

  function renderSetAsContact(isContact:boolean, _:any, info:FieldInfo<Member>) {
    return isContact
      ? <ButtonStripButton icon='CheckCircle' small autoLoader selected={true} />
      : <ButtonStripButton icon='Circle' small autoLoader onClick={() => setAsContact(info.record)} />
  }

  function add() {
    Modal.show<Member>(
      <Modal title="Add team member" ok='Send invite'>
        <Form icon="User" title="Team member details" onOk={invite}>
          <Section label="Email address" name="email" required component={EmailField} />
          <Section label="Select role" name="role" component={RadioGroup} options={roleOptions} required />
        </Form>
      </Modal>
    );
  }

  async function onResend(event: React.MouseEvent, invite: Invite) {
    event.preventDefault();

    resend({ variables: { inviteToken: invite.token }, successMsg: 'Invite sent' });
  }

  function canRemove(memberOrInvite: MemberOrInvite) {
    if (isInvite(memberOrInvite)) {
      return true;
    }

    const isLastUser = members.length < 2;

    if (isLastUser) {
      return false;
    }

    const isCurUser = memberOrInvite.email === user?.email;

    if (isCurUser) {
      return false;
    }

    return canEditTeam;
  }

  async function onRemove(event: React.MouseEvent, memberOrInvite: MemberOrInvite) {
    event.preventDefault();

    const invite = isInvite(memberOrInvite);

    const title = invite ? 'Revoke invite' : 'Remove team member';
    const content = invite ? `Are you sure you want to revoke the invite to ${memberOrInvite.email}?` : `Are you sure you want to remove the team member ${memberOrInvite.email}?`;
    const result = await Modal.warning({ title, content }, true);

    if (result.action == 0) {
      return;
    }

    if ('name' in memberOrInvite) {
      remove(memberOrInvite);
    } else {
      revoke({ variables: { inviteToken: (memberOrInvite as MemberInvitation).token }, successMsg: 'Invite revoked' });
    }
  }

  function isInvite(memberOrInvite: MemberOrInvite) {
    return !('name' in memberOrInvite);
  }

  return render();
}

const roleOptions = [{
    label: 'Administrator',
    value: MemberRole.Admin
  }, {
    label: 'Member',
    value: MemberRole.Member
  }
]
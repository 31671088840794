import * as React from 'react';
import { useParams } from 'react-router';

import { Field, FormModel, InputField, Panel, Part, RepeatingSection, Section } from 'app2/components';

import { CourseLink, HistoryLink } from '../../shared';

import { adminSeasonUpdate, useSeasonQuery } from './generated';

export function Season() {
  const { season } = useParams<{season:string}>();
  const [result] = useSeasonQuery({variables: {id:season}});
  const item = result.data?.season;

  function render() {
    return <Panel icon='Home' title={`Seasons > ${item?.name} (${season})`} initialValues={item} type='toggle' onOk={submit}>
      <Section label="ID">
        <Part name="id" readOnly />
        <Part name="id" readOnly component={HistoryLink} type='Season' />
      </Section>
      <Section label='Name' name='name' component={InputField} />
      <Section label='Homeroom rate' name='homeroomRate' component={InputField} />
      <Section label='Transaction fee rate' name='txnFeeRate' component={InputField} />
      <Section label='Activities'>
        <RepeatingSection name='courses' fields={[
          <Field name='name' label='Name' component={CourseLink} />,
          <Field name='status' label='Status' />
        ]} />
      </Section>      
    </Panel>
  }

  async function submit(form:FormModel) {
    const attributes = {
      id: season,
      name: form.values.name,
      homeroomRate: parseFloat(form.values.homeroomRate as any),
      txnFeeRate: parseFloat(form.values.txnFeeRate as any),
    }

    const [success] = await adminSeasonUpdate({variables:{attributes}});

    return success;
  }


  return render();
}

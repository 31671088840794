import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TimeEntriesFilterOptionsQueryVariables = Types.Exact<{
  ownerType: Types.EntityKind;
  ownerId: Types.Scalars['ID'];
  name: Types.Scalars['String'];
}>;

export type TimeEntriesFilterOptionsQuery = { timeEntriesQuery?: { filterOptions?: Array<{ label: string; value?: string | number | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export const TimeEntriesFilterOptionsDocument = gql`
  query TimeEntriesFilterOptions($ownerType: EntityKind!, $ownerId: ID!, $name: String!) {
    timeEntriesQuery(ownerType: $ownerType, ownerId: $ownerId, filterOptions: $name) {
      filterOptions {
        label
        value
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type TimeEntriesFilterOptionsArgs = Omit<UseQueryArgs<TimeEntriesFilterOptionsQueryVariables, TimeEntriesFilterOptionsQuery>, 'query'>;

export function useTimeEntriesFilterOptionsQuery(options: TimeEntriesFilterOptionsArgs = {}) {
  return useQuery<TimeEntriesFilterOptionsQueryVariables, TimeEntriesFilterOptionsQuery>({ query: TimeEntriesFilterOptionsDocument, ...options });
}

export type TimeEntriesFilterOptionsOptions = ExecQueryOptions<TimeEntriesFilterOptionsQueryVariables>;

export function timeEntriesFilterOptions(options: TimeEntriesFilterOptionsOptions) {
  return executeQuery<TimeEntriesFilterOptionsQuery, TimeEntriesFilterOptionsQueryVariables>(TimeEntriesFilterOptionsDocument, options);
}

export type TimeEntriesFilterOptionsSelections = TimeEntriesFilterOptionsQuery['timeEntriesQuery'];

import * as Types from '../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type StudentRefundsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type StudentRefundsQuery = {
  student?:
    | {
        id: string;
        refunds: Array<
          | {
              createdAt: string;
              amount: number;
              remainder: number;
              pending: boolean;
              description?: string | null | undefined;
              initiatedBy?: { name?: string | null | undefined } | null | undefined;
              enrollment: { billPeriod?: string | null | undefined; course: { disambiguatedName?: string | null | undefined } };
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
};

export const StudentRefundsDocument = gql`
  query StudentRefunds($id: ID!) {
    student(student: $id) {
      id
      refunds {
        createdAt
        amount
        remainder
        pending
        description
        initiatedBy {
          name
        }
        enrollment {
          billPeriod
          course {
            disambiguatedName
          }
        }
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type StudentRefundsArgs = Omit<UseQueryArgs<StudentRefundsQueryVariables, StudentRefundsQuery>, 'query'>;

export function useStudentRefundsQuery(options: StudentRefundsArgs = {}) {
  return useQuery<StudentRefundsQueryVariables, StudentRefundsQuery>({ query: StudentRefundsDocument, ...options });
}

export type StudentRefundsOptions = ExecQueryOptions<StudentRefundsQueryVariables>;

export function studentRefunds(options: StudentRefundsOptions) {
  return executeQuery<StudentRefundsQuery, StudentRefundsQueryVariables>(StudentRefundsDocument, options);
}

export type StudentRefundsSelections = StudentRefundsQuery['student'];

import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SiteGradesQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type SiteGradesQuery = { site?: { id: string; grades?: Array<{ label: string; value: string; hidden?: boolean | null | undefined }> | null | undefined } | null | undefined };

export const SiteGradesDocument = gql`
  query SiteGrades($id: ID!) {
    site(site: $id) {
      id
      grades {
        label
        value
        hidden
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type SiteGradesArgs = Omit<UseQueryArgs<SiteGradesQueryVariables, SiteGradesQuery>, 'query'>;

export function useSiteGradesQuery(options: SiteGradesArgs = {}) {
  return useQuery<SiteGradesQueryVariables, SiteGradesQuery>({ query: SiteGradesDocument, ...options });
}

export type SiteGradesOptions = ExecQueryOptions<SiteGradesQueryVariables>;

export function siteGrades(options: SiteGradesOptions) {
  return executeQuery<SiteGradesQuery, SiteGradesQueryVariables>(SiteGradesDocument, options);
}

export type SiteGradesSelections = SiteGradesQuery['site'];

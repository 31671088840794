import * as React from 'react'
import { Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';

import { hasAppMode } from 'app2/components/AppModes'

import { STRIPE_PUBLIC_KEY } from './constants';
import { StripeLoader } from './StripeLoader';

let stripe:Promise<Stripe>;

export function StripeElements2(props:React.PropsWithChildren<any>) {
  if (!stripe) {
    if (hasAppMode('test')) {
      loadStripe.setLoadParameters({advancedFraudSignals: false});
    }
    
    stripe = loadStripe(STRIPE_PUBLIC_KEY);
  }

  return <StripeLoader>
    <Elements stripe={stripe} {...elementOptions} {...props} />
  </StripeLoader>
}

const elementOptions = {
  options: {
      fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css?family=Muli:300,400,500,600,700,900'
      }
    ]
  }
}

import * as React from 'react';
import { omit } from 'lodash-es';

import { CourseKind, CourseStatus, authorizedPreferences, authorizedCoursePreferenceKeys } from 'app2/api';
import { findCol, mergeCols } from 'app2/components';
import { CoursesTable, useCourseCols, useCourseTableState, nonCancelledStatuses } from 'app2/views/shared';

import { CourseSelections } from '../generated';

const PREFS_VERSION = "1"

interface Props {
  course: CourseSelections;
}

export function TimeSlots(props:Props) {
  const course = props.course;
  const state = useCourseTableState();
  const queryVars = {courseId: course?.id, kinds: [CourseKind.TimeSlot], statuses:nonCancelledStatuses};

  function render() {
    return <CoursesTable
      header={{icon:'Clock', title:'Time slots', options: []}} kind={CourseKind.TimeSlot}
      queryVars={{...queryVars, totals: [nonCancelledStatuses]}}
      courseModel={getCourseModel()}
      state={state}
      showAppend={true}
      defaultValues={getCourseDefaults()}
      prefs={authorizedPreferences.coursePreferences(course?.id)}
      prefsKey={authorizedCoursePreferenceKeys.courseVariantsTable}
      prefsVersion={PREFS_VERSION}
    />
  }

  function getCourseModel() {
    const courseModel = useCourseCols({site:course?.site?.id, courses:state.coursesCollection, queryVars, timezone: course?.site?.timezone});

    return React.useMemo(() => {
      const courseDaysCol = findCol(courseModel.cols, 'courseDays');
      return {
        ...courseModel, 
        cols: mergeCols([{name:'name', tab:'/enrollments'}, {...courseDaysCol, edit:{...courseDaysCol.edit, add:false}}, 'teacher', 'enrolledCount', 'waitlistedCount'], courseModel.cols)
      }
    }, [courseModel])
  }

  function getCourseDefaults() {
    if (!course) {
      return {};
    }

    return {
      ...omit(course, 'id', 'courseDays', 'childVariantCourses', 'kind'),
      searchable: true,
    }
  }

  return render();
}

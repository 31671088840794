import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentOrdersQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ParentOrdersQuery = {
  user?:
    | {
        receipts?:
          | Array<
              | { id: string; createdAt: string; total: number }
              | {
                  status: Types.PaymentStatus;
                  paymentFailureReason?: string | null | undefined;
                  id: string;
                  createdAt: string;
                  total: number;
                  items: Array<{
                    id: string;
                    total: number;
                    product:
                      | { typename: 'Donation'; site: { charityName?: string | null | undefined; name?: string | null | undefined } }
                      | {
                          id: string;
                          billPeriod?: string | null | undefined;
                          withdrawable?: boolean | null | undefined;
                          isChildEnrollment: boolean;
                          typename: 'Enrollment';
                          enrollmentPriceTierUse?: { cartPriceTierUse: { name: string } } | null | undefined;
                          course: {
                            id: string;
                            name?: string | null | undefined;
                            lastWithdrawDate?: string | null | undefined;
                            courseFramework?: { id: string } | null | undefined;
                            company?: { slug: string; name?: string | null | undefined } | null | undefined;
                          };
                          priceConfig?:
                            | { kind: Types.PriceConfigKind; updateDescription?: string | null | undefined }
                            | { kind: Types.PriceConfigKind; updateDescription?: string | null | undefined }
                            | { kind: Types.PriceConfigKind; updateDescription?: string | null | undefined }
                            | { kind: Types.PriceConfigKind; updateDescription?: string | null | undefined }
                            | null
                            | undefined;
                          student?: { id: string; name: string } | null | undefined;
                        };
                  }>;
                }
              | { id: string; createdAt: string; total: number }
              | {
                  description?: string | null | undefined;
                  status: Types.PaymentStatus;
                  void: boolean;
                  id: string;
                  createdAt: string;
                  total: number;
                  order?: { id: string; createdAt: string } | null | undefined;
                  item: {
                    id: string;
                    product:
                      | { typename: 'Donation'; site: { charityName?: string | null | undefined; name?: string | null | undefined } }
                      | {
                          billPeriod?: string | null | undefined;
                          typename: 'Enrollment';
                          course: { id: string; name?: string | null | undefined; courseFramework?: { id: string } | null | undefined; company?: { slug: string; name?: string | null | undefined } | null | undefined };
                          student?: { id: string; name: string } | null | undefined;
                        };
                  };
                }
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const ParentOrdersDocument = gql`
  query ParentOrders {
    user: currentUser {
      receipts {
        id
        createdAt
        total
        ... on Order {
          status
          paymentFailureReason
          items {
            id
            total
            product {
              ... on Enrollment {
                typename: __typename
                id
                billPeriod
                withdrawable
                enrollmentPriceTierUse {
                  cartPriceTierUse {
                    name
                  }
                }
                isChildEnrollment
                course {
                  id
                  name
                  lastWithdrawDate
                  courseFramework {
                    id
                  }
                  company {
                    slug
                    name
                  }
                }
                priceConfig {
                  kind
                  updateDescription
                }
                student {
                  id
                  name
                }
              }
              ... on Donation {
                typename: __typename
                site {
                  charityName
                  name
                }
              }
            }
          }
        }
        ... on Refund {
          order {
            id
            createdAt
          }
          description
          status
          void
          item {
            id
            product {
              ... on Enrollment {
                typename: __typename
                billPeriod
                course {
                  id
                  name
                  courseFramework {
                    id
                  }
                  company {
                    slug
                    name
                  }
                }
                student {
                  id
                  name
                }
              }
              ... on Donation {
                typename: __typename
                site {
                  charityName
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type ParentOrdersArgs = Omit<UseQueryArgs<ParentOrdersQueryVariables, ParentOrdersQuery>, 'query'>;

export function useParentOrdersQuery(options: ParentOrdersArgs = {}) {
  return useQuery<ParentOrdersQueryVariables, ParentOrdersQuery>({ query: ParentOrdersDocument, ...options });
}

export type ParentOrdersOptions = ExecQueryOptions<ParentOrdersQueryVariables>;

export function parentOrders(options: ParentOrdersOptions) {
  return executeQuery<ParentOrdersQuery, ParentOrdersQueryVariables>(ParentOrdersDocument, options);
}

export type ParentOrdersSelections = ParentOrdersQuery['user'];

import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminMissingDepositPayoutsQueryVariables = Types.Exact<{
  kind: Types.Scalars['JSON'];
}>;

export type AdminMissingDepositPayoutsQuery = {
  payouts?:
    | Array<{
        id: string;
        scheduledAt?: string | null | undefined;
        status?: string | null | undefined;
        amount?: number | null | undefined;
        paymentServiceId?: string | null | undefined;
        lastError?: string | null | undefined;
        target?:
          | { id: string; name?: string | null | undefined; stripeAccount?: string | null | undefined; stripeBalance?: number | null | undefined }
          | { id: string; name?: string | null | undefined; stripeAccount?: string | null | undefined; stripeBalance?: number | null | undefined }
          | null
          | undefined;
      }>
    | null
    | undefined;
};

export const AdminMissingDepositPayoutsDocument = gql`
  query AdminMissingDepositPayouts($kind: JSON!) {
    payouts(queries: [$kind]) {
      id
      scheduledAt
      status
      amount
      paymentServiceId
      lastError
      target {
        id
        name
        stripeAccount
        stripeBalance
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminMissingDepositPayoutsArgs = Omit<UseQueryArgs<AdminMissingDepositPayoutsQueryVariables, AdminMissingDepositPayoutsQuery>, 'query'>;

export function useAdminMissingDepositPayoutsQuery(options: AdminMissingDepositPayoutsArgs = {}) {
  return useQuery<AdminMissingDepositPayoutsQueryVariables, AdminMissingDepositPayoutsQuery>({ query: AdminMissingDepositPayoutsDocument, ...options });
}

export type AdminMissingDepositPayoutsOptions = ExecQueryOptions<AdminMissingDepositPayoutsQueryVariables>;

export function adminMissingDepositPayouts(options: AdminMissingDepositPayoutsOptions) {
  return executeQuery<AdminMissingDepositPayoutsQuery, AdminMissingDepositPayoutsQueryVariables>(AdminMissingDepositPayoutsDocument, options);
}

export type AdminMissingDepositPayoutsSelections = AdminMissingDepositPayoutsQuery['payouts'][0];

(AdminMissingDepositPayoutsDocument as any).additionalTypenames = ['Payout'];

import * as React from 'react';
import { isEmpty } from 'lodash-es';
import pluralize from 'pluralize';

import { EnrollmentUtils, Enrollment } from 'app2/api';
import { Modal, RepeatingSection, Form, Section, Dropdown, FormModel, Info, Field } from 'app2/components';
import { courseKindBehavior } from 'app2/views/shared-public';

import { errorPathTransform } from '../../../error';
import { CourseSelections } from '../../generated';
import { DistinctEnrollmentsSelections, useEnrollmentDestinationsQuery } from '../enrolled/gql';

import { moveEnrollments } from './generated';

interface FormEnrollment {
  student: string;
}

interface FormValues {
  activity: string;
  enrollments: FormEnrollment[];
}

type MoveStudentsForm = FormModel<FormValues>;

interface Props {
  course: CourseSelections;
  enrollments: DistinctEnrollmentsSelections[];
}

export function MoveStudentsModal(props: Props) {
  const [result] = useEnrollmentDestinationsQuery({ variables: { sourceId: props.course?.id } });
  const initialValues = React.useMemo(() => ({ enrollments: props.enrollments.map(e => ({ student: EnrollmentUtils.getStudentName(e as unknown as Enrollment) })) }), props.enrollments);

  const noEligibleCourses = !result.fetching && isEmpty(result.data?.enrollmentDestinations);
  const allFree = props.enrollments.every(e => e.listPrice === 0);
  const units = courseKindBehavior[props.course.kind].units.toLowerCase();

  function render() {
    return (
      <Modal title={`Move student to a different ${units}`} ok="Move">
        <Form icon="BookOpen" title="Select activity" initialValues={initialValues} onOk={handleSubmit} onNavigation="nothing">
          <RepeatingSection name="enrollments" bordered fields={[<Field label="Student" name="student" />]} />
          {renderDropdown()}
          {renderWarnings()}
        </Form>
      </Modal>
    );
  }

  function renderDropdown() {
    const options = (result.data?.enrollmentDestinations || []).map(c => ({ label: `${c.disambiguatedName} (${c.maxCapacity - c.enrolledCount} open ${pluralize('spot', c.maxCapacity - c.enrolledCount)})`, value: c.id }));
    const article = units.startsWith('a') ? 'an' : 'a';

    return <Section label={`Select ${article} ${units} to move these students to`} name="activity" edit={Dropdown} options={options} disabled={noEligibleCourses} placeholder={`Select ${units}`} required />;
  }

  function renderWarnings() {
    const warnings = noEligibleCourses
      ? `There aren't any eligible ${pluralize(units)} to move these students to at this time. Students can only be moved to active or upcoming ${pluralize(units)} by the same provider.`
      : !allFree
        ? 'Moving a student does not affect the price paid. If the new activity is less expensive you can do a partial refund. If the new activity is more expensive you should remove and refund the student and invite them to the new activity.'
        : '';

    return warnings ? <Info type="warning">{warnings}</Info> : undefined;
  }

  async function handleSubmit(form: MoveStudentsForm) {
    const ids = props.enrollments.map(e => e.id);
    const [success] = await moveEnrollments({
      variables: { ids, destinationCourseId: form.values.activity, sourceCourseId: props.course?.id },
      successMsg: 'Students moved',
      error: { handler: form, transform: [errorPathTransform('ids', 'enrollments')] }
    });
    return success;
  }

  return render();
}

import * as React from 'react';

import { NavigationSection, NavigationLink } from 'app2/views/shared-public';

export const parentLinks = <NavigationSection icon='Users' name='Family'>
  <NavigationLink to='/family/schedule' label='Schedule' />
  <NavigationLink to='/family/payment' label='Payment' />
  <NavigationLink to='/family/family' label='Family' />
  <NavigationLink to='/family/messages' label='Messages' />
</NavigationSection>

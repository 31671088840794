import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SiteWithTimeTrackingQueryVariables = Types.Exact<{
  site: Types.Scalars['ID'];
}>;

export type SiteWithTimeTrackingQuery = { site?: { timeEntryQrCode?: string | null | undefined; timeEntryUrl?: string | null | undefined } | null | undefined };

export const SiteWithTimeTrackingDocument = gql`
  query SiteWithTimeTracking($site: ID!) {
    site(site: $site) {
      timeEntryQrCode
      timeEntryUrl
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type SiteWithTimeTrackingArgs = Omit<UseQueryArgs<SiteWithTimeTrackingQueryVariables, SiteWithTimeTrackingQuery>, 'query'>;

export function useSiteWithTimeTrackingQuery(options: SiteWithTimeTrackingArgs = {}) {
  return useQuery<SiteWithTimeTrackingQueryVariables, SiteWithTimeTrackingQuery>({ query: SiteWithTimeTrackingDocument, ...options });
}

export type SiteWithTimeTrackingOptions = ExecQueryOptions<SiteWithTimeTrackingQueryVariables>;

export function siteWithTimeTracking(options: SiteWithTimeTrackingOptions) {
  return executeQuery<SiteWithTimeTrackingQuery, SiteWithTimeTrackingQueryVariables>(SiteWithTimeTrackingDocument, options);
}

export type SiteWithTimeTrackingSelections = SiteWithTimeTrackingQuery['site'];

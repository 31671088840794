import * as React from 'react';

import { compact, CourseKind, CourseUtils, EnrollmentUtils, RateType } from 'app2/api';
import { CheckboxField, Modal, RepeatingSection, Info, Form, Field, FormModel, Part, useForm } from 'app2/components';
import { errorPathTransform, StudentNameField, ScheduleField } from 'app2/views/shared';

import { DistinctEnrollmentsSelections } from '../enrolled/gql';
import { WaitlistedEnrollmentsSelections } from '../waitlisted';
import { CourseSelections } from '../../generated';

import { removeEnrollments } from './generated';

type Enrollment = DistinctEnrollmentsSelections | WaitlistedEnrollmentsSelections;

interface FormValues {
  enrollments: Enrollment[];
  childEnrollments: boolean;
}

type RemoveStudentsForm = FormModel<FormValues>;

interface Props {
  course: CourseSelections;
  enrollments: Enrollment[];
}

export function RemoveStudentsModal(props: Props) {
  const initialValues = React.useMemo(() => ({ enrollments: props.enrollments }), [props.enrollments]);
  const hasRefundableEnrollments = props.enrollments.some(e => e.balance > 0);
  const allWaitlist = props.enrollments.filter(e => EnrollmentUtils.waitlisted(e as WaitlistedEnrollmentsSelections)).length == props.enrollments.length;
  const title = allWaitlist ? 'Remove from waitlist' : hasRefundableEnrollments ? 'Remove without refunding' : 'Remove';
  const hasBillPeriod = (props.enrollments?.[0] as any).billPeriod != null;
  const hasOptions = props.course.options?.length > 0;

  const form = useForm<FormValues>(initialValues, undefined, { alwaysSave: true });

  function render() {
    const priceType = CourseUtils.getRateOrPriceType(props.course.rates);

    return <Modal title={title} ok={title}>
        <Form form={form} onOk={handleSubmit} onNavigation="nothing">
        <RepeatingSection name="enrollments" bordered fields={[
          <Field label="Student" name="student.name" nameKind='full' component={StudentNameField} />,
          priceType == RateType.advanced && <Field label="Schedule" name="rosterPeriod" component={ScheduleField} />,
          priceType != RateType.basic && hasBillPeriod && <Field label="Bill period" name="billPeriod" />,  
        ]} />
        {renderWarnings()}
        {hasOptions && <Part component={CheckboxField} label="Remove from activity options" name="childEnrollments" />}
      </Form>
    </Modal>
  }

  function renderWarnings() {
    const messages = compact([
      !allWaitlist && hasRefundableEnrollments ? `Are you sure you want to remove these students without refunding them? If you'd like to refund them as well, please click "Refund" instead.` : undefined,
      getRemoveAthelticsWarnings(props.course)
    ]);

    if (!messages.length) {
      return;
    }

    return <Info type="warning">{messages}</Info>
  }

  async function handleSubmit(form: RemoveStudentsForm) {
    const [success] = await removeEnrollments({
      variables: { courseId: props.course?.id, ids: props.enrollments.map(e => e.id), childEnrollments: form.values.childEnrollments },
      successMsg: 'Students removed',
      error: { handler: form, transform: [errorPathTransform('ids', 'enrollments')] }
    });
    return success;
  }

  return render();
}

export function getRemoveAthelticsWarnings(course: CourseSelections) {
  if (course.kind == CourseKind.AthleticIndividualReg) {
    return "Removing a registration purchase, does not automatically remove the student from their team.";
  }

  if (course.kind == CourseKind.AthleticReg) {
    return "Removing a registration purchase, does not automatically cancel the associated team.";
  }

  if (course.kind == CourseKind.Team) {
    return "Removing a student from a team does not refund any registration fees";
  }
}

import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminCourseReviewsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type AdminCourseReviewsQuery = {
  course?:
    | {
        id: string;
        name?: string | null | undefined;
        reviews: Array<{
          title?: string | null | undefined;
          comments?: string | null | undefined;
          instructorRating?: number | null | undefined;
          overallRating?: number | null | undefined;
          qualityRating?: number | null | undefined;
          enrollment?: { student?: { id: string; name: string } | null | undefined; parent?: { id: string; name?: string | null | undefined } | null | undefined } | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export const AdminCourseReviewsDocument = gql`
  query AdminCourseReviews($id: ID!) {
    course(course: $id) {
      id
      name
      reviews {
        title
        comments
        instructorRating
        overallRating
        qualityRating
        enrollment {
          student {
            id
            name
          }
          parent {
            id
            name
          }
        }
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminCourseReviewsArgs = Omit<UseQueryArgs<AdminCourseReviewsQueryVariables, AdminCourseReviewsQuery>, 'query'>;

export function useAdminCourseReviewsQuery(options: AdminCourseReviewsArgs = {}) {
  return useQuery<AdminCourseReviewsQueryVariables, AdminCourseReviewsQuery>({ query: AdminCourseReviewsDocument, ...options });
}

export type AdminCourseReviewsOptions = ExecQueryOptions<AdminCourseReviewsQueryVariables>;

export function adminCourseReviews(options: AdminCourseReviewsOptions) {
  return executeQuery<AdminCourseReviewsQuery, AdminCourseReviewsQueryVariables>(AdminCourseReviewsDocument, options);
}

export type AdminCourseReviewsSelections = AdminCourseReviewsQuery['course'];

import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerSeasonEnrollmentsSeasonQueryVariables = Types.Exact<{
  seasonId: Types.Scalars['ID'];
}>;

export type OrganizerSeasonEnrollmentsSeasonQuery = {
  season?:
    | {
        id: string;
        name: string;
        courseKindGroups: Array<Types.CourseKindGroup>;
        hasConfigurablePricing: boolean;
        hasNonSeasonPricing: boolean;
        enrollmentOpens: string;
        enrollmentCloses: string;
        holidays: Array<string | null | undefined>;
        coursesBegin: string;
        coursesFinish: string;
      }
    | null
    | undefined;
};

export const OrganizerSeasonEnrollmentsSeasonDocument = gql`
  query OrganizerSeasonEnrollmentsSeason($seasonId: ID!) {
    season(season: $seasonId) {
      id
      name
      courseKindGroups
      hasConfigurablePricing
      hasNonSeasonPricing
      enrollmentOpens
      enrollmentCloses
      holidays
      coursesBegin
      coursesFinish
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type OrganizerSeasonEnrollmentsSeasonArgs = Omit<UseQueryArgs<OrganizerSeasonEnrollmentsSeasonQueryVariables, OrganizerSeasonEnrollmentsSeasonQuery>, 'query'>;

export function useOrganizerSeasonEnrollmentsSeasonQuery(options: OrganizerSeasonEnrollmentsSeasonArgs = {}) {
  return useQuery<OrganizerSeasonEnrollmentsSeasonQueryVariables, OrganizerSeasonEnrollmentsSeasonQuery>({ query: OrganizerSeasonEnrollmentsSeasonDocument, ...options });
}

export type OrganizerSeasonEnrollmentsSeasonOptions = ExecQueryOptions<OrganizerSeasonEnrollmentsSeasonQueryVariables>;

export function organizerSeasonEnrollmentsSeason(options: OrganizerSeasonEnrollmentsSeasonOptions) {
  return executeQuery<OrganizerSeasonEnrollmentsSeasonQuery, OrganizerSeasonEnrollmentsSeasonQueryVariables>(OrganizerSeasonEnrollmentsSeasonDocument, options);
}

export type OrganizerSeasonEnrollmentsSeasonSelections = OrganizerSeasonEnrollmentsSeasonQuery['season'];

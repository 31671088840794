import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminSitesQueryVariables = Types.Exact<{
  term: Types.Scalars['OptionValue'];
}>;

export type AdminSitesQuery = {
  sites?:
    | Array<{
        id: string;
        name?: string | null | undefined;
        city?: string | null | undefined;
        state?: string | null | undefined;
        zipcode?: string | null | undefined;
        partner: boolean;
        retired?: boolean | null | undefined;
        active?: boolean | null | undefined;
        slug: string;
      }>
    | null
    | undefined;
};

export const AdminSitesDocument = gql`
  query AdminSites($term: OptionValue!) {
    sites(filters: [{ name: "name", op: CONTAINS, value: $term }]) {
      id
      name
      city
      state
      zipcode
      partner
      retired
      active
      slug
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminSitesArgs = Omit<UseQueryArgs<AdminSitesQueryVariables, AdminSitesQuery>, 'query'>;

export function useAdminSitesQuery(options: AdminSitesArgs = {}) {
  return useQuery<AdminSitesQueryVariables, AdminSitesQuery>({ query: AdminSitesDocument, ...options });
}

export type AdminSitesOptions = ExecQueryOptions<AdminSitesQueryVariables>;

export function adminSites(options: AdminSitesOptions) {
  return executeQuery<AdminSitesQuery, AdminSitesQueryVariables>(AdminSitesDocument, options);
}

export type AdminSitesSelections = AdminSitesQuery['sites'][0];

(AdminSitesDocument as any).additionalTypenames = ['Site'];

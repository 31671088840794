import * as React from 'react'
import { useHistory } from 'react-router';

import logoImage from 'images/logo.png'

import { isImpersonatingUser } from 'app2/api'
import { Dropdown, hasAppMode, HBox, VBox, Icon, Img, Link, useBreakpoints, Breakpoints, DropdownZindex, MenuSeparator, Viewport } from 'app2/components'

import { SitesDropdown, SitesDropdownProps } from '../sites';
import { useCurrentUser } from '../user';

import { BetaIcon, useIsBetaRoute } from './Beta';

import { ShoppingCart } from './ShoppingCart'
import { AccountMenu } from './AccountMenu';

export const headerZindex = DropdownZindex + 100;

export interface HeaderProps {
  onClickHamburger: () => void;
  primaryActions?:React.ReactNode;
  secondaryActions?:React.ReactNode;
}

export function Header(props:HeaderProps) {
  const { user, loading } = useCurrentUser();
  const history = useHistory();
  const isBetaRoute = useIsBetaRoute();

  const breakpoint = useBreakpoints();
  const embedded = hasAppMode('embed');

  function render() {
    if (embedded) {
      return <Viewport><ShoppingCart position='absolute' right='8px' /></Viewport>;
    }

    return <VBox px={['$16', '$30']} py='$12' borderBottom='solid 1px' borderColor='border' zIndex={headerZindex} bg='white'>
      <HBox width='100%' vAlign='center' hItemSpace={['$8', '$20']} whiteSpace='nowrap'>
        {props.onClickHamburger && <Icon name='Menu' size='medium' display={['unset', 'unset', 'none']} onClick={props.onClickHamburger} />}
        <Link to='/'><Img src={logoImage} height={['26px', '28px', '34px']} py='6px' style={{objectFit:'contain', objectPosition:'left'}}  /></Link>
        {breakpoint == Breakpoints.desktop && renderSiteSearch({maxWidth:'390px'})}
        {renderDeactivatedUserWarning()}
        {renderActionsAndControls()}
      </HBox>
      {props.secondaryActions && <HBox mt='$12' width='100%'>{props.secondaryActions}</HBox>}
      {breakpoint != Breakpoints.desktop && renderSiteSearch({mt:'$8'})}
    </VBox>
  }

  function renderActionsAndControls() {
    if (loading) {
      return;
    }

    return <>
      {renderBetaIndicator()}
      <VBox flex={1} />
      {props.primaryActions}
      {renderUserControls()}
      <ShoppingCart />
    </>
  }

  function renderUserControls() {
    return user ? renderLoggedIn() : renderLoggedOut();
  }

  function renderSiteSearch(extraProps:SitesDropdownProps) {
    return <SitesDropdown placeholder='Find your school' icon='Search' width='100%' searchableOnly={true} activeOnly={true} onChange={onSelectSite} {...extraProps} />
  }

  function renderDeactivatedUserWarning() {
    if (!user || !user.deactivated || !isImpersonatingUser()) {
      return;
    }

    return <HBox bg='warning' borderRadius='standard' text='subtitle2' color='white' p='4px'>Deactivated user</HBox>;
  }

  function renderLoggedIn() {
    return <AccountMenu />
  }

  function renderLoggedOut() {
    return <>
      <MenuSeparator type='vertical' height='34px' mr='$20' />
      <Link to='https://meetings.hubspot.com/homeroom/book-a-demo?utm_campaign=Demos%20Scheduled&amp;utm_source=Direct' button='secondary' small mr='$8'>Get a demo</Link>
      <Link to='/login' button='primary' small>Log in</Link>
    </>
  }

  function renderBetaIndicator() {
    return isBetaRoute
      ? <BetaIcon />
      : ''
  }

  function onSelectSite(event:React.ChangeEvent<Dropdown>) {
    const site = event.target.value;

    history.push(`/sites/${site.slug}`);

  }

  return render();
}

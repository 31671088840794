import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type VendorSitesQueryVariables = Types.Exact<{
  vendor: Types.Scalars['ID'];
}>;

export type VendorSitesQuery = {
  vendor?: { company?: { sites: Array<{ id: string; name?: string | null | undefined; userSiteRole?: Types.MemberRole | null | undefined; typename: 'Site' }> } | null | undefined } | null | undefined;
};

export const VendorSitesDocument = gql`
  query VendorSites($vendor: ID!) {
    vendor(vendor: $vendor) {
      company {
        sites {
          typename: __typename
          id
          name
          userSiteRole
        }
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type VendorSitesArgs = Omit<UseQueryArgs<VendorSitesQueryVariables, VendorSitesQuery>, 'query'>;

export function useVendorSitesQuery(options: VendorSitesArgs = {}) {
  return useQuery<VendorSitesQueryVariables, VendorSitesQuery>({ query: VendorSitesDocument, ...options });
}

export type VendorSitesOptions = ExecQueryOptions<VendorSitesQueryVariables>;

export function vendorSites(options: VendorSitesOptions) {
  return executeQuery<VendorSitesQuery, VendorSitesQueryVariables>(VendorSitesDocument, options);
}

export type VendorSitesSelections = VendorSitesQuery['vendor'];

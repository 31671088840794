import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompanyRevokeInviteMutationVariables = Types.Exact<{
  inviteToken: Types.Scalars['String'];
}>;

export type CompanyRevokeInviteMutation = { companyInviteRevoke?: { entity?: { id: string } | { id: string } | null | undefined } | null | undefined };

export const CompanyRevokeInviteDocument = gql`
  mutation companyRevokeInvite($inviteToken: String!) {
    companyInviteRevoke(inviteToken: $inviteToken) {
      entity {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type CompanyRevokeInviteOptions = ExecMutationOptions<CompanyRevokeInviteMutationVariables>;

export function companyRevokeInvite(options: CompanyRevokeInviteOptions) {
  return executeMutation<CompanyRevokeInviteMutation, CompanyRevokeInviteMutationVariables>(CompanyRevokeInviteDocument, options);
}

import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompanySitesQueryVariables = Types.Exact<{
  company: Types.Scalars['ID'];
}>;

export type CompanySitesQuery = { company?: { sites: Array<{ id: string; name?: string | null | undefined; userSiteRole?: Types.MemberRole | null | undefined; typename: 'Site' }> } | null | undefined };

export const CompanySitesDocument = gql`
  query CompanySites($company: ID!) {
    company(company: $company) {
      sites {
        typename: __typename
        id
        name
        userSiteRole
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type CompanySitesArgs = Omit<UseQueryArgs<CompanySitesQueryVariables, CompanySitesQuery>, 'query'>;

export function useCompanySitesQuery(options: CompanySitesArgs = {}) {
  return useQuery<CompanySitesQueryVariables, CompanySitesQuery>({ query: CompanySitesDocument, ...options });
}

export type CompanySitesOptions = ExecQueryOptions<CompanySitesQueryVariables>;

export function companySites(options: CompanySitesOptions) {
  return executeQuery<CompanySitesQuery, CompanySitesQueryVariables>(CompanySitesDocument, options);
}

export type CompanySitesSelections = CompanySitesQuery['company'];

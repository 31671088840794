import * as React from 'react';
import moment from 'moment';
import { useParams, useHistory } from 'react-router';
import { debounce, findLast } from "lodash";
import { UseQueryState } from 'urql';

import { CheckedIn, EnrollmentStatusFilter, RosterGroupingKind, userPreferences } from 'app2/api';
import { ActionButton,  Button, copyToClipboard, DatePicker, DataTable, DropdownZindex, iso8601Date, HBox, MenuItem, Phone, Point, TabletOrDesktop, Tabs, Text, useRef, useLifecycle, useBreakpoints, Breakpoints, useSafeState, parseSingleDate, VBox } from 'app2/components';
import { useCourseDates } from 'app2/views/shared-public';
import { downloadRoster, HrDataTable, createRosterView, byActivity, ContentType } from 'app2/views/shared'

import { CopyParentEmailsAction } from '../CopyParentEmailsAction';

import { AttendanceSelections, AttendanceQuery, setAttendance, useAttendanceQuery, useAttendanceCourseQuery } from './generated';
import { CourseHeader } from './CourseHeader';

const TABLE_PREFS_VERSION = '10';

interface Props {
  token?:string;
  url:string;
  prefsKey?:string;
  studentModal:React.ComponentType<{id:string}>;
  header?:boolean;
  // dictates if the check in/out tabs should be shown
  // by default they show on mobile automatically
  tabs?:boolean;
}

type ViewMode = 'all' | 'check-in' | 'check-out' | 'check-in-mobile' | 'check-out-mobile';

interface AttenanceCounts {
  total:number;
  present:number;
  checkedIn:number;
  checkedOut:number;
}

export function Attendance(props: Props) {
  const history = useHistory();
  let { date:urlDate } = useParams<{date?:string}>();
  const date = urlDate ? parseSingleDate(urlDate)?.format('YYYY-MM-DD') : null;

  const course = getCourse();
  const site = course?.site;

  const dates = useCourseDates(course);
  const session = dates.sessions.find(d => d.isSame(date, 'date'));
  const next = dates.sessions.find(d => d.isAfter(date, 'date'));
  const prev = findLast(dates.sessions, d => d.isBefore(date, 'date'));
  const isSessionDay = session != null;

  const tableRef = useRef<DataTable<AttendanceSelections>>();

  const breakpoint = useBreakpoints();

  const [studentCounts, setStudentCounts] = useSafeState<AttenanceCounts>({total:0, present:0, checkedIn: 0, checkedOut: 0});

  useLifecycle({onUpdate});

  function onUpdate() {
    if (date) {
      return;
    }

    setDate(moment(), true);
  }

  function render() {
    return <>
      {props.header && <CourseHeader course={course} date={date} day={session?.courseDay} />}
      <Phone>
        <Tabs position='sticky' top='0px' background='white' zIndex={DropdownZindex} tabStrip={{mb:0}} tabs={[
          {label: 'Check in', name: 'check-in-mobile', content: renderTable('check-in-mobile')},
          {label: 'Check out', name: 'check-out-mobile', content: renderTable('check-out-mobile')}
        ]} />
      </Phone>
      <TabletOrDesktop>
        {!props.tabs
          ? renderTable('all')
          : <Tabs position='sticky' top='0px' background='white' zIndex={DropdownZindex} tabStrip={{mb:0}} tabs={[
                {label: 'Check in', name: 'check-in', content: renderTable('check-in')},
                {label: 'Check out', name: 'check-out', content: renderTable('check-out')}
              ]} />}
      </TabletOrDesktop>
    </>
  }

  function renderTable(mode:ViewMode) {
    const cols = getCols(mode);
    const none = isSessionDay 
      ? course.hasEnrolledStudents
        ? 'No enrolled students on this day'
        : 'No enrolled students' 
      : 'Not an activity day'

    const mobile = mode == 'check-in-mobile' || mode == 'check-out-mobile'

    return <HrDataTable<AttendanceSelections>
      header={{ icon: 'Users', title: 'Attendance', subtitle: renderSubtitle(), options: renderOptions(), secondaryActions:mobile ? 'hide' : renderSecondaryActions(mode), editing: true, header:renderDate() }}
      table={{ cols, ref: tableRef, none, cellStyle: 'read-only', rowHeaders: !mobile, fillWidth: true, editable: true, atLeastOneEditableRow: false, onDataUpdate: handleAttendanceChange, sortFilterWhenEditable: true, rowNumbers:false, appendable: false, stickyOffset: mode != 'all' ? new Point(0, 36) : undefined }}
      prefs={userPreferences} prefsVersion={TABLE_PREFS_VERSION} prefsKey={prefsKey(mode)}
      queryHook={useAttendanceQuery} queryResponseHandler={onQueryResponse}
      queryOptions={{variables:{groupingId: props?.token, date:iso8601Date(date)}}}
    />
  }

  function renderSubtitle() {
    return <VBox gap='$8'>
      <Text text='body' textAlign='center'>{studentCounts.total} enrolled / {studentCounts.present} present</Text>
      <Text text='body' textAlign='center'>{studentCounts.checkedIn} checked-in / {studentCounts.checkedOut} checked-out</Text>
    </VBox>
  }

  function renderDate() {
    const isFuture = moment(date).isAfter(moment(), 'date');
    const warning = isFuture ? 'Attendance date is in the future' : undefined;
    const small = breakpoint == Breakpoints.phone || breakpoint == Breakpoints.tablet;

    return <HBox gap={['6px', '$16']} flex={1} hAlign='center'>
      <Button kind='tertiary' icon='Left' iconPosition='left' whiteSpace='normal' disabled={!prev} onClick={onPrev}>{small ? 'Prev' : 'Previous class'}</Button>
      <DatePicker legend={dates.legend} minWidth={['95px', '150px']} maxWidth={['95px', '150px']} value={date} dateFormat={[DatePicker.SHORT_DATE_FORMAT, DatePicker.DEFAULT_DATE_FORMAT]} shadowFormat={['', 'MMM D, YYYY ddd']} onChange={onChange} warning={warning} />
      <Button kind='tertiary' icon='Right' whiteSpace='normal' disabled={!next}  onClick={onNext}>{small ? 'Next' : 'Next class'}</Button>
    </HBox>
  }

  function renderOptions() {
    return [
      <CopyParentEmailsAction table={tableRef.current} />,
      <MenuItem label="Download roster PDF" onClick={() => handleDownloadRoster(ContentType.PDF)} />,
      <MenuItem label="Download roster CSV" onClick={() => handleDownloadRoster(ContentType.CSV)} />,
    ]
  }

  function renderSecondaryActions(mode:ViewMode) {
    const checkIn = mode == 'all' || mode == 'check-in' || mode == 'check-in-mobile';
    const checkOut = mode == 'all' || mode == 'check-out' || mode == 'check-out-mobile';

    return [
      checkIn && <ActionButton icon="UserCheck" onClick={handleCheckedIn}>Check in</ActionButton>,
      checkOut && <ActionButton icon="LogOut" onClick={handleCheckOut}>Check out</ActionButton>,
      <ActionButton icon="XCircle" onClick={handleAbsent}>Absent</ActionButton>,
      course?.behavior?.attendanceLink && <ActionButton icon="BookOpen" selection={false} onClick={() => copyToClipboard(`${location.origin}/activities/${props.token}/attendance`)}>Copy instructor link</ActionButton>
    ]
  }

  function onQueryResponse(response:UseQueryState<AttendanceQuery>) {
    const students = response?.data?.rosterByGrouping?.items;

    if (students) {
      const present = students.filter(s => s.checkedIn == CheckedIn.Present && !s.checkedOut).length;
      const checkedIn = students.filter(s => s.checkedIn == CheckedIn.Present).length;
      const checkedOut = students.filter(s => s.checkedOut).length;
      setStudentCounts({total: students.length, present, checkedIn, checkedOut});
    }

    return {items:students};
  }


  function prefsKey(mode:ViewMode) {
    return props.prefsKey ? `${props.prefsKey}/${mode}` : (props.token ? `/obfuscated/activities/${props.token}/attendance/${mode}` : undefined);
  }

  function getCourse() {
    const [result] = useAttendanceCourseQuery({variables:{token: props.token}})
    const course = result.data?.course;

    return course;
  }

  function getCols(mode:ViewMode) {
    return React.useMemo(() => {
      const options = {course, site, groupingId: props?.token, status:EnrollmentStatusFilter.Attendance, cols:cols[mode], studentModal: props.studentModal, date}
      return createRosterView<AttendanceSelections>(options).cols
    }, [course, site, cols[mode], date])
  }

  function onPrev() {
    setDate(prev);
  }

  function onNext() {
    setDate(next);
  }

  function onChange(event:React.ChangeEvent<DatePicker>) {
    const date = event.target.value as string;
    setDate(date);
  }

  function setDate(date:moment.Moment | string, replace = false) {
    if (!date) {
      return;
    }

    const url = `${props.url}/${moment(date).format('YYYY-MM-DD')}`;

    if (replace) {
      history.replace(url)
    }
    else {
      history.push(url)
    }
  }

  function handleCheckedIn() {
    tableRef.current.selections.updateSelected({checkedIn:CheckedIn.Present});
  }

  function handleAbsent() {
    tableRef.current.selections.updateSelected({checkedIn:CheckedIn.Absent});
  }

  function handleCheckOut() {
    tableRef.current.selections.updateSelected({checkedOut:true});
  }

  const handleAttendanceChange = debounce(async () => {
    const changes = tableRef.current.getChanges();
    const attendances = Object.keys(changes).map(enrollmentId => {
      const attendance = changes[enrollmentId].item;

      return {
        course: props.token,
        student:attendance.student.id,
        date:iso8601Date(date),
        checkedIn: attendance.checkedIn,
        checkedInAt: attendance.checkedInAt,
        checkedOut: attendance.checkedOut,
        checkedOutAt: attendance.checkedOutAt,
        checkoutDest: attendance.checkoutDest
      }
    });

    if (!attendances.length) {
      return;
    }

    await setAttendance({variables:{attendances}});
  }, 1000);

  function handleDownloadRoster(contentType:ContentType) {
    const fileName = ['Homeroom roster', course.name, date];
    const variables = {groupingId: props.token, groupingKind: RosterGroupingKind.Course, enrollmentStatus: EnrollmentStatusFilter.Attendance, date:iso8601Date(date)}; 

    return downloadRoster(fileName, contentType, 'rosterByGrouping', variables, tableRef.current, byActivity, ['title', 'cols', 'groups']);
  }

  return render();
}

const cols = {
    all: [
    'student.firstName',  
    'student.lastName', 
    'student.grade', 
    'student.age', 
    'student.classroom.displayName',
    'otherEnrollments',
    'checkedIn',
    'checkedInAt',
    'checkedInBy',
    'checkedOut',
    'checkedOutAt',
    'checkedOutBy',
    'checkoutDest',
    'groups'
  ],
  'check-in': [
    'student.firstName', 
    'student.lastName', 
    'checkedIn',
    'checkedInAt',
    'checkedInBy',
    'student.grade', 
    'student.age', 
    'student.classroom.displayName',
    'otherEnrollments',
    'groups'
  ],
  'check-out': [
    'student.firstName', 
    'student.lastName', 
    'checkedOut',
    'checkedOutAt',
    'checkedOutBy',
    'checkoutDest',
    'student.grade', 
    'student.age', 
    'student.classroom.displayName',
    'otherEnrollments',
    'checkedIn',
    'checkedInAt',
    'checkedInBy',
    'groups'
  ],
  'check-in-mobile': [
    {name:'student.firstName' as any, label: 'First', width: 100}, 
    {name:'student.lastName' as any, label: 'Last', width: 110}, 
    {name: 'checkedIn' as any, width: 105}, 
    {name: 'otherEnrollments' as any, width: 200}, 
    'groups'

  ],
  'check-out-mobile': [
    {name:'student.firstName' as any, label: 'First', width: 100}, 
    {name:'student.lastName' as any, label: 'Last', width: 110}, 
    {name: 'checkedOut' as any, width: 105}, 
    'checkoutDest',
    {name: 'otherEnrollments' as any, width: 200}, 
    'groups',
  ]
};

import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
import { CommonCompanyFragmentDoc } from '../../shared/generated/commonCompany.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompanyTeachersQueryVariables = Types.Exact<{
  company: Types.Scalars['ID'];
}>;

export type CompanyTeachersQuery = {
  company?:
    | {
        id: string;
        name?: string | null | undefined;
        userCompanyRole?: Types.MemberRole | null | undefined;
        nonPartnerApproved?: boolean | null | undefined;
        teachers?: Array<{ id: string; name: string; email?: string | null | undefined; phone?: string | null | undefined }> | null | undefined;
      }
    | null
    | undefined;
};

export const CompanyTeachersDocument = gql`
  query CompanyTeachers($company: ID!) @urql(additionalTypenames: ["Teacher"]) {
    company(company: $company) {
      ...commonCompany
      teachers {
        id
        name
        email
        phone
      }
    }
  }
  ${CommonCompanyFragmentDoc}
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type CompanyTeachersArgs = Omit<UseQueryArgs<CompanyTeachersQueryVariables, CompanyTeachersQuery>, 'query'>;

export function useCompanyTeachersQuery(options: CompanyTeachersArgs = {}) {
  return useQuery<CompanyTeachersQueryVariables, CompanyTeachersQuery>({ query: CompanyTeachersDocument, ...options });
}

export type CompanyTeachersOptions = ExecQueryOptions<CompanyTeachersQueryVariables>;

export function companyTeachers(options: CompanyTeachersOptions) {
  return executeQuery<CompanyTeachersQuery, CompanyTeachersQueryVariables>(CompanyTeachersDocument, options);
}

export type CompanyTeachersSelections = CompanyTeachersQuery['company'];

(CompanyTeachersDocument as any).additionalTypenames = ['Teacher'];

import * as React from 'react';

import { StudentCustomFields } from './StudentCustomFields';
import { StudentDetails } from './StudentDetails';
import { StudentEnrollments } from './StudentEnrollments';
import { StudentRefunds } from './StudentRefunds';
import { StudentLogEntries } from './StudentLogEntries';

export function studentTabs(id:string, editing:boolean) {
  return [{
    label: 'Profile',
    content: <StudentDetails id={id} editing={editing} />
  },
  {
    label: 'Refunds',
    content: <StudentRefunds id={id} />
  },
  {
    label: 'Activities',
    content: <StudentEnrollments id={id} />
  },
  {
    label: 'Registration questions',
    content: <StudentCustomFields id={id} overrideRequired={true} />
  },
  {
    label: 'Notes',
    content: <StudentLogEntries id={id} />
  }]
}

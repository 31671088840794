import * as React from 'react'
import { useParams } from 'react-router';

import { BooleanLabelField, DateField, Field, Form, RepeatingSection } from 'app2/components';

import { UserLink, HistoryLink } from '../shared';

import { useAdminCourseEnrollmentsQuery } from './generated';

export function Enrollments() {
  const { course } = useParams<{course:string}>();

  const [result] = useAdminCourseEnrollmentsQuery({variables:{id:course}});
  const item = result.data?.course;

  function render() {
    return <Form initialValues={item} editing={false}>
      <RepeatingSection name='enrollments' fields={[
        <Field name='id' label='ID' />,
        <Field name='student.name' label='Student' />,
        <Field name='student.parents.0.name' label='Parent' component={UserLink} />,
        <Field name='rosterPeriod' label='Schedule'/>,
        <Field name='discountedPrice' label='Purchase price'/>,
        <Field name='purchased' label='Purchased' component={BooleanLabelField} />,
        <Field name='startDate' label='Start' component={DateField} />,
        <Field name='priceConfig.kind' label='Type' />,
        <Field name='status' label='Status' format={(value:string) => value == "PAID" ? "Enrolled" : value} />,
        <Field name='id' label='History' component={HistoryLink} type='Enrollment' />,
      ]} />
    </Form>
  }

  return render();
}

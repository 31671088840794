import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminUsersQueryVariables = Types.Exact<{
  term: Array<Types.Scalars['JSON']> | Types.Scalars['JSON'];
}>;

export type AdminUsersQuery = { adminUsers?: Array<{ id: string; name?: string | null | undefined; email: string; phone?: string | null | undefined }> | null | undefined };

export const AdminUsersDocument = gql`
  query AdminUsers($term: [JSON!]!) {
    adminUsers(queries: $term, sorts: [{ name: "lastName", direction: ASC }]) {
      id
      name
      email
      phone
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminUsersArgs = Omit<UseQueryArgs<AdminUsersQueryVariables, AdminUsersQuery>, 'query'>;

export function useAdminUsersQuery(options: AdminUsersArgs = {}) {
  return useQuery<AdminUsersQueryVariables, AdminUsersQuery>({ query: AdminUsersDocument, ...options });
}

export type AdminUsersOptions = ExecQueryOptions<AdminUsersQueryVariables>;

export function adminUsers(options: AdminUsersOptions) {
  return executeQuery<AdminUsersQuery, AdminUsersQueryVariables>(AdminUsersDocument, options);
}

export type AdminUsersSelections = AdminUsersQuery['adminUsers'][0];

(AdminUsersDocument as any).additionalTypenames = ['User'];

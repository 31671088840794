import * as React from 'react';

import { ParentPage } from 'app2/views';

import { PaymentMethod } from './PaymentMethod';
import { PurchaseHistory } from './PurchaseHistory';
import { BilledLater } from './BilledLater';

export function Payment() {
  function render() {
    return <ParentPage title='Payment'>
      <PaymentMethod />
      <PurchaseHistory />
      <BilledLater />
    </ParentPage>
  }

  return render();
}

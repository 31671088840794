import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerDonationConfigQueryQueryVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
}>;

export type OrganizerDonationConfigQueryQuery = {
  site?: { id: string; donationConfig: { enabled: boolean; nonprofit: boolean; ein?: string | null | undefined; charityName?: string | null | undefined } } | null | undefined;
};

export const OrganizerDonationConfigQueryDocument = gql`
  query OrganizerDonationConfigQuery($siteId: ID!) {
    site(site: $siteId) {
      id
      donationConfig {
        enabled
        nonprofit
        ein
        charityName
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type OrganizerDonationConfigQueryArgs = Omit<UseQueryArgs<OrganizerDonationConfigQueryQueryVariables, OrganizerDonationConfigQueryQuery>, 'query'>;

export function useOrganizerDonationConfigQueryQuery(options: OrganizerDonationConfigQueryArgs = {}) {
  return useQuery<OrganizerDonationConfigQueryQueryVariables, OrganizerDonationConfigQueryQuery>({ query: OrganizerDonationConfigQueryDocument, ...options });
}

export type OrganizerDonationConfigQueryOptions = ExecQueryOptions<OrganizerDonationConfigQueryQueryVariables>;

export function organizerDonationConfigQuery(options: OrganizerDonationConfigQueryOptions) {
  return executeQuery<OrganizerDonationConfigQueryQuery, OrganizerDonationConfigQueryQueryVariables>(OrganizerDonationConfigQueryDocument, options);
}

export type OrganizerDonationConfigQuerySelections = OrganizerDonationConfigQueryQuery['site'];

import * as React from 'react';

import { DateField, Field, noOpErrorHandler, Panel, RepeatingSection, TimeField, useForm } from 'app2/components';

import { UserLink } from '../../shared';

import { Version } from './Version';
import { useAdminHistoryQuery } from './generated';

interface Props {
  type:string;
  id:string;
}

export function HistoryVersions(props:Props) {
  const { type:propsType, id } = props;
  const type = allowedTypes.indexOf(propsType) != -1 ? propsType : undefined;
  const [result] = useAdminHistoryQuery({variables: {type, id}, error: noOpErrorHandler, debounce: {delay:2000}});
  const form = useForm({versions: result.data?.history}, [result.data?.history]);

  function render() {
    return <Panel title='Versions' form={form}>
        <RepeatingSection name='versions' equalWidths={false} fields={[
          <Field label='ID' name='id' whiteSpace='nowrap' />,
          <Field label='Date' name='createdAt' whiteSpace='nowrap' component={DateField} />,
          <Field label='Time' name='createdAt' whiteSpace='nowrap' component={TimeField} />,
          <Field label='User' name='user.name' whiteSpace='nowrap' component={UserLink} />,
          <Field label='Attributes' name='attributes' component={Version} />,
        ]} />
      </Panel>
  }

  return render();
}

export const historyTypes = [
  {label: 'School', value: 'Site'},
  {label: 'Provider', value: 'Company'},
  {label: 'Season', value: 'Season'},
  {label: 'Student', value: 'Student'},
  {label: 'User', value: 'User'},
  {label: 'Enrollment', value: 'Enrollment'},
  {label: 'Activity', value: 'Course'},
]

const allowedTypes = historyTypes.map(ht => ht.value);
import * as React from 'react';

import { Link, FieldInfo } from 'app2/components'

interface NameAndId {
  id:string;
  name:string;
}

interface Props {
  info?:FieldInfo<NameAndId>;
  course?:NameAndId;
}

export function CourseLink(props:Props) {
  const season = props.course || props.info.record;

  return <Link className='hr-value' text='body' to={`/admin2/activities/${season?.id}`}>{season?.name} ({season.id})</Link>
}

CourseLink.fieldProps = {
  fieldProperty: 'info',
}
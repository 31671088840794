import * as React from 'react';
import { isEqual } from 'lodash-es';
import { castArray } from 'lodash-es';
import moment from 'moment';

import { dispatchChangeEvent } from './dom-utils';
import { AllIconNames, IconProps, Icon } from './icons';
import { TextProps } from './Text';

export type OptionValue = string | number | any;

export interface OptionLabelProps extends TextProps {
  icon:AllIconNames | IconProps | React.ReactElement<IconProps>
}
export interface Option<T = any> {
  label:React.ReactNode | OptionLabelProps;
  value:T;
  // only need to supply a text option if the label is
  // a React element and not text
  text?: string;
  // if you want an alternative label for the tags
  tag?:string;
}

// as a hack we support using id on OptionValue's, this function returns that if found
// todo - this should be removed and unify Options with ObservableCollection, which has a concept of id
export function getOptionValue(value:OptionValue) {
  return value !== undefined && value !== null && typeof value == 'object' && 'id' in value && !moment.isMoment(value) ? value.id : value;
}

export function getOptionStringValue(value:OptionValue) {
  return JSON.stringify(getOptionValue(value));
}

export function compareOptionValueArrays(left:OptionValue[], right:OptionValue[]) {
  if (left?.length != right?.length) {
    return false;
  }

  if (!left && !right) {
    return true;
  }

  return left.every((value, index) => compareOptionValues(value, right[index]));
}

export function compareOptionValues(left:OptionValue, right:OptionValue) {
  if ((left && right) && (moment.isMoment(left) || moment.isMoment(right))) {
    return moment(left).isSame(right)
  }

  // use isEqual for values that aren't primatives so that
  // they don't need to be identical but can be clones
  return isEqual(getOptionValue(left), getOptionValue(right));
}

export function findOption<T extends string | Option = Option>(options:T[], value:OptionValue, createFromValue:boolean = false):T {
  // support for when the value is just an array of strings and there's no options
  if (createFromValue && !options && Array.isArray(value)) {
    options = value;
  }

  const option = options?.find(o => compareOptionValues(typeof o === 'string' ? o : o.value, value));

  return !option && Array.isArray(value)
    ? findOption(options, value[0])
    : option;
}

export function findOptionByLabel<T extends string | Option = Option>(options:T[], label:string, allowPartial?:boolean) {
  if (label === undefined || label === null) {
    return;
  }

  const labelUpper = label.toString().toUpperCase();
  let option = options?.find(o => getOptionLabelForCompare(o) == labelUpper);

  if (!option && allowPartial) {
    option = options?.find(o => getOptionLabelForCompare(o).indexOf(labelUpper) != -1);
  }

  return option;
}

export function getOptionLabel(option:Option | string) {
  return !option 
    ? '' 
    : typeof option === 'string'
      ? option
      : (option.text || (typeof option.label == 'string' ? option.label : ''));
}

export function getOptionLabelForCompare(option:Option | string) {
  // need to trim the text value because when comparing
  // user input to find a matching option, the user
  // input is always trimmed as well
  return getOptionLabel(option).toUpperCase().trim();
}


// helper method for creating an option based ui

export interface SelectOptionInterface {
  selected:OptionValue[];
}

export interface SelectOptionsProps {
  options?:(string | Option)[];
  selected?:OptionValue[];
  onChange?:React.ChangeEventHandler<SelectOptionInterface>;
}

export function useSelectOptions(multiple:boolean, props:SelectOptionsProps, ref?:React.RefObject<HTMLElement>, useArrayForSelected:boolean = false) {
  const [selectedState, setSelectedState] = React.useState([]);
  const options = React.useMemo(() => getOptions(), [props.options]);
  const selected = castArray(props.selected ?? selectedState).filter(v => v !== undefined);

  setUpImperative();

  function setUpImperative() {
    ref = ref || React.useRef();

    React.useImperativeHandle<any, any>(ref, () => {
      return {
        selected:() => selected
      }
    });
  }

  function getOptions() {
    return props.options?.filter(optionOrString =>  !!optionOrString).map((optionOrString, index) => typeof optionOrString == 'string' 
      ? { label: optionOrString, text: optionOrString, value: optionOrString }
      : optionOrString);
  }

  function isSelected(value:OptionValue) {
    return selected && selected?.findIndex(selected => compareOptionValues(selected, value)) != -1;
  }

  function onChange(event:React.ChangeEvent<HTMLInputElement> | React.MouseEvent, value:OptionValue) {
    event?.preventDefault();
    event?.stopPropagation();

    const updated = !multiple
      ? [value]
      : isSelected(value)
        ? selected.filter(val => !compareOptionValues(val, value))
        : [...selected, value]

    setSelectedState(updated);

    dispatchChangeEvent(event?.target, multiple || useArrayForSelected ? updated : updated[0], props.onChange, 'selected');
  }

  return {options, selected, isSelected, onChange};
}


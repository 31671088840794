import { UserPreferences } from '../shared/preferences';

export const parentPreferencesKeys = {
  showWeekends: 'showWeekends',
};

export class ParentPreferences extends UserPreferences {
  get showWeekends(): boolean {
    return this.load(parentPreferencesKeys.showWeekends, false);
  }

  set showWeekends(value: boolean) {
    this.save(parentPreferencesKeys.showWeekends, value);
  }
}

export const parentPreferences = new ParentPreferences();

import * as React from 'react'
import { Redirect } from 'react-router-dom';

import { HBox, Link, hasAppMode } from 'app2/components'
import { PublicPage, marketingUrl, useCurrentUser } from 'app2/views/shared-public'

import { ComesFirst } from './ComesFirst'
import { Testimonial } from './Testimonial'
import { MoreThan } from './MoreThan'
import { Why } from './Why'
import { Learn } from './Learn'
import { Trusted } from './Trusted'
import { Who } from './Who'
import { Quotes } from './Quotes'

export function Home() {
  const { user, fetching, loading } = useCurrentUser();

  if (!hasAppMode('embed') && user && !fetching && !loading) {
    if (user.roles.admin) {
      return <Redirect to='/admin2' />
    }

    if (user.roles.organizer) {
      return <Redirect to='/organizer' />
    }

    if (user.roles.provider) {
      return <Redirect to='/provider' />
    }

    if (user.roles.parent) {
      return <Redirect to='/family' />
    }

    if (user.roles.course) {
      return <Redirect to='/instructor' />
    }

    return <Redirect to='/add-role' />
  }

  return <PublicPage hAlign='center' useMaxWidth maxWidth='1280px' pt={null} above={<>
      <SwitchBanner />
      <ComesFirst />
    </>}>
    <Testimonial />
    <MoreThan />
    <Why />
    <Learn />
    <Trusted />
    <Who />
    <Quotes />
  </PublicPage>
}

function SwitchBanner() {
  return <HBox text='subtitle2' px='$16' py='$8' textAlign='center' hAlign='center' vAlign='center' bg='courseDay' width='100%' fontSize={['14px', '16px']}>
    <span>Switch your school to Homeroom today for a $250 donation.&nbsp;&nbsp;<Link to={marketingUrl('get-250-for-your-school-with-homeroom')} text='subtitle2' fontSize={['14px', '16px']} underline={false}>Learn more</Link>.</span>
  </HBox>
}

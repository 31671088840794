import * as React from 'react';

import { createForm, CreateFormOptions } from './FormModelImpl';
import { useFormSubscription } from './useFormSubscription';

export function useForm<T>(initialValues: Partial<T> | (() => Partial<T>) = {}, deps?: any[], options?:CreateFormOptions<T>) {
  if (deps === undefined) {
    deps = [initialValues]
  }

  const form = React.useMemo(() => createForm<T>({...options, values: typeof initialValues === 'function' ? initialValues() : initialValues}), deps);

  useFormSubscription({ form });

  return form;
}

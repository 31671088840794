import * as React from 'react'
import { useParams, useHistory } from 'react-router';
import * as qs from 'query-string';
import { commaListsAnd } from 'common-tags';
import { uniqBy, sortBy } from 'lodash-es';

import { EnrollmentUtils, UserUtils, SHARER_PARAM, PROVIDER_BACKGROUND_IMAGE_WIDTH, PROVIDER_BACKGROUND_IMAGE_RATIO } from 'app2/api';
import { buildCloudinaryImageUrl, Calendar, CloudinaryPhoto, HRule, Form, FormContent, FormattedText, Link, Panel, Section, Box, HBox, Tabs, Text, phoneWidth, VBox } from 'app2/components';
import { COURSE_IMAGE_WIDTH, COURSE_IMAGE_RATIO, CourseInfo, courseKindBehavior, EmbedBackLink, PublicPage, PublicCompanyLink, PublicSiteLink, useCourseDates, useCurrentUser, useCart } from 'app2/views/shared-public';
import { removeAuthError } from 'app2/views/shared';
import { ParentStudentLink } from 'app2/views/parent/shared';
import defaultCourseImg from 'images/default_course_img.png';

import { PublicCourseParams } from '../publicRoutes';

import { CourseMessages } from './CourseMessages';
import { CourseMessagingActions } from './CourseMessagingActions';
import { usePublicCourseQuery, useParentCourseQuery } from './generated';

export function Course() {
  const { user } = useCurrentUser();

  const history = useHistory();
  let { id, tab } = useParams<PublicCourseParams>();

  if (history.location.search?.length) {
    const query = qs.parse(history.location.search);
    id = query.id || id;
  }

  const {course, parentCourse} = getCourse();
  const dates = useCourseDates(course);
  const { cart } = useCart();

  const behavior = courseKindBehavior[course?.kind];

  logShare();

  function getCourse() {
    const [result] = usePublicCourseQuery({variables: {id}});
    const course = result?.data?.course;

    const [parentResult] = useParentCourseQuery({variables: {id}, error: removeAuthError, pause: !course?.company});
    const parentCourse = parentResult?.data?.course;

    if (course?.company) {
      course.company = {...course.company, ...parentCourse?.company};
    }

    return {course, parentCourse: parentCourse?.parentEnrollments?.length ? parentCourse : null};
  }

  function render() {
    if (!course) {
      return <></>
    }

    return <PublicPage useMaxWidth position='relative' markingLinksWhenLoggedIn={false} back={<EmbedBackLink />}>
      <Form values={{course, parentCourse}} editing={false} maxWidth='100%' p='0px' pr='0px'>
        {renderHeader()}
        <HRule mb='$40' />
        {renderBody()}
      </Form>
    </PublicPage>
  }

  function renderHeader() {
    return <Box width='100%' layout={['vbox', 'vbox', 'hbox']} mb='$60' gap='$40' vAlign='top'>
      <VBox minWidth='300px' maxWidth={['unset', 'unset', '40%']} gap='$8' breakpoints={[phoneWidth, 840]} containerBreakpoints={true} >
        <CourseInfo course={course} cart={cart} partner={course?.site?.partner} siteCompanies={course?.site?.siteCompanies} kind='course-page' primaryActions={[<CourseMessagingActions course={course} parentCourse={parentCourse} />]} />
        {renderStudents()}
      </VBox>
      <Box borderRadius='standard' height={['200px', '200px', '250px']} maxHeight='300px' width={['100%', '100%', 'unset']} flex={['unset', 'unset', 1]} backgroundSize='cover' backgroundImage={`url(${getCourseImageUrl()})`} />
    </Box>
  }

  function renderStudents() {
    const students = sortBy(uniqBy(parentCourse?.parentEnrollments?.filter(e => !EnrollmentUtils.waitlisted(e))?.map(e => e.student), 'id'), 'name');

    if (!students?.length) {
      return;
    }

    return students.length == 1
      ? <Text text='body'><ParentStudentLink student={students[0]} inline /> is enrolled in this activity.</Text>
      : <Text text='body'>{students.map((s, index) => <React.Fragment key={s.id}>
        <ParentStudentLink student={s} inline />{index < students.length - 2 ? ', ' : index < students.length - 1 ? ' and ' : ''}
      </React.Fragment>)} are enrolled in this activity.</Text>
  }

  function getCourseImageUrl() {
    if (course.courseImage) {
      return buildCloudinaryImageUrl(course.courseImage, COURSE_IMAGE_RATIO, COURSE_IMAGE_WIDTH);
    }

    if (course.company?.profileBackground) {
      return buildCloudinaryImageUrl(course.company.profileBackground, PROVIDER_BACKGROUND_IMAGE_RATIO, PROVIDER_BACKGROUND_IMAGE_WIDTH);
    }

    return defaultCourseImg;
  }

  function renderBody() {
    return parentCourse
      ? renderTabs()
      : renderMiddleAndProvider();
  }
  
  function renderTabs() {
    return <Tabs baseUrl={`/activities/${course?.id}/:tab`} urlParameter='tab' tabs={[
      {label: 'About', name:'info', content: renderMiddleAndProvider()},
      {label: 'Messages', name:'messages', content: <CourseMessages course={course} parentCourse={parentCourse} />},
    ]} />
  }

  function renderMiddleAndProvider() {
    return <>
      {renderMiddle()}
      {renderProvider()}
    </>
  }

  function renderMiddle() {
    return <HBox gap='$40' layout={['vbox', 'vbox', 'hbox']} mb='$60' >
      <VBox gap='form-content' flex={1}>
        <Section label='Description' name='course.description' component={FormattedText} />
        <Section label='Location'><PublicSiteLink site={course?.site} flex='unset' />{parentCourse?.room ? ' - ' + parentCourse.room : ''}</Section>
        <Section label='What to bring' name='course.supplies' none='No supplies needed. All supplies will be provided for this activity.' format={(supplies:string[]) => commaListsAnd`${supplies}`} hidden={behavior?.fields?.supplies === false} />
        <Section label='Cancellation policy' layout='vbox'>
          <Section name='course.company.cancellationPolicy.text' none='Refunds are subject to provider and school policies.' />
        </Section>
      </VBox>
      {renderCalendar()}
    </HBox>
  }

  function renderCalendar() {
    return <FormContent flex='unset'>
      <Section label='Schedule'>
        <Calendar width={['100%', '', '']}  start={dates.start} legend={dates.legend} />
      </Section>
    </FormContent>
  }

  function renderProvider() {
    return <Panel title='About the provider'>
      <HBox gap='$16' layout={['vbox', 'hbox']}>
        <VBox gap='$16' hAlign='center'>
          {course.company?.profilePhoto
            ? <CloudinaryPhoto src={course.company?.profilePhoto} ratio={1} imageWidth={200} mr='$20' borderRadius={100} />
            : <VBox bg='brand' hAlign='center' vAlign='center' color='white' text='heading2' fontSize={['30px', '80px']} overflow='hidden'
              width={['80px', '200px']} height={['80px', '200px']} borderRadius={['40px', '200px']}>{UserUtils.getInitials(course?.company?.name)}</VBox>}
          <VBox gap='$4' hAlign='center'>
            <PublicCompanyLink company={course.company} underline={false} />
            <Link email={parentCourse?.company?.email} underline={false} />
            <Link phone={parentCourse?.company?.phone} underline={false} />
          </VBox>
        </VBox>
        <VBox gap='$16' hAlign='left'>
          <Section name='course.company.headline' none={false} component={FormattedText} />
          <Section name='course.company.about' none={false} component={FormattedText} />
          <PublicCompanyLink company={course.company} underline={false} button='secondary'>Visit page</PublicCompanyLink>
        </VBox>
      </HBox>
    </Panel>
  }

  async function logShare() {
    const searchParams = new URLSearchParams(location.search)
    const sharerUUID = searchParams.get(SHARER_PARAM)

    // we call this no matter what in case there are 
    // pending shares and the user has now logged in
    await UserUtils.recordCourseShare(id, sharerUUID, user);

    if (user?.uuid && user?.uuid != sharerUUID && tab != 'messages') {
      searchParams.delete('id');
      searchParams.set(SHARER_PARAM, user.uuid);
      history.replace({ pathname: `/activities/${id}`, search: searchParams.toString() });
    }
  }

  return render();
}

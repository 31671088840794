import * as React from 'react';
import { useHistory } from 'react-router';

import { Enrollment, CartUtils, EnrollmentUtils } from 'app2/api';
import { HBox, Field, FieldRendererProps, Form, formatCurrency, formatDecimalAligned, getScrollParent, Img, Link, RepeatingSection, Text, VBox, scrollTo } from 'app2/components';
import { FacebookLoader } from 'app/scriptLoading';
import { coursePriceUnitLabels, useCurrentUser } from 'app2/views/shared-public';
import { EnrollmentActivityLink } from 'app2/views/shared';

import { CheckoutModel } from './CheckoutModel';
import { Total } from './payment';
import popper from './popper.png'

type CartedEnrollment = CheckoutModel['cart']['enrollments'][0]; 

interface Props {
  purchase:CheckoutModel;
  statementDescriptor:string;
}

export function PurchaseSummary(props:Props) {
  const { user } = useCurrentUser();
  const { allWaitlist, waitlisted = [], purchases = [], billedLater = [], ongoingInstances, ongoingProrated } = CartUtils.classifyEnrollments(props.purchase.cart);
  const element = React.useRef<HTMLDivElement>();

  updateUrl();

  function updateUrl() {
    const history = useHistory();

    React.useEffect(() => {
      if (history.location.pathname != '/checkout#payment') {
        // be careful about replacing this url as customers rely on this 
        // when embedding, to do gtm conversion tracking
        history.replace('/checkout#thankyou');
        // this is needed because we are only doing a hash change, so the 
        // code that monitors url change wont necessarily scroll to the top
        scrollTo(getScrollParent(element.current), {left: 0, top:0});
      }
    }, []);
  }

  function render() {
    return <FacebookLoader>
      <HBox ref={element} gap='$40' layout={['vbox', 'vbox', 'hbox']}>
        <VBox>
          <HBox text='heading1' mb='$30' vAlign='center' gap='$20'>You're all set! <Img src={popper} height='80px' /></HBox>
          {renderSchedule()}
          {renderFamily()}
          {renderPurchase()}
          {renderBilledLater()}
          {renderWaitlist()}
        </VBox>
      </HBox>
    </FacebookLoader>
  }

  function renderSchedule() {
    if (allWaitlist) {
      return;
    }

    return <VBox mb='$40' gap='$12' hAlign='left'>
      <Text text='body'>You’re all signed up! A confirmation email has been sent to {user?.email}.</Text>
      {props?.statementDescriptor && <Text text='body' bold>Purchases will appear on your credit card statement as {props?.statementDescriptor}.</Text>}
    </VBox>    
  }

  function renderFamily() {
    if (allWaitlist) {
      return;
    }

    return <VBox mb='$40' gap='$12' hAlign='left'>
      <Text text='heading2'>What to expect next</Text>
      <Text text='body'>Some programs may require additional information or forms to fill out. You will be able to complete registration, view your schedule and make changes in your Homeroom account.</Text>
      <Link button='primary' icon='User' iconPosition='left' to='/family/family'  target="_top">Go to my Homeroom account</Link>
    </VBox>    
  }

  function renderPurchase() {
    if (!purchases.length) {
      return;
    }

    return <VBox gap={[undefined, '$12']} hAlign='left' width='100%'>
      <Text text='heading2'>Purchase summary</Text>
      <Form values={{purchases}} width='100%' editing={false} pr={0} overflow='hidden'>
        <RepeatingSection name='purchases' numbered={false} equalWidths={false} bordered fields={[
          <Field label='Activity' name='course' render={renderActivity} />,
          <Field label='Schedule' name='rosterPeriod' />,
          <Field label='Name' name='student.id' format={(id:string) => props.purchase.user.students.find(s => s.id == id).name}/>,
          <Field label={formatDecimalAligned('Amount')} name='amount' format={formatDecimalAligned} />
          ]} />

        <Form values={props.purchase} editing={false}><HBox hAlign='right'><Total purchaseSummary /></HBox></Form>
      </Form>
    </VBox>    
  }

  function renderActivity(props:FieldRendererProps<Enrollment>) {
    const activityLink = <EnrollmentActivityLink info={props.info} badge waitlist='never' role='public' />;
    const e = props.info?.record;
    const instance = ongoingInstances.has(e.id);
    const isProrated = ongoingProrated.has(e.id);
    
    return !instance
      ? activityLink
      : <VBox>
        {activityLink}
        <Text text='body'>{`${isProrated ? 'prorated ' : ''}first ${coursePriceUnitLabels[EnrollmentUtils.unit(e)]}`}</Text>
      </VBox>
  }

  function renderBilledLater() {
    if (!billedLater.length) {
      return;
    }

    return <VBox gap={[undefined, '$12']} hAlign='left' width='100%'>
      <Text text='heading2'>Billed later</Text>
      <Text text='subtitle2'>Homeroom will automatically charge your payment on file at a later date</Text>
      <Form values={{templates: billedLater}} width='100%' editing={false} pr={0} overflow='hidden'>
        <RepeatingSection name='templates' numbered={false} equalWidths={false} bordered fields={[
          <Field label='Activity' name='course' component={EnrollmentActivityLink} badge waitlist='never' role='public' />,
          <Field label='Schedule' name='rosterPeriod' />,
          <Field label='Name' name='student.id' format={(id:string) => props.purchase.user.students.find(s => s.id == id).name}/>,
          <Field label='Amount' format={(e:CartedEnrollment) => `${e.rosterAmount}`} />
          ]} />
      </Form>
    </VBox>    
  }
  function renderWaitlist() {
    if (!waitlisted.length) {
      return;
    }

    const total = waitlisted.reduce((p, e) => p + e.amount, 0);

    return <VBox gap={[undefined, '$12']} hAlign='left' width='100%' mt='$40'>
      <Text text='heading2'>Waitlist summary</Text>
      <Text text='subtitle2'>If a spot opens up in the next 30 minutes, we'll automatically purchase it for you. Otherwise, we'll
      email you when a spot becomes available and you'll have 24 hours to accept it.</Text>
      <Form values={{waitlisted}} width='100%' editing={false} pr={0}>
        <RepeatingSection name='waitlisted' numbered={false} equalWidths={false} bordered fields={[
          <Field label='Activity' name='course' component={EnrollmentActivityLink} badge waitlist='always' role='public' />,
          <Field label='Name' name='student.id' format={(id:string) => props.purchase.user.students.find(s => s.id == id).name}/>,
          <Field label={formatDecimalAligned('Amount')} name='amount' format={formatDecimalAligned} />
          ]} />
      </Form>

      <VBox width="100%" hAlign="right">
        <VBox vItemSpace="$16" hAlign="justify" width="100%" maxWidth="390px" mb="$16">
          {allWaitlist &&
          <HBox hAlign="justify" mb="$16">
            <Text text="subtitle1">Paid:</Text>
            <Text text="subtitle1">{formatCurrency(0)}</Text>
          </HBox>}

          <HBox hAlign="justify" mb="$16">
            <Text text="subtitle1">Charged later, if a spot opens up:</Text>
            <Text text="subtitle1">{formatCurrency(total)}</Text>
          </HBox>
        </VBox>
      </VBox>    
    </VBox>    
  }
  
  return render();
}

import * as React from 'react'
import { Elements, injectStripe } from 'react-stripe-elements'

import { MultiContextProvider } from 'app2/components'

import { StripeLoader } from './StripeLoader';

// wraps StripeLoader, inejctStripe and Elements
// in one, and provides stripe via multi-context

interface WithChild {
  children:React.ReactElement;
}

export function StripeElements(props:WithChild) {
  return <StripeLoader>
    <Elements {...elementOptions}>
      <Injector>{props.children}</Injector>
    </Elements>
  </StripeLoader>
}

export const elementOptions = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Muli:300,400,500,600,700,900'
    }
  ]
}

function Injector2(props:WithChild) {
  const {children, ...remaining} = props;

  return <MultiContextProvider stripe={(props as any).stripe}>
    {React.cloneElement(props.children, remaining)}
  </MultiContextProvider>
}

const Injector = injectStripe(Injector2);

export interface StripeContext {
  stripe?: any;
} 
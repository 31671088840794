import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ScheduleCoursesQueryVariables = Types.Exact<{
  seasonId: Types.Scalars['OptionValue'];
}>;

export type ScheduleCoursesQuery = {
  courses?:
    | Array<{
        id: string;
        status: Types.CourseStatus;
        name?: string | null | undefined;
        siteGradeLabels?: Array<string | null | undefined> | null | undefined;
        ageGroup?: string | null | undefined;
        room?: string | null | undefined;
        startDate?: string | null | undefined;
        endDate?: string | null | undefined;
        noEnrichmentDays: Array<string | null | undefined>;
        courseDays: Array<{ days?: Array<string> | null | undefined; start?: string | null | undefined; finish?: string | null | undefined } | null | undefined>;
        childVariantCourses: Array<{
          id: string;
          status: Types.CourseStatus;
          name?: string | null | undefined;
          siteGradeLabels?: Array<string | null | undefined> | null | undefined;
          ageGroup?: string | null | undefined;
          room?: string | null | undefined;
          startDate?: string | null | undefined;
          endDate?: string | null | undefined;
          noEnrichmentDays: Array<string | null | undefined>;
          courseDays: Array<{ days?: Array<string> | null | undefined; start?: string | null | undefined; finish?: string | null | undefined } | null | undefined>;
        }>;
      }>
    | null
    | undefined;
};

export const ScheduleCoursesDocument = gql`
  query ScheduleCourses($seasonId: OptionValue!) {
    courses(queries: ["requested", "not_cancelled", "attendable"], filters: [{ name: "seasonId", op: EQ, value: $seasonId }]) {
      id
      status
      name
      siteGradeLabels
      ageGroup
      room
      courseDays {
        days
        start
        finish
      }
      startDate
      endDate
      noEnrichmentDays
      childVariantCourses {
        id
        status
        name
        siteGradeLabels
        ageGroup
        room
        courseDays {
          days
          start
          finish
        }
        startDate
        endDate
        noEnrichmentDays
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type ScheduleCoursesArgs = Omit<UseQueryArgs<ScheduleCoursesQueryVariables, ScheduleCoursesQuery>, 'query'>;

export function useScheduleCoursesQuery(options: ScheduleCoursesArgs = {}) {
  return useQuery<ScheduleCoursesQueryVariables, ScheduleCoursesQuery>({ query: ScheduleCoursesDocument, ...options });
}

export type ScheduleCoursesOptions = ExecQueryOptions<ScheduleCoursesQueryVariables>;

export function scheduleCourses(options: ScheduleCoursesOptions) {
  return executeQuery<ScheduleCoursesQuery, ScheduleCoursesQueryVariables>(ScheduleCoursesDocument, options);
}

export type ScheduleCoursesSelections = ScheduleCoursesQuery['courses'][0];

(ScheduleCoursesDocument as any).additionalTypenames = ['Course'];

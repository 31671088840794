import * as React from 'react';
import { isEqual } from 'lodash-es';

export function useDeepCompareMemo(obj:any) {
  const ref = React.useRef(null);

  if (!isEqual(ref.current, obj)) {
    ref.current = obj;
  }

  return ref.current;
}
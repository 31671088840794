import * as React from 'react';

import { Link, FieldInfo } from 'app2/components'

interface Props {
  info?:FieldInfo<{id:string}>;
  type?:string;
  id?:string;
}

export function HistoryLink(props:Props) {
  const type = props.type;
  const id = props.id || props.info?.record?.id;

  return <Link className='hr-value' text='body' to={`/admin2/history/${type}/${id}`}>History</Link>
}

HistoryLink.fieldProps = {
  fieldProperty: 'info',
}
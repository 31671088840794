import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type PublicCompanyDirectoryQueryVariables = Types.Exact<{
  zipCode?: Types.InputMaybe<Types.Scalars['String']>;
  site?: Types.InputMaybe<Types.Scalars['String']>;
  keywords?: Types.InputMaybe<Types.Scalars['String']>;
  grades?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  tags?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
}>;

export type PublicCompanyDirectoryQuery = {
  directoryCompanySearch?:
    | {
        stems?: Array<string> | null | undefined;
        total: number;
        courses: Array<{
          distance?: number | null | undefined;
          reviewAverage?: number | null | undefined;
          reviewCount?: number | null | undefined;
          course: {
            id: string;
            name?: string | null | undefined;
            description?: string | null | undefined;
            status: Types.CourseStatus;
            grades?: Array<string | null | undefined> | null | undefined;
            maxCapacity?: number | null | undefined;
            startDate?: string | null | undefined;
            endDate?: string | null | undefined;
            classesCount: number;
            courseImage?: string | null | undefined;
            courseCardImage?: string | null | undefined;
            courseTags: Array<{ id: string; name?: string | null | undefined } | null | undefined>;
            courseDays: Array<{ days?: Array<string> | null | undefined; start?: string | null | undefined; finish?: string | null | undefined } | null | undefined>;
            prices: {
              season: { days?: number | null | undefined; price?: number | null | undefined };
              seasons: Array<{ days?: number | null | undefined; price?: number | null | undefined }>;
              recurring: Array<{ days?: number | null | undefined; unit?: Types.RecurringUnit | null | undefined; price?: number | null | undefined }>;
              dropIn: { price?: number | null | undefined };
              usage: Array<{ unit?: Types.UsageUnit | null | undefined; price?: number | null | undefined }>;
            };
            company?:
              | {
                  id: string;
                  name?: string | null | undefined;
                  headline?: string | null | undefined;
                  about?: string | null | undefined;
                  profilePhoto?: string | null | undefined;
                  sites: Array<{ slug: string; name?: string | null | undefined }>;
                }
              | null
              | undefined;
            site: { slug: string; name?: string | null | undefined };
          };
        }>;
      }
    | null
    | undefined;
};

export const PublicCompanyDirectoryDocument = gql`
  query PublicCompanyDirectory($zipCode: String, $site: String, $keywords: String, $grades: [String!], $tags: [String!]) {
    directoryCompanySearch(zipCode: $zipCode, site: $site, keywords: $keywords, grades: $grades, tags: $tags) {
      courses {
        course {
          id
          name
          description
          status
          courseTags {
            id
            name
          }
          grades
          courseDays {
            days
            start
            finish
          }
          maxCapacity
          startDate
          endDate
          classesCount
          prices {
            season {
              days
              price
            }
            seasons {
              days
              price
            }
            recurring {
              days
              unit
              price
            }
            dropIn {
              price
            }
            usage {
              unit
              price
            }
          }
          courseImage
          courseCardImage
          company {
            id
            name
            headline
            about
            profilePhoto
            sites {
              slug
              name
            }
          }
          site {
            slug
            name
          }
        }
        distance
        reviewAverage
        reviewCount
      }
      stems
      total
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type PublicCompanyDirectoryArgs = Omit<UseQueryArgs<PublicCompanyDirectoryQueryVariables, PublicCompanyDirectoryQuery>, 'query'>;

export function usePublicCompanyDirectoryQuery(options: PublicCompanyDirectoryArgs = {}) {
  return useQuery<PublicCompanyDirectoryQueryVariables, PublicCompanyDirectoryQuery>({ query: PublicCompanyDirectoryDocument, ...options });
}

export type PublicCompanyDirectoryOptions = ExecQueryOptions<PublicCompanyDirectoryQueryVariables>;

export function publicCompanyDirectory(options: PublicCompanyDirectoryOptions) {
  return executeQuery<PublicCompanyDirectoryQuery, PublicCompanyDirectoryQueryVariables>(PublicCompanyDirectoryDocument, options);
}

export type PublicCompanyDirectorySelections = PublicCompanyDirectoryQuery['directoryCompanySearch'];

import * as React from 'react'
import * as ReactIs from 'react-is'
import { cloneDeep, omit, omitBy, isUndefined } from 'lodash-es'

import { BoxProps, Box, VBox } from '../Box';
import { Button } from '../Button';
import { Icon } from '../icons';
import { Field, FieldParent, useFormInfo, FormInfo, useField, FieldChangeHandler, FieldRendererProps, mergeFieldComponentProps, UpdateOptions } from '../form';
import { hasNewId } from '../datatable';
import { TextType } from '../Text';
import { useForceUpdate } from '../utils';

import { PartProps } from './Part';
import { CardTable, CardTableRow } from './CardTable';
import { FieldError } from './FieldError';
import { addInfoTip } from './FieldWithLabel';

export type RepeatingSectionFieldProps<T> = React.ComponentElement<PartProps<T>, any> | string | React.ReactElement;

export interface RepeatingSectionProps<T> extends Omit<BoxProps, 'onChange'> {
  name:keyof T;
  // applies only to add and remove, does not apply to child fields
  readOnly?:boolean;
  assignIds?:boolean;
  onChange?:FieldChangeHandler<T, keyof T>;
  // if you pass in a string, then instead of automatically
  // removing the item, it will toggle the specified property
  onRemove?:((pos:number) => any) | string;
  // a field of 'remove' puts a trash can in, and removes the item from the array (but only in edit mode)
  // a field of 'hide' puts a eye in, and removes the item from the array (but only in edit mode)
  // width props are automatically extracted from the fields and applied to the table
  fields:RepeatingSectionFieldProps<T>[];
  // if you use the remove option, this hides the trash icon for the first item
  requireOne?:boolean;
  // unlike above, this will not reprevent the last one from being removed, but like above
  // it will add one if there are none (while editing)
  alwaysOne?:boolean;
  // unlike above, this will not prevent the last one from being removed and will not add one back
  initialOne?:boolean;
  // if you specify a string, this adds a button that adds new *blank* entry in the form (only in edit mode)
  // if you specify any non-string, it just adds what you pass in and you must handle the click (only in edit mode)
  add?:React.ReactNode;
  // default record used when you specify an add string
  defaultRecord?:any;
  // buttonCols is the number of values in each row that stays a column on mobile
  buttonCols?:number;
  maxCols?:number;
  maxRows?:number;
  separator?:boolean;
  // show a 'None' label when there's no entries
  none?:React.ReactNode;
  mode?:'auto' | 'table' | 'card';
  errors?: string[];
  bordered?:boolean;
  equalWidths?:boolean;
  numbered?:boolean;
  // start numbering at, defaults to 1
  start?:number;
  headers?:boolean;
  // additional props for the table in table mode
  table?:React.CSSProperties;
  labelType?:TextType;
}

export function RepeatingSection<T>(props:RepeatingSectionProps<T>) {
  const {name, readOnly, assignIds, onChange, onRemove, fields, add, defaultRecord, requireOne, alwaysOne, initialOne, buttonCols, maxRows, headers, ...remaining} = props;

  // filter the fileds in case there are undefined/nulls from ternary usage to optionally include fields
  // also assigns some props as col props vs field props, such as width
  const {cols, originalFields, memoFields} = React.useMemo(() => extractFieldsAndColProps(fields), [fields]);
  const formInfo = useFormInfo();
  // hack because nothing is binding to the archived flag
  const subscribeToChildren = typeof props.onRemove == 'string'
  const fieldInfo = useField({ name, onChange, assignIds }, subscribeToChildren);

  if (!formInfo || !fieldInfo) {
    return <div />;
  }

  const items = Array.isArray(fieldInfo.value) ? fieldInfo.value : [];
  const hasRemove = memoFields.length && isRemoveField(memoFields[memoFields.length - 1]);
  const hasHide = hasRemove && memoFields[memoFields.length - 1] == 'hide';

  // hack because alwaysOne is stupid (its almost the same as requireOne)
  // this is for initialOne that allows removing the pre-added one
  // and marks whether we started with items or added one so we dont add again
  const startedWithItemsOrAddedOne = React.useRef(items.length > 0);
  React.useMemo(() => startedWithItemsOrAddedOne.current = items.length > 0, [formInfo.form]);

  // auto add one if we are in editing and one is required...probably should be
  // done as an effect hook
  const forceUpdate = useForceUpdate();

  if (items.length == 0 && (props.requireOne || props.alwaysOne || (props.initialOne && !startedWithItemsOrAddedOne.current)) && formInfo.editing) {
    startedWithItemsOrAddedOne.current = true
    addEntry(props, formInfo, null, {dirty: false});
    forceUpdate();
    return <div />;
  }

  const labels = memoFields.map((field, index) => renderLabel(formInfo, field, originalFields, index));
  const rows = items.map((item, index) => {
    const row:CardTableRow = memoFields.map((field, fieldIndex) => renderField(props, index, fieldIndex, field, formInfo, hasHide));
    const parentInfo = formInfo.form.getInfo((formInfo.parents || []).concat([props.name as string]), index.toString() as keyof T);
    row.error = parentInfo.errors.join('; ');
    
    return row;
  })

  return <CardTable errors={fieldInfo.errors} labels={labels} cols={cols} rows={rows} buttonCols={buttonCols === undefined && hasRemove ? 1 : buttonCols} align='baseline' headers={headers || !!fieldInfo.errors.length} {...remaining}>
    {renderAdd(props, formInfo, items)}
  </CardTable>
}

function isRemoveField<T>(field:RepeatingSectionFieldProps<T>) {
  return field == 'remove' || field == 'hide' || (typeof field == 'object' && field.props?.name == 'remove');
}

function getFieldName<T>(field:RepeatingSectionFieldProps<T>) {
  return typeof field == 'object' ? field.props?.name : field;
}

function extractFieldsAndColProps<T>(fields:RepeatingSectionFieldProps<T>[]) {
  const cols:any[] = [];
  const originalFields:RepeatingSectionFieldProps<T>[] = []
  const memoFields:RepeatingSectionFieldProps<T>[] = [];

  fields.filter(field => !!field && (!ReactIs.isElement(field) || (!field.props.hide && !field.props.hidden))).forEach(field => {
    originalFields.push(field);

    if (ReactIs.isElement(field)) {
      const props = field.props;
      const col:any = omitBy({width: props.width, minWidth: props.minWidth}, isUndefined);
      cols.push(col);
      memoFields.push(React.cloneElement({...field, props: omit(props, ['width', 'minWidth', 'infoTip'])}));
    }
    else {
      memoFields.push(field);
    }
  })

  return {cols, originalFields, memoFields};
}

function renderLabel<T>(formInfo:FormInfo<T>, field:RepeatingSectionFieldProps<T>, originalFields:RepeatingSectionFieldProps<T>[], index:number) {
  if (typeof field == 'string') {
    return '';
  }

  const originalField = originalFields[index] as any;
  let label = field.props.label || field.props.component?.label;

  if (formInfo.editing && field.props.required) {
    label += ' *';
  }

  if (originalField.props.infoTip) {
    label = addInfoTip(label, originalField.props.infoTip);
  }

  return label;
}

function renderField<T>(props:RepeatingSectionProps<T>, itemIndex:number, fieldIndex:number, fieldOrString:RepeatingSectionFieldProps<T>, formInfo:FormInfo<T>, hasHide:boolean) {
  const unremoveable = itemIndex == 0 && props.requireOne;
  const removed = typeof props.onRemove == 'string' ? getRemovedState(props, formInfo, itemIndex) : undefined;

  if (isRemoveField(fieldOrString)) {
    const icon = getFieldName(fieldOrString) == 'remove' ? 'Trash2' : removed ? 'EyeOff' : 'Eye';
    const hideRemove = (!formInfo.editing && props.readOnly !== false) || props.readOnly || (typeof fieldOrString == 'object' && fieldOrString.props.render && !fieldOrString.props.render({field: fieldOrString, info: formInfo.form.getInfo([props.name] as any, itemIndex as any)}));

    return hideRemove
      ? <React.Fragment key='remove'></React.Fragment>
      : <Field key='remove' render={() => <Icon name={icon} alt='remove' button size='small' onClick={() => onClickRemove(props, formInfo, itemIndex)}
          /* these make it so that we avoid layout issues when going from 1 row that can't be removed to
             multiple rows where 2+ can be removed. */
          opacity={unremoveable ? 0 : undefined} pointerEvents={unremoveable ? 'none' : undefined} />} />;
  }
  
  const field = fieldOrString as React.ComponentElement<PartProps<T>, any>;
  const fieldProps = mergeFieldComponentProps(field.props, formInfo.editing)
  const originalRender = fieldProps.render;
  
  return <FieldParent key={`${itemIndex}:${fieldIndex}`} names={[props.name as any, itemIndex]}>
    {// if the field is actually a field, wrap the field component so we can render an error
    // else just let it through and its up to the user to display the errors
    field.type != Field
    ? field
    : React.cloneElement(field, {name:field.props.name, render: (rendererProps:FieldRendererProps) => {
        // can't put disabled on the field, because field applies to all rows
        if (removed && !rendererProps.info.disabled) {
          rendererProps.info.disabled = true;
          rendererProps.children = React.cloneElement(rendererProps.children as any, {disabled: true});
        }

        const children = originalRender ? originalRender(rendererProps) : rendererProps.children;
        const error = (rendererProps.info?.errors || []).join('; ');
        const removeProps = removed ? (!hasHide ? {color: 'red', textDecoration: 'line-through'} : {opacity: .8, textDecoration: 'line-through'}) : undefined; 
        const width = fieldProps.stretch !== false ? '100%' : undefined;
        const hAlign = fieldProps.stretch !== false ? undefined : 'left'

        return <VBox width={width} hAlign={hAlign} {...removeProps}>{children}<FieldError error={error} /></VBox>
      }
    })}
  </FieldParent>
}

function renderAdd<T>(props:RepeatingSectionProps<T>, formInfo:FormInfo<T>, items:any[]) {
  if (!props.add || !formInfo.editing || props.readOnly) {
    return;
  }

  if (props.maxRows && items.length >= props.maxRows) {
    return;
  }

  return <>
    <Box key='add' flex={1} minHeight='16px' />
    {typeof props.add == 'string' 
      ? <Button icon='PlusCircle' iconPosition='left' kind='tertiary' text={props.labelType} onClick={(event) => addEntry(props, formInfo, event)}>{props.add}</Button>
      : props.add}
  </>
}

function addEntry<T>(props:RepeatingSectionProps<T>, formInfo:FormInfo<T>, event?:React.MouseEvent<HTMLElement>, options?:UpdateOptions) {
  // when inside a form, buttons default to submit, this blocks that
  event?.preventDefault();

  const existing = formInfo.form.getValue(formInfo.parents, props.name);
  const entries = ((Array.isArray(existing) ? existing : []) as unknown as (keyof T)[]).slice();
  entries.push(cloneDeep(props.defaultRecord) || {} as keyof T);

  formInfo.form.setValue(formInfo.parents, props.name, entries as any, options);
}

function onClickRemove<T>(props:RepeatingSectionProps<T>, formInfo:FormInfo<T>, itemIndex:number) {
  if (typeof props.onRemove == 'string') {
    if (isNewRow(props, formInfo, itemIndex)) {
      removeEntry(props, formInfo, itemIndex);
    }
    else {
      toggleRemovedState(props, formInfo, itemIndex);
    }
  }
  else
  if (props.onRemove) {
    return props.onRemove(itemIndex);
  }
  else {
    removeEntry(props, formInfo, itemIndex);
  }
}

function isNewRow<T>(props:RepeatingSectionProps<T>, formInfo:FormInfo<T>, itemIndex:number) {
  const row = formInfo.form.getValue(formInfo.parents, `${props.name}.${itemIndex}` as any)
  return hasNewId(row);
}

function removeEntry<T>(props:RepeatingSectionProps<T>, formInfo:FormInfo<T>, itemIndex:number) {
  const entries = ((formInfo.form.getValue(formInfo.parents, props.name) || []) as unknown as (keyof T)[]).slice();
  entries.splice(itemIndex, 1);

  formInfo.form.setValue(formInfo.parents, props.name, entries as any);
}

function toggleRemovedState<T>(props:RepeatingSectionProps<T>, formInfo:FormInfo<T>, itemIndex:number) {
  return setRemovedState(props, formInfo, itemIndex, !getRemovedState(props, formInfo, itemIndex));
}

function setRemovedState<T>(props:RepeatingSectionProps<T>, formInfo:FormInfo<T>, itemIndex:number, value:boolean) {
  return formInfo.form.setValue(formInfo.parents, getRemovedPath(props, itemIndex) as any, value as any);
}

function getRemovedState<T>(props:RepeatingSectionProps<T>, formInfo:FormInfo<T>, itemIndex:number) {
  return formInfo.form.getValue(formInfo.parents, getRemovedPath(props, itemIndex) as any) as unknown as boolean;
}

function getRemovedPath<T>(props:RepeatingSectionProps<T>, itemIndex:number) {
  return `${props.name}.${itemIndex}.${props.onRemove}` as any
}

RepeatingSection.defaultProps = {
  numbered: true,
  headers: true
}

let counter = 0;
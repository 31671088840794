import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentCopyStudentContactsMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type ParentCopyStudentContactsMutation = { studentCopyContacts?: { student?: { id: string } | null | undefined } | null | undefined };

export const ParentCopyStudentContactsDocument = gql`
  mutation ParentCopyStudentContacts($id: ID!) {
    studentCopyContacts(id: $id) {
      student {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type ParentCopyStudentContactsOptions = ExecMutationOptions<ParentCopyStudentContactsMutationVariables>;

export function parentCopyStudentContacts(options: ParentCopyStudentContactsOptions) {
  return executeMutation<ParentCopyStudentContactsMutation, ParentCopyStudentContactsMutationVariables>(ParentCopyStudentContactsDocument, options);
}

import * as React from 'react';
// @ts-ignore
import { StripeProvider } from 'react-stripe-elements';
import { loadStripe } from '@stripe/stripe-js/pure';

import { ScriptLoader, ScriptLoaderComponentState, ScriptLoaderComponentProps } from '../ScriptLoader';

import { STRIPE_PUBLIC_KEY } from './constants';
import { stripeUrl, updateStripeUrlIfNeeded } from './StripeLoader';

// loading stripe puts the Stripe constructor on window
type StripeConstructor = new (publicKey: string) => any;
declare const Stripe: StripeConstructor;

interface State extends ScriptLoaderComponentState {
  stripe?: any;
}

export class SafeStripeProvider extends ScriptLoader<ScriptLoaderComponentProps, State> {
  static defaultProps = {
    scripts: [{ script: stripeUrl, isUrl: true, blockInTestMode: true }]
  };

  constructor(props: ScriptLoaderComponentProps) {
    updateStripeUrlIfNeeded(props);
    super(props);

    // stripe has to be null not undefined, before its loaded
    this.state = Object.assign(this.state, { stripe: null });
  }

  render() {
    return <StripeProvider stripe={this.state.stripe}>
      {this.props.children}
    </StripeProvider>
  }

  onScriptsLoaded() {
    try {
      loadStripe.setLoadParameters({advancedFraudSignals: false});
      this.setState({ stripe: new Stripe(STRIPE_PUBLIC_KEY as string) });
    }
    catch(e) {
      console.warn('Stripe not loaded');
    }
  }
}

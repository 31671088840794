import * as amplitude from 'amplitude-js';
import moment from 'moment';
import shallowEqual from 'shallowequal';

import { isRealProduction, isProductionEnv, isAutomatedTestingEnv } from 'app/helpers/environments';

import { EventRecorder, EventProperties, Callback, doNothing } from './EventRecorder';
import { LoggedInUser } from './LoggedInUser';

const capture = isProductionEnv() && !isAutomatedTestingEnv();
const amplitudeKey = 'bb7c44fb6474d3ef94e2da38fa155f02';
const amplitudeTestKey = 'f1c9e109684d3f553656a5ca9352db9b';

export class AmplitudeAnalytics extends EventRecorder {
  // @ts-ignore
  client: amplitude.AmplitudeClient;
  previousUserProperties: EventProperties;
  urlParams: EventProperties;

  constructor() {
    super();

    this.capture = capture;
    this.load();
  }

  load() {
    if (!this.capture) {
      return;
    }

    this.client = amplitude.getInstance();
    this.client.init(
      isRealProduction() ? amplitudeKey : amplitudeTestKey,
      undefined,
      {
        includeUtm: true,
        includeGclid: true,
        includeReferrer: true,
        domain: '.homeroom.com'
      }
    );

    this.previousUserProperties = {};
    this.urlParams = {};
  }

  onLogRocketUrlUpdated(url: string): void {
    super.onLogRocketUrlUpdated(url);
    this.updateUserProperties();
  }

  onAppStart(urlParams: EventProperties): void {
    this.urlParams = urlParams;
    this.updateUserProperties();
  }

  updateUserProperties() {
    const properties = {
      logRocketUrl: this.logRocketUrl,
      userId: this.user?.id,
      email: this.user?.email,
      ...this.urlParams
    };

    if (shallowEqual(properties, this.previousUserProperties)) {
      return;
    }

    this.client.setUserProperties(properties);
    this.previousUserProperties = properties;
  }

  identifyUser(user:LoggedInUser, callback: Callback = doNothing): void {
    this.user = user;
    this.updateUserProperties();

    this.client.setUserId(String(user.id));
    callback();
  }

  identifyNoUser(callback: Callback = doNothing): void {
    // Amplitude says you can call setUserId null + regenerateDeviceId
    // as a way of anonymizing the user.  It doesn't really say you
    // should call setUserId null for a user logout.  We're seeing
    // some user fragmentation in Amplitude, so for now we are going to
    // try not unsetting the user.

    callback();
  }

  log(event: string, data?: EventProperties, callback: Callback = doNothing): void {
    const dataWithStandardProperties = {
      ...this.standardEventProperties,
      ...data,
    };

    this.client.logEventWithTimestamp(event, dataWithStandardProperties, moment().valueOf(), callback);
  }

  pageView(url: string): void {
    this.log('viewPage', { 'Previous Location': this.prevLocation });

    super.pageView(url);
  }
}

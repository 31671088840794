import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentStudentEnrollmentOptionsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type ParentStudentEnrollmentOptionsQuery = {
  student?:
    | {
        enrollments?:
          | Array<
              | {
                  id: string;
                  pickup?: string | null | undefined;
                  dismissal?: string | null | undefined;
                  status: Types.EnrollmentStatusEnum;
                  course: { id: string; disambiguatedName?: string | null | undefined; options: Array<{ id: string; title: string } | null | undefined> };
                  enrollmentOptions: Array<{
                    option: { id: string };
                    choices: Array<{
                      status: Types.EnrollmentStatusEnum;
                      waitlistKind: Types.WaitlistKind;
                      priceConfig?: { kind: Types.PriceConfigKind } | { kind: Types.PriceConfigKind } | { kind: Types.PriceConfigKind } | { kind: Types.PriceConfigKind } | null | undefined;
                      course: { id: string; disambiguatedName?: string | null | undefined };
                    }>;
                  }>;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const ParentStudentEnrollmentOptionsDocument = gql`
  query ParentStudentEnrollmentOptions($id: ID!) @urql(additionalTypenames: ["Enrollment"]) {
    student(student: $id) {
      enrollments(queries: [["CONFIRMED", "WAITLISTED"], "SCHEDULED", "PRESENT"]) {
        id
        pickup
        dismissal
        status
        course {
          id
          disambiguatedName
          options {
            id
            title
          }
        }
        enrollmentOptions {
          option {
            id
          }
          choices {
            status
            waitlistKind
            priceConfig {
              kind
            }
            course {
              id
              disambiguatedName
            }
          }
        }
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type ParentStudentEnrollmentOptionsArgs = Omit<UseQueryArgs<ParentStudentEnrollmentOptionsQueryVariables, ParentStudentEnrollmentOptionsQuery>, 'query'>;

export function useParentStudentEnrollmentOptionsQuery(options: ParentStudentEnrollmentOptionsArgs = {}) {
  return useQuery<ParentStudentEnrollmentOptionsQueryVariables, ParentStudentEnrollmentOptionsQuery>({ query: ParentStudentEnrollmentOptionsDocument, ...options });
}

export type ParentStudentEnrollmentOptionsOptions = ExecQueryOptions<ParentStudentEnrollmentOptionsQueryVariables>;

export function parentStudentEnrollmentOptions(options: ParentStudentEnrollmentOptionsOptions) {
  return executeQuery<ParentStudentEnrollmentOptionsQuery, ParentStudentEnrollmentOptionsQueryVariables>(ParentStudentEnrollmentOptionsDocument, options);
}

export type ParentStudentEnrollmentOptionsSelections = ParentStudentEnrollmentOptionsQuery['student'];

(ParentStudentEnrollmentOptionsDocument as any).additionalTypenames = ['Enrollment'];

import { DocumentNode } from 'graphql';
import { createClient, dedupExchange, cacheExchange, fetchExchange, Client, Operation, OperationContext, OperationResult, TypedDocumentNode } from 'urql';
import { devtoolsExchange } from '@urql/devtools';
import { authExchange } from '@urql/exchange-auth';
import { retryExchange } from '@urql/exchange-retry';

import { GuestCart } from './parent';

import { baseAPIUrl, isDevelopmentEnv } from 'app/helpers';
import { ApiVersion, AuthToken, AuthTokenService, buildHeaders } from './account';

const exchanges = [
  dedupExchange,
  cacheExchange,
  authExchange({
    addAuthToOperation,
    getAuth
  }),
  retryExchange({
    initialDelayMs: 1000,
    maxDelayMs: 5000,
    randomDelay: true,
    maxNumberAttempts: 3,
    retryIf: (err:any) => err && err.networkError,
  }),
  fetchExchange
];

if (isDevelopmentEnv()) {
  exchanges.unshift(devtoolsExchange);
}

// this wrapper is because there's no way to
// reset the urql cache and their best advice is
// to make a new client
// https://github.com/FormidableLabs/urql/issues/297

export type OnClientReset = () => void;

export class UrlClient {
  listeners: OnClientReset[];
  client: Client;

  constructor() {
    this.listeners = [];
    this.reset();
  }

  onReset(cb: OnClientReset) {
    this.listeners.push(cb);
  }

  reset() {
    this.client = createClient({
      maskTypename: true,
      url: `${baseAPIUrl()}/graphql`,
      exchanges,
      fetch: fetchOverride,
      fetchOptions: {
        credentials: 'include'
      },
      requestPolicy: 'cache-and-network'
    });

    const listeners = this.listeners.slice();

    for (const listener of listeners) {
      listener();
    }
  }
}

export const urql: UrlClient = new UrlClient();

interface AuthState {
  token?: AuthToken;
}

function addAuthToOperation({ operation }: { authState: AuthState; operation: Operation }) {
  const fetchOptions = typeof operation.context.fetchOptions === 'function' ? operation.context.fetchOptions() : operation.context.fetchOptions || {};
  return {
    ...operation,
    context: {
      ...operation.context,
      fetchOptions: {
        ...fetchOptions,
        headers: {
          ...fetchOptions.headers,
          ...buildHeaders()
        }
      }
    }
  };
}

async function getAuth(_params: {
  authState: AuthState;
  mutate<Data = any, Variables extends object = {}>(query: DocumentNode | TypedDocumentNode<Data, Variables> | string, variables?: Variables, context?: Partial<OperationContext>): Promise<OperationResult<Data>>;
}): Promise<AuthState> {
  return null;
}

async function fetchOverride(input: RequestInfo, init?: RequestInit) {
  const response = await fetch(input, init);
  const token = AuthTokenService.fromHeaders(response.headers);
  ApiVersion.checkVersion(response);
  AuthTokenService.storePrimaryToken(token);
  GuestCart.storeToken(response.headers);

  return response;
}

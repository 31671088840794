import * as React from 'react'

import { Dropdown, Field, FieldInfo, Part, useFormInfo } from 'app2/components';

import { ClassOptionProps, EnrollmentWithClassOption } from './ClassOptionProps';

export function BeforeClass(props:ClassOptionProps) {
  const info = useFormInfo();
  const enrollment = props.enrollment || info.form.getValue(info.parents, '');

  if (!enrollment.pickupDropoffRequired) {
    return <></>;
  }

  const options = props.siteOptions[enrollment.course.site.id]?.beforeClass;
  const editable = Boolean(options?.length && info.editing && info.editing && (props.editable ?? !enrollment.isChildEnrollment));
  const Tag = props.kind == 'field' ? Field : Part;

  return <Tag name='pickup' label='Pre-activity' required={props.allHaveBeforeClass ? dropoffRequired : undefined} autoLoader onChange={props.onChange} editing={Boolean(editable)} edit={<Dropdown options={options} placeholder='Pre-activity pickup *' disablePhoneInput />} />
}
function dropoffRequired(info:FieldInfo<EnrollmentWithClassOption>) {
  return info.record.pickupDropoffRequired;
}

import * as React from 'react';

import { Redirect } from 'app2/components';
import { Route, marketingUrl } from 'app2/views/shared-public';

import { Login, Register, PasswordReset, PasswordResetRequest } from './account'
import { FileViewer, NotFound } from './misc'
import { Course, CompanyAcceptInvite, CourseAcceptInvite } from './company';
import { Home } from './home';
import { Games, Policies, PublicAttendanceCode, PublicAttendanceStudents, Site, SiteAcceptInvite } from './site';
import { CompanyDirectory } from './directory';
import { SiteDirectory } from './directory';
import { PrivateEnrollment } from './parent';
import { seoRoutes } from './seo'

export const publicRoutes = [
  <Route path="/" exact component={Home} />,
  <Route path="/sites/:site/games" component={Games} exact />,
  <Route path="/sites/private/:site/group/:tab" component={Site} />,
  <Route path="/sites/private/:site/:tab?" component={Site} />,
  <Route path="/sites/:site/family-attendance/students" component={PublicAttendanceStudents} helpscout={false} />,
  <Route path="/sites/:site/family-attendance" component={PublicAttendanceCode} helpscout={false} />,
  <Route path="/sites/:site/group/:tab?" component={Site} />,
  <Route path="/sites/:site/:tab?" component={Site} />,
  <Route path="/policies/:policy" component={Policies} />,
  <Route path="/organizer/team-invite/:token" component={SiteAcceptInvite} />,

  <Route path="/activities/:id/:tab?/:thread?" component={Course} />,
  <Route path="/provider/team-invite/:token" component={CompanyAcceptInvite} />,
  <Route path="/provider/activity-invite/:token" component={CourseAcceptInvite} />,

  <Route path="/directory/provider/:zip?" component={CompanyDirectory} exact />,
  <Route path="/directory/school/:zip?" component={SiteDirectory} exact />,

  <Route path="/login" component={Login} exact role='logged-out' />,
  <Route path="/register" component={Register} exact role='logged-out' />,
  <Route path="/change_password" component={PasswordReset} exact role='logged-out' />,
  <Route path="/password_reset" component={PasswordResetRequest} exact role='logged-out' />,

  <Route path="/family/activity-invite/:token" component={PrivateEnrollment} exact />,

  <Route path="/files/:id" component={FileViewer} exact />,

  ...seoRoutes,
  
  <Redirect from="/directory" to="/directory/provider/:zip?" exact />,

  <Redirect from="/terms" to={marketingUrl('tou')} exact />,
  <Redirect from="/tou" to={marketingUrl('tou')} exact />,
  <Redirect from="/service-provider-agreement" to={marketingUrl('service-provider-agreement')} exact />,
  <Redirect from="/privacy" to={marketingUrl('privacy')} exact />,

  // old
  <Redirect from="/sites2/:site/:tab?" to="/sites/:site/:tab?" />,
  <Redirect from="/courses/:id" to="/activities/:id" exact />,
  <Redirect from="/activity/:id" to="/activities/:id" exact />,
  <Redirect from="/classes/:id" to="/activities/:id" exact />,
  <Redirect from="/private_enrollment/:token" to="/family/activity-invite/:token" exact />,
  <Redirect from="/member_onboarding/:token" to="/provider/team-invite/:token" />,
  <Redirect from="/vendor_onboarding/:token" to="/provider/activity-invite/:token" exact />,
  <Redirect from="/provider_onboarding/:token" to="/provider/activity-invite/:token" />,
  <Redirect from="/site_onboarding/:token" to="/organizer/team-invite/:token" />,

  <Route path="/not_found" component={NotFound} exact />,
  <Route path="*" component={NotFound} exact />,
]

export interface PublicSiteParams {
  site: string;
  tab?: 'enrichment' | 'enrichment-listing' | 'camp' | 'camp-listing';
  code?: string;
}

export interface PublicCourseParams {
  id: string;
  tab?: string;
}

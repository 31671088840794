import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SingleAdminUserQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type SingleAdminUserQuery = {
  adminUser?:
    | { firstName: string; lastName: string; name?: string | null | undefined; email: string; phone?: string | null | undefined; deactivatedAt?: string | null | undefined; deletedAt?: string | null | undefined }
    | null
    | undefined;
};

export const SingleAdminUserDocument = gql`
  query SingleAdminUser($id: ID!) {
    adminUser(user: $id) {
      firstName
      lastName
      name
      email
      phone
      deactivatedAt
      deletedAt
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type SingleAdminUserArgs = Omit<UseQueryArgs<SingleAdminUserQueryVariables, SingleAdminUserQuery>, 'query'>;

export function useSingleAdminUserQuery(options: SingleAdminUserArgs = {}) {
  return useQuery<SingleAdminUserQueryVariables, SingleAdminUserQuery>({ query: SingleAdminUserDocument, ...options });
}

export type SingleAdminUserOptions = ExecQueryOptions<SingleAdminUserQueryVariables>;

export function singleAdminUser(options: SingleAdminUserOptions) {
  return executeQuery<SingleAdminUserQuery, SingleAdminUserQueryVariables>(SingleAdminUserDocument, options);
}

export type SingleAdminUserSelections = SingleAdminUserQuery['adminUser'];

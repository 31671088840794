import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompanyVendorQueryVariables = Types.Exact<{
  vendor: Types.Scalars['ID'];
}>;

export type CompanyVendorQuery = {
  vendor?:
    | {
        companyName: string;
        siteCompany: boolean;
        inCompliance: boolean;
        vendorInCompliance: boolean;
        showProviderCompliance: boolean;
        showTeacherCompliance: boolean;
        site: {
          id: string;
          name?: string | null | undefined;
          streetAddress?: string | null | undefined;
          city?: string | null | undefined;
          state?: string | null | undefined;
          zipcode?: string | null | undefined;
          contact?: { name?: string | null | undefined; email?: string | null | undefined; phone?: string | null | undefined } | null | undefined;
        };
        vendorTeachers?:
          | Array<
              { id: string; inCompliance?: boolean | null | undefined; teacher?: { email?: string | null | undefined; id: string; name: string; phone?: string | null | undefined } | null | undefined } | null | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const CompanyVendorDocument = gql`
  query CompanyVendor($vendor: ID!) {
    vendor(vendor: $vendor) {
      companyName
      siteCompany
      site {
        id
        name
        streetAddress
        city
        state
        zipcode
        contact {
          name
          email
          phone
        }
      }
      inCompliance
      vendorInCompliance
      showProviderCompliance
      showTeacherCompliance
      vendorTeachers {
        id
        inCompliance
        teacher {
          email
          id
          name
          phone
        }
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type CompanyVendorArgs = Omit<UseQueryArgs<CompanyVendorQueryVariables, CompanyVendorQuery>, 'query'>;

export function useCompanyVendorQuery(options: CompanyVendorArgs = {}) {
  return useQuery<CompanyVendorQueryVariables, CompanyVendorQuery>({ query: CompanyVendorDocument, ...options });
}

export type CompanyVendorOptions = ExecQueryOptions<CompanyVendorQueryVariables>;

export function companyVendor(options: CompanyVendorOptions) {
  return executeQuery<CompanyVendorQuery, CompanyVendorQueryVariables>(CompanyVendorDocument, options);
}

export type CompanyVendorSelections = CompanyVendorQuery['vendor'];

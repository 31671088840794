import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
import { CommonCompanyFragmentDoc } from '../../shared/generated/commonCompany.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompanyInfoQueryVariables = Types.Exact<{
  company: Types.Scalars['ID'];
}>;

export type CompanyInfoQuery = {
  company?:
    | {
        size?: number | null | undefined;
        email?: string | null | undefined;
        address?: string | null | undefined;
        city?: string | null | undefined;
        state?: string | null | undefined;
        zipcode?: string | null | undefined;
        website?: string | null | undefined;
        phone?: string | null | undefined;
        about?: string | null | undefined;
        headline?: string | null | undefined;
        teachingRadius?: number | null | undefined;
        id: string;
        name?: string | null | undefined;
        userCompanyRole?: Types.MemberRole | null | undefined;
        nonPartnerApproved?: boolean | null | undefined;
      }
    | null
    | undefined;
};

export const CompanyInfoDocument = gql`
  query CompanyInfo($company: ID!) {
    company(company: $company) {
      ...commonCompany
      size
      email
      address
      city
      state
      zipcode
      website
      phone
      about
      headline
      teachingRadius
    }
  }
  ${CommonCompanyFragmentDoc}
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type CompanyInfoArgs = Omit<UseQueryArgs<CompanyInfoQueryVariables, CompanyInfoQuery>, 'query'>;

export function useCompanyInfoQuery(options: CompanyInfoArgs = {}) {
  return useQuery<CompanyInfoQueryVariables, CompanyInfoQuery>({ query: CompanyInfoDocument, ...options });
}

export type CompanyInfoOptions = ExecQueryOptions<CompanyInfoQueryVariables>;

export function companyInfo(options: CompanyInfoOptions) {
  return executeQuery<CompanyInfoQuery, CompanyInfoQueryVariables>(CompanyInfoDocument, options);
}

export type CompanyInfoSelections = CompanyInfoQuery['company'];

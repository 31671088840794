import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DirectorySiteQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type DirectorySiteQuery = { site?: { id: string; name?: string | null | undefined; slug: string; zipcode?: string | null | undefined } | null | undefined };

export const DirectorySiteDocument = gql`
  query DirectorySite($id: ID!) {
    site(site: $id) {
      id
      name
      slug
      zipcode
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type DirectorySiteArgs = Omit<UseQueryArgs<DirectorySiteQueryVariables, DirectorySiteQuery>, 'query'>;

export function useDirectorySiteQuery(options: DirectorySiteArgs = {}) {
  return useQuery<DirectorySiteQueryVariables, DirectorySiteQuery>({ query: DirectorySiteDocument, ...options });
}

export type DirectorySiteOptions = ExecQueryOptions<DirectorySiteQueryVariables>;

export function directorySite(options: DirectorySiteOptions) {
  return executeQuery<DirectorySiteQuery, DirectorySiteQueryVariables>(DirectorySiteDocument, options);
}

export type DirectorySiteSelections = DirectorySiteQuery['site'];

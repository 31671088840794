import * as Types from '../../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EnrollmentsRefundBreakdownsQueryVariables = Types.Exact<{
  ids: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
  amount?: Types.InputMaybe<Types.Scalars['Float']>;
  kind: Types.RefundKind;
}>;

export type EnrollmentsRefundBreakdownsQuery = {
  enrollmentsRefundBreakdowns?:
    | {
        familiesReceive: number;
        nonrefundableSiteFee?: number | null | undefined;
        otherFeeReversals: number;
        providerWithdrawAmount: number;
        siteWithdrawAmount: number;
        individualBreakdowns: Array<{ familyReceives: number; otherFeeReversals: number; providerWithdrawAmount: number; siteWithdrawAmount: number; enrollment: { id: string } }>;
      }
    | null
    | undefined;
};

export const EnrollmentsRefundBreakdownsDocument = gql`
  query EnrollmentsRefundBreakdowns($ids: [ID!]!, $amount: Float, $kind: RefundKind!) {
    enrollmentsRefundBreakdowns(ids: $ids, amount: $amount, kind: $kind) {
      familiesReceive
      individualBreakdowns {
        enrollment {
          id
        }
        familyReceives
        otherFeeReversals
        providerWithdrawAmount
        siteWithdrawAmount
      }
      nonrefundableSiteFee
      otherFeeReversals
      providerWithdrawAmount
      siteWithdrawAmount
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type EnrollmentsRefundBreakdownsArgs = Omit<UseQueryArgs<EnrollmentsRefundBreakdownsQueryVariables, EnrollmentsRefundBreakdownsQuery>, 'query'>;

export function useEnrollmentsRefundBreakdownsQuery(options: EnrollmentsRefundBreakdownsArgs = {}) {
  return useQuery<EnrollmentsRefundBreakdownsQueryVariables, EnrollmentsRefundBreakdownsQuery>({ query: EnrollmentsRefundBreakdownsDocument, ...options });
}

export type EnrollmentsRefundBreakdownsOptions = ExecQueryOptions<EnrollmentsRefundBreakdownsQueryVariables>;

export function enrollmentsRefundBreakdowns(options: EnrollmentsRefundBreakdownsOptions) {
  return executeQuery<EnrollmentsRefundBreakdownsQuery, EnrollmentsRefundBreakdownsQueryVariables>(EnrollmentsRefundBreakdownsDocument, options);
}

export type EnrollmentsRefundBreakdownsSelections = EnrollmentsRefundBreakdownsQuery['enrollmentsRefundBreakdowns'];

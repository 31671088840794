import * as React from 'react'

import { HBox, Text, VBox } from 'app2/components'

import { Section } from './Section'

export function Quotes() {
  return <Section layout='vbox' >
    <Text text='heading2' mb='$20'>Customer quotes</Text>
    <HBox width='100%' layout={['vbox', 'hbox']} hItemSpace={[undefined, '8px']} vItemSpace={['8px', undefined]} itemFlex={1/3}>
      <Quote quote='“Thank you for another successful enrollment experience - you are all my heroes tonight!”' author='-Chau, PTA Organizer, Genesee Hill Elementary' borderRadius='30px 8px 8px 8px' />
      <Quote quote='“Homeroom does all of the work I used to do.”' author='-Jen, PTA Organizer, Cumberland Elementary' borderRadius='8px 30px 8px 8px' />
      <Quote quote='“I have to let you know that this system is a dream come true!”' author='-Parent, Oak Knoll Elementary School' borderRadius='8px 8px 30px 8px' />
    </HBox>
  </Section>
}

interface QuoteProps {
  quote:string;
  author:string;
  borderRadius:string;
}

function Quote(props:QuoteProps) {
  return <VBox bg='formBackground' p='$30' height='230px' borderRadius={props.borderRadius}>
    <Text text='subtitle2' mb='$20'>{props.quote}</Text>
    <Text text='body'>{props.author}</Text>
  </VBox>
}

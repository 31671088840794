import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompanyResendInviteMutationVariables = Types.Exact<{
  inviteToken: Types.Scalars['String'];
}>;

export type CompanyResendInviteMutation = { companyInviteResend?: { entity?: { id: string } | { id: string } | null | undefined } | null | undefined };

export const CompanyResendInviteDocument = gql`
  mutation companyResendInvite($inviteToken: String!) {
    companyInviteResend(inviteToken: $inviteToken) {
      entity {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type CompanyResendInviteOptions = ExecMutationOptions<CompanyResendInviteMutationVariables>;

export function companyResendInvite(options: CompanyResendInviteOptions) {
  return executeMutation<CompanyResendInviteMutation, CompanyResendInviteMutationVariables>(CompanyResendInviteDocument, options);
}

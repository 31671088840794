import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentAttendanceStudentsQueryVariables = Types.Exact<{
  code: Types.Scalars['String'];
}>;

export type ParentAttendanceStudentsQuery = {
  parentAttendanceStudents?:
    | {
        owner: { name: string };
        items: Array<{
          checkedInAt?: string | null | undefined;
          checkedOut?: boolean | null | undefined;
          startsAt: string;
          endsAt: string;
          course: { id: string; name?: string | null | undefined };
          student?: { id: string; name: string } | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export const ParentAttendanceStudentsDocument = gql`
  query ParentAttendanceStudents($code: String!) {
    parentAttendanceStudents(code: $code) {
      owner {
        name
      }
      items {
        checkedInAt
        checkedOut
        startsAt
        endsAt
        course {
          id
          name
        }
        student {
          id
          name
        }
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type ParentAttendanceStudentsArgs = Omit<UseQueryArgs<ParentAttendanceStudentsQueryVariables, ParentAttendanceStudentsQuery>, 'query'>;

export function useParentAttendanceStudentsQuery(options: ParentAttendanceStudentsArgs = {}) {
  return useQuery<ParentAttendanceStudentsQueryVariables, ParentAttendanceStudentsQuery>({ query: ParentAttendanceStudentsDocument, ...options });
}

export type ParentAttendanceStudentsOptions = ExecQueryOptions<ParentAttendanceStudentsQueryVariables>;

export function parentAttendanceStudents(options: ParentAttendanceStudentsOptions) {
  return executeQuery<ParentAttendanceStudentsQuery, ParentAttendanceStudentsQueryVariables>(ParentAttendanceStudentsDocument, options);
}

export type ParentAttendanceStudentsSelections = ParentAttendanceStudentsQuery['parentAttendanceStudents'];

import * as React from 'react';
import { Redirect, useParams } from 'react-router';

import { Tabs } from 'app/app2/components';
import { TimeTrackingTable } from 'app2/views/shared'

import { ReportRouteParams } from '..';
import { OrganizerPage, SeasonDropdown, useCurrentSite } from '../shared';

import { OrganizerReportTransactions } from './OrganizerReportTransactions';
import { CourseSummaryReportTable } from './CourseSummaryReportTable';
import { ComplianceReportTable } from './ComplianceReportTable';

export function Reports() {
  const { site: siteId, season, report } = useParams<ReportRouteParams>();
  const { site } = useCurrentSite();

  const tabList = [
    {
      label: 'Activities overview',
      name: 'overview',
      content: <CourseSummaryReportTable />
    },
    {
      label: 'Transactions',
      name: 'transactions',
      content: <OrganizerReportTransactions site={site} />
    },
    {
      label: 'Time tracking',
      name: 'time-tracking',
      content: <TimeTrackingTable entity={site} />
    }
  ];

  if (site?.usingCompliance === false && report === 'compliance') {
    return <Redirect to={`/organizer/${siteId}/reports`} />;
  }

  if (site?.usingCompliance || report === 'compliance') {
    tabList.push({
      label: 'Compliance',
      name: 'compliance',
      content: <ComplianceReportTable />
    });
  }

  const seasonDropdown = !['transactions', 'adjustments', 'compliance'].includes(report);
  const sitesDropdown = !['transactions'].includes(report);

  return (
    <OrganizerPage title="Reports" sites={sitesDropdown}>
      {seasonDropdown !== false ? <SeasonDropdown /> : ''}
      <Tabs width="100%" tabs={tabList} baseUrl={`/organizer/${siteId}/reports/${season ? season + '/:report' : ':report'}`} urlParameter="report" />
    </OrganizerPage>
  );
}

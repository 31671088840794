import * as React from 'react'

import { Tabs } from 'app2/components'

import { CompanyPage, useBasicCompany } from '../shared'

import { CompanyCourseSummaryReport } from './CompanyCourseSummaryReport';
import { CompanyTransactionsReport } from './CompanyTransactionsReport'

export function Reports() {
  const { company } = useBasicCompany();

  function render() {
    return <CompanyPage title='Reports'>
      {renderTabs()}
    </CompanyPage>
  }

  function renderTabs() {
    return <Tabs width="100%" baseUrl={`/provider/${company?.id}/reports/:report`} urlParameter="report" tabs={[
      {
        label: 'Activities overview',
        name: 'overview',
        content: <CompanyCourseSummaryReport />
      },
      {
        label: 'Transactions',
        name: 'transactions',
        content: <CompanyTransactionsReport />
      }
    ]}
    />  
  }

  return render();
}
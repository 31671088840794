import * as React from 'react';
import { Redirect, useLocation, useParams } from 'react-router';

import { AttendanceAction } from 'app2/api';
import { Beta } from 'app2/views/shared-public';
import { CheckboxField, DropdownField, Field, FieldInfo, FieldProps, FieldRendererProps, Form, formatDate, FormModel, HBox, Icon, Link, RepeatingSection, Saveable, Text, TIME_FORMAT, useForm, VBox } from 'app2/components';

import { PublicSiteParams } from '../../publicRoutes';

import { usePublicSiteQuery } from '../registration/generated';

import { AttendanceCancel } from './AttendanceCancel';
import { PublicAttendancePage } from './PublicAttendancePage';
import { ParentAttendanceStudentsSelections, parentSetAttendance, useParentAttendanceStudentsQuery } from './generated';

export function PublicAttendanceStudents() {
  const { site: siteId } = useParams<PublicSiteParams>();
  const [siteQueryResult] = usePublicSiteQuery({ variables: { id: siteId } });
  const site = siteQueryResult?.data?.site;
  const location = useLocation();
  const code = location.state?.code;

  const [studentsQueryResult] = useParentAttendanceStudentsQuery({ variables: { code: code?.toString() }, pause: !code });
  const loading = studentsQueryResult.loading || studentsQueryResult.fetching;
  const owner = studentsQueryResult?.data?.parentAttendanceStudents?.owner;
  const rosterItems = studentsQueryResult?.data?.parentAttendanceStudents.items;

  const form = useForm<FormValues>(() => ({ attendances: rosterItems?.map(item => ({ ...item, include: true, action: item.checkedInAt ? AttendanceAction.CheckOut : AttendanceAction.CheckIn })) }), [rosterItems], {alwaysSave: true});
  const codeInputPath = `/sites/${siteId}/family-attendance`;

  const [success, setSuccess] = React.useState(false);

  function render() {
    if (site && !site?.usingParentAttendance) {
      return <Redirect to="/" />;
    }

    if (!code || (!loading && !rosterItems)) {
      return <Redirect to={codeInputPath} />;
    }

    return <PublicAttendancePage>{renderContent()}</PublicAttendancePage>;
  }

  function renderContent() {
    if (success) {
      return (
        <VBox hAlign="center" gap="$8" width="100%">
          <HBox vAlign="center" mb="$16">
            <Icon name="CheckCircle" size={34} mr="$8" />
            <Text text="heading1">Success!</Text>
          </HBox>
          <Text text="body" mb="$40">
            Please hand the device back to a staff member.
          </Text>
          <Link button="primary" to={codeInputPath} replace state={{}}>
            Continue
          </Link>
        </VBox>
      );
    } else {
      return renderForm();
    }
  }

  function renderForm() {
    return (
      <Saveable ok="Submit" cancel={<AttendanceCancel />} width="100%">
        <Form
          title={<Text text={['heading2', 'heading1']}>Welcome{owner && owner.name.length ? `, ${owner.name.split(' ')[0]}` : ''}!</Text>}
          subtitle="Select the students to check in or out." onNavigation="nothing"
          onOk={handleSubmit} form={form} width="100%" pr={0} mr={0}>
          <RepeatingSection
            name="attendances"
            numbered={false}
            headers={false}
            equalWidths={false}
            width="100%"
            fields={[<Field format={formatStudent} />, <Field name="action" label="Action" required render={renderAction} width="175px" />]}
          />
        </Form>
      </Saveable>
    );
  }

  function formatStudent(val: ParentAttendanceStudentsSelections['items'][0], props: FieldProps<any, any, any>, info: FieldInfo<Attendance['course']>) {
    return (
      <HBox vAlign="baseline" gap="$8">
        <Field name="include" component={CheckboxField} />
        <VBox>
          <Text text="subtitle1" whiteSpace="nowrap">
            {val?.student?.name}
          </Text>
          <Text text="body">{val?.course?.name}</Text>
          {renderSessionTimes(val)}
        </VBox>
      </HBox>
    );
  }

  function renderSessionTimes(val: RosterItem) {
    const startsAt = val?.startsAt;
    const endsAt = val?.endsAt;

    return <Beta>{formatDate(startsAt, TIME_FORMAT, site?.timezone)} - {formatDate(endsAt, TIME_FORMAT, site?.timezone)}</Beta>
  }

  function renderAction(fieldProps: FieldRendererProps<Attendance>) {
    const checkedIn = Boolean(fieldProps.info.record.checkedInAt);
    const checkedOut = fieldProps.info.record.checkedOut;
    const checkInOption = { value: AttendanceAction.CheckIn, label: 'Check in' };
    const checkOutOption = { value: AttendanceAction.CheckOut, label: 'Check out' };

    let options = [checkInOption, checkOutOption];
    if (checkedIn && !checkedOut) {
      options = [checkOutOption];
    }

    return <Field placeholder="Check in or out" options={options} name="action" component={DropdownField} disabled={checkedOut} />;
  }

  async function handleSubmit(form: FormModel<FormValues>) {
    const attendances = form.values.attendances.filter(a => a.include).map(({ student, course, action }) => ({ student: student.id, course: course.id, action }));

    if (!attendances.length) {
      return false;
    }

    const [success] = await parentSetAttendance({ variables: { code: code.toString(), attendances }, successMsg: 'Attendance submitted' });
    if (success) {
      setSuccess(true);
    }

    return success;
  }

  return render();
}

interface FormValues {
  attendances?: Attendance[];
}

interface Attendance extends RosterItem {
  action: AttendanceAction;
  include: boolean;
}

type RosterItem = ParentAttendanceStudentsSelections['items'][0];

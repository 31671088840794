import * as React from 'react'
import { isEqual, cloneDeep } from 'lodash-es';

import { Course, CourseDay, CourseDayUtils, CourseDayName } from 'app2/api';
import { BoxProps, createForm, Field, Form, FormModel, RepeatingSection, TimePicker, UpdateType, FormContext, MultiContext } from 'app2/components'

import { DaysButtonStrip } from './DaysButtonStrip';

const defaultDay = {days: [] as CourseDayName[], start: '', finish: ''};

export type CourseDaysContext = FormContext<Pick<Course, 'courseDays'>>
export const DEFAULT_ADD_DAY = 'Add day and time';

interface Props extends BoxProps {
  add?:string;
}


export class CourseDaysForm extends React.Component<Props, any, CourseDaysContext> {
  static defaultProps = {
    add: DEFAULT_ADD_DAY
  }

  static contextType = MultiContext;
  context:CourseDaysContext;

  localForm = createForm({values:{courseDays: [] as CourseDay[]}});

  constructor(props:BoxProps, context:CourseDaysContext) {
    super(props);

    this.localForm.setValue('courseDays', cloneDeep(context?.formInfo?.form?.getValue(context?.formInfo?.parents, 'courseDays')));
  }

  get parents() {
    return this.context.formInfo.parents;
  }

  get mainForm() {
    return this.context.formInfo.form;
  }

  render() {
    return <Form autoFocus={false} form={this.localForm} onChange={this.onFormChange} p='$12' bg='white' border='solid 1px' borderColor='border' borderRadius='standard'
      editing containerBreakpoints={false} maxWidth='100%' height='100%'>
      <RepeatingSection flex={1} requireOne buttonCols={1} name='courseDays' add={this.props.add} defaultRecord={defaultDay} assignIds={false}
        fields={[
          <Field label='Select days' name='days' required multiple component={DaysButtonStrip} width='unset' />,
          <Field label='Start time' name='start' required component={TimePicker} width='133px' />,
          <Field label='End time' name='finish' required component={TimePicker} width='133px' />,
          'remove'
        ]}
        />
    </Form>
  }

  componentDidUpdate() {
    this.checkForMainFormChange();
  }

  onFormChange = (form:FormModel, type:UpdateType) => {
    if (!type.anyValue && !type.reset) {
      return;
    }

    this.checkForLocalFormChange();
  }

  checkForMainFormChange() {
    if (this.formsDiffer()) {
      this.localForm.setValue('courseDays', this.cloneDays(this.mainForm.getValue(this.parents, 'courseDays')));
    }
  }

  checkForLocalFormChange() {
    if (this.formsDiffer()) {
      this.mainForm.setValue(this.parents, 'courseDays', this.cloneDays(this.localForm.values.courseDays));
    }
  }

  formsDiffer() {
    const formDays = this.getValidDays(this.mainForm?.getValue(this.parents, 'courseDays'));
    const stateDays = this.getValidDays(this.localForm?.values.courseDays);

    return !isEqual(formDays, stateDays);
  }

  getValidDays(days:CourseDay[]) {
    return days?.filter(day => CourseDayUtils.isValid(day)) || [];
  }

  cloneDays(days:CourseDay[]) {
    return this.getValidDays(days?.map(cd => ({...cd, days: CourseDayUtils.sortDays(cd.days)})) || []);
  }
}

import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminReviewsFilterOptionsQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Array<Types.Filter> | Types.Filter>;
  filterOptions: Types.Scalars['String'];
}>;

export type AdminReviewsFilterOptionsQuery = { reviewsQuery?: { filterOptions?: Array<{ label: string; value?: string | number | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export const AdminReviewsFilterOptionsDocument = gql`
  query AdminReviewsFilterOptions($filters: [Filter!], $filterOptions: String!) {
    reviewsQuery(filters: $filters, filterOptions: $filterOptions, pageSize: 1000) {
      filterOptions {
        label
        value
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminReviewsFilterOptionsArgs = Omit<UseQueryArgs<AdminReviewsFilterOptionsQueryVariables, AdminReviewsFilterOptionsQuery>, 'query'>;

export function useAdminReviewsFilterOptionsQuery(options: AdminReviewsFilterOptionsArgs = {}) {
  return useQuery<AdminReviewsFilterOptionsQueryVariables, AdminReviewsFilterOptionsQuery>({ query: AdminReviewsFilterOptionsDocument, ...options });
}

export type AdminReviewsFilterOptionsOptions = ExecQueryOptions<AdminReviewsFilterOptionsQueryVariables>;

export function adminReviewsFilterOptions(options: AdminReviewsFilterOptionsOptions) {
  return executeQuery<AdminReviewsFilterOptionsQuery, AdminReviewsFilterOptionsQueryVariables>(AdminReviewsFilterOptionsDocument, options);
}

export type AdminReviewsFilterOptionsSelections = AdminReviewsFilterOptionsQuery['reviewsQuery'];

import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerSitePoliciesQueryVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
}>;

export type OrganizerSitePoliciesQuery = {
  site?:
    | {
        policy?:
          | {
              bathroom?: string | null | undefined;
              classroom?: string | null | undefined;
              lateParent?: string | null | undefined;
              missingStudent?: string | null | undefined;
              parking?: string | null | undefined;
              schoolRules?: string | null | undefined;
              snacks?: string | null | undefined;
              studentDismissal?: string | null | undefined;
              studentPickup?: string | null | undefined;
              teacherCheckin?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const OrganizerSitePoliciesDocument = gql`
  query OrganizerSitePolicies($siteId: ID!) {
    site(site: $siteId) {
      policy {
        bathroom
        classroom
        lateParent
        missingStudent
        parking
        schoolRules
        snacks
        studentDismissal
        studentPickup
        teacherCheckin
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type OrganizerSitePoliciesArgs = Omit<UseQueryArgs<OrganizerSitePoliciesQueryVariables, OrganizerSitePoliciesQuery>, 'query'>;

export function useOrganizerSitePoliciesQuery(options: OrganizerSitePoliciesArgs = {}) {
  return useQuery<OrganizerSitePoliciesQueryVariables, OrganizerSitePoliciesQuery>({ query: OrganizerSitePoliciesDocument, ...options });
}

export type OrganizerSitePoliciesOptions = ExecQueryOptions<OrganizerSitePoliciesQueryVariables>;

export function organizerSitePolicies(options: OrganizerSitePoliciesOptions) {
  return executeQuery<OrganizerSitePoliciesQuery, OrganizerSitePoliciesQueryVariables>(OrganizerSitePoliciesDocument, options);
}

export type OrganizerSitePoliciesSelections = OrganizerSitePoliciesQuery['site'];

import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentReviewQueryVariables = Types.Exact<{
  review: Types.Scalars['String'];
}>;

export type ParentReviewQuery = {
  review?:
    | {
        overallRating?: number | null | undefined;
        qualityRating?: number | null | undefined;
        instructorRating?: number | null | undefined;
        comments?: string | null | undefined;
        enrollment?: { course: { name?: string | null | undefined } } | null | undefined;
      }
    | null
    | undefined;
};

export const ParentReviewDocument = gql`
  query ParentReview($review: String!) {
    review(review: $review) {
      overallRating
      qualityRating
      instructorRating
      comments
      enrollment {
        course {
          name
        }
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type ParentReviewArgs = Omit<UseQueryArgs<ParentReviewQueryVariables, ParentReviewQuery>, 'query'>;

export function useParentReviewQuery(options: ParentReviewArgs = {}) {
  return useQuery<ParentReviewQueryVariables, ParentReviewQuery>({ query: ParentReviewDocument, ...options });
}

export type ParentReviewOptions = ExecQueryOptions<ParentReviewQueryVariables>;

export function parentReview(options: ParentReviewOptions) {
  return executeQuery<ParentReviewQuery, ParentReviewQueryVariables>(ParentReviewDocument, options);
}

export type ParentReviewSelections = ParentReviewQuery['review'];

import * as React from 'react'

import { BoxProps, VBox, HBox } from './Box';
import { IconNames, Icon } from './icons';
import { Subtitle2 } from './Text';

export interface InfoProps extends Omit<BoxProps, 'children'> {
  type: 'success' | 'warning' | 'error';
  title?:string | false;
  children?:string[] | React.ReactNode;
  message?:string[] | React.ReactNode;
  icon?:boolean | IconNames;
  small?:boolean;
}

export function Info(props:React.PropsWithChildren<InfoProps>) {
  const {type, title:propsTitle, message, children, icon, small, ...remaining} = props;
  const iconName = typeof icon == 'string' ? icon : {
    success: 'CheckCircle',
    warning: 'AlertTriangle',
    error: 'AlertCircle'
  }[type] as IconNames;
  const title = propsTitle === false ? undefined : propsTitle || {
    success: 'Success',
    warning: 'Attention',
    error: 'Error'
  }[type];
  const content = children || message;
  const layoutProps = props.layout != 'hbox' ?
    {pb:'$12'} : {pr: '$12'}

  return <VBox bg={type + 'Fill'} p={small ? '$8' : '$16'} border='solid 1px' borderColor={type} borderRadius='standard' text='body' hAlign='center' className={type == 'error' ? 'hr_error_locator' : undefined} {...remaining}>
    {icon && <HBox vAlign='center' {...layoutProps}><Icon name={iconName} strokeWidth={1} color={type} mr='$8' size='medium' />{propsTitle && <Subtitle2 color={type}>{title}</Subtitle2>}</HBox>}
    {isArrayOfStrings(content) && (content as string[]).length > 1 ? bulletize(content as string[]) : content}
  </VBox>
}

Info.defaultProps = {
  icon: true
}

function isArrayOfStrings(data:React.ReactNode) {
  return Array.isArray(data) && data.filter(item => typeof item == 'string').length == data.length;
}

export function bulletize(messages:string[]) {
  return <VBox css={{listStyleType: "'-  '", paddingLeft: '20px'}} gap='$8'>
    {messages.map((c, i) => <li key={i}>{c}</li>)}
  </VBox>
}

// if an array is passed in, this separates each item
// into it's own line

export function itemize(messages:React.ReactNode) {
  if (!Array.isArray(messages)) {
    return messages;
  }

  return <>
    {messages.map((c, i) => <div key={i}>{c}</div>)}
  </>
}

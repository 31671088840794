import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerSitePriceTiersQueryVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
}>;

export type OrganizerSitePriceTiersQuery = { site?: { priceTiers: Array<{ id: string; displayOrder: number; name: string; rate: number } | null | undefined> } | null | undefined };

export const OrganizerSitePriceTiersDocument = gql`
  query OrganizerSitePriceTiers($siteId: ID!) {
    site(site: $siteId) {
      priceTiers {
        id
        displayOrder
        name
        rate
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type OrganizerSitePriceTiersArgs = Omit<UseQueryArgs<OrganizerSitePriceTiersQueryVariables, OrganizerSitePriceTiersQuery>, 'query'>;

export function useOrganizerSitePriceTiersQuery(options: OrganizerSitePriceTiersArgs = {}) {
  return useQuery<OrganizerSitePriceTiersQueryVariables, OrganizerSitePriceTiersQuery>({ query: OrganizerSitePriceTiersDocument, ...options });
}

export type OrganizerSitePriceTiersOptions = ExecQueryOptions<OrganizerSitePriceTiersQueryVariables>;

export function organizerSitePriceTiers(options: OrganizerSitePriceTiersOptions) {
  return executeQuery<OrganizerSitePriceTiersQuery, OrganizerSitePriceTiersQueryVariables>(OrganizerSitePriceTiersDocument, options);
}

export type OrganizerSitePriceTiersSelections = OrganizerSitePriceTiersQuery['site'];

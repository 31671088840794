import * as React from 'react';

import { StudentModalBase } from '../modal'
import { StudentLogEntries } from '../tabs';

import { RestrictedStudentDetails } from './RestrictedStudentDetails';

import { useRestrictedStudentDetailsQuery } from './generated';

interface Props {
  id: string;
  token: string;
}

export function RestrictedStudentModal(props: Props) {
  const [result] = useRestrictedStudentDetailsQuery({ variables: { token: props.token, id: props.id } });
  const student = result?.data?.studentByToken;

  function render() {
    return <StudentModalBase student={student} tabs={renderTabs()} />
  }

  function renderTabs() {
    return [{
      label: 'Profile',
      content: <RestrictedStudentDetails token={props.token} id={props.id} />
    }, {
      label: 'Notes',
      content: <StudentLogEntries id={props.id} />
    }]
  }

  return render();
}

export function restrictedModalRenderer(token: string) {
  return function(props:{id:string}) {
    return <RestrictedStudentModal id={props.id} token={token} />
  }
}


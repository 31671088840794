import * as React from 'react';

import { Checkbox } from '../../Checkbox';
import { HBox } from '../../Box';
import { Icon } from '../../icons';
import { FormLabel } from '../../Text';
import { Tooltip } from '../../Tooltip';
import { theme } from '../../theme';

import { RowHeaderRendererProps } from './RowHeaderRendererProps';

export function RowHeaderRenderer(props: RowHeaderRendererProps) {
  // when we render only checkbox, we still render the number
  // mainly for tis gridlines, and then the width (in the tables
  // colWidth function) is cut short hide the row numbers.

  return props.table.props.rowSelection
    ? renderCheckboxAndRowNumber(props)
    : renderOnlyRowNumber(props)
}

function renderCheckboxAndRowNumber(props: RowHeaderRendererProps) {
  delete props.style.overflow;

  return <HBox data-row={props.row} data-field='TableRowHeader' paddingLeft="12px" vAlign="center" hAlign="left" bg="#FFF" style={props.style} paddingTop='1px' paddingBottom='1px'>
    {renderCheckbox(props)}
    {renderRowNumber(props)}
  </HBox>
}

function renderOnlyRowNumber(props: RowHeaderRendererProps) {
  return <HBox data-row={props.row} data-field='TableRowHeader' vAlign="center" hAlign="center" bg="#FFF" style={props.style} paddingTop='1px' paddingBottom='1px'>
    {renderRowNumber(props)}
  </HBox>
}

function renderCheckbox(props: RowHeaderRendererProps) {
  return (
    <Checkbox
      onMouseDown={event => event.stopPropagation()}
      checked={props.table.selections.containsRow(props.row)}
      onChange={event => {
        props.table.selections = props.table.selections
          .clone()
          .toggleRow(props.row);
        
        props.table.focus();
      }}
    />
  );
}

function renderRowNumber(props:RowHeaderRendererProps) {
  const errors = props.table.getRecordErrors(props.row);
  const hasError = errors && errors.length;
  const hackRowNumbersOnlyWithLockedColsFix = !props.table.props.rowNumbers && props.table.lockedCol != -1 ? .5 : 1;
  const border = `${hackRowNumbersOnlyWithLockedColsFix}px solid ${hasError ? theme.colors.error : theme.colors.border}`;
  const bg = hasError ? 'errorFill' : '#F6F6F6';
  const zIndex = hasError ? 1 : undefined;
  const rowNumber = hasError ? <Icon name='Info' color='error' size='small' /> : (props.table.props.rowNumbers ? <FormLabel>{props.row + 1}</FormLabel> : undefined);
  const header = <HBox position='relative' bg={bg} vAlign="center" hAlign="center" width='100%' height='100%' 
      border={border} borderRadius="3px 0px 0px 3px" color={hasError ? 'error' : undefined} zIndex={zIndex}>
      {rowNumber}
  </HBox>;

  return hasError ? renderTooltip(header, errors) : header;
}

function renderTooltip(contents:any, errors:string[]) {
  return <Tooltip tip={errors}>
    {contents}
  </Tooltip>
}
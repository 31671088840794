import * as React from 'react';

import { CourseKind } from 'app2/api';
import { Button, DateField, Field, Panel, RepeatingSection } from 'app2/components';
import { GradeField } from 'app2/views/shared-public';

import { ParentPage, ParentStudentLink, ParentStudentDetails } from 'app2/views/parent/shared';

import { CcContacts } from './CcContacts';
import { useParentStudentsQuery } from './generated';

export function Family() {
  const [result] = useParentStudentsQuery();
  const user = result.data?.user;

  function render() {
    return <ParentPage title='Family'>
      <Panel icon='Users' title='Family' values={user} primaryActions={<Button iconPosition='left' icon='Plus' onClick={<ParentStudentDetails purpose='add' courseKinds={[CourseKind.Enrichment]} />}>Add student</Button>}>
        <RepeatingSection name='students' buttonCols={1} fields={[
          <Field label='Name' name='name' component={<ParentStudentLink />} />,
          <Field label='Date of birth' name='dob' component={DateField} />,
          <Field label='Grade' name='grade' component={GradeField} />,
          <Field label='Health & comments' name='formattedMedicalAndNotes' />,
          <Field label='Name' name='name' component={<ParentStudentLink edit display={false} />} />,
          ]}/>
      </Panel>

      <CcContacts newUi={true} />
    </ParentPage>
  }

  return render();
}

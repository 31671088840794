import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type PublicCartExpirationQueryVariables = Types.Exact<{ [key: string]: never }>;

export type PublicCartExpirationQuery = { cartExpiration?: string | null | undefined };

export const PublicCartExpirationDocument = gql`
  query PublicCartExpiration {
    cartExpiration
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type PublicCartExpirationArgs = Omit<UseQueryArgs<PublicCartExpirationQueryVariables, PublicCartExpirationQuery>, 'query'>;

export function usePublicCartExpirationQuery(options: PublicCartExpirationArgs = {}) {
  return useQuery<PublicCartExpirationQueryVariables, PublicCartExpirationQuery>({ query: PublicCartExpirationDocument, ...options });
}

export type PublicCartExpirationOptions = ExecQueryOptions<PublicCartExpirationQueryVariables>;

export function publicCartExpiration(options: PublicCartExpirationOptions) {
  return executeQuery<PublicCartExpirationQuery, PublicCartExpirationQueryVariables>(PublicCartExpirationDocument, options);
}

export type PublicCartExpirationSelections = PublicCartExpirationQuery['cartExpiration'];

import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentSiteClassroomsQueryVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
}>;

export type ParentSiteClassroomsQuery = { site?: { id: string; classrooms: Array<{ id: string; name: string; grade: string }> } | null | undefined };

export const ParentSiteClassroomsDocument = gql`
  query ParentSiteClassrooms($siteId: ID!) {
    site(site: $siteId) {
      id
      classrooms: studentAssignableClassrooms {
        id
        name
        grade
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type ParentSiteClassroomsArgs = Omit<UseQueryArgs<ParentSiteClassroomsQueryVariables, ParentSiteClassroomsQuery>, 'query'>;

export function useParentSiteClassroomsQuery(options: ParentSiteClassroomsArgs = {}) {
  return useQuery<ParentSiteClassroomsQueryVariables, ParentSiteClassroomsQuery>({ query: ParentSiteClassroomsDocument, ...options });
}

export type ParentSiteClassroomsOptions = ExecQueryOptions<ParentSiteClassroomsQueryVariables>;

export function parentSiteClassrooms(options: ParentSiteClassroomsOptions) {
  return executeQuery<ParentSiteClassroomsQuery, ParentSiteClassroomsQueryVariables>(ParentSiteClassroomsDocument, options);
}

export type ParentSiteClassroomsSelections = ParentSiteClassroomsQuery['site'];

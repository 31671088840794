import * as React from 'react'

import { Text, VBox, Img } from 'app2/components'

import { Section } from './Section'
import why from './why.png'

export function Why() {
  return <Section layout={['vbox', 'hbox']} itemWidth={['100%', '50%']}>
    <VBox hAlign='left' vAlign='center' pr='$20' pb='$30'>
      <Text text='heading2' mb='$8'>Why Homeroom?</Text>
      <Text text='body' fontSize='16px'>Bring your school the best afterschool classes with our all-in-one platform that gets you from planning your activities to managing enrollments, facilitating payments and tracking daily rosters.</Text>
    </VBox>
    <Img src={why} style={{objectFit:'cover'}} />
  </Section>
}

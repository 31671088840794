import * as React from 'react';
import { castArray } from 'lodash-es';

import { Subtitle2, HBox, Icon, IconNames, VBox, Heading2, Link, LinkProps, Phone, PhoneOrTablet, Desktop, TabletOrDesktop } from 'app/app2/components';

import swoosh from './swoosh.svg';

interface Props {
  icon?:IconNames;
  title:string;
  details?:string[];
  primary?:LinkProps | LinkProps[];
  secondary?:LinkProps | LinkProps[];
  swoosh:'left' | 'center' | 'right'
  breakAt?:number;
}

export function SwooshHeader(props:Props) {
  const details = props.details?.filter(l => !!l) || [];
  const middleLayout = props.breakAt === 0 ? 'vbox' : 'hbox';
  const DesktopLayout = props.breakAt === 0 ? Desktop : TabletOrDesktop;
  const PhoneLayout = props.breakAt === 0 ? PhoneOrTablet : Phone;

  function render() {
    return <HBox bg='brand' width='100%' p={['$16', '$30', '$30']} mb={['$20', '$20', '$30']} borderRadius='form' layout={['vbox', middleLayout, 'hbox']} backgroundImage={`url(${swoosh})`} backgroundRepeat='no-repeat' backgroundPosition={props.swoosh}>
      <DesktopLayout>
        {props.title && (
            <>
              <VBox>
                <HBox vAlign="top" mb="$8">
                  {props.icon && <Icon name={props.icon} size={24} mr="$10" color="white" />}
                  <Heading2 color="white">{props.title}</Heading2>
                </HBox>
                <VBox pl="32px">
                  {details.map((line, index) => (
                    <Subtitle2 color="white" key={index}>
                      {line}
                    </Subtitle2>
                  ))}
                </VBox>
              </VBox>
              <HBox flex={1} />
            </>
        )}
        {renderLinks()}
      </DesktopLayout>
      <PhoneLayout>
        {props.title && (
          <>
            <VBox textAlign="center">
              <Heading2 color="white" textAlign="center">{props.icon && <Icon name={props.icon} size={24} color="white" />} {props.title}</Heading2>
              <VBox pb="$30">
                {details.map((line, index) => (
                  <Subtitle2 color="white" key={index}>
                    {line}
                  </Subtitle2>
                ))}
              </VBox>
            </VBox>
            <HBox flex={1} />
          </>
        )}
        {renderLinks()}
      </PhoneLayout>
    </HBox>
  }

  function renderLinks() {
    if (!props.primary && !props.secondary) {
      return null;
    }

    const primary = props.primary ? castArray(props.primary).filter(l => !!l) : [];
    const secondary = props.secondary ? castArray(props.secondary).filter(l => !!l) : [];

    return <VBox hAlign='center' gap='$8'>
      <HBox gap='$8'>{primary.map((link, index) => <Link key={index} small button='primary' whiteSpace={[undefined, 'nowrap']} {...link} />)}</HBox>
      <HBox gap='$8'>{secondary.map((link, index) => <Link key={index} small button='secondary' whiteSpace={[undefined, 'nowrap']} {...link} />)}</HBox>
    </VBox>
  }

  return render();
}

SwooshHeader.defaultProps = {
  swoosh: 'right',
  breakAt: 1
}

import * as React from 'react';

import { Course, DeepPartial, StudentUtils, sortEnrollments } from 'app2/api';
import { Body, Dropdown, Field, FormModel, FieldRendererProps, Panel, RepeatingSection, formatDate } from 'app2/components';
import { EnrollmentActivityLink } from 'app2/views/shared';

import { AfterClass, BeforeClass, useClassOptions, UseClassOptionsSite, ParentStudentLink } from 'app2/views/parent/shared';
import { ParentScheduleSelections, parentUpdateEnrollments } from './generated';

type Student = ParentScheduleSelections['students'][0];
type Enrollment = DeepPartial<ParentScheduleSelections['students'][0]['enrollments'][0]>;
type StudentEnrollment = DeepPartial<Enrollment & {student:Student}>;
type Enrollments = {enrollments:StudentEnrollment[]};
type Site = ParentScheduleSelections['students'][0]['enrollments'][0]['course']['site'];

interface Props {
  parent:ParentScheduleSelections;
}

export function EnrollmentsTable(props:Props) {
  const parent = props.parent;
  const {sites, enrollments} = React.useMemo(getSitesAndEnrollments, [parent]);
  const { allHaveBeforeClass, hasBeforeClass, siteOptions } = useClassOptions((sites as unknown) as UseClassOptionsSite[]);
  const studentOptions = React.useMemo(() => parent.students.map(s => ({label: StudentUtils.getStudentName(s), value: s.id})), [parent.students]);

  if (!props.parent) {
    return <></>
  }

  function render() {
    return <Panel initialValues={{enrollments}} title='Current & upcoming activities' type='toggle' onOk={onOk}>
      <RepeatingSection name='enrollments' equalWidths={false} fields={[
        <Field label='Activity' name='id' component={EnrollmentActivityLink} role='public' badge />,
        <Field label='Name' render={(props:FieldRendererProps) => <Field name='student.id' readOnly={props.info.value.isChildEnrollment} display={ParentStudentLink} edit={<Dropdown options={studentOptions} />} />} />,
        <Field label='Dates' name='course' readOnly format={(c:Course) => <Body>{formatDate(c.startDate, 'abbreviated')} - {formatDate(c.endDate, 'abbreviated')}</Body>} />,
        <Field label='Schedule' name='rosterPeriod' readOnly />,
        <Field label='Location' name='course.room' readOnly none={false} />,
        hasBeforeClass && <Field label='Pre-activity' component={BeforeClass} kind='field' allHaveBeforeClass={allHaveBeforeClass} siteOptions={siteOptions} />,
        <Field label='Post-activity' component={AfterClass} kind='field' siteOptions={siteOptions} />,
        <Field label='Group' name='groups' readOnly format={(groups:string[]) => groups?.join(', ')} none={false} />,
      ]} />
    </Panel>
  }

  function getSitesAndEnrollments() {
    const sites:Site[] = [];
    let enrollments:DeepPartial<StudentEnrollment>[] = [];

    if (!parent?.students) {
      return {sites, enrollments};
    }

    parent.students.forEach(s => s.enrollments.forEach(e => {
      enrollments.push({...e, student: s});

      if (sites.find(s => s.id == e.course.site.id)) {
        return;
      }

      sites.push(e.course.site);
    }));

    enrollments = sortEnrollments(enrollments, true)

    return {sites, enrollments};
  }

  async function onOk(form:FormModel<Enrollments>) {
    const enrollments = form.values.enrollments.map(e => ({id: e.id, studentId: e.student.id, dismissal: e.dismissal, pickup: e.pickup}));
    const [result] = await parentUpdateEnrollments({variables:{enrollments}});

    return result;
  }

  return render();
}

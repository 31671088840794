import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentSetAttendanceMutationVariables = Types.Exact<{
  code: Types.Scalars['String'];
  attendances: Array<Types.ParentAttendanceInput> | Types.ParentAttendanceInput;
}>;

export type ParentSetAttendanceMutation = { parentSetAttendance?: { attendances?: Array<{ id: string }> | null | undefined } | null | undefined };

export const ParentSetAttendanceDocument = gql`
  mutation ParentSetAttendance($code: String!, $attendances: [ParentAttendanceInput!]!) @urql(additionalTypenames: ["RosterItem", "Enrollment"]) {
    parentSetAttendance(code: $code, attendances: $attendances) {
      attendances {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type ParentSetAttendanceOptions = ExecMutationOptions<ParentSetAttendanceMutationVariables>;

export function parentSetAttendance(options: ParentSetAttendanceOptions) {
  return executeMutation<ParentSetAttendanceMutation, ParentSetAttendanceMutationVariables>(ParentSetAttendanceDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['RosterItem', 'Enrollment']) }
  });
}

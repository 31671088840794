import * as React from 'react'
import * as ReactIs from 'react-is'
import * as ReactDOM from 'react-dom';

import { VBox } from '../Box';
import { Shield } from '../shield';
import { ThemeProvider } from '../theme';
import { Viewport } from '../embed';
import { createKey } from '../dom-utils';

import { Notification, NotificationProps } from './Notification'

export type NotificationElement = React.ReactElement<NotificationProps>;
export type NotificationOptions = string | string[] | NotificationElement | NotificationProps;

interface State {
  elements:NotificationElement[];
  set:(notifications:NotificationElement[]) => void;
}

let state:State;

export function NotificationManager() {
  const [elements, set] = React.useState<NotificationElement[]>([]);
  state = {elements, set}

  return <ThemeProvider>
    <Viewport zIndex={9999999999}>
      <VBox position='absolute' right='$20' top='$20' vItemSpace='$20' maxWidth='calc(min(100% - 40px))' pointerEvents='none'>
        {elements}
      </VBox>
    </Viewport>
  </ThemeProvider>
}

NotificationManager.success = function(message:React.ReactNode, options?:Partial<NotificationProps>):React.Key {
  return NotificationManager.add({ type: 'success', message, ...options })
}

NotificationManager.warning = function(message:React.ReactNode, options?:Partial<NotificationProps>):React.Key {
  return NotificationManager.add({ type: 'warning', message, ...options })
}

NotificationManager.error = function(message:React.ReactNode, options?:Partial<NotificationProps>):React.Key {
  return NotificationManager.add({ type: 'error', message, ...options })
}

NotificationManager.add = function(notificationOrProps:NotificationOptions):React.Key {
  const notification = typeof notificationOrProps == 'string' || Array.isArray(notificationOrProps) || (ReactIs.isElement(notificationOrProps) && notificationOrProps.type != Notification)
    ? create(<Notification type='success' message={notificationOrProps} />)
    : (notificationOrProps as NotificationElement).props == undefined
      ? create(<Notification {...notificationOrProps as NotificationProps} />)
      : create(notificationOrProps as NotificationElement);

  if (!state) {
    const notificationManager = document.createElement('div');
    document.body.appendChild(notificationManager);

    ReactDOM.render(<NotificationManager />, notificationManager, () => add(notification));
  }
  else {
    add(notification);
  }

  return notification.key;
}

function create(notification:NotificationElement) {
  const key = notification.key || createKey();
  const style = Object.assign({}, notification.props.style, {pointerEvents: 'auto'});

  return React.cloneElement(notification, {key, style, onClickClose: () => NotificationManager.remove(key)});
}

function add(notification:NotificationElement) {
  state.set([notification, ...state.elements]);
}

NotificationManager.remove = function(key:React.Key) {
  const pos = state.elements.findIndex(element => element.key == key);

  if (pos == -1) {
    return;
  }

  const elements = state.elements.slice();
  elements.splice(pos, 1);

  state.set(elements);
}

NotificationManager.removeAll = function() {
  state.set([]);
}

import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TeacherCourseKindsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TeacherCourseKindsQuery = { currentUser?: { courseKindGroups: Array<Types.CourseKindGroup> } | null | undefined };

export const TeacherCourseKindsDocument = gql`
  query TeacherCourseKinds {
    currentUser {
      courseKindGroups
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type TeacherCourseKindsArgs = Omit<UseQueryArgs<TeacherCourseKindsQueryVariables, TeacherCourseKindsQuery>, 'query'>;

export function useTeacherCourseKindsQuery(options: TeacherCourseKindsArgs = {}) {
  return useQuery<TeacherCourseKindsQueryVariables, TeacherCourseKindsQuery>({ query: TeacherCourseKindsDocument, ...options });
}

export type TeacherCourseKindsOptions = ExecQueryOptions<TeacherCourseKindsQueryVariables>;

export function teacherCourseKinds(options: TeacherCourseKindsOptions) {
  return executeQuery<TeacherCourseKindsQuery, TeacherCourseKindsQueryVariables>(TeacherCourseKindsDocument, options);
}

export type TeacherCourseKindsSelections = TeacherCourseKindsQuery['currentUser'];

import * as Types from '../../../../../../api/graphql';

import gql from 'graphql-tag';
import { StudentDetailFieldsWithoutCodesFragmentDoc } from './studentDetailsFieldsWithoutCodes.generated';
export type StudentDetailFieldsFragment = {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  nickname?: string | null | undefined;
  dob?: string | null | undefined;
  grade: string;
  automaticGradeUpdate: boolean;
  notes?: string | null | undefined;
  parents: Array<{ attendanceCodeCode?: string | null | undefined; id: string; name?: string | null | undefined; phone?: string | null | undefined; email: string }>;
  emergencyContacts: Array<{
    attendanceCodeCode?: string | null | undefined;
    id: string;
    name?: string | null | undefined;
    relation?: string | null | undefined;
    phone?: string | null | undefined;
    email?: string | null | undefined;
  }>;
  authorizedPickupContacts: Array<
    | { attendanceCodeCode?: string | null | undefined; id: string; name?: string | null | undefined; relation?: string | null | undefined; phone?: string | null | undefined; email?: string | null | undefined }
    | null
    | undefined
  >;
  site?: { id: string; name?: string | null | undefined } | null | undefined;
  classroom?: { id: string; name: string; grade: string } | null | undefined;
  medical?:
    | {
        epiPen?: boolean | null | undefined;
        conditions?: string | null | undefined;
        allergies?: Array<{ allergicTo: string; notes?: string | null | undefined }> | null | undefined;
        medications?: Array<{ medication: string; dose: string; notes?: string | null | undefined }> | null | undefined;
      }
    | null
    | undefined;
};

export const StudentDetailFieldsFragmentDoc = gql`
  fragment studentDetailFields on Student {
    ...studentDetailFieldsWithoutCodes
    parents {
      attendanceCodeCode
    }
    emergencyContacts {
      attendanceCodeCode
    }
    authorizedPickupContacts {
      attendanceCodeCode
    }
  }
  ${StudentDetailFieldsWithoutCodesFragmentDoc}
`;

import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminCourseQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type AdminCourseQuery = {
  course?:
    | {
        id: string;
        name?: string | null | undefined;
        season: { id: string; name: string };
        site: { id: string; name?: string | null | undefined };
        company?: { id: string; name?: string | null | undefined } | null | undefined;
      }
    | null
    | undefined;
};

export const AdminCourseDocument = gql`
  query AdminCourse($id: ID!) {
    course(course: $id) {
      id
      name
      season {
        id
        name
      }
      site {
        id
        name
      }
      company {
        id
        name
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminCourseArgs = Omit<UseQueryArgs<AdminCourseQueryVariables, AdminCourseQuery>, 'query'>;

export function useAdminCourseQuery(options: AdminCourseArgs = {}) {
  return useQuery<AdminCourseQueryVariables, AdminCourseQuery>({ query: AdminCourseDocument, ...options });
}

export type AdminCourseOptions = ExecQueryOptions<AdminCourseQueryVariables>;

export function adminCourse(options: AdminCourseOptions) {
  return executeQuery<AdminCourseQuery, AdminCourseQueryVariables>(AdminCourseDocument, options);
}

export type AdminCourseSelections = AdminCourseQuery['course'];

import { axios } from 'app2/api';
import moment from 'moment';

import { downloadFile } from '../utils';

import { ReportView } from './report-view';

export enum ContentType {
  CSV = 'text/csv',
  PDF = 'application/pdf'
}

export function downloadReport(fileNameOrStrings:string | any[], contentType:ContentType, view:ReportView) {
  const fileName = Array.isArray(fileNameOrStrings) 
    ? fileNameOrStrings.
      filter(p => !!p).
      map(p => (p instanceof Date || moment.isMoment(p) ? moment(p).format('MM-DD-YYYY') : String(p).trim())).
      join(' ') 
    : fileNameOrStrings;

  return downloadFile(
    axios.post(`/reports/download`, {
      params: {
        'content_type': contentType,
        filename: fileName,
        view
      },
      responseType: 'blob',
    }),
    contentType
  );
}

import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
import { ReportTransactionsCommonFragmentDoc } from '../../../shared/reports/generated/reportTransactionsCommon.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompanyReportTransactionsQueryVariables = Types.Exact<{
  type: Types.EntityKind;
  current: Types.Scalars['ID'];
  sorts?: Types.InputMaybe<Array<Types.Sort> | Types.Sort>;
  filters?: Types.InputMaybe<Array<Types.Filter> | Types.Filter>;
  totals?: Types.InputMaybe<Array<Types.Total> | Types.Total>;
}>;

export type CompanyReportTransactionsQuery = {
  reportTransactions?:
    | {
        totals?: Array<number | null | undefined> | null | undefined;
        total?: number | null | undefined;
        items: Array<
          | {
              rosterPeriod?: string | null | undefined;
              billPeriod?: string | null | undefined;
              companyFee?: number | null | undefined;
              discountAmount?: number | null | undefined;
              discountCodes?: Array<string> | null | undefined;
              trackingParameters?: string | null | undefined;
              id?: string | null | undefined;
              transactionDate?: string | null | undefined;
              transactionStatus?: string | null | undefined;
              transactionDescription?: string | null | undefined;
              paymentService?: Types.PaymentService | null | undefined;
              companyEarnings?: number | null | undefined;
              companyHomeroomFee?: number | null | undefined;
              companyProcessingFee?: number | null | undefined;
              companyInternalTransferDate?: string | null | undefined;
              companyExternalTransferDate?: string | null | undefined;
              companyExternalTransferAmount?: number | null | undefined;
              companyExternalTransferStatus?: string | null | undefined;
              site?: { id: string; name?: string | null | undefined } | null | undefined;
              season?: { id: string; name: string; formattedKind?: string | null | undefined } | null | undefined;
              company?: { id: string; name?: string | null | undefined } | null | undefined;
              course?:
                | {
                    id: string;
                    name?: string | null | undefined;
                    grades?: Array<string | null | undefined> | null | undefined;
                    siteId: string;
                    status: Types.CourseStatus;
                    startDate?: string | null | undefined;
                    endDate?: string | null | undefined;
                    classesCount: number;
                  }
                | null
                | undefined;
              student?: { id: string; nameWithNickname: string; grade: string; classroom?: { siteId: string } | null | undefined } | null | undefined;
              parent?: { id: string; name?: string | null | undefined; email: string } | null | undefined;
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
};

export const CompanyReportTransactionsDocument = gql`
  query CompanyReportTransactions($type: EntityKind!, $current: ID!, $sorts: [Sort!], $filters: [Filter!], $totals: [Total!]) {
    reportTransactions(type: $type, current: $current, sorts: $sorts, filters: $filters, totals: $totals) {
      items {
        ...reportTransactionsCommon
      }
      totals
      total
    }
  }
  ${ReportTransactionsCommonFragmentDoc}
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type CompanyReportTransactionsArgs = Omit<UseQueryArgs<CompanyReportTransactionsQueryVariables, CompanyReportTransactionsQuery>, 'query'>;

export function useCompanyReportTransactionsQuery(options: CompanyReportTransactionsArgs = {}) {
  return useQuery<CompanyReportTransactionsQueryVariables, CompanyReportTransactionsQuery>({ query: CompanyReportTransactionsDocument, ...options });
}

export type CompanyReportTransactionsOptions = ExecQueryOptions<CompanyReportTransactionsQueryVariables>;

export function companyReportTransactions(options: CompanyReportTransactionsOptions) {
  return executeQuery<CompanyReportTransactionsQuery, CompanyReportTransactionsQueryVariables>(CompanyReportTransactionsDocument, options);
}

export type CompanyReportTransactionsSelections = CompanyReportTransactionsQuery['reportTransactions']['items'][0];

import * as React from 'react';

import { PriceBreakdown } from 'app2/api';
import { VBox, BoxProps } from 'app2/components';

import { FeeItem, FeeTotal } from '../../currency';

export type FeeBreakdownType = Pick<PriceBreakdown, 'vendorCharges' | 'siteCharges' | 'homeroomCharges' | 'listPrice' | 'vendorCreditCardFee' | 'vendorReceives' | 'siteCreditCardFee' | 'siteReceives'>;

interface Props extends FeeBreakdownType {
  boxProps?: BoxProps;
}

export function FeeBreakdown(props: Props) {
  function render() {
    return (
        <VBox borderRadius="standard" bg="backgroundMediumGrey" p="$16" vItemSpace="$16" {...props.boxProps}>
        <FeeItem label="Activity pricing" amount={props.vendorCharges} />
        {/* <FeeItem label="Materials fee" amount={props.vendorMaterialsFee} /> */}
        <FeeItem label="School fee" amount={props.siteCharges} />
        <FeeItem label="Service fee" amount={props.homeroomCharges} />
        <FeeTotal label="Family pays" amount={props.listPrice} />

        <VBox width="100%" height="1px" bg="border" />

        <FeeItem label="Activity pricing" amount={props.vendorCharges} />
        {/* <FeeItem label="Materials fee" amount={props.vendorMaterialsFee} /> */}
        <FeeItem label="Credit card fees" amount={props.vendorCreditCardFee} />
        <FeeTotal label="Provider receives" amount={props.vendorReceives} />

        <VBox width="100%" height="1px" bg="border" />

        <FeeItem label="School fee" amount={props.siteCharges} />
        <FeeItem label="Credit card fees" amount={props.siteCreditCardFee} />
        <FeeTotal label="School receives" amount={props.siteReceives} />
      </VBox>
    );
  }

  return render();
}

FeeBreakdown.defaultProps = {
  boxProps: {}
};

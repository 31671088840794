import * as React from 'react';
import { useParams } from 'react-router';
import { castArray } from 'lodash-es';

import { Company, Season, Site, EntityKind, FilterOperator, TotalOperator } from 'app2/api';
import { DataTable, DataTableColumn, DateInput, DatePicker, HBox, MenuItem, Option, useLifecycle } from 'app2/components';
import { ContentType, createReportViewFromTableV2, downloadReport, HrDataTableProps, HrDataTable } from 'app2/views/shared';

import { useReportCols } from './useReportCols';
import { ReportTransactionsCommonFragment, reportTransactionsFilterOptions } from './generated';

type TransactionColumn = ReportTransactionsCommonFragment;

interface Props extends Partial<HrDataTableProps<TransactionColumn>> {
  type: EntityKind;
  entity: Pick<Company, 'id' | 'name' | 'timezone'> | Pick<Site, 'id' | 'name' | 'usingPriceTiers' | 'usingTrackingParameters' | 'timezone'>;
  season?: Pick<Season, 'id' | 'name'>;
  totals:DataTableColumn[];
}

export function ReportTransactions(props:Props) {
  const {type, entity, season, totals, ...remaining} = props;

  const [depositDates, setDepositDates] = React.useState<DateInput[]>();
  const { course } = useParams<{course:string}>();

  const isSite = type == EntityKind.Site;
  const usesSeason = isSite

  const table = React.useRef<DataTable<TransactionColumn>>(null);
  const totalVars = React.useMemo(() => props.totals.map(c => ({name: c.name, op: TotalOperator.Sum})), [props.totals]);
  const variables = { type: props.type, current: props.entity?.id, totals: totalVars, filters:course ? [{name:'course.id', op:FilterOperator.Eq, value: course}] : undefined};
  const cols = useReportCols({usesSeason, season, depositDates, isSite, getFilterOptions, entity});

  useLifecycle({onMount});

  function render() {
    return <HrDataTable<TransactionColumn>
    header={{icon: 'Book', title:'Transactions', primaryActions: renderDepositsDropdown(), options: renderOptions()}} totals={props.totals} filterSummaries={['site.name', 'season.name', props.totals.length == 3 ? '' : undefined]}
    table={{ref:table, cols, rowSelection:false, none:'No transactions to show'}} sortFilterType='v2' prefsKey='reportsTransactions'
    {...remaining}
    queryOptions={{pause: !(props.entity?.id && (!usesSeason || season?.id)), variables}} />
  }

  function renderOptions() {
    return [
      <MenuItem autoLoader onClick={() => download(ContentType.CSV)}>Download CSV</MenuItem>,
    ]
  }

  function renderDepositsDropdown() {
    return <HBox text='body' hAlign='center' vAlign='center' gap='$4'>Deposits<DatePicker period type='range' value={depositDates} onChange={onChooseExternalTransfer} /></HBox>
  }

  function onMount() {
    if (course) {
      props.prefs?.clear?.('reportsTransactions');
    }
  }

  function onChooseExternalTransfer(event:React.ChangeEvent<DatePicker>) {
    props.prefs?.clear?.('reportsTransactions');
    setDepositDates(castArray(event.target.value));
  }

  async function getFilterOptions(table:DataTable<TransactionColumn>, col:DataTableColumn<TransactionColumn>):Promise<Option[]> {
    const siteCol = table.allCols.find(col => col.name == 'site.name' as keyof TransactionColumn);
    // need to filter out blank and not blank options...probably this should be done on the server
    const selected = ((siteCol.filter || []) as string[]).filter(s => s && s != "NOT NULL");
    const [success, result] = await reportTransactionsFilterOptions({variables: {name: col.name, type, current: props.entity?.id, selected}});
    
    return result.data?.reportTransactionsFilterOptions as Option[] || [];
  }

  async function download(contentType:ContentType) {
    const report = createReportViewFromTableV2('reportTransactions', variables, table.current);
    return downloadReport(`${props.entity.name} transactions`, contentType, report);
  }

  return render();
}

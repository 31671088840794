import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerCourseSummaryReportQueryVariables = Types.Exact<{
  seasonId: Types.Scalars['ID'];
}>;

export type OrganizerCourseSummaryReportQuery = {
  siteCourseSummaryReport?:
    | {
        totalEnrollments?: number | null | undefined;
        totalParentSpend: number;
        totalProviderEarnings?: number | null | undefined;
        totalSchoolEarnings: number;
        data: Array<{ id: string; course: string; grades: string; period: string; provider: string; enrollments: number; listPrice: number; schoolEarnings: number } | null | undefined>;
      }
    | null
    | undefined;
};

export const OrganizerCourseSummaryReportDocument = gql`
  query OrganizerCourseSummaryReport($seasonId: ID!) {
    siteCourseSummaryReport(season: $seasonId) {
      data {
        id
        course
        grades
        period
        provider
        enrollments
        listPrice
        schoolEarnings
      }
      totalEnrollments
      totalParentSpend
      totalProviderEarnings
      totalSchoolEarnings
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type OrganizerCourseSummaryReportArgs = Omit<UseQueryArgs<OrganizerCourseSummaryReportQueryVariables, OrganizerCourseSummaryReportQuery>, 'query'>;

export function useOrganizerCourseSummaryReportQuery(options: OrganizerCourseSummaryReportArgs = {}) {
  return useQuery<OrganizerCourseSummaryReportQueryVariables, OrganizerCourseSummaryReportQuery>({ query: OrganizerCourseSummaryReportDocument, ...options });
}

export type OrganizerCourseSummaryReportOptions = ExecQueryOptions<OrganizerCourseSummaryReportQueryVariables>;

export function organizerCourseSummaryReport(options: OrganizerCourseSummaryReportOptions) {
  return executeQuery<OrganizerCourseSummaryReportQuery, OrganizerCourseSummaryReportQueryVariables>(OrganizerCourseSummaryReportDocument, options);
}

export type OrganizerCourseSummaryReportSelections = OrganizerCourseSummaryReportQuery['siteCourseSummaryReport'];

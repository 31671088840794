import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RevokeInviteMutationVariables = Types.Exact<{
  inviteToken: Types.Scalars['String'];
}>;

export type RevokeInviteMutation = { siteInviteRevoke?: { entity?: { id: string } | { id: string } | null | undefined } | null | undefined };

export const RevokeInviteDocument = gql`
  mutation revokeInvite($inviteToken: String!) {
    siteInviteRevoke(inviteToken: $inviteToken) {
      entity {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type RevokeInviteOptions = ExecMutationOptions<RevokeInviteMutationVariables>;

export function revokeInvite(options: RevokeInviteOptions) {
  return executeMutation<RevokeInviteMutation, RevokeInviteMutationVariables>(RevokeInviteDocument, options);
}

import * as React from 'react';

import { CourseUtils, ChangePricingPeriod, PriceConfigKind, Course } from 'app2/api';
import { Info, formatShortDate, useFormInfo } from 'app2/components';
import { CartCourse, coursePriceUnitLabels, EnrollmentConfig } from 'app2/views/shared-public';

import { EnrollmentDiscountForm, useEnrollmentBreakdown, getRecurringPricingFromBreakdown } from '../price-config';
import { DistinctEnrollmentsSelections } from '../../enrolled/gql';

interface Props {
  course:CartCourse;
  config?:EnrollmentConfig;
  type: 'invite' | 'add';
  changingEnrollment?:DistinctEnrollmentsSelections;
  effective?:ChangePricingPeriod;
}

export function EnrollmentChangeWarnings(props:Props) {
  const { course, config, type, changingEnrollment, effective } = props;
  const formInfo = useFormInfo<EnrollmentDiscountForm>();
  const form = formInfo.form;
  const breakdown = useEnrollmentBreakdown(course.id, form.values.format, form.values.rate, config, changingEnrollment, effective);

  function render() {
    const messages = getWarningMessages();

    return messages.length ? <Info type="warning">{messages}</Info> : <></>;
  }

  function getWarningMessages() {
    const messages = [];

    if (type == 'invite') {
      messages.push("A spot will be held for this student until their family enrolls.");
    }
    else
    if (config) {
      const configMesage = {
        [PriceConfigKind.Season]: () => `${getSeasonMessage()} Student will be enrolled in all activity dates.`,
        [PriceConfigKind.ConfigurableSeason]: () => `${getSeasonMessage()} Student will be enrolled in select activity dates.`,
        [PriceConfigKind.DropIn]: () => `Family’s payment method will be charged immediately. Student will be enrolled in this activity on ${formatShortDate(config?.dropIn?.dates)}.`,
        [PriceConfigKind.Usage]: () => 'Student will be enrolled in all activity dates and will be billed dynamically based on attendance.',
        [PriceConfigKind.Recurring]: () => {
          if (!breakdown?.standard) {
            return ''
          }

          const {first, date, amount} = getRecurringPricingFromBreakdown(breakdown);
          return `Family will be charged a ${first.prorated ? 'prorated ' : ' '}fee of ${first?.amount} ${first?.date} and ${amount}/${coursePriceUnitLabels[config.recurring.unit]} starting on ${date}. Student will be enrolled in this activity on ${formatShortDate(config?.recurring?.startDate)}.`;
        }
      }[config?.kind]();

      messages.push(configMesage);
    }

    return messages;
  }

  function getSeasonMessage() {
    const deposit = CourseUtils.usingDeposit((props.course as Course).rates);
    const installments = CourseUtils.usingInstallments((props.course as Course).rates);

    if (!deposit && !installments) {
      return `Family’s payment method will be charged immediately.`
    }

    if (deposit) {
      return `Family’s payment method will be charged immediately for the deposit.`
    }

    return `Any installment dates prior to today will be charged immediately to the family’s payment method`
  }

  return render();
}

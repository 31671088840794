import { isRealProduction, isAutomatedTestingEnv, isDevelopmentEnv } from 'app/helpers';

import { EventProperties, EventRecorder, Callback, doNothing } from './EventRecorder';
import { LoggedInUser } from './LoggedInUser';

const testing = !isRealProduction();

interface CustomerIoSdk {
  identify(args:{id:string, email:string}): void;
  reset():void;
  page(name:string, args?:any):void;
  track(event:string, args?:any):void;
}

declare var _cio:CustomerIoSdk;


export class CustomerIo extends EventRecorder {
  // this is test only id
  id:string = 'c6c6e8a41525332aeba2';

  constructor() {
    super();

    this.capture = !(isRealProduction() || isAutomatedTestingEnv() || isDevelopmentEnv());
    this.load();
  }

  load() {
    if (!this.capture) {
      return;
    }

    const script1 = document.createElement('script');
    script1.text = `
    var _cio = _cio || [];
    (function() {
        var a,b,c;a=function(f){return function(){_cio.push([f].
        concat(Array.prototype.slice.call(arguments,0)))}};b=["load","identify",
        "sidentify","track","page","on","off"];for(c=0;c<b.length;c++){_cio[b[c]]=a(b[c])};
        var t = document.createElement('script'),
            s = document.getElementsByTagName('script')[0];
        t.async = true;
        t.id    = 'cio-tracker';
        t.setAttribute('data-site-id', '${this.id}');
        t.setAttribute('data-use-array-params', 'true');
        t.setAttribute('data-use-in-app', 'true');
        t.src = 'https://assets.customer.io/assets/track.js';
        //If your account is in the EU, use:
        //t.src = 'https://assets.customer.io/assets/track-eu.js'
        s.parentNode.insertBefore(t, s);
    })();    
    `

    document.body.appendChild(script1);
  }

  get recordForHomeroomStaff() {
    return true;
  }

  identifyUser(user:LoggedInUser, callback: Callback = doNothing): void {
    _cio.identify({
      id: testing ? "applications@homeroom.com" : user.id.toString(),
      email: testing ? "applications@homeroom.com" : user.email
    })

    callback();
  }

  identifyNoUser(callback?: Callback): void {
    // the reset function doesnt' exist all the time (probably doesn't exist while its still in shim mode)
    if (!_cio.reset) {
      return;
    }

    _cio.reset();
    callback();
  }

  log(event: string, data?: EventProperties, callback?: Callback): void {
    const dataWithStandardProperties = {
      ...this.standardEventProperties,
      ...data,
    };

    _cio.track(event, dataWithStandardProperties);
    
    callback?.();
  }

  pageView(url: string): void {
    _cio.page(url, {url});

    super.pageView(url);
  }
}

import * as React from 'react';
import * as urql from 'urql';
import { omitBy, isUndefined } from 'lodash-es';

import { axios, CourseKind } from 'app2/api';
import { DataTable, orderedParseValues, MenuItem } from 'app2/components';
import { downloadFile, copyEmails, PartialHrDataTableProps, useCourseCols } from 'app2/views/shared';
import { courseKindBehavior } from 'app2/views/shared-public';

import { HrDataTable } from '../datatable';

import { CoursesQueryVars } from '../course/edit';

import { TableCoursesSelections, useTableCoursesQuery, TableCoursesQuery } from './generated'
import { AppendRowAction, CancelCourseAction, CloseEnrollmentAction, DeleteRowAction, OpenEnrollmentAction } from './table-actions';
import { useCourseTableState } from './useCourseTableState';
import { saveCoursesTable } from './saveCoursesTable';

export interface CourseTableProps extends Partial<PartialHrDataTableProps<TableCoursesSelections>> {
  tableRef?:React.RefObject<DataTable<TableCoursesSelections>>;
  queryVars:CoursesQueryVars;
  courseModel:ReturnType<typeof useCourseCols>;
  state?:ReturnType<typeof useCourseTableState>;
  showAppend?:boolean;
  saveChanges?:string;
  none?:React.ReactNode;
  defaultValues?:any;
  kind?:CourseKind;
}

export function CoursesTable(props:CourseTableProps) {
  const { tableRef, queryVars, courseModel, showAppend, saveChanges, none, defaultValues, header:headerProps, table:tableProps, kind, ...remaining } = props;

  const table = tableRef || React.useRef<DataTable<TableCoursesSelections>>();
  const tableState = props.state || useCourseTableState();
  const { editing, setEditing, coursesCollection, setCourses, coursesQueryResult, setCoursesQueryResult } = tableState;

  React.useEffect(() => {
    if (coursesCollection != table.current?.data) {
      setCourses(table.current?.data)
    }
  });

  function render() {
    const behavior = courseKindBehavior[kind];

    return <HrDataTable<TableCoursesSelections>
      header={{
        id: 'activities',
        icon: 'Book',
        title: 'Activities',
        units: behavior?.units || 'Activities',
        editing,
        onEdit: startEditing,
        onCancelEdits: stopEditing,
        onSaveEdits: saveChangesRegular,
        options: renderOptions(),
        secondaryActions: renderSecondaryActions(),
        saveChanges,
        ...omitBy(headerProps, isUndefined)
      }}
      table={{
        ref: table,
        cols:courseModel.cols,
        editable: true,
        lockable: true,
        onRowReset: courseModel.onFormReset,
        none: renderNoneMsg(),
        // finding and validating framework, teacher, etc is dependent on vendor
        // so we want that pasted first
        parseRow: orderedParseValues(['vendor', 'courseFramework', 'teacher',
          // since there's a relationship between all these (such as changing
          // course days will change the start/end dates), we have them paste
          // in this order that way the pasted values will overwrite the
          // calculated values
          'courseDays', 'enrollmentOpens', 'enrollmentCloses', 'startDate', 'endDate']),
          defaultValues: {
            kind: props.kind,
            // specify classesCount 0 because the class count
            // calculation can get triggered for a variety of reasons
            // and currently when determining if a row is blank we're
            // not ignoring readonly fields
            classesCount: 0,
            courseDays:[],  // need to set coursedays to an array due to a bug in formmodel validation, that needs a value to hang errors off of and it will create an object by default
            ...defaultValues
          },
        ...omitBy(tableProps, isUndefined)
      }}
      queryHook={useTableCoursesQuery}
      queryOptions={{ pause: editing ? true : false, variables: queryVars }}
      queryResponseHandler={handleQueryResponse}
      {...remaining}
    />
  }

  function renderNoneMsg() {
    if (!coursesQueryResult || !coursesQueryResult.items) {
      return;
    }

    const total = props.header.total ?? coursesQueryResult.totals?.[0];
    const filtered = coursesQueryResult.items.length != total;

    if (filtered) {
      return `Showing 0 of ${total} activities.  Update the column filters to see more.`
    }

    return none;
  }

  function renderOptions() {
    if (editing) {
      return [];
    }

    return [
      <MenuItem onClick={downloadCSV}>Download CSV</MenuItem>,
      <MenuItem onClick={copyProviderEmailsToClipboard}>Copy provider emails</MenuItem>
    ]
  }

  function renderSecondaryActions() {
    return editing ? renderEditingActions() : renderNonEditingActions();
  }

  function renderNonEditingActions() {
    return [
      <CancelCourseAction table={table.current} kind={props.kind} />,
      <OpenEnrollmentAction table={table.current} kind={props.kind} />,
      <CloseEnrollmentAction table={table.current} kind={props.kind} />,
    ];
  }

  function renderEditingActions() {
    return [
      showAppend && <AppendRowAction table={table.current} />,
      <DeleteRowAction table={table.current} />,
    ];
  }

  function handleQueryResponse(response:urql.UseQueryState<TableCoursesQuery>) {
    const newResult = response.data?.seasonCourses;

    if (coursesQueryResult != newResult) {
      setCoursesQueryResult(newResult);
    }

    return newResult;
  }

  function startEditing() {
    setEditing(true);
  }

  function stopEditing() {
    setEditing(false);
  }

  async function saveChangesRegular() {
    return saveCoursesTable(table.current, queryVars, tableState, false);
  }

  function downloadCSV() {
    return downloadFile(
      axios.get(`/seasons/${queryVars.seasonId}/export_courses`, {
        responseType: 'blob'
      }),
      'text/csv'
    );
  }

  function copyProviderEmailsToClipboard() {
    const courses = table.current.allItems;
    copyEmails(courses.map(c => c.vendor?.email));
  }

  return render();
}
import { DataTableColumn } from 'app2/components';

export const byActivity = {
  title: [
    {cols:['course.disambiguatedName', 'weekday', 'variables.date', 'groups'], separator: ' - '},
    {cols:['formattedDaysAndTimes', 'course.room', 'course.season.name', 'course.site.name', 'course.teacher.name'], separator: '; '} 
  ],
  groups: ['course.disambiguatedName', 'weekday', 'groups'],
  // only one column sort is supported for now
  sort: ['student.lastName', 'student.firstName'],
  cols: ['student.firstName', 'student.lastName', 'student.classroom.displayName', 
    'student.grade', 'otherEnrollments', 'rosterPeriod', 'added', 'student.formattedContacts', 'student.authorizedPickupContacts', 
    'pickup', 'dismissal', 'student.formattedMedicalAndNotes'] as (string | DataTableColumn)[],
}

import * as React from 'react'
import { SystemStyleObject } from '@styled-system/css'

import { HBox, VBox, BoxProps } from '../Box';
import { Table, Thead, Tr, Td } from '../Tags';
import { Body, Text, TextType } from '../Text';

import { Icon } from '../icons';
import { useBreakpoints } from '../breakpoints';
import { theme } from '../theme';

import { FieldError } from './FieldError'

// CardTable displays tabular data:
//  desktop/table - displays a table
//  phone - displays each row as a card
//
// takes an array of labels and an array of rows.  
// each row should have an array of values that matches
// the dimensions of the labels array.
// 
// buttonCols specifies the number of columns on the
// right side that are "buttons" and should be disaplyed in
// mobile view as part of the first label.  for example:
//
// on desktop (where [x] is a delete button and buttonCols = 1)
// name | phone | 
// joe  | 555   | [x]
// jane | 444   | [x]
//
// on phone
// name       [x]
// joe
// 
// phone
// 555
//
// name       [x]
// jane
// 
// phone
// 444

export type CardTableRow = React.ReactNode[] & {error?:React.ReactNode};

export interface CardTableProps extends BoxProps {
  // errors that apply to the entire table, not specific to a row
  errors?:string[];
  labels:React.ReactNode[];
  // properties to be added to the columns
  // when in table mode
  cols?:any[];
  labelType:TextType;
  rows:CardTableRow[];
  // buttonCols is the number of values in each row that stays a column on mobile
  buttonCols?:number;
  maxCols?:number;
  separator?:boolean;
  // show a 'None' label when there's no entries
  none?:React.ReactNode;
  // vertical alignment for the table rows
  align?:'top' | 'middle' | 'baseline',
  // normally CardTable will display as table on desktop otherwise a card but you can override this and pick one
  mode?:'auto' | 'table' | 'card';
  // creates a table that has a
  // background, is inset, and scrolls
  bordered?:boolean;
  // whether each column (in table mode) has the same width (defaults to true)
  equalWidths?:boolean;
  numbered?:boolean;
  // start numbering at, defaults to 1
  start?:number;
  // in desktop mode, whether to show the headers
  // they always show in mobile mode (otherwise just make 
  // label empty will omit it for both modes)
  headers?:boolean;
  // additional props for the table in table mode
  table?:React.CSSProperties;
}

export function CardTable(props:CardTableProps) {
  const {errors, labels, cols, labelType, rows, buttonCols, maxCols, separator, none, children, align, mode, bordered, equalWidths, numbered, start, headers, table, ...remaining} = props;
  const breakpoint = useBreakpoints();
  const styles = bordered ? {hAlign:'left' as any, borderRadius:"standard", bg:"backgroundMediumGrey", pt:"$30", px:"$30", pb:"$10", overflowY:'auto' as any, minHeight:'150px'} : {hAlign:'left' as any};

  if (!rows) {
    return <VBox {...styles} {...remaining}>
      {renderNone(props)}
      {children}
    </VBox>;
  }

  return <VBox {...styles} {...remaining}>
    {(breakpoint == 0 && mode == 'auto' && props.labels.length > 1) || mode == 'card' ? renderPhone(props) : renderNonPhone(props)}
    {children}
  </VBox>
}

function renderPhone<T>(props:CardTableProps) {
  const tableErrors = !!props.errors?.length;
  const separator = {mb:'$30', borderBottom:'solid 1px', borderColor:'border'};

  if (props.rows.length == 0) {
    return renderNone(props);
  }

  return props.rows.map((row, index) => {
    let buttons:React.ReactNode;
    const rowNo = index + props.start - 1;
    const lastRow = index == props.rows.length - 1;

    return <HBox key={index} data-row={rowNo} width='100%' vAlign='baseline' {...index != props.rows.length - 1 ? separator : {}}>
      {(props.numbered || tableErrors) && <Text text={props.labelType} width='25px'>{props.numbered ? renderRowNumber(row, rowNo) : ''}{tableErrors ? renderErrorIcon(props.errors) : ''}</Text>}
      <VBox flex={1}>
        {props.labels.map((label, fieldIndex) => {
          const lastField = fieldIndex == props.labels.length - props.buttonCols - 1;

          if (fieldIndex == 0 && props.buttonCols) {
            buttons = <React.Fragment key={fieldIndex}>
              <HBox vAlign='center' hItemSpace='$8'>{row.slice(props.labels.length - props.buttonCols)}</HBox>
            </React.Fragment>;
          }

          if (fieldIndex >= props.labels.length - props.buttonCols) {
            return '';
          }

          return <VBox key={fieldIndex} pb={lastRow && lastField ? undefined : '$30'} width='100%' hideEmpty>
            {label && <HBox pb='$8' width='100%' vAlign='baseline'><Text text={props.labelType} whiteSpace='nowrap' color={tableErrors ? 'error' : undefined}>{label}</Text></HBox>}
            <Body>{row[fieldIndex]}</Body>
          </VBox>
        })}
      </VBox>
      {buttons}
    </HBox>
  })
}

function renderNonPhone<T>(props:CardTableProps) {
  const maxCols = Number.isFinite(props.maxCols) ? props.maxCols : props.labels.length - props.buttonCols;

  if (maxCols == 0) {
    return renderPhone(props);
  }

  const labelCols = props.labels.slice(0, maxCols);
  const buttonColsStart = props.labels.length - props.buttonCols;
  const buttonCols = props.labels.slice(buttonColsStart);
  const remainingCols = props.labels.slice(maxCols, buttonColsStart);
  const multirow = remainingCols.length > 0;
  const theadStyles = props.bordered ? {zIndex:1, position: 'sticky' as any, top:'-30px', bg: 'backgroundMediumGrey'} : undefined;
  const tableErrors = !!props.errors?.length;
  const css = props.separator 
    ? multirow 
      ? separatorCssMultirow 
      : separatorCssSinglerow 
    : cssRegular;

  
  const colsWithoutWidth = labelCols.filter((l, index) => index < maxCols && !(props.cols?.[index] && (('width' in props.cols?.[index]) || ('minWidth' in props.cols?.[index])))).length;
  
  return <Table width='100%' css={css} style={props.table}>
    {props.rows.length != 0 && 
    <Thead {...theadStyles}>
      <Tr>
        {(props.numbered || tableErrors) && <Td pb='$8' verticalAlign='baseline'>{tableErrors ? renderErrorIcon(props.errors) : ''}</Td>}
        {labelCols.map((label, index) => <Td key={index} text={props.labelType} width={props.equalWidths ? (100 / colsWithoutWidth) + '%' : undefined} pb={props.headers ? '$8': undefined} verticalAlign='baseline' color={tableErrors ? 'error' : undefined} {...props.cols?.[index]}>{props.headers && label}</Td>)}
        {buttonCols.length ? <Td pb={props.headers ? '$8': undefined} verticalAlign='baseline' {...props.cols?.[labelCols.length]} /> : undefined}
      </Tr>
    </Thead>}
    <tbody>
    {props.rows.length == 0
    ? <Tr verticalAlign={props.align}><Td /><Td colSpan={labelCols.length + buttonCols.length}>{renderNone(props)}</Td></Tr>
    : props.rows.map((row, index) => {
      const rowNo = index + props.start - 1;
      return <React.Fragment key={index}>
        <Tr verticalAlign={props.align} data-row={rowNo}>
          {(props.numbered || tableErrors) && <Td text='body' minWidth='22px' width='22px' pr='$8' pb={row.error ? '$4' : '$20'}>{props.numbered ? renderRowNumber(row, rowNo) : ''}</Td>}
          {labelCols.map((label, fieldIndex) => {return <Td key={fieldIndex} data-field={label} text='body' pb={row.error ? '$4' : '$20'} pr='$8'>{row[fieldIndex]}</Td>})}
          {buttonCols.map((label, fieldIndex) => {return <Td key={'bc' + fieldIndex} text='body' pb={row.error ? '$4' : '$20'} >{row[buttonColsStart + fieldIndex]}</Td>})}
        </Tr>
        {row.error ? <Tr>{props.numbered && <Td />}<Td colSpan={labelCols.length + buttonCols.length} pb='$16'>{renderRowError(row.error)}</Td></Tr> : null}
        {multirow ? <Tr verticalAlign={props.align}>
          <Td />
          <Td text='body' colSpan={labelCols.length || 1}>
            {remainingCols.map((label, fieldIndex) => <VBox key={fieldIndex} data-field={label} text='body'>
                {label && <Text text={props.labelType} mb='$8'>{label}</Text>}
                <Body mb='$30' preserveWhitespace hideEmpty>{row[maxCols + fieldIndex]}</Body>
              </VBox>)}
          </Td>
        </Tr> : null}
      </React.Fragment>
    })}
    </tbody>
  </Table>
}

function renderRowNumber(row:CardTableRow, index:number) {
  return !row.error
    ? (index + 1) + '.'
     // the magic numbers of top and mt are needed because the baseline alignment is causing the icon to be to high
    : renderErrorIcon(row.error)
}

function renderErrorIcon(error:React.ReactNode) {
  return <Icon name='AlertCircle' alt='Error' size='small' color='error' position='relative' top='2px' mt='-2px' tooltip={error} />;
}

function renderRowError(error:React.ReactNode) {
  return <FieldError error={error} />
}

function renderNone(props:CardTableProps) {
  const tableErrors = !!props.errors?.length;

  if ((props.none === '' || props.none === false || props.none === null) && !tableErrors) {
    return '';
  }

  return <Body width='100%'>{tableErrors ? renderErrorIcon(props.errors) : ''}{props.none || 'None'}</Body>;
}

CardTable.defaultProps = {
  labelType: 'subtitle2',
  start: 1,
  buttonCols: 0,
  align: 'top',
  mode: 'auto',
  equalWidths: true,
  numbered: true,
  headers: true
}

const cssRegular:SystemStyleObject = {
  ['tbody > tr:last-child > td']:{
    paddingBottom:'unset',
  },
}

const separatorCssSinglerow:SystemStyleObject = {
  ['tr > td']:{
    paddingBottom:'60px'
  },
  ['tr:last-child > td']:{
    paddingBottom:'unset',
  },
  ['tr']:{
    position: 'relative',
  },
  ['tr::after']:{
    content: '""',
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    borderBottom: 'solid 1px ' + theme.colors.border,
    marginBottom: '30px'
  },
  ['tr:last-child::after']:{
    borderBottom: 'unset'
  }
}

const separatorCssMultirow:SystemStyleObject = {
  ['tr:nth-child(even) > *:last-child']:{
    paddingBottom:'30px'
  },
  ['tr:nth-child(even)']:{
    position: 'relative',
  },
  ['tr:nth-child(even)::after']:{
    content: '""',
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    borderBottom: 'solid 1px ' + theme.colors.border,
    marginBottom: '30px'
  },
  ['tr:nth-child(even):last-child::after']:{
    borderBottom: 'unset'
  },
  ['tr:last-child > td']:{
    paddingBottom:'unset',
  },
}

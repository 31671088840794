import * as React from 'react';
import * as ReactIs from 'react-is';
import { useParams, useLocation } from 'react-router';

import { usePreference } from '../preferences';

import { TabStripTab } from './TabStripTab';
import { TabStripHookProps } from './TabStripHookProps';
import { UseSelectedTabState } from './UseSelectedTabState';

export function useSelectedTab(props:TabStripHookProps):UseSelectedTabState {
  const [tab, setTab] = usePreference(props.pref, props.prefName, props.selected);
  const {baseUrl, urlParameter, mode} = props;
  const params = useParams() as any;
  const usingUrlParameter = !(!urlParameter || !baseUrl);
  let tabNameFromUrl:string;
  const location = useLocation();
  const hash = location.hash;
  const {tabs, selected, hideTabs} = applyUrlParameter(normalizeTabs(props.tabs));

  function normalizeTabs(tabs:(string | React.ReactElement | TabStripTab)[]) {
    return props.tabs?.filter?.(tab => !!tab).map?.(tabOrString => {
      if (typeof tabOrString == 'string' || ReactIs.isElement(tabOrString)) {
        return {
          label: tabOrString
        }
      }
      else {
        return tabOrString;
      }
    }) || [];
  }

  function applyUrlParameter(tabs:TabStripTab[]) {
    tabs = tabs?.map(t => ({disabled: props.disabled, ...t}));

    const controlled = props.selected !== undefined;
    const curSelected = Math.max(0, Math.min((controlled ? props.selected : tab) || 0, tabs.length - 1))
    let hideTabs = mode == 'hide-tabs' || ((mode === undefined || mode == 'hide-single-tab') && tabs.length == 1)

    if (!usingUrlParameter) {
      return {tabs, selected:curSelected, hideTabs};
    }

    tabNameFromUrl = urlParameter === 'hash' ? hash.substring(1) : params[urlParameter];
    const tabIndexFromUrl = tabNameFromUrl === undefined ? -1 : tabs.findIndex(tab => tab.name == tabNameFromUrl || tab.name + props.urlHideSuffix == tabNameFromUrl);
    // if we can't find the tab from the url, then we back down to the controlled property if present.  
    // if not present then we go to the first tab, because if the user goes back in history, then
    // the current selected tab is wrong, and it should be the first tab 
    const selected = Math.min(tabs.length - 1, (tabIndexFromUrl != -1 ? tabIndexFromUrl : controlled ? curSelected : props.pref?.load(props.prefName)) as number || 0);
    const isUrlHideSuffix = (tabs[selected] && props.urlHideSuffix && tabs[selected].name + props.urlHideSuffix == tabNameFromUrl)
    hideTabs = hideTabs || isUrlHideSuffix;

    if (baseUrl) {
      const isHash = props.urlParameter == 'hash';
      const hasHash = baseUrl.indexOf('#:hash');

      tabs.forEach(tab => {
        tab.to = isHash && !hasHash
          ? baseUrl + '#' + tab.name + (hideTabs && isUrlHideSuffix ? props.urlHideSuffix : '')
          : baseUrl.replace(':' + urlParameter, tab.name + (hideTabs && isUrlHideSuffix ? props.urlHideSuffix : ''));
      });
    }

    return {tabs, selected, hideTabs};
  }

  return {selected, tabs, setTab, hideTabs, tabNameFromUrl}
}

import * as React from 'react'

import { Link, Text, VBox, Img } from 'app2/components'
import { marketingUrl } from 'app2/views/shared-public';

import { Section } from './Section'
import whoWeArea from './who-we-are.png'

export function Who() {
  return <Section layout={['vbox', 'hbox']} itemWidth={['100%', '50%']}>
    <Img src={whoWeArea} height={['307px', '402px']} style={{objectFit:'cover'}} />
    <VBox hAlign='left' vAlign='center' p={['$16', '$40', '$60']} bg='marketingBg'>
      <Text text='heading2' mb='$8'>Who we are</Text>
      <Text text='body' mb='$20' fontSize='16px'>We are former afterschool providers, teachers, parents and PTO leaders who believe every child deserves access to quality afterschool programs.  Learn more about the team and how we support schools nationwide.</Text>
      <Link button='primary' to={marketingUrl('about-us')}>About us</Link>
    </VBox>
  </Section>
}

import * as React from 'react';

import { InputMaybe, Scalars, SortDirectionEnum } from 'app2/api';
import { DataTable, DataTableColumn, DataTableColumnSort, useStateWithDeps } from 'app2/components';

// converst HR datatable columns to regular datatable columns
// and returns the sort and filter params that resided on the columns
// to be used for the query api

export function useSortFilter<T>(hrCols: DataTableColumn<T>[]) {
  const {cols: standardizedCols, sortFilterMap} = React.useMemo(() => convertHrColsToRegularCols(hrCols), [hrCols]);
  const [cols, setCols] = useStateWithDeps(standardizedCols, [standardizedCols]);

  const sortFilter = React.useMemo(() => buildSortFilter(cols), [cols]);
  const handleSortFilter = React.useCallback((table: DataTable<T>) => {
    setCols(table.allCols);
  }, [standardizedCols]);

  function buildSortFilter(propsCols: DataTableColumn<T>[], curCols?: DataTableColumn<T>[]) {
    return {cols: propsCols, sort:extractSort(curCols || propsCols, sortFilterMap), filters:extractFilter(curCols || propsCols, sortFilterMap)}
  }

  return { cols, filters:sortFilter.filters, sort:sortFilter.sort, handleSortFilter };
}

interface TableFilters {
  [by: string]: InputMaybe<Array<Scalars[keyof Scalars]>>;
}

export function extractFilter<T>(cols: DataTableColumn<T>[], mapping: QueryApiSortFilterParamMapping) {
  return cols.reduce((acc: TableFilters, col: DataTableColumn<T>) => {
    const by = mapping[col.name as string]?.filter;
    let filter = col.filter;

    if (filter && by) {
      // The filter list is sorted to help ensure cached results are used regardless of the order in which the options were selected by the user.
      acc[by] = filter.slice().filter(f => f !== undefined).sort();
    }
    return acc;
  }, {});
}

export type TableFilter = ReturnType<typeof extractFilter>;

const sortDirectionMapping = {
  [DataTableColumnSort.ascending]: SortDirectionEnum.Asc,
  [DataTableColumnSort.descending]: SortDirectionEnum.Desc
};

export interface QueryApiSortFilterParamMapping {
  [name: string]: {
    sort?: string;
    filter?: string;
  };
}

export function extractSort<T>(cols: DataTableColumn<T>[], mapping: QueryApiSortFilterParamMapping) {
  const col = cols.find(c => c.sort);

  // in case the saved sort is no longer present in the columns
  if (!col) {
    return;
  }

  // in case the column is no longer sortable
  if (!mapping[col.name as string]) {
    return;
  }

  return { by: mapping[col.name as string].sort, direction: sortDirectionMapping[col.sort as DataTableColumnSort.ascending | DataTableColumnSort.descending] };
}

export type TableSort = ReturnType<typeof extractSort>;

// our HR column definitions have extra attributes on them to help map the columns to the query
// api sort and filter.  this removes those attributes and returns a map of columns to those
// attributes so we can use them to build the sort and filter params for the query api

export function convertHrColsToRegularCols<T>(cols: DataTableColumn<T>[]) {
  // clone the cols because we modify them below
  cols = cols.map(col => {return {...col}});

  return {cols, sortFilterMap: cols.reduce((acc: QueryApiSortFilterParamMapping, col: DataTableColumn<T>) => {
    if (col.sortParam) {
      (acc[col.name as string] ??= {}).sort = col.sortParam;
      delete col.sortParam;
    }

    if (col.filterParam) {
      (acc[col.name as string] ??= {}).filter = col.filterParam;
      delete col.filterParam;
    }

    return acc
  }, {})}
}

// gets the current sort/filter from the table columns
// you need to pass in the original columns before useSortFilter
// was applied, because it strips off all the API params
// or the results of it (a QueryApiSortFilterParamMapping)

export function extractTableSortFilter<T>(cols:DataTableColumn<T>[], mappingOrDefinitions:DataTableColumn<T>[] | QueryApiSortFilterParamMapping) {
  const sortFilterMap:QueryApiSortFilterParamMapping = Array.isArray(mappingOrDefinitions)
    ? convertHrColsToRegularCols(mappingOrDefinitions).sortFilterMap
    : mappingOrDefinitions;

  const sort = extractSort(cols, sortFilterMap);
  const filter = extractFilter(cols, sortFilterMap);

  return {sort, filter};
}

import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerTeamQueryVariables = Types.Exact<{
  siteId: Types.Scalars['ID'];
}>;

export type OrganizerTeamQuery = {
  site?:
    | {
        id: string;
        teamInvites?: Array<{ id: string; token: string; email: string; sentAt: string; role: Types.MemberRole } | null | undefined> | null | undefined;
        teamMembers?: Array<{ id: string; email: string; name: string; role: Types.MemberRole; isContact: boolean }> | null | undefined;
      }
    | null
    | undefined;
};

export const OrganizerTeamDocument = gql`
  query OrganizerTeam($siteId: ID!) {
    site(site: $siteId) {
      id
      teamInvites {
        id
        token
        email
        sentAt
        role
      }
      teamMembers {
        id
        email
        name
        role
        isContact
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type OrganizerTeamArgs = Omit<UseQueryArgs<OrganizerTeamQueryVariables, OrganizerTeamQuery>, 'query'>;

export function useOrganizerTeamQuery(options: OrganizerTeamArgs = {}) {
  return useQuery<OrganizerTeamQueryVariables, OrganizerTeamQuery>({ query: OrganizerTeamDocument, ...options });
}

export type OrganizerTeamOptions = ExecQueryOptions<OrganizerTeamQueryVariables>;

export function organizerTeam(options: OrganizerTeamOptions) {
  return executeQuery<OrganizerTeamQuery, OrganizerTeamQueryVariables>(OrganizerTeamDocument, options);
}

export type OrganizerTeamSelections = OrganizerTeamQuery['site'];

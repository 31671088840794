import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
import { OptionFieldsFragmentDoc } from '../../gql/generated/optionFields.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EnrollmentsFilterOptionsQueryVariables = Types.Exact<{
  groupingId: Types.Scalars['ID'];
  groupingKind: Types.RosterGroupingKind;
  enrollmentStatus: Types.EnrollmentStatusFilter;
  filterKind: Types.RosterFilterKind;
}>;

export type EnrollmentsFilterOptionsQuery = { rosterFilterOptions?: Array<{ label: string; value?: string | number | null | undefined } | null | undefined> | null | undefined };

export const EnrollmentsFilterOptionsDocument = gql`
  query EnrollmentsFilterOptions($groupingId: ID!, $groupingKind: RosterGroupingKind!, $enrollmentStatus: EnrollmentStatusFilter!, $filterKind: RosterFilterKind!)
    @urql(additionalTypenames: ["RosterItem", "Enrollment"]) {
    rosterFilterOptions(groupingId: $groupingId, groupingKind: $groupingKind, enrollmentStatus: $enrollmentStatus, filterKind: $filterKind) {
      ...optionFields
    }
  }
  ${OptionFieldsFragmentDoc}
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type EnrollmentsFilterOptionsArgs = Omit<UseQueryArgs<EnrollmentsFilterOptionsQueryVariables, EnrollmentsFilterOptionsQuery>, 'query'>;

export function useEnrollmentsFilterOptionsQuery(options: EnrollmentsFilterOptionsArgs = {}) {
  return useQuery<EnrollmentsFilterOptionsQueryVariables, EnrollmentsFilterOptionsQuery>({ query: EnrollmentsFilterOptionsDocument, ...options });
}

export type EnrollmentsFilterOptionsOptions = ExecQueryOptions<EnrollmentsFilterOptionsQueryVariables>;

export function enrollmentsFilterOptions(options: EnrollmentsFilterOptionsOptions) {
  return executeQuery<EnrollmentsFilterOptionsQuery, EnrollmentsFilterOptionsQueryVariables>(EnrollmentsFilterOptionsDocument, options);
}

export type EnrollmentsFilterOptionsSelections = EnrollmentsFilterOptionsQuery['rosterFilterOptions'][0];

(EnrollmentsFilterOptionsDocument as any).additionalTypenames = ['Option', 'RosterItem', 'Enrollment'];

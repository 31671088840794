import { colId, colReportId, DataTable, GroupedDataTableHandle, DataTableColumn } from 'app2/components';

import { extractTableSortFilter, QueryApiSortFilterParamMapping, buildSortFilter } from '..'

import { ReportTitleRow } from './ReportTitleRow';
import { ReportGroup } from './ReportGroup';
import { ReportColumn } from './ReportColumn';
import { ReportSort } from './ReportSort';
import { ReportFilter } from './ReportFilter';

export interface ReportView {
  title?:ReportTitleRow[];
  groups?:ReportGroup[];
  cols:ReportColumn[];
  query:string;
  variables?: {
    sorts?:ReportSort[];
    filters?:ReportFilter[];  
  } & any;
}

export function createReportViewFromTable(query:string, variables:any, groupedOrRegularTable:DataTable | GroupedDataTableHandle, mappingOrDefinitions:DataTableColumn[] | QueryApiSortFilterParamMapping):ReportView {
  const groupedTable = (groupedOrRegularTable as GroupedDataTableHandle).tables ? (groupedOrRegularTable as GroupedDataTableHandle) : null;
  const table = groupedTable?.tables?.[0]?.current || groupedOrRegularTable as DataTable;

  const { sort, filter } = extractTableSortFilter(table.allCols, mappingOrDefinitions);
  const groups = groupedTable?.props?.groupBy?.map?.(c => groupedTable.props.cols.find(col => colId(col) === colId(c)))?.map(col => ({name: (col.name || col.id).toString(), label: col.label.toString()}))

  return {
    query,
    variables: {...variables, sort, filters: filter},
    title: [],
    groups, 
    cols: reportCols(table.cols)
  }
}

export function createReportViewFromTableV2(query:string, variables:any, groupedOrRegularTable:DataTable | GroupedDataTableHandle):ReportView {
  const groupedTable = (groupedOrRegularTable as GroupedDataTableHandle).tables ? (groupedOrRegularTable as GroupedDataTableHandle) : null;
  const table = groupedTable?.tables?.[0]?.current || groupedOrRegularTable as DataTable;

  const sortFilter = buildSortFilter(table.allCols, {variables});
  const groups = groupedTable?.props?.groupBy?.map?.(c => groupedTable.props.cols.find(col => colId(col) === colId(c)))?.map(col => ({name: colReportId(col), label: col.label.toString()}))

  return {
    query,
    variables: {...variables, ...sortFilter},
    title: [],
    groups, 
    cols: reportCols(table.cols)
  }
}


export function reportCols<T = any>(cols:DataTableColumn<T>[]) {
  return cols.filter(c => !c.hidden).map(col => ({name: colReportId(col), label: col.label.toString(), width: Number(((col.width || 100) * PIXELS_TO_POINTS_RATIO).toFixed(2))}))
}

// the actual ration is 2/3 but since the server is using a smaller font and less
// padding then the ui, more fits in.  so we make the ratio a little smaller to
// to allow more to fit in the ui columns to make it look more like the server
export const PIXELS_TO_POINTS_RATIO = 15/24;
export const POINTS_TO_PIXELS_RATIO = 1/PIXELS_TO_POINTS_RATIO;

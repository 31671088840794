import { CourseKind, CourseKindGroup, RateType } from 'app2/api';
import { AllIconNames } from 'app2/components';

export interface CourseKindBehavior {
  group:CourseKindGroup;
  table:CourseKind;
  icon:AllIconNames;
  showCapacity:boolean;
  // indicates that a course's start/end dates will match the seasons start/end
  seasonDates:boolean;
  sessionType: 'days' | 'dates' | 'time-slots' | 'none';
  registration: {
    // week is deprecated
    group:'day' | 'week' | 'grade' | 'none';
    sort: 'time' | 'date' | 'grade';
  },
  rostersGroupByDay: boolean;
  allStudentDetails:boolean;
  // indicates when displaying activity info, if it should show the provider
  // name if the provider is the organizer/provider
  showCompanyName:boolean;
  registrationLayout:'tile' | 'row';
  showSessionCount:boolean;
  pricingDefault:RateType;
  kinds:CourseKind[];
  label:string;
  name:string;
  order?:number;
  // indicates this type of activity is for org + provider only
  siteCompany?:boolean;
  // if an activity is not attendable then its sessions will not show in the schedule
  attendable?:boolean;
  // if an activity is not registerable then it will be marked searchable = false by default
  registerable?:boolean;
  units?:string;
  management?: {
    info?:boolean;
    enrollments?:boolean;
    billing?:boolean;
    attendance?:boolean;
    messages?:boolean;
    reviews?:boolean;
    preview?:boolean;
    options?:boolean;
    customFields?:boolean;
    calendar?:boolean;
    addStudent?:boolean;
    move:boolean;
  },
  fields: {
    courseDays?:boolean;
    courseTimes?:boolean;
    enrollmentPeriod?:boolean;
    supplies?:boolean;
    framework?:boolean;
    grades?:boolean;
    dob?:boolean;
    dismissal?:boolean;
    teamMaxCapacity?: boolean;
    pricing?: {
      none?:boolean;
      season?: {
        basic?:boolean;
        installments?:boolean;
        days?:boolean;
      },
      dropIn?:boolean;
      recurring?:boolean;
      usage?:boolean;
    }
  },
  defaults: {
    minCapacity?:number;
    maxCapacity?:number;
    teamMaxCapacity?:number;
  },
  terms?:{ 
    name: string,
    teacher:string;
    price?:string;
    participant?:string;
    family?:string;
    create?:string;
    delete?:string;
    publish?:string;
    session:'class' | 'session';
    season:'season' | 'session';
  }
}

const base:Partial<CourseKindBehavior> = {
  icon: 'BookOpen',
  showCapacity: true,
  seasonDates: true,
  sessionType: 'days',
  registration: {
    group: 'day',
    sort: 'time'
  },
  rostersGroupByDay: false,
  allStudentDetails: true,
  showCompanyName: true,
  registrationLayout: 'tile',
  showSessionCount: true,
  pricingDefault: RateType.basic,
  siteCompany: false,
  attendable: true,
  registerable: true,
  units: 'Activity',
  terms: {
    name: 'Activity name',
    teacher: 'Instructor',
    price: 'Season price',
    participant: 'student',
    family: 'family',
    session: 'class',
    season:'season',
    },
  management: {
    info: true,
    enrollments: true,
    billing: true,
    attendance:true,
    messages:true,
    reviews:true,
    preview:true,
    options:true,
    customFields:true,
    calendar:true,
    addStudent: true,
    move: true,
  },
  fields: {
    courseDays:true,
    courseTimes:false,
    enrollmentPeriod:true,
    supplies: true,
    grades:true,
    dob:true,
    dismissal:true,
    teamMaxCapacity: false,
    pricing: {
      season: {
        basic:true,
        installments:true,
        days:true
      },
      dropIn:true,
      recurring:true,
      usage:true
    }
  }
}

const athletics:Partial<CourseKindBehavior> = {
  ...base,
  registration: {
    group: 'none',
    sort: 'date'
  },
  management: {
    ...base.management,
    move: false,
  },
  showCapacity: false,
  seasonDates: true,
  sessionType: 'none',
  showCompanyName: false,
  showSessionCount: false,
  siteCompany: true,
  terms: {
    name: 'Name',
    teacher: 'Coach',
    price: 'Price',
    participant: 'student',
    family: 'family',
    session: 'session',
    season:'session',
  },
}

export const courseKindBehavior:Record<CourseKindGroup | CourseKind, CourseKindBehavior> = {
  [CourseKindGroup.Enrichment]: {
    ...base,
    group:CourseKindGroup.Enrichment,
    table:CourseKind.Enrichment,
    kinds: [CourseKind.Enrichment],
    label: 'Enrichment',
    name: 'enrichment',
    order:6,
  }, 
  [CourseKindGroup.Camp]: {
    ...base,
    group:CourseKindGroup.Camp,
    table:CourseKind.Camp,
    icon: 'Sun',
    showCapacity: true,
    seasonDates: false,
    sessionType: 'dates',
    registration: {
      // week is deprecated and not implemented
      group: 'week',
      sort: 'date'
    },
    rostersGroupByDay: false,
    allStudentDetails: false,
    showCompanyName: false,
    registrationLayout: 'row',
    showSessionCount: false,
    pricingDefault: RateType.basic,
    kinds: [CourseKind.Camp],
    label: 'Camp',
    name: 'camp',
    order:5,
    units: 'Activity',
    terms: {
      ...base.terms,
      session: 'session',
      season:'session',
    }
  },
  [CourseKindGroup.ExtendedCare]: {
    ...base,
    group:CourseKindGroup.ExtendedCare,
    table:CourseKind.ExtendedCare,
    icon: 'Bell',
    showCapacity: false,
    seasonDates: true,
    sessionType: 'days', 
    registration: {
      group: 'none',
      sort: 'date'
    },
    rostersGroupByDay: true,
    allStudentDetails: true,
    showCompanyName: false,
    registrationLayout: 'row',
    showSessionCount: false,
    pricingDefault: RateType.advanced,
    kinds: [CourseKind.ExtendedCare],
    label: 'Extended care',
    name: 'extended-care',
    order:7,
    units: 'Activity',
    terms: {
      ...base.terms,
      session: 'class',
      season:'season',  
    }
  },
  [CourseKindGroup.LessonSet]: {
    ...base,
    group:CourseKindGroup.LessonSet,
    table:CourseKind.LessonSet,
    kinds: [CourseKind.LessonSet],
    label: 'Lessons',
    name: 'lessons',
    icon: 'Target',
    units: 'Lesson',
    registration: {
      group: 'none',
      sort: 'grade'
    },
    order:8,
    sessionType: 'time-slots',
    registrationLayout: 'row',
  }, 
  [CourseKind.TimeSlot]: {
    ...base,
    group:CourseKindGroup.LessonSet,
    table:CourseKind.TimeSlot,
    kinds: [CourseKind.TimeSlot],
    label: 'Lessons',
    name: 'lessons',
    icon: 'Target',
    units: 'Lesson',
    registration: {
      group: 'none',
      sort: 'grade'
    },
    order:8,
    sessionType: 'days',
    registrationLayout: 'row',
  }, 
  [CourseKindGroup.Athletics]: {
    ...athletics,
    group:CourseKindGroup.Athletics,
    kinds: [CourseKind.AthleticReg, CourseKind.Team, CourseKind.Game],
    label: 'Athletics',
    name: 'athletics',
    units: 'Registration fee',
    icon: 'Clipboard',
    order:0,
    registrationLayout:'row',
    allStudentDetails: false,
  }, 
  [CourseKind.AthleticReg]: {
    ...athletics,
    group:CourseKindGroup.Athletics,
    table:CourseKind.AthleticReg,    
    kinds: [CourseKind.AthleticReg, CourseKind.AthleticIndividualReg],
    label: 'Registration fees',
    name: 'registration-fees',
    icon: 'DollarSign',
    order:1,
    allStudentDetails: false,
    attendable: false,
    units: 'Registration fee',
    registrationLayout:'row',
    management: {
      info: true,
      enrollments: true,
      billing: true,
      attendance:false,
      messages:true,
      reviews:false,
      preview:true,
      options:false,
      customFields:true,
      calendar:false,
      addStudent: true,
    },
    fields: {
      courseDays:false,
      courseTimes:false,
      supplies: false,
      framework: false,
      grades:true,
      dismissal:false,
      dob:false,
      teamMaxCapacity: true,
      pricing: {
        season: {
          basic:true,
          installments:true,
          days:false
        },
        dropIn:false,
        recurring:false,
        usage:false
      },
    },
    defaults: {
      minCapacity:0,
      maxCapacity:50,
      teamMaxCapacity:30,
    },
    terms: {
      ...athletics.terms,
      participant: 'coach',
      family: 'coach',
      create: 'Create registration fee',
      delete: 'Delete registration fee',
      publish: 'Publish registration fee'
    },
  }, 
  [CourseKind.AthleticIndividualReg]: {
    ...athletics,
    group:CourseKindGroup.Athletics,
    table:CourseKind.AthleticReg,    
    kinds: [CourseKind.AthleticIndividualReg],
    label: 'Individual registration fees',
    icon: 'DollarSign',
    order:2,
    allStudentDetails: false,
    attendable: false,
    units: 'Registration fee',
    registrationLayout:'row',
    management: {
      info: true,
      enrollments: true,
      billing: true,
      attendance:false,
      messages:true,
      reviews:false,
      preview:true,
      options:false,
      customFields:true,
      calendar:false,
      addStudent: true,
    },
    fields: {
      courseDays:false,
      courseTimes:false,
      supplies: false,
      grades:true,
      dismissal:true,
      dob:true,
      framework: false,
      teamMaxCapacity: false,
      pricing: {
        season: {
          basic:true,
          installments:true,
          days:false
        },
        dropIn:false,
        recurring:false,
        usage:false
      }
    },
  },
  [CourseKind.Team]: {
    ...athletics,
    group:CourseKindGroup.Athletics,
    table:CourseKind.Team,
    kinds: [CourseKind.Team],
    label: 'Teams',
    units: 'Team',
    name: 'teams',
    icon: 'Users',
    order:3,
    allStudentDetails: false,
    attendable: false,
    registerable: false,
    sessionType: 'none', 
    terms: {
      ...athletics.terms,
      create: 'Create team',
      delete: 'Delete team',
      publish: 'Publish team'
    },
    management: {
      info: true,
      enrollments: true,
      billing: false,
      attendance:false,
      messages:true,
      reviews:false,
      preview:false,
      options:false,
      customFields:true,
      calendar:false,
      addStudent: true,
      move: true,
    },
    fields: {
      courseDays:false,
      courseTimes:false,
      enrollmentPeriod:false,
      supplies: true,
      framework: false,
      grades:true,
      dob:true,
      dismissal:true,
      teamMaxCapacity: false,
      pricing: {
        season: {
          basic:false,
          installments:false,
          days:false
        },
        dropIn:false,
        recurring:false,
        usage:false
      }
    },
    defaults: {
      minCapacity:0,
      maxCapacity:50
    },
  }, 
  [CourseKind.Game]: {
    ...athletics,
    group:CourseKindGroup.Athletics,
    table:CourseKind.Game,
    kinds: [CourseKind.Game],
    label: 'Games',
    units: 'Game',
    name: 'games',
    icon: 'Award',
    seasonDates: false,
    order:4,
    allStudentDetails: false,
    registerable: false,
    terms: {
      ...athletics.terms,
      create: 'Create game',
      delete: 'Delete game',
      publish: 'Publish game'
    },
    management: {
      info: false,
      enrollments: false,
      billing: false,
      attendance:false,
      messages:false,
      reviews:false,
      preview:false,
      options:false,
      customFields:false,
      calendar:false,
      addStudent: false,
    },
    fields: {
      courseDays:false,
      courseTimes:true,
      enrollmentPeriod: false,
      supplies: true,
      framework: false,
      grades:true,
      dob:true,
      dismissal:true,
      teamMaxCapacity:false,
      pricing: {
        season: {
          basic:false,
          installments:false,
          days:false
        },
        dropIn:false,
        recurring:false,
        usage:false
      }
    },
  }, 
} as Record<CourseKindGroup | CourseKind, CourseKindBehavior>

// this is to avoid null errors while things are loading
courseKindBehavior[undefined as CourseKindGroup] = courseKindBehavior[CourseKindGroup.Enrichment];
courseKindBehavior[null as CourseKindGroup] = courseKindBehavior[CourseKindGroup.Enrichment];
courseKindBehavior['' as CourseKindGroup] = courseKindBehavior[CourseKindGroup.Enrichment];

export function showAllStudentDetails(courseKinds:(CourseKind | CourseKindGroup)[]) {
  const seasonBehaviors = courseKinds?.map(kind => courseKindBehavior[kind]) || [courseKindBehavior[CourseKindGroup.Enrichment]];
  const allStudentDetails = seasonBehaviors.find(b => b.allStudentDetails);
  return allStudentDetails;
}

export const courseKindGroup:Record<CourseKind, CourseKindGroup> = {
  [CourseKind.AthleticIndividualReg]: CourseKindGroup.Athletics,
  [CourseKind.AthleticReg]: CourseKindGroup.Athletics,
  [CourseKind.Team]: CourseKindGroup.Athletics,
  [CourseKind.Game]: CourseKindGroup.Athletics,
  [CourseKind.Camp]: CourseKindGroup.Camp,
  [CourseKind.Course]: CourseKindGroup.Enrichment,
  [CourseKind.Enrichment]: CourseKindGroup.Enrichment,
  [CourseKind.ExtendedCare]: CourseKindGroup.ExtendedCare,
  [CourseKind.LessonSet]: CourseKindGroup.LessonSet,
  [CourseKind.TimeSlot]: CourseKindGroup.LessonSet,
  [CourseKind.Variant]: CourseKindGroup.LessonSet,
};

// map kinds to groups
Object.keys(courseKindGroup).forEach(kind => courseKindBehavior[kind as CourseKind] = courseKindBehavior[kind as CourseKind] || courseKindBehavior[courseKindGroup[kind as CourseKind]]);

// mark pricing none if all pieces are none
Object.keys(courseKindBehavior).forEach(kind => {
  const pricing = courseKindBehavior[kind as CourseKind].fields.pricing;

  const allNone = [pricing?.season?.basic, pricing?.season?.days, pricing?.season?.installments, pricing?.dropIn, pricing?.recurring, pricing?.usage].every(p => p === false);

  if (allNone) {
    pricing.none = true;
  }
});

export function courseTerms(courseKind:CourseKind) {
  return courseKindBehavior[courseKind].terms;
}
